import { validateWorkspaceId, validateWorkspaceName } from 'util/workspace';

import { KN_WORKSPACE } from 'util/defines';
import { WorkspaceIdQuery } from 'util/workspace';
import { addUserWorkspace } from 'util/cache/user';
import gql from 'graphql-tag';
import { updateSelection } from 'util/selectionUtils';
import { validateServiceId } from 'util/service';

const DEFAULT_THUMBNAIL_URL = process.env.PUBLIC_URL + '/thumbs/workspace.jpg';

const AddWorkspaceMutation = gql`
  mutation AddWorkspace($input: AddWorkspaceInput!) {
    addWorkspace(input: $input)
  }
`;

const CloneWorkspaceMutation = gql`
  mutation CloneWorkspace(
    $userId: ID!
    $workspaceId: ID!
    $newName: String
    $newWorkspaceServiceId: ID
  ) {
    cloneWorkspace(
      userId: $userId
      workspaceId: $workspaceId
      newName: $newName
      newWorkspaceServiceId: $newWorkspaceServiceId
    )
  }
`;

/**
 * Validates a name and workspace service ID for creating a new workspace or
 * cloning an existing one.
 *
 * @param {string} name Name for the new workspace
 * @param {string} workspaceId Id for the new workspace
 * @param {string} workspaceServiceId ID for the new workspace service
 * @param {Object} client Apollo Client
 */
async function validateCreateWorkspace({
  name,
  workspaceId,
  workspaceServiceId,
  client
}) {
  const nameMessage = validateWorkspaceName(name);
  const errorMessages = {};
  if (nameMessage) {
    errorMessages['name'] = nameMessage;
  }

  const workspaceIdMessage = await validateWorkspaceId(workspaceId, client);
  if (workspaceIdMessage) {
    errorMessages['workspaceId'] = workspaceIdMessage;
  }

  const serviceIdMessage = await validateServiceId(workspaceServiceId, client);
  if (serviceIdMessage) {
    errorMessages['workspaceServiceId'] = serviceIdMessage;
  }

  return {
    isValid: Object.keys(errorMessages).length === 0,
    validationErrors: errorMessages
  };
}

/**
 * Creates a new workspace.
 *
 * @param {string} name Name for the new workspace
 * @param {string} workspaceId Id for the new workspace
 * @param {string} workspaceServiceId ID for the new workspace service
 * @param {string} userId ID of the user performing the action
 * @param {Object} client Apollo Client
 * @returns {Object} An object containing isValid, validationErrors,
 *      isCreated, createdWorkspaceId, error
 */
export async function createWorkspace({
  name,
  workspaceId,
  workspaceServiceId,
  userId,
  client
}) {
  const validationResult = await validateCreateWorkspace({
    name,
    workspaceId,
    workspaceServiceId,
    client
  });

  if (!validationResult.isValid) {
    return validationResult;
  } else {
    try {
      // Call the create mutation
      const { data, errors } = await client.mutate({
        mutation: AddWorkspaceMutation,
        variables: {
          input: {
            name: name.trim(),
            id: workspaceId,
            workspaceServiceId,
            userId,
            thumbnailUrl: DEFAULT_THUMBNAIL_URL
          }
        },
        update: (store, { data }) => {
          const addWorkspaceId = data?.addWorkspace;
          if (addWorkspaceId) {
            addUserWorkspace(store, addWorkspaceId);
            updateSelection({
              workspaceId: addWorkspaceId,
              client: store,
              selectedInstances: { id: addWorkspaceId, kindName: KN_WORKSPACE }
            });

            // Update workspace query in the cache in case it has been stored as null.
            store.writeQuery({
              query: WorkspaceIdQuery,
              variables: { id: data.addService },
              data: {
                workspace: {
                  id: addWorkspaceId,
                  __typename: KN_WORKSPACE
                }
              }
            });
          }
        }
      });

      // Throw any GraphQL errors that the server returned
      if (errors) {
        throw new Error(
          errors.reduce(
            (acc, cur) => acc.concat(acc.length ? ', ' : '', cur.message),
            ''
          )
        );
      }

      return {
        ...validationResult,
        isCreated: true,
        createdWorkspaceId: data.addWorkspace
      };
    } catch (error) {
      return { ...validationResult, isCreated: false, error };
    }
  }
}

/**
 * Clones an existing workspace.
 *
 * @param {string} workspaceId ID of the workspace to be cloned
 * @param {string} newName Name for the new workspace
 * @param {string} newWorkspaceServiceId ID for the new workspace service
 * @param {string} userId ID of the user performing the action
 * @param {Object} client Apollo Client
 * @returns {Object} An object containing isValid, validationErrors,
 *      isCreated, createdWorkspaceId, error
 */
export async function cloneWorkspace(
  workspaceId,
  newName,
  newWorkspaceServiceId,
  userId,
  client
) {
  const validationResult = await validateCreateWorkspace({
    name: newName,
    workspaceServiceId: newWorkspaceServiceId,
    client
  });

  if (!validationResult.isValid) {
    return validationResult;
  } else {
    try {
      // Call the clone mutation
      const { data, errors } = await client.mutate({
        mutation: CloneWorkspaceMutation,
        variables: {
          userId,
          workspaceId,
          newName: newName.trim(),
          newWorkspaceServiceId
        }
      });

      const cloneWorkspaceId = data && data.cloneWorkspace;
      if (cloneWorkspaceId) {
        addUserWorkspace(client, cloneWorkspaceId);
        updateSelection({
          workspaceId: cloneWorkspaceId,
          client,
          selectedInstances: { id: cloneWorkspaceId, kindName: KN_WORKSPACE }
        });
      }

      // Throw any GraphQL errors that the server returned
      if (errors) {
        throw new Error(
          errors.reduce(
            (acc, cur) => acc.concat(acc.length ? ', ' : '', cur.message),
            ''
          )
        );
      }

      return {
        ...validationResult,
        isCreated: true,
        createdWorkspaceId: data.cloneWorkspace
      };
    } catch (error) {
      return { ...validationResult, isCreated: false, error };
    }
  }
}
