import { arrayToMap } from 'util/helpers';
import { isEqual } from 'lodash';
import { omitTypename } from './omitTypename';

function diffLocks(oldArr, newArr) {
  const oldState = arrayToMap(oldArr);

  // Filter down to only existing items that have had their locks change.
  return newArr.filter(o => {
    if (!oldState[o.id]) return false;

    const newObj = omitTypename(o);
    const oldObj = omitTypename(oldState[o.id]);

    return !isEqual(newObj, oldObj);
  });
}

/**
 * Computes diff on locks on Workspaces, Graphs, and Functions.
 *
 * @param {Object} oldLocks An old locks object.
 * @param {Object} newLocks A new locks object.
 * @return {Object} A lock object that only contains the locks that have changed.
 */
export function locksDiff(oldLocks, newLocks) {
  const {
    workspaces: oldWorkspaces,
    knowledgeGraphs: oldGraphs,
    functions: oldFunctions
  } = oldLocks;
  const {
    workspaces: newWorkspaces,
    knowledgeGraphs: newGraphs,
    functions: newFunctions
  } = newLocks;

  const diff = {};

  const workspaces = diffLocks(oldWorkspaces, newWorkspaces);
  const graphs = diffLocks(oldGraphs, newGraphs);
  const functions = diffLocks(oldFunctions, newFunctions);

  // Only give the object the key if it has a diff.
  // This allows for valid empty check in calling functions.
  if (workspaces.length) diff.workspaces = workspaces;
  if (graphs.length) diff.knowledgeGraphs = graphs;
  if (functions.length) diff.functions = functions;

  return diff;
}
