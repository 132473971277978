import { KN_FUNCTION_EXECUTION, KN_FUNCTION_RESULTS } from 'util/defines';
import {
  SnackNotificationDefaults,
  SnackNotificationQueryResolver
} from 'util/snackUtils';

import { GraphNodeStateQueryResolver } from 'util/graph';
import { SelectionQueryResolver } from 'util/selectionUtils';
import UserContext from 'util/UserContext';

// Defaults are needed for any client-side object written to Apollo
export const workspaceDefaults = {
  functionResults: { results: [], __typename: KN_FUNCTION_RESULTS },
  functionExecution: {
    arguments: [],
    __typename: KN_FUNCTION_EXECUTION
  },
  subscribeToLinks:
    !!UserContext.getSubscribeToLinks() &&
    // Some local storage implementations store booleans as Strings, hence this additional check
    UserContext.getSubscribeToLinks().toString() === 'true',
  ...SnackNotificationDefaults
};

export default {
  Query: {
    ...SnackNotificationQueryResolver,
    ...SelectionQueryResolver,
    ...GraphNodeStateQueryResolver
  },
  Mutation: {}
};
