import { useCallback, useState } from 'react';

/**
 * Custom hook to handle an basic opening and closing of a dialog.
 *
 * @returns {Object} Variables needed to set up the props for dealing with
 *  a dialog.
 *    isDialogOpen Indicates if the dialog is open
 *    onDialogOpen Handles opening dialog
 *    onDialogClose Handles closing the dialog
 */
export function useDialog() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onDialogOpen = useCallback(
    event => {
      event && event.stopPropagation(); //prevent selection
      setIsDialogOpen(true);
    },
    [setIsDialogOpen]
  );

  const onDialogClose = useCallback(
    event => {
      event && event.stopPropagation(); //prevent selection
      setIsDialogOpen(false);
    },
    [setIsDialogOpen]
  );

  return {
    isDialogOpen,
    onDialogOpen,
    onDialogClose
  };
}
