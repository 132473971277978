import React from 'react';
import { SvgIcon } from '@material-ui/core';

/**
 * Renders an SVG Icon in the shape of a lambda symbol.  The SVG path was
 * generated with https://www.npmjs.com/package/text-to-svg.
 *
 * @param {Object} props The properties to pass to SvgIcon.
 */
export default function LambdaIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M13.17 11.26L7.22 19.90L4.63 19.90Q9.47 13.48 13.03 8.43L13.03 8.43Q13.00 6.25 12.67 5.34L12.67 5.34Q12.28 4.31 11.18 4.31L11.18 4.31Q9.71 4.31 7.92 6.48L7.92 6.48L6.81 4.88Q9.50 2.61 11.34 2.61L11.34 2.61Q13.90 2.61 14.61 5.09L14.61 5.09Q14.87 6.04 14.87 8.11L14.87 8.11Q14.87 11.88 15.46 14.11L15.46 14.11Q16.18 16.92 18.19 19.90L18.19 19.90L15.69 19.90Q13.75 16.11 13.17 11.26L13.17 11.26Z" />
    </SvgIcon>
  );
}
