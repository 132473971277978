import { KN_PORTAL_GRAPH, KN_WORKSPACE, LockedByType } from 'util/defines';
import { WorkspaceContext, useApolloClient } from 'components/context';
import {
  getLockMutationInput,
  getLockQueryMutationData,
  getLockedState,
  mergeLockData
} from 'util/permissions';
import { useCallback, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { handleError } from 'util/snackUtils';

/**
 * Custom hook to get the user who locked a Workspace, whether the current
 * user can edit the Workspace, and provides a function for updating the locked by user.
 *
 * @param {Object} options Optional parameters.
 * @param {string} options.workspaceId The ID of the Workspace. If not specified,
 * uses the ID of the active Workspace.
 * @param {Object} options.queryOptions Apollo query option object
 * that is passed to the query.
 * @param {Object} options.mutationOptions Apollo mutation option object
 * that is passed to the mutation.
 * @returns {Object} An object containing the following properties:
 *    {string} lockedBy The user locking the Workspace
 *    {boolean} canEdit True if the current user can edit the Workspace
 *    {function} toggleLockedBy A function to call that toggles the locked state
 *    of the Workspace between unlocked and locked by the current user.
 *    {error} toggleLockedByError If the mutation that tries to change the lockedBy
 *    field on the Workspace errors, the error is returned in this field.
 *    {Object} Icon Returns the appropriate lock icon to display.
 */
export function useWorkspaceLockedBy(options = {}) {
  const { workspaceId } = options;
  return useLockedBy({ workspaceId }, options);
}

/**
 * Custom hook to get the user who locked a portal graph, whether the current
 * user can edit the graph, and provides a function for updating the locked by user.
 * This also checks to see if the graph should act as locked because the workspace
 * is locked by another user.
 *
 * @param {string} graphId The ID of the graph.
 * @param {Object} options Optional parameters.
 * @param {string} options.workspaceId The ID of the Workspace. If not specified,
 * uses the ID of the active Workspace.
 * @param {Object} options.queryOptions Apollo query option object
 * that is passed to the query.
 * @param {Object} options.mutationOptions Apollo mutation option object
 * that is passed to the mutation.
 * @returns {Object} An object containing the following properties:
 *    {string} lockedBy The user locking the graph
 *    {boolean} canEdit True if the current user can edit the graph
 *    {function} toggleLockedBy A function to call that toggles the locked state
 *    of the graph between unlocked and locked by the current user.
 *    {error} toggleLockedByError If the mutation that tries to change the lockedBy
 *    field on the graph errors, the error is returned in this field.
 *    {Object} Icon Returns the appropriate lock icon to display.
 */
export function useGraphLockedBy(graphId, options = {}) {
  const graphLockData = useLockedBy({ graphId }, options);
  const workspaceLockData = useLockedBy(options);
  return mergeLockData(graphLockData, workspaceLockData);
}

/**
 * Custom hook to get the user who locked a portal graph or Workspace, whether the current
 * user can edit the graph or Workspace, and provides a function for updating the locked by user.
 *
 * @param {Object} ids The IDs object. Only one ID should be specified.
 * @param {string} ids.graphId The ID of the graph.
 * @param {string} ids.workspaceId The ID of the Workspace.
 * @param {Object} options Optional parameters.
 * @param {Object} options.queryOptions Apollo query option object
 * that is passed to the query.
 * @param {Object} options.mutationOptions Apollo mutation option object
 * that is passed to the mutation.
 * @returns {Object} An object containing the following properties:
 *    {string} lockedBy The user locking the graph or Workspace
 *    {boolean} canEdit True if the current user can edit the graph or Workspace
 *    {function} toggleLockedBy A function to call that toggles the locked state
 *    of the graph or Workspace between unlocked and locked by the current user.
 *    {error} toggleLockedByError If the mutation that tries to change the lockedBy
 *    field on the graph or Workspace errors, the error is returned in this field.
 *    {Object} Icon Returns the appropriate lock icon to display.
 */
function useLockedBy({ graphId, workspaceId }, options = {}) {
  const { queryOptions = {}, mutationOptions = {} } = options;
  const { workspaceId: activeWorkspaceId } = useContext(WorkspaceContext) ?? {};

  const {
    lockedByType,
    id,
    query,
    mutation,
    errorMessage
  } = getLockQueryMutationData(graphId, workspaceId ?? activeWorkspaceId);

  const { data: lockedByData } = useQuery(query, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-only',
    ...queryOptions
  });
  const client = useApolloClient();

  const { canEdit, lockedBy, updatedLockedBy, Icon } = getLockedState(
    lockedByData?.portalGraph ?? lockedByData?.workspace
  );

  let optimisticResponse = getOptimisticResponse(
    id,
    lockedByType,
    updatedLockedBy
  );

  const [updateLockedBy, { error: toggleLockedByError }] = useMutation(
    mutation,
    {
      optimisticResponse,
      onError: error => {
        handleError(client, errorMessage, error);
      },
      ...mutationOptions
    }
  );

  const toggleLockedBy = useCallback(() => {
    updateLockedBy({
      variables: {
        input: getLockMutationInput(
          id,
          updatedLockedBy,
          lockedByType,
          activeWorkspaceId
        )
      }
    });
  }, [updatedLockedBy, lockedByType, updateLockedBy, activeWorkspaceId, id]);

  return {
    lockedBy,
    canEdit,
    toggleLockedBy,
    toggleLockedByError,
    Icon
  };
}

function getOptimisticResponse(id, lockedByType, updatedLockedBy) {
  if (lockedByType === LockedByType.GRAPH) {
    return {
      updateGraph: {
        updatedGraph: {
          id,
          lockedBy: updatedLockedBy,
          __typename: KN_PORTAL_GRAPH
        },
        __typename: 'UpdateGraphOutput'
      }
    };
  } else {
    return {
      updateWorkspace: {
        updatedWorkspace: {
          id,
          lockedBy: updatedLockedBy,
          __typename: KN_WORKSPACE
        },
        __typename: 'UpdateWorkspaceOutput'
      }
    };
  }
}
