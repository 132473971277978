import { AssistantServiceQuery, AssistantServicesFragment } from './graphql';
import {
  KN_SERVICE,
  SERVICE_THUMBNAIL_URL,
  ServiceCategory
} from 'util/defines';
import {
  addService,
  addServiceToInventory,
  refreshServiceSchemaAndInventory,
  reloadServiceSchemaAndInventory
} from 'util/service';

import deleteServiceById from 'util/deleteService';
import { getFunctionsByService } from './function';
import { getGraphObject } from './graph';
import { getKindsByService } from './kind';
import { getServiceUrl } from 'util/helpers';
import { getWorkspace } from './workspace';
import gql from 'graphql-tag';
import removeFromWorkspace from 'util/removeFromWorkspace';

const AssistantServiceParentServiceQuery = gql`
  query AssistantServiceParentService($aggregates: [ID]) {
    servicesFiltered(
      serviceType: WORKSPACE
      aggregates: $aggregates
      isDeleted: false
    ) {
      ...AssistantServices
    }
  }
  ${AssistantServicesFragment}
`;

/**
 * Returns a service matching the supplied ID.
 *
 * @param {string} id the ID of the service to be retrieved.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Service} An object representing the service.
 */
export async function getServiceById(
  id,
  diagramEngine,
  getGraphDimensions,
  client
) {
  try {
    const { data, errors } = await client.query({
      query: AssistantServiceQuery,
      variables: { id }
    });

    if (errors) throw Error(`Error/s in client query: ${errors}`);

    // Augment returned service with function/kind queries.
    // Service objects sanitize their output.
    if (data?.service) {
      return getServiceObject(
        data.service,
        diagramEngine,
        getGraphDimensions,
        client
      );
    } else {
      return null;
    }
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API error in getServiceById: ${ex.message}`)
    );
  }
}

/**
 * Gets a service based on id, name, serviceType and endpointUrl properties of an input object.
 * Helper : Not exposed via listener.
 *
 * @param {Object} service
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Service} A composite object.
 */
export function getServiceObject(
  service,
  diagramEngine,
  getGraphDimensions,
  client
) {
  const {
    id,
    name,
    description,
    serviceType,
    endpointUrl,
    aggregatedServices,
    isDeleted,
    workspace
  } = service;

  // Don't send over deleted services
  if (isDeleted) return null;

  // Don't send over assistants
  if (serviceType === ServiceCategory.ASSISTANT) return null;

  return {
    id,
    name,
    description,
    endpointUrl: getServiceUrl(id, endpointUrl),
    aggregatedServices: aggregatedServices
      ? aggregatedServices.map(a => a.id)
      : [],
    getKinds: () => getKindsByService(id, client),
    getFunctions: () => getFunctionsByService(id, client),
    getWorkspace: () => {
      if (!workspace?.id || serviceType === ServiceCategory.EXTERNAL) {
        return null;
      }

      return getWorkspace(
        workspace.id,
        diagramEngine,
        getGraphDimensions,
        client
      );
    },

    /**
     * Return the parent service of a model or logic service.  This is only
     * needed when you have a kind or function that is from a workspace, and
     * you need access to the workspace service that it is part of.
     *
     * @deprecated This functionality will be removed in 3.3.0, as it will no
     * longer be needed.
     *
     * @return {Service|null} The parent service if the current service is a
     * logic or model service and there is a service that aggregates it.
     */
    getParentService: async () => {
      if (
        serviceType !== ServiceCategory.MODEL &&
        serviceType !== ServiceCategory.LOGIC
      ) {
        return null;
      }

      const results = await client.query({
        query: AssistantServiceParentServiceQuery,
        variables: {
          aggregates: [id]
        }
      });

      const parentService = results?.data?.servicesFiltered.find(ps =>
        ps?.aggregatedServices?.find(as => as.id === id)
      );

      return parentService
        ? getServiceObject(parentService, diagramEngine, getGraphObject, client)
        : null;
    }
  };
}

/**
 * Gets an assistant based on id, name, serviceType and endpointUrl properties
 * of an input object.
 *
 * @param {Service} input The information about the assistants
 * @return {Object} A composite object with the assistants id, name, description, and endpointUrl.
 */
export function getAssistantObject({
  id,
  name,
  description,
  serviceType,
  endpointUrl
}) {
  // Only send over assistants
  if (serviceType !== ServiceCategory.ASSISTANT) return null;

  return {
    id,
    name,
    description,
    endpointUrl: getServiceUrl(id, endpointUrl)
  };
}

/**
 * Imports a service into a workspace based on the serviceId.
 *
 * @param {string} serviceId The ID of the service to be imported.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {string} The ID of the service imported into workspace.
 */
export async function importService(serviceId, workspaceId, client) {
  try {
    const { data, errors } =
      (await addServiceToInventory(serviceId, workspaceId, client)) || {};

    if (errors) {
      throw new Error(
        `addServiceToInventory failed: ${JSON.stringify(errors)}`
      );
    }

    if (data && data.addServiceToWorkspace) {
      return data.addServiceToWorkspace.id;
    } else {
      return null;
    }
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API error in importService: ${ex.message}`)
    );
  }
}

/**
 * Imports a list of services into a workspace based on the serviceId.
 *
 * @param {Array<string>} serviceIds The IDs of the services to be imported.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Array<string>} The IDs of the services imported into workspace.
 */
export async function importServices(serviceIds, workspaceId, client) {
  const errors = [];
  const services = [];
  for (const sid of serviceIds) {
    try {
      services.push(await importService(sid, workspaceId, client));
    } catch (ex) {
      errors.push(ex);
    }
  }

  if (errors.length) {
    return Promise.reject(errors);
  } else {
    return services.filter(Boolean);
  }
}

/**
 * Refreshes a services schema.
 *
 * @param {string} serviceId The service id
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {ApolloClient} client The client used to access the Apollo.
 */
export async function refreshServiceSchemaForAssistant(
  serviceId,
  workspaceId,
  client
) {
  try {
    await refreshServiceSchemaAndInventory(workspaceId, serviceId, client);
  } catch (ex) {
    return Promise.reject(
      `Assistant API Error in 'refreshService': ${ex.message}`
    );
  }
}

/**
 * Reloads the service kinds and functions in inventory.
 *
 * @param {string} data The service id.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {ApolloClient} client The client used to access the Apollo.
 */
export async function reloadServiceSchemaForAssistant(
  serviceId,
  workspaceId,
  client
) {
  try {
    await reloadServiceSchemaAndInventory(workspaceId, serviceId, client);
  } catch (ex) {
    return Promise.reject(
      `Assistant API Error in 'reloadServiceItems': ${ex.message}`
    );
  }
}

/**
 * Adds a service to the service catalog.
 *
 * @param {Object} input The addService input object.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {string} The service ID for the added service.
 */
export async function createService(input, client) {
  try {
    const svc = await addService(
      {
        thumbnailUrl: SERVICE_THUMBNAIL_URL,
        ...input,
        isSystem: false
      },
      client
    );

    if (svc && svc.data && svc.data.addService) {
      return svc.data.addService;
    }
  } catch (ex) {
    return Promise.reject(`Assistant API Error in 'addService': ${ex.message}`);
  }
}

/**
 * Deletes a service.
 *
 * @param {string} serviceId The service Id.
 * @param {string} workspaceId  The workspace Id.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {string} The service ID for the deleted service.
 */
export async function deleteService(serviceId, workspaceId, client) {
  try {
    await deleteServiceById(serviceId, client);
    await removeFromWorkspace({
      items: [{ id: serviceId, kindName: KN_SERVICE }],
      workspaceId,
      client,
      cleanBoilerplate: false,
      cleanServiceChildren: true,
      cleanKindsFromFields: true
    });
    return serviceId;
  } catch (e) {
    return Promise.reject(
      new Error(`Assistant API Error in 'deleteService': ${e.message}`)
    );
  }
}
