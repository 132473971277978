import {
  AllNonSystemServicesQuery,
  FunctionDetailsFragment,
  KindDetailsFragment,
  ServiceDetailsFragment
} from 'graphql/Portal';
import {
  IdRegex,
  KN_FUNCTION,
  KN_KIND,
  KN_SERVICE,
  KN_WORKSPACE
} from 'util/defines';
import { isEmpty, pick, uniqBy } from 'lodash';

import Logger from './Logger';
import gql from 'graphql-tag';
import removeFromWorkspace from './removeFromWorkspace';
import { updateFunctionInstance } from './function';

const ServiceIdQuery = gql`
  query ServiceIdQuery($id: ID!) {
    service(id: $id) {
      id
      isDeleted
    }
  }
`;

const AddServiceMutation = gql`
  mutation AddService($input: AddServiceInput!) {
    addService(input: $input)
  }
`;

const AddServiceToWorkspaceMutation = gql`
  mutation AddServiceToWorkspace($workspaceId: ID!, $serviceId: ID!) {
    addServiceToWorkspace(workspaceId: $workspaceId, serviceId: $serviceId) {
      id
      name
      description
      serviceType
      isSystem
      endpointUrl
      aggregatedServices {
        id
      }
      kinds {
        id
        name
        description
        isGenerated
        serviceId
        service {
          id
          name
        }
      }
      functions {
        id
        name
        description
        isGenerated
        functionType
        service {
          id
          name
        }
      }
    }
  }
`;

const AddServiceWorkspaceFragment = gql`
  fragment WorkspaceAddService on Workspace {
    id
    services {
      id
    }
  }
`;

const RefreshServiceSchemaMutation = gql`
  mutation RefreshServiceSchema($id: ID!) {
    refreshServiceSchema(id: $id) {
      id
      aggregatedServices {
        id
      }
      ...ServiceDetails
    }
  }
  ${ServiceDetailsFragment}
`;

const ServiceUpdateInventoryFragment = gql`
  fragment ServiceUpdateInventory on Workspace {
    inventory {
      serviceKinds {
        id
        service {
          id
        }
      }
      functions {
        id
        service {
          id
        }
      }
    }
  }
`;

const ReloadServiceKindsAndFunctionsQuery = gql`
  query AssistantServiceKindsAndFunctions($id: ID!) {
    service(id: $id) {
      id
      aggregatedServices {
        id
      }
      kinds {
        ...KindDetails
      }
      functions {
        ...FunctionDetails
        description
      }
    }
  }
  ${KindDetailsFragment}
  ${FunctionDetailsFragment}
`;

const ServiceFunctionsFragment = gql`
  fragment ServiceFunctions on Service {
    id
    functions {
      id
    }
  }
`;

const ServiceKindsFragment = gql`
  fragment ServiceKinds on Service {
    id
    kinds {
      id
    }
  }
`;

const WorkspaceServiceIdQuery = gql`
  query WorkspaceServiceId($id: ID!) {
    workspace(id: $id) {
      id
      workspaceServiceId
    }
  }
`;

const PICK_PARAMS_ID_TYPENAME = ['id', '__typename'];

/**
 * Check to see if the given service ID already exists.
 *
 * @param {String} id Service ID to check for
 * @param {Object} client Apollo Client.
 */
export const isExistingServiceId = async (id, client) => {
  return await client
    .query({
      query: ServiceIdQuery,
      variables: { id },
      fetchPolicy: 'no-cache'
    })
    .then(({ data }) => {
      return data && data.service && data.service.id && !data.service.isDeleted;
    });
};

/**
 * Validate service ID, if provided. Check that is contains only allowed
 * characters and does not already exist.
 *
 * @param {String} id Service ID to validate
 * @param {Object} client Apollo Client.
 * @returns {String} Error message if not valid.
 */
export const validateServiceId = async (id, client) => {
  if (id) {
    if (!IdRegex.test(id)) {
      return 'Service ID must start with a letter or number, and can contain only numbers, letters, dashes, and periods.';
    }
    const res = await isExistingServiceId(id, client);
    if (res) {
      return 'Service ID already exists.';
    }
  }
  return null;
};

/**
 * Validates required fields on a service input object.
 *
 * @param {Object} data The service input object.
 * @return {Object} An object containing the validation errors.
 */
export const validateServiceFields = async (data, client) => {
  let errors = {};

  if (data.id) {
    const message = await validateServiceId(data.id, client);
    if (message) {
      errors['id'] = message;
    }
  }

  if (!data.endpointUrl) {
    errors['endpointUrl'] = 'Endpoint URL is required';
  }

  if (!data.name) {
    errors['name'] = 'Service name is required';
  }

  if (!data.serviceType) {
    errors['serviceType'] = 'Service Type is required';
  }

  return errors;
};

/**
 * Adds a non-system service to the catalog.
 *
 * @param {Object} serviceData The service input.
 * @param {ApolloClient} client The apollo client instance.
 */
export const addService = async (serviceData, client) => {
  const errors = await validateServiceFields(serviceData, client);
  if (!isEmpty(errors)) {
    throw new Error(`Error adding service: ${JSON.stringify(errors)}`);
  }
  return await client.mutate({
    mutation: AddServiceMutation,
    variables: {
      input: serviceData
    },
    update: (store, { data }) => {
      if (!data.addService) return;

      try {
        const {
          name,
          description,
          thumbnailUrl,
          endpointUrl,
          isSystem,
          serviceType
        } = serviceData;

        let serviceStoreData = {
          id: data.addService,
          name,
          description,
          serviceType,
          thumbnailUrl,
          endpointUrl,
          isSystem,
          isReadOnly: false,
          __typename: KN_SERVICE
        };

        let { allNonSystemServices } = store.readQuery({
          query: AllNonSystemServicesQuery,
          variables: { take: -1 }
        });
        allNonSystemServices.push(serviceStoreData);
        store.writeQuery({
          query: AllNonSystemServicesQuery,
          variables: { take: -1 },
          data: { allNonSystemServices }
        });
      } catch {
        // Nothing to do here,  when this throws it just means that there
        // is no data in the cache to update, and all is good.  The try/catch
        // is needed to keep an error from showing up in the console.
      }

      // Update service query in the cache in case it has been stored as null.
      store.writeQuery({
        query: ServiceIdQuery,
        variables: { id: data.addService },
        data: {
          service: {
            id: data.addService,
            isDeleted: false,
            __typename: KN_SERVICE
          }
        }
      });
    }
  });
};

/**
 * Adds the Workspace Service from one workspace to another.
 *
 * @param {string} workspaceToAddId ID of the workspace to get the service ID from.
 * @param {string} targetWorkspaceId ID of the workspace to add the service to.
 * @param {ApolloClient} client The client used to access graphql.
 */
export async function addServiceFromWorkspaceToInventory(
  workspaceToAddId,
  targetWorkspaceId,
  client
) {
  const { data } = await client.query({
    query: WorkspaceServiceIdQuery,
    variables: { id: workspaceToAddId }
  });

  const { workspaceServiceId } = data?.workspace ?? {};
  if (!workspaceServiceId) {
    throw new Error(
      `Failed to load information about Workspace ${workspaceToAddId}`
    );
  }

  await addServiceToInventory(workspaceServiceId, targetWorkspaceId, client);
}

/**
 * Adds a service to the workspace inventory.
 *
 * @param {string} serviceId The service ID.
 * @param {string} workspaceId The workspace ID.
 * @param {ApolloClient} client The apollo client instance.
 * @return {Promise<Service>} A promise that resolves to the service object added to the inventory.
 */
export const addServiceToInventory = async (serviceId, workspaceId, client) => {
  const workspaceFragmentId = `${KN_WORKSPACE}:${workspaceId}`;

  return client.mutate({
    mutation: AddServiceToWorkspaceMutation,
    variables: {
      workspaceId,
      serviceId
    },
    update: (store, { data }) => {
      if (data.addServiceToWorkspace) {
        try {
          // Add the service to the workspace's list of services
          const wsServices = store.readFragment({
            id: workspaceFragmentId,
            fragment: AddServiceWorkspaceFragment
          });

          wsServices.services = uniqBy(
            wsServices.services.concat([
              pick(data.addServiceToWorkspace, PICK_PARAMS_ID_TYPENAME)
            ]),
            s => s.id
          );

          store.writeFragment({
            id: workspaceFragmentId,
            fragment: AddServiceWorkspaceFragment,
            data: wsServices
          });

          // Add the kinds and functions to the workspace's inventory
          const serviceIds = [
            serviceId,
            ...data.addServiceToWorkspace.aggregatedServices?.map(s => s.id)
          ];
          const kinds = data.addServiceToWorkspace.kinds ?? [];
          const functions = data.addServiceToWorkspace.functions ?? [];
          functions.map(f => updateFunctionInstance(f, client));

          addServiceChildrenToInventory(
            workspaceId,
            serviceIds,
            kinds,
            functions,
            client
          );
        } catch (e) {
          // The error being thrown here means that the cache is being updated
          // before the Workspace inventory information is in the cache.  This
          // happens when the Assistant API is importing a service to a
          // Workspace that is not visible in the UI
          Logger.debug(
            `Failed to add Service ${serviceId} to Workspace ${workspaceId} with error`,
            e
          );
        }
      }
    }
  });
};

/**
 * Performs a schema refresh of the specified service.
 *
 * @param {string} serviceId The ID of the service to be refreshed.
 * @param {ApolloClient} client The apollo client instance.
 * @return {Object} The refreshed service schema.
 */
export const refreshServiceSchema = async (serviceId, client) => {
  return client.mutate({
    mutation: RefreshServiceSchemaMutation,
    variables: { id: serviceId }
  });
};

/**
 * Performs a schema refresh of the specified service and then adds its
 * child kinds and functions to the workspace inventory.
 *
 * @param {string} workspaceId The workspace Id.
 * @param {string} serviceId The service Id.
 * @param {ApolloClient} client The apollo client to access the store through
 */
export async function refreshServiceSchemaAndInventory(
  workspaceId,
  serviceId,
  client
) {
  const { data } = (await refreshServiceSchema(serviceId, client)) || {};

  if (data && data.refreshServiceSchema) {
    const serviceIds = [
      serviceId,
      ...data.refreshServiceSchema.aggregatedServices?.map(s => s.id)
    ];
    const kinds = data.refreshServiceSchema.kinds ?? [];
    const functions = data.refreshServiceSchema.functions ?? [];
    functions.map(f => updateFunctionInstance(f, client));

    await cleanServiceChildrenFromWorkspace(
      workspaceId,
      serviceIds,
      kinds.map(k => k.id),
      functions.map(f => f.id),
      client
    );

    addServiceChildrenToInventory(
      workspaceId,
      serviceIds,
      kinds,
      functions,
      client
    );
  }
}

/**
 * Handles requerying for a service's child kinds and functions and then
 * adding them to the workspace inventory.
 *
 * @param {string} workspaceId The workspace Id.
 * @param {string} serviceId The service Id.
 * @param {ApolloClient} client The apollo client to access the store through
 */
export async function reloadServiceSchemaAndInventory(
  workspaceId,
  serviceId,
  client
) {
  // Network call for fresh data.
  const { data } =
    (await client.query({
      query: ReloadServiceKindsAndFunctionsQuery,
      variables: { id: serviceId },
      fetchPolicy: 'network-only'
    })) || {};

  if (data && data.service) {
    const serviceIds = [
      serviceId,
      ...data.service.aggregatedServices?.map(s => s.id)
    ];
    const kinds = data.service.kinds ?? [];
    const functions = data.service.functions ?? [];
    functions.map(f => updateFunctionInstance(f, client));

    await cleanServiceChildrenFromWorkspace(
      workspaceId,
      serviceIds,
      kinds.map(k => k.id),
      functions.map(f => f.id),
      client
    );

    addServiceChildrenToInventory(
      workspaceId,
      serviceIds,
      kinds,
      functions,
      client
    );
  }
}

/**
 * Removes from the workspace inventory a list of kinds and functions from a
 * selection of services that no longer exist.
 *
 * @param {string} workspaceId ID of the workspace to clean out.
 * @param {Array<string>} serviceIds IDs of the services to check against.
 * @param {Array<string>} kindIds IDs of the updated Kinds list.
 * @param {Array<string>} functionIds IDs of the updated Function list.
 * @param {ApolloClient} client The apollo client to access the store through.
 */
async function cleanServiceChildrenFromWorkspace(
  workspaceId,
  serviceIds,
  kindIds,
  functionIds,
  client
) {
  try {
    // Get the inventory
    const workspace = client.readFragment({
      id: `${KN_WORKSPACE}:${workspaceId}`,
      fragment: ServiceUpdateInventoryFragment
    });
    const inventory = { ...workspace.inventory };

    const existingServiceKinds = inventory.serviceKinds.filter(k =>
      serviceIds.includes(k.service?.id)
    );
    const existingServiceFunctions = inventory.functions.filter(f =>
      serviceIds.includes(f.service?.id)
    );

    const deletedKinds = existingServiceKinds.filter(
      k => !kindIds.includes(k.id)
    );
    const deletedFunctions = existingServiceFunctions.filter(
      f => !functionIds.includes(f.id)
    );

    // If there are kinds or functions that have been removed from the service,
    // then make sure to remove their uses from within the workspace.
    if (!(isEmpty(deletedKinds) && isEmpty(deletedFunctions))) {
      await removeFromWorkspace({
        items: deletedKinds
          .map(k => ({ id: k.id, kindName: KN_KIND }))
          .concat(
            deletedFunctions.map(f => ({ id: f.id, kindName: KN_FUNCTION }))
          ),
        workspaceId,
        cleanKindsFromFields: true,
        client
      });
    }
  } catch (e) {
    // The error being thrown here means that the cache is being read before the
    // Workspace inventory information is in the cache.  This happens when the
    // Assistant API is updating the children of a Workspace that is not visible
    // in the UI
    Logger.debug(
      `Failed to update children for Workspace ${workspaceId} with error`,
      e
    );
  }
}

/**
 * Handles adding a service's child kinds and functions to the workspace inventory.
 *
 * @param {string} workspaceId The workspace Id.
 * @param {Array<string>} serviceIds The service Id as well as its aggregated service Ids.
 * @param {Array<Object>} kinds Kinds to add to the inventory
 * @param {Array<Object>} functions Functions to add to the inventory
 * @param {ApolloClient} client The apollo client to access the store through
 * @return {Object} The persisted workspace inventory object.
 */
function addServiceChildrenToInventory(
  workspaceId,
  serviceIds,
  kinds,
  functions,
  client
) {
  try {
    // Get the inventory
    const workspace = client.readFragment({
      id: `${KN_WORKSPACE}:${workspaceId}`,
      fragment: ServiceUpdateInventoryFragment
    });
    const inventory = { ...workspace.inventory };

    // Add the kinds to the inventory
    let updatedKinds = inventory.serviceKinds
      .filter(k => !serviceIds.includes(k.service?.id))
      .concat(
        kinds.filter(Boolean).map(k => ({
          ...pick(k, PICK_PARAMS_ID_TYPENAME),
          service: pick(k.service, PICK_PARAMS_ID_TYPENAME)
        }))
      );
    inventory.serviceKinds = uniqBy(updatedKinds, k => k.id);

    // Add the functions to the inventory
    const funcs = inventory.functions
      .filter(f => !serviceIds.includes(f.service?.id))
      .concat(
        functions.filter(Boolean).map(f => ({
          ...pick(f, PICK_PARAMS_ID_TYPENAME),
          service: pick(f.service, PICK_PARAMS_ID_TYPENAME)
        }))
      );
    inventory.functions = uniqBy(funcs, f => f.id);

    // Save the updated inventory
    client.writeFragment({
      id: `${KN_WORKSPACE}:${workspaceId}`,
      fragment: ServiceUpdateInventoryFragment,
      data: { inventory, __typename: KN_WORKSPACE }
    });

    // Return workspace object with updated inventory.
    workspace.inventory = inventory;
    return workspace;
  } catch (e) {
    // The error being thrown here means that the cache is being updated before
    // the Workspace inventory information is in the cache.  This happens when
    // the Assistant API is updating the children ofa Workspace that is not
    // visible in the UI
    Logger.debug(
      `Failed to update children for Workspace ${workspaceId} with error`,
      e
    );
  }

  return null;
}

/**
 * Adds references to Kinds and Functions to services.
 *
 * @param {Array<string>} serviceIds IDs of the services to update
 * @param {string} kindIds IDs of the Kinds to add
 * @param {string} functionIds IDs of the Function to add
 * @param {Object} store Apollo store
 */
export function addKindsAndFunctionsToServices(
  serviceIds,
  kindIds,
  functionIds,
  store
) {
  addOrRemoveKindsAndFunctionsFromServices(
    serviceIds,
    kindIds,
    functionIds,
    true,
    store
  );
}

/**
 * Removes references to Kinds and Functions from services.
 *
 * @param {Array<string>} serviceIds IDs of the services to update
 * @param {string} kindIds IDs of the Kinds to remove
 * @param {string} functionIds IDs of the Function to remove
 * @param {Object} store Apollo store
 */
export function removeKindsAndFunctionsFromServices(
  serviceIds,
  kindIds,
  functionIds,
  store
) {
  addOrRemoveKindsAndFunctionsFromServices(
    serviceIds,
    kindIds,
    functionIds,
    false,
    store
  );
}

/**
 * Adds or removes references to Kinds and Functions from services.
 *
 * @param {Array<string>} serviceIds IDs of the services to update
 * @param {string} kindIds IDs of the Kinds to add or remove
 * @param {string} functionIds IDs of the Function to add or remove
 * @param {boolean} add True to add, false to remove
 * @param {Object} store Apollo store
 */
function addOrRemoveKindsAndFunctionsFromServices(
  serviceIds,
  kindIds,
  functionIds,
  add,
  store
) {
  if (isEmpty(functionIds) && isEmpty(kindIds)) return;

  for (const serviceId of serviceIds) {
    if (functionIds?.length) {
      try {
        const service = store.readFragment({
          id: `${KN_SERVICE}:${serviceId}`,
          fragment: ServiceFunctionsFragment
        });
        if (add) {
          service.functions = service.functions.concat(
            functionIds.map(id => ({ id, __typename: KN_FUNCTION }))
          );
        } else {
          service.functions = service.functions.filter(
            id => !functionIds.includes(id)
          );
        }

        store.writeFragment({
          id: `${KN_SERVICE}:${serviceId}`,
          fragment: ServiceFunctionsFragment,
          data: service
        });
      } catch (e) {
        // Ignore errors pulling from the cache. This just means one of the Services
        // isn't in cache and thus there is nothing to update.
      }
    }

    if (kindIds?.length) {
      try {
        const service = store.readFragment({
          id: `${KN_SERVICE}:${serviceId}`,
          fragment: ServiceKindsFragment
        });
        if (add) {
          service.kinds = service.kinds.concat(
            kindIds.map(id => ({ id, __typename: KN_FUNCTION }))
          );
        } else {
          service.kinds = service.kinds.filter(id => !kindIds.includes(id));
        }

        store.writeFragment({
          id: `${KN_SERVICE}:${serviceId}`,
          fragment: ServiceKindsFragment,
          data: service
        });
      } catch (e) {
        // Ignore errors pulling from the cache. This just means one of the Services
        // isn't in cache and thus there is nothing to update.
      }
    }
  }
}
