import {
  FieldModifiers,
  FieldNames,
  FieldTypes,
  KN_ASSISTANT,
  KN_FIELD_VALUE,
  KN_FUNCTION,
  KN_INSTANCE,
  KN_INSTANCE_REF,
  KN_SERVICE,
  KindUUID
} from './defines';
import { arrayToMap, getFunctionKindId, getServiceKindId } from 'util/helpers';

import { FieldValueDetailsFragment } from 'graphql/Portal';
import { KN_KIND } from './defines';
import Papa from 'papaparse';
import { buildInstanceRefId } from './helpers';
import { getKindByIdFromCache } from './kind';
import gql from 'graphql-tag';
import { isNil } from 'lodash';
import { updateRelatedEntityIds } from 'util/name';

const NodeWidgetInstanceRefFragmentName = 'NodeWidgetInstanceRef';
const NodeWidgetInstanceRefFragment = gql`
  fragment NodeWidgetInstanceRef on InstanceRef {
    id
    instance {
      id
      kindId
      fieldIds
      fieldValues {
        ...FieldValueDetails
      }
    }
    kind {
      id
      name
      schema {
        id
        name
      }
    }
  }
  ${FieldValueDetailsFragment}
`;

export const InstanceFieldValuesFragmentName = 'InstanceFieldValues';
export const InstanceFieldValuesFragment = gql`
  fragment InstanceFieldValues on Instance {
    fieldIds
    fieldValues {
      ...FieldValueDetails
    }
  }
  ${FieldValueDetailsFragment}
`;

/**
 * Checks to see if the modifiers for a field indicate its value is a list.
 *
 * @param {String} modifiers The modifiers for the field
 * @returns {boolean} True if its a list, otherwise false
 */
export function isListField(modifiers) {
  return modifiers && modifiers.includes(FieldModifiers.LIST);
}

/**
 * Checks to see if the modifiers for a field indicate its value is required.
 *
 * @param {String} modifiers The modifiers for the field
 * @returns {boolean} True if its required, otherwise false
 */
export function isRequiredField(modifiers) {
  return modifiers && modifiers.includes(FieldModifiers.NONULL);
}

/**
 * Checks to see if the modifiers for a field indicate its value is ephemeral.
 *
 * @param {String} modifiers The modifiers for the field
 * @returns {boolean} True if its ephemeral, otherwise false
 */
export function isEphemeralField(modifiers) {
  return modifiers && modifiers.includes(FieldModifiers.EPHEMERAL);
}

/**
 * Mapping of FieldModfier to its boolean field name.
 */
export const FieldModifierNameMap = Object.freeze({
  [FieldModifiers.LIST]: 'isList',
  [FieldModifiers.NONULL]: 'isRequired',
  [FieldModifiers.EPHEMERAL]: 'isEphemeral'
});

/**
 * Reshape the field to add properties to make the it more usable (isListField,
 * isRequiredField, isEphemeralField, displayType, etc).
 *
 * @param {Field} field The kind or function's schema
 * @param {string} parentType The type of parent the field belongs to (kind, function, etc)
 * @returns {Object} The update field
 */
export function simplifyField(field, parentType) {
  return {
    ...field,
    displayType: getFieldDisplayType(field),
    isList: !!isListField(field.modifiers),
    isRequired: !!isRequiredField(field.modifiers),
    isEphemeral: !!isEphemeralField(field.modifiers),
    isReservedField: !!isReservedField(field, parentType)
  };
}

/**
 * Reshape the schema such that fields have the added properties to make the
 * schema more usable (isListField, isRequiredField, isEphemeralField, and
 * displayType).
 *
 * @param {Array<Field>} schema The kind or function's schema
 * @param {string} parentType The type of parent the field belongs to (kind, function, etc)
 * @returns {Array<Object>} The schema array with the update fields
 */
export function simplifySchema(schema, parentType) {
  return schema && schema.map(f => simplifyField(f, parentType));
}

/**
 * Reshape the schema such that fields can be looked up by id.
 * Also, add properties to make the schema more usable (isListField, isRequiredField, isEphemeralField).
 *
 * @param {Object} schema The kind's schema
 * @param {string} parentType The type of parent the field belongs to (kind, function, etc)
 * @returns {Object} Map containing the field ids as keys and the field schema info as the values.
 */
export function getSchemaMap(schema, parentType) {
  return arrayToMap(schema, simplifyField, 'id', parentType);
}

/**
 * Add field values to the schema.
 *
 * @param {Object} kind The kind with schema
 * @param {Object} instance The instance of the kind with fieldIds and fieldValues
 */
export function getSchemaWithValues(kind, instance) {
  const schemaMap = getSchemaMap(kind.schema, KN_KIND);

  if (!instance) return Object.values(schemaMap);

  return instance.fieldIds.map((fieldId, i) => {
    const field = schemaMap[fieldId];
    const fieldValue = instance.fieldValues[i];
    const value = getFieldValue(field, fieldValue);
    return { ...field, value };
  });
}

/**
 * Format the type for display, including required field and list decorators.
 *
 * @param {String} type Name of the type
 * @param {boolean} isList True if the field value is a list
 * @param {boolean} isRequired True if the field value is required
 */
export const formatDisplayType = (type, isList, isRequired) => {
  let displayType = isList ? `[${type}${isRequired ? '!' : ''}]` : type;
  displayType = isRequired ? `${displayType}!` : displayType;
  return displayType || '';
};

/**
 * Get the field's type for display, including required field and list decorators
 *
 * @param {Object} field The field
 * @returns {String} Field's type for display, including ! and [] as needed
 */
export function getFieldDisplayType(field) {
  if (!field) return null;

  // If the field's type is KIND, then try to get the name of the kind.
  const fieldType =
    field.type === KN_KIND.toUpperCase() && field.kind
      ? field.kind.name
      : field.type;

  return formatDisplayType(
    fieldType,
    isListField(field.modifiers),
    isRequiredField(field.modifiers)
  );
}

/**
 * Get the basic value for a field for display
 *
 * @param {Object} field Field information
 * @param {Object} fieldValue Field value object with all the typed fields
 */
function getFieldValue(field, fieldValue) {
  const valueSelector = field ? getFieldValuePropName(field) : null;
  return fieldValue ? fieldValue[valueSelector] : '';
}

/**
 * Gets the name of the property of FieldValue containing the actual value.
 *
 * In order to determine whether the type is a list or not, it uses the first populated option from ...
 * (1) field.isList property, (2) isList parameter, and finally, (3) uses isListField(field.modifiers).
 *
 * @param {Object} field The field
 * @param {Boolean} isList True if its a list field; Optional, will try to use field.isList first.
 * @returns Name of the property which contains the value.
 */
export function getFieldValuePropName(field, isList) {
  return field.isList || isList || isListField(field.modifiers)
    ? `l_${field.type}`
    : field.type;
}

/**
 * Reshape records of field values into an array of name value pairs.
 *
 * @param {Object} kind The kind.
 * @param {Array} fieldIds Array of field ids
 * @param {Array} records Array of records containing FieldValues
 * @returns Array of objects where the keys are the field names with the values.
 */
export function reshapeRecords(kind, fieldIds, records) {
  const schemaMap = getSchemaMap(kind.schema, KN_KIND);
  return (
    records &&
    records.map(record => {
      return fieldIds.reduce((acc, fieldId, i) => {
        const field = schemaMap[fieldId];

        // Make sure the field still exists before trying to add its value to
        // the records map.
        if (field) {
          const fieldValue = record[i];
          const value = getFieldValue(field, fieldValue);
          acc[field.name] = valueToDisplayString(value);
        }

        return acc;
      }, {});
    })
  );
}

/**
 * Returns the value of a field within an Instance of a Kind by the name of the field.
 *
 * @param {Kind} kind The Kind the Instance belongs to
 * @param {Instance} instance The Instance
 * @param {string} fieldName The name of the field containing the value is returned
 */
export function getInstanceFieldValueByName(kind, instance, fieldName) {
  if (!(kind && instance)) return null;

  let field = kind.schema.find(f => f.name === fieldName);
  if (!field) return null;

  let valueIndex = instance.fieldIds.indexOf(field.id);
  if (valueIndex === -1) return null;

  let fieldValue = instance.fieldValues[valueIndex][field.type];

  return fieldValue;
}

/**
 * Returns the ordinal of a field within an Instance of a Kind by the name of the field.
 *
 * @param {Kind} kind The Kind the Instance belongs to
 * @param {Instance} instance The Instance
 * @param {string} fieldName The name of the field containing the value is returned
 */
export function getInstanceFieldOrdinalByName(kind, instance, fieldName) {
  let field = kind.schema.find(f => f.name === fieldName);
  let fieldOrdinal = field ? instance.fieldIds.indexOf(field.id) : -1;

  return fieldOrdinal;
}

/**
 * Converts the given value to a list of the given type.
 *
 * @param {string} value The data to parse the list out of
 * @param {FieldType} type The type of the field the data is being parsed for
 */
export function convertValueToListFieldType(value, type) {
  if (typeof value === 'string') {
    switch (type) {
      case FieldTypes.JSON: {
        return JSON.parse(`[${value}]`).map(data => JSON.stringify(data));
      }
      default: {
        const results = Papa.parse(value);
        const list = results.data[0];
        if (results.data.length) {
          return list
            .map(item => convertStringToFieldType(item, type))
            .filter(item => !isNil(item));
        } else {
          return [];
        }
      }
    }
  } else if (!isNil(value) && !Array.isArray(value)) {
    return [value];
  }

  return value;
}

/**
 * Converts a string value to the given type.
 *
 * @param {string} value The data to parse the result out of
 * @param {FieldType} type The type of the field the data is being parsed for
 */
export function convertStringToFieldType(value, type) {
  if (typeof value === 'string') {
    switch (type) {
      case FieldTypes.INT: {
        if (!(value && value.trim())) return null;
        const rval = parseInt(Number(value));
        if (isNaN(rval)) {
          return null;
        }
        return rval;
      }
      case FieldTypes.FLOAT: {
        if (!(value && value.trim())) return null;
        const rval = Number(value);
        if (isNaN(rval)) {
          return null;
        }
        return rval;
      }
      case FieldTypes.BOOLEAN: {
        const rval = value.trim().toLowerCase();
        return rval === 'true';
      }
      default: {
        return value.trim();
      }
    }
  }

  return value;
}

/**
 * Removes timezone formatting from a Time or DateTime string.
 *
 * @param {string} value The Time or DateTime string
 */
export const stripTimezoneFormatting = value =>
  value.replace(/(Z|[-+]{1}\d{2}:\d{2})$/, '');

/**
 * Update the name of an entity (kind or function) in the local cache.
 * The update fragments are only executed if the STRING containing the
 * name has actually changed.
 *
 * @param {Apollo Store} store Local apollo cache store
 * @param {String} workspaceId Workspace Id
 * @param {String} entityId Id of the kind or function
 * @param {String} name Updated name of the kind or function
 */
export function updateInstanceNameInCache(store, workspaceId, entityId, name) {
  const instanceRefId = buildInstanceRefId(workspaceId, entityId);

  const originalInstanceRef = store.readFragment({
    id: `${KN_INSTANCE_REF}:${instanceRefId}`,
    fragment: NodeWidgetInstanceRefFragment,
    fragmentName: NodeWidgetInstanceRefFragmentName
  });

  // Only update the instanceRef if one is already exists
  if (originalInstanceRef) {
    const instanceRef = { ...originalInstanceRef };

    const nameFieldOrdinal = getInstanceFieldOrdinalByName(
      instanceRef.kind,
      instanceRef.instance,
      'name'
    );

    if (nameFieldOrdinal > -1) {
      const fieldValues = instanceRef.instance.fieldValues;

      // Only update if the name has actually changed
      if (fieldValues[nameFieldOrdinal].STRING !== name) {
        fieldValues[nameFieldOrdinal] = {
          ...fieldValues[nameFieldOrdinal],
          STRING: name
        };

        store.writeFragment({
          id: `${KN_INSTANCE_REF}:${instanceRefId}`,
          fragment: NodeWidgetInstanceRefFragment,
          data: instanceRef,
          fragmentName: NodeWidgetInstanceRefFragmentName
        });

        updateRelatedEntityIds(
          originalInstanceRef.kind.name,
          store,
          entityId,
          workspaceId,
          name
        );
      }
    }
  }
}

/**
 * Turns a value into a string that can be rendered with react properly.
 *
 * @param {any} value The value to turn into a display string
 * @return {string} The display string
 */
export function valueToDisplayString(value) {
  return isNil(value) ? '(empty)' : String(value);
}

/**
 * Checks to see if the field has a reserved name.
 * For Kinds, 'id' is a reserved field.
 *
 * @param {Object} field The field to check
 * @param {string} parentType The type of parent the field belongs to (kind, function, etc)
 * @return {boolean} True when the field name is reserved.
 */
export function isReservedField(field, parentType = KN_KIND) {
  return parentType === KN_KIND && field && field.name === FieldNames.ID;
}

/**
 * Updates string fields in an instance in the cache. Expects that both the
 * instance and its kind are already in cache.
 *
 * @param {string} kindId ID of the instance's kind
 * @param {string} instanceId ID of the instance being updated
 * @param {Array<Object>} fields The list of string fields to update
 * @param {string} fields[].name The field name to update
 * @param {string} fields[].value The new value for the field
 * @param {ApolloClient} store The client to use to update the instance
 */
export function updateInstanceData(kindId, instanceId, fields, store) {
  const id = `${kindId}:${KN_INSTANCE}:${instanceId}`;
  const instance = store.readFragment({
    id,
    fragment: InstanceFieldValuesFragment,
    fragmentName: InstanceFieldValuesFragmentName
  });

  const kind = getKindByIdFromCache(kindId, store);

  if (!instance || !kind) return;

  // Update the field values in the instance
  const newInstance = { ...instance };
  fields.forEach(f => {
    const nameOrd = getInstanceFieldOrdinalByName(kind, newInstance, f.name);
    newInstance.fieldValues[nameOrd].STRING = f.value;
  });

  store.writeFragment({
    id,
    fragment: InstanceFieldValuesFragment,
    fragmentName: InstanceFieldValuesFragmentName,
    data: {
      fieldIds: newInstance.fieldIds,
      fieldValues: newInstance.fieldValues.map(fv => ({
        ...fv,
        __typename: KN_FIELD_VALUE
      })),
      __typename: KN_INSTANCE
    }
  });
}

/**
 * Grabs the ID for kinds that are always kept in cache based on their names,
 * otherwise it returns null.
 *
 * Currently supported kinds: Kind, Function, Service
 *
 * @param {string} kindName The name of the Kind to get the ID for
 * @param {ApolloClient} client The client used to access the cache
 * @returns {string|null} ID of the kind or null
 */
export function getKindIdFromKindName(kindName, client) {
  switch (kindName) {
    case KN_KIND:
      return KindUUID;
    case KN_FUNCTION:
      return getFunctionKindId(client);
    case KN_SERVICE:
    case KN_ASSISTANT:
      return getServiceKindId(client);
    default:
      return null;
  }
}
