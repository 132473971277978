import {
  BoilerplateFunctionFragment,
  FieldDetailsFragment,
  KindDetailsFragment
} from 'graphql/Portal';
import {
  addReferencesToCreatedEntities,
  removeReferencesToDeletedEntities
} from './entity';

import { KN_KIND } from './defines';
import { addKindsAndFunctionsToServices } from './service';
import { addToWorkspaceInventories } from './workspace';
import gql from 'graphql-tag';
import { updateInstanceNameInCache } from 'util/instanceUtils';

export const KindQuery = gql`
  query Kind($id: ID!) {
    kind(tenantId: 0, id: $id) {
      id
      schema {
        id
        name
        type
        modifiers
      }
    }
  }
`;

const KindsQuery = gql`
  query KindSchemas($ids: [ID!]!) {
    kinds(tenantId: 0, ids: $ids) {
      id
      schema {
        id
        name
        type
        modifiers
      }
    }
  }
`;

export const KindNameQuery = gql`
  query KindName($id: ID!) {
    kind(tenantId: 0, id: $id) {
      id
      name
    }
  }
`;

const KindValidateQuery = gql`
  query KindValidate($id: ID!) {
    kind(tenantId: 0, id: $id) {
      id
      isGenerated
      service {
        id
      }
    }
  }
`;

const AddKindsMutation = gql`
  mutation AddKinds($input: [AddKindInput!]!) {
    addKinds(input: $input) {
      newKinds {
        ...KindDetails
      }
      updatedServices {
        id
      }
      newBoilerplateKinds {
        ...KindDetails
      }
      newBoilerplateFunctions {
        ...BoilerplateFunction
      }
      workspaceServiceReferences {
        serviceId
        workspaceIds
      }
      updatedWorkspaces {
        id
        workspaceServiceId
        modelServiceId
        logicServiceId
      }
    }
  }
  ${KindDetailsFragment}
  ${BoilerplateFunctionFragment}
`;

const UpdateKindsMutation = gql`
  mutation UpdateKinds($kindInputs: [UpdateKindInput!]!) {
    updateKinds(input: $kindInputs) {
      id
      name
      description
      thumbnailUrl
      isPublic
      isManaged
      nameField
      schema {
        ...FieldDetails
      }
    }
  }
  ${FieldDetailsFragment}
`;

const DeleteKindMutation = gql`
  mutation DeleteKind($kindId: ID!) {
    deleteKind(kindId: $kindId) {
      updatedPortalGraphs {
        id
      }
      updatedWorkspaces {
        id
      }
      updatedImplementations {
        id
      }
      updatedOperations {
        id
      }
      updatedFields {
        id
        type
        typeKindId
      }
      updatedFunctions {
        id
        outputType
        outputKindId
      }
      updatedArguments {
        id
        type
        typeKindId
      }
      updatedServices {
        id
      }
      workspaces {
        id
      }
      deletedBoilerplateKindIds
      deletedBoilerplateFunctionIds
      deletedPortalGraphNodeIds
      deletedInstanceRefIds
      deletedArgumentValueIds
      deletedOperationIds
      workspaceServiceReferences {
        serviceId
        workspaceIds
      }
      existingEntityIds
    }
  }
`;

const BoilerplateForKindsQuery = gql`
  query BoilerplateForKinds($kindIds: [ID!]!) {
    boilerplateForKinds(kindIds: $kindIds) {
      kinds {
        id
        name
        isGenerated
        serviceId
        schema {
          id
          name
          type
          modifiers
          readonly
          kind {
            id
            name
          }
        }
        service {
          id
          name
        }
      }
      functions {
        id
        name
        isGenerated
        functionType
        arguments {
          id
          name
          type
          modifiers
          kind {
            id
            name
          }
        }
        outputType
        outputKindId
        outputModifiers
        kind {
          id
          name
        }
        service {
          id
          name
        }
      }
      instances {
        id
        kindId
        fieldValues {
          STRING
        }
      }
    }
  }
`;

const KindIsDeletedFragment = gql`
  fragment KindIsDeleted on Kind {
    isDeleted
  }
`;

/**
 * Checks to see that the kind exists, is from the model service, and is not a
 * generated kind.
 *
 * @param {string} id The ID of the kind to check
 * @param {string} modelServiceId The ID of the model service the kind should be from
 * @param {ApolloClient} client The client to use to access the cache
 */
async function isKindEditable(id, modelServiceId, client) {
  const kind = await getKindById(id, client, KindValidateQuery);

  if (!kind) {
    throw new Error('Invalid Kind ID supplied.');
  }

  if (!kind.service || kind.service.id !== modelServiceId) {
    throw new Error('Only Kinds in the current workspace can be updated.');
  }

  if (kind.isGenerated) {
    throw new Error('Generated Kinds cannot be updated.');
  }
}

/**
 * Creates a kind.
 *
 * @param {Object} kind kind input object.
 * @param {string} modelServiceId the ID of the backing model service.
 * @param {Object} client Graphql client instance.
 * @return the created kind object.
 */
export async function createKind(kind, modelServiceId, client) {
  return (await createKinds([kind], modelServiceId, client))[0];
}

/**
 * Creates a group of kinds.
 *
 * @param {Array<AddKindInput>} kinds The kinds to create.
 * @param {string} modelServiceId the ID of the backing model service.
 * @param {Object} client Graphql client instance.
 * @return the created kinds.
 */
export async function createKinds(kinds, modelServiceId, client) {
  const input = kinds.map(kind => ({
    ...kind,
    serviceId: modelServiceId
  }));

  const { data: returnData } = await client.mutate({
    mutation: AddKindsMutation,
    variables: { input },
    update: (store, { data }) => {
      const { newKinds } = data.addKinds;

      addReferencesToCreatedEntities(data.addKinds, store);

      // Update Kind queries in the cache in case they have been stored as null.
      for (const kind of newKinds) {
        store.writeQuery({
          query: KindNameQuery,
          variables: { id: kind.id },
          data: {
            kind: {
              id: kind.id,
              name: kind.name,
              __typename: KN_KIND
            }
          }
        });
      }
    }
  });

  return returnData.addKinds.newKinds;
}

/**
 * Updates a group of kinds.
 *
 * @param {Array<AddKindInput>} kindInputs The updated information about the kinds.
 * @param {string} workspaceId The ID of the workspace.
 * @param {string} modelServiceId The ID of the backing model service.
 * @param {ApolloClient} client Graphql client instance.
 * @return The updated kinds.
 */
export async function updateKinds(
  kindInputs,
  workspaceId,
  modelServiceId,
  client
) {
  // If the service id is specified, make sure that it is set to the
  // modelServiceId.
  for (const kindInput of kindInputs) {
    if (
      kindInput.serviceId !== undefined &&
      kindInput.serviceId !== modelServiceId
    ) {
      throw new Error('Only kinds in the current workspace can be updated.');
    }

    // Make sure we have a valid kind to update
    await isKindEditable(kindInput.id, modelServiceId, client);
  }

  // Call the update mutation
  const { data } = await client.mutate({
    mutation: UpdateKindsMutation,
    variables: { kindInputs },
    update: (store, { data }) => {
      if (data.updateKinds) {
        kindInputs.forEach(kindInput => {
          updateInstanceNameInCache(
            store,
            workspaceId,
            kindInput.id,
            kindInput.name
          );
        });
        updateBoilerplateForKinds(
          kindInputs.map(k => k.id),
          client
        );
      }
    }
  });

  return data.updateKinds;
}

/**
 * Gets new boilerplate kinds and functions for a given kind from the server.
 *
 * @param {Array<string>} kindIds Ids of the kinds to get the new boilerplate for
 * @param {ApolloClient} client The client to use to access the store and make queries
 * @returns {Promise} A promise that resolves to the result of the query to to get the boilerplate
 * for the kinds.
 */
export function updateBoilerplateForKinds(kindIds, client) {
  return client.query({
    query: BoilerplateForKindsQuery,
    variables: { kindIds: kindIds },
    fetchPolicy: 'network-only'
  });
}

/**
 * Adds boilerplate kinds and functions for a given kind from the server.
 *
 * @param {Array<string>} kindIds Ids of the kinds to get the new boilerplate for
 * @param {Array<string>} workspaceIds The IDs of the workspaces to add the boilerplate to
 * @param {string} modelServiceID ID of the model service to add the boilerplate to
 * @param {string} workspaceServiceID ID of the Workspace service to add the boilerplate to
 * @param {ApolloClient} client The client to use to access the store and make queries
 */
export function addBoilerplateForKinds(
  kindIds,
  workspaceIds,
  modelServiceId,
  workspaceServiceId,
  client
) {
  return client
    .query({
      query: BoilerplateForKindsQuery,
      variables: { kindIds: kindIds }
    })
    .then(({ data }) => {
      if (data?.boilerplateForKinds) {
        const kindIds = data.boilerplateForKinds.kinds.map(k => k.id);
        const functionIds = data.boilerplateForKinds.functions.map(f => f.id);
        addToWorkspaceInventories(workspaceIds, client, {
          serviceKindIds: kindIds,
          serviceFunctionIds: functionIds
        });
        addKindsAndFunctionsToServices(
          [modelServiceId, workspaceServiceId],
          kindIds,
          functionIds,
          client
        );
      }
    });
}

/**
 * Deletes a kind and removes references to it.
 *
 * @param {string} kindId Id of the kind to be deleted
 * @param {string} workspaceId Id of the current Workspace
 * @param {string} modelServiceId Id of the workspace model service
 * @param {Object} client The apollo client to access the store through
 * @throws Will throw an exception if deleting the kind fails
 */
export async function deleteKind(kindId, workspaceId, modelServiceId, client) {
  // Make sure we have a valid kind to update
  await isKindEditable(kindId, modelServiceId, client);

  return client.mutate({
    mutation: DeleteKindMutation,
    variables: { kindId },
    update: (store, { data }) => {
      const { existingEntityIds } = data.deleteKind;
      removeReferencesToDeletedEntities(data.deleteKind, store, {
        kindIds: [kindId]
      });

      store.writeFragment({
        id: `${KN_KIND}:${kindId}`,
        fragment: KindIsDeletedFragment,
        data: { isDeleted: true, __typename: KN_KIND }
      });

      if (!existingEntityIds.length) {
        // Kind does not exist on the server.
        // We may not have any data returned from the server
        // but we can at least remove any references out of the current Workspace.
        // TODO QP-483: Use a call to removeReferencesToDeletedEntities
        // started below
        /*
        const boilerplateIds = getBoilerplateIds(
          workspaceModelServiceId,
          kindId
        );
        // Remove references to the boilerplate entities from the Workspace inventory
        removeReferencesToDeletedEntities({
          deletedBoilerplateKindIds: boilerplateIds.kinds,
          deletedBoilerplateFunctionIds: boilerplateIds.functions,
          workspace: {
            id: workspaceId
          }
        }, store, {
          kindIds: [kindId]
        })
        */
        //TODO QP-483: Update selection to remove deleted Kinds and Functions
      }
    }
  });
}

/**
 * Returns a kind given the supplied ID.
 *
 * @param {string} id ID of the kind to load.
 * @param {Object} client Graphql client.
 * @param {Object} query The GraphQL query, defaults to a basic kind query
 * @return {Promise<Kind>} The retrieved kinds.
 */
export async function getKindById(id, client, query = KindQuery) {
  const kind = await client.query({
    query,
    variables: { id }
  });
  return kind.data.kind;
}

/**
 * Returns (from the cache) a kind given the supplied ID.
 *
 * @param {string} id ID of the kind to load.
 * @param {Object} store Graphql client.
 * @param {Object} query The GraphQL query, defaults to a basic kind query
 * @return {Promise<Kind>} The retrieved kinds.
 */
export function getKindByIdFromCache(id, store, query = KindQuery) {
  try {
    const data = store.readQuery({
      query,
      variables: { id }
    });
    return data.kind;
  } catch {
    // If Apollo throws an error, then it means the kind is not in the cache.
    return null;
  }
}

/**
 * Returns a group of kinds given the supplied IDs.
 *
 * @param {Array<string>} data IDs of the kinds to load.
 * @param {Object} client Graphql client.
 * @param {Object} query The GraphQL query, defaults to a basic kind query
 * @return {Promise<Kind>} The retrieved kinds.
 */
export async function getKindsById(ids, client, query = KindsQuery) {
  const kind = await client.query({
    query,
    variables: { ids }
  });
  return kind.data.kinds;
}
