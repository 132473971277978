import { Centered } from './Centered';
import ErrorIcon from '@material-ui/icons/Error';
import Logger from 'util/Logger';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  color: ${({ theme }) => theme.palette.error.contrastText};
  background: ${({ theme }) => theme.palette.error.dark};
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  margin-left: ${({ theme }) => theme.spacing()}px;
`;

const ErrorContainer = styled.div`
  overflow-y: auto;
  max-height: 500px;
  margin-top: ${({ theme }) => theme.spacing()}px;
`;

const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.contrastText};
  word-break: break-all;
`;

const stringify = x => (typeof x === 'string' ? x : JSON.stringify(x));

export const Error = ({ message, location, error, className }) => {
  const locStr = stringify(location);
  const errStr = stringify(error);

  const logMsg = `ERROR: ${message} ${locStr} ${errStr}`;
  Logger.error(logMsg);

  const Message = () => (
    <MessageContainer>
      <Text variant="h6">{message}</Text>

      {error && (
        <ErrorContainer>
          {typeof error === 'string' && <Text variant="body2">{error}</Text>}
          {error.graphQLErrors && error.graphQLErrors.length > 0 && (
            <div>
              <Text variant="subtitle1">Error Details:</Text>
              <ul>
                {error.graphQLErrors.map((e, i) => (
                  <li key={`error${i}`}>
                    <Text variant="subtitle1">
                      {typeof e === 'string' ? e : e.message}
                    </Text>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {error.networkError && (
            <div>
              <Text variant="subtitle1">Network Error</Text>
              {error.networkError.message && (
                <Text variant="body2">
                  Message: {error.networkError.message}
                </Text>
              )}
              {error.networkError.statusCode && (
                <Text variant="body2">
                  Status: {error.networkError.statusCode}
                </Text>
              )}
              {error.networkError.result &&
                error.networkError.result.errors.map(x => {
                  return (
                    <div key={'msg' + x.messaage}>
                      <Text variant="body2">{x.message}</Text>
                      {x.locations.map(y => {
                        return (
                          <Text key={y.line + y.column} variant="body2">
                            Line: {y.line} Column: {y.column}
                          </Text>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          )}
        </ErrorContainer>
      )}
    </MessageContainer>
  );

  return (
    <Root className={className}>
      <ErrorIcon fontSize="large" />
      <Message />
    </Root>
  );
};

Error.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  location: PropTypes.any,
  error: PropTypes.any.isRequired
};

export const CenteredError = props => (
  <Centered className={props.className}>
    <Error {...props} />
  </Centered>
);
