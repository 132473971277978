import {
  FieldNames,
  KN_FIELD_VALUE,
  KN_FILE,
  KN_FUNCTION,
  KN_INSTANCE,
  KN_INSTANCE_REF,
  KN_PORTAL_GRAPH,
  KN_SERVICE,
  KN_WORKSPACE
} from './defines';
import {
  FunctionNameFragment,
  InstanceDetailsFragment,
  PortalGraphNameFragment,
  PortalGraphNameQuery,
  WorkspaceInternalServicesIdsFragment
} from 'graphql/Portal';
import {
  InstanceFieldValuesFragment,
  InstanceFieldValuesFragmentName,
  getInstanceFieldOrdinalByName
} from 'util/instanceUtils';

import { buildInstanceRefId } from './helpers';
import gql from 'graphql-tag';

const ServiceNameQuery = gql`
  query ServiceName($id: ID!) {
    service(id: $id) {
      id
      name
    }
  }
`;

const FileNameQuery = gql`
  query FileName($id: ID!) {
    file(id: $id) {
      id
      name
    }
  }
`;

const InstanceRefNamesFragmentName = 'InstanceRefNames';
const InstanceRefNamesFragment = gql`
  fragment InstanceRefNames on InstanceRef {
    id
    kind {
      id
      schema {
        id
        name
      }
    }
    instance {
      ...InstanceDetails
    }
  }
  ${InstanceDetailsFragment}
`;

const WorkspaceNameQuery = gql`
  query WorkspaceName($id: ID!) {
    workspace(id: $id) {
      id
      name
    }
  }
`;

/**
 * Generates a kind name from a default name and a beginning ordinal
 *
 * @param {string} baseName The default name used when creating the new Kind
 * @param {number} startOrdinal The starting number used when generating the Kind name.
 *                              The generated name will increment this number until a unique name is formed.
 */
export const getNewName = ({
  names,
  baseName = 'NewNode',
  startOrdinal = 2,
  forceAddOrdinal = false
}) => {
  let ordinal = startOrdinal;
  let newName = forceAddOrdinal ? `${baseName}_${ordinal}` : baseName;
  while (names.includes(newName)) {
    newName = `${baseName}_${ordinal}`;
    ordinal++;
  }

  return newName;
};

/**
 * Updates an instance in the cache by loading it's connected instance ref.
 *
 * @param {string} instanceRefId ID of the instanceRef to load
 * @param {string} name The new name for the instance
 * @param {ApolloClient} store The client to use to update the instance
 */
function updateInstanceNameFromInstanceRef(instanceRefId, name, store) {
  const instanceRef = store.readFragment({
    id: `${KN_INSTANCE_REF}:${instanceRefId}`,
    fragment: InstanceRefNamesFragment,
    fragmentName: InstanceRefNamesFragmentName
  });

  if (!instanceRef) return;

  const { instance, kind } = instanceRef;

  // update the name in the functions instance
  const newInstance = { ...instance };
  const nameOrd = getInstanceFieldOrdinalByName(
    kind,
    newInstance,
    FieldNames.NAME
  );
  newInstance.fieldValues[nameOrd].STRING = name;
  store.writeFragment({
    id: `${newInstance.kindId}:${KN_INSTANCE}:${newInstance.id}`,
    fragment: InstanceFieldValuesFragment,
    fragmentName: InstanceFieldValuesFragmentName,
    data: {
      fieldIds: newInstance.fieldIds,
      fieldValues: newInstance.fieldValues.map(fv => ({
        ...fv,
        __typename: KN_FIELD_VALUE
      })),
      __typename: KN_INSTANCE
    }
  });
}

/**
 * Updates the name of related entities in the cache.
 *
 * @param {string} kindName The name of the kind the base entity lives in
 * @param {ApolloClient} store The apollo client to use to access the store
 * @param {string} id The ID of the entity to check for related entities of
 * @param {string} workspaceId The ID of the workspace being used
 * @param {string} name The new name for the entity
 */
export function updateRelatedEntityIds(kindName, store, id, workspaceId, name) {
  switch (kindName) {
    case KN_SERVICE:
    case KN_WORKSPACE:
      store.writeQuery({
        query: WorkspaceNameQuery,
        variables: { id: id },
        data: {
          workspace: {
            id: id,
            name: name,
            __typename: KN_WORKSPACE
          }
        }
      });

      const {
        workspaceServiceId,
        modelServiceId,
        logicServiceId
      } = store.readFragment({
        id: `${KN_WORKSPACE}:${workspaceId}`,
        fragment: WorkspaceInternalServicesIdsFragment
      });

      store.writeQuery({
        query: ServiceNameQuery,
        variables: { id: workspaceServiceId },
        data: {
          service: {
            id: workspaceServiceId,
            name: name,
            __typename: KN_SERVICE
          }
        }
      });

      store.writeQuery({
        query: ServiceNameQuery,
        variables: { id: modelServiceId },
        data: {
          service: {
            id: modelServiceId,
            name: name,
            __typename: KN_SERVICE
          }
        }
      });

      store.writeQuery({
        query: ServiceNameQuery,
        variables: { id: logicServiceId },
        data: {
          service: {
            id: logicServiceId,
            name: name,
            __typename: KN_SERVICE
          }
        }
      });

      break;

    case KN_FUNCTION:
      // Update the name on the function graph object
      try {
        const graphFragmentId = `${KN_PORTAL_GRAPH}:${id}`;
        const graph = store.readFragment({
          id: graphFragmentId,
          fragment: PortalGraphNameFragment
        });
        if (graph) {
          graph.name = name;
          store.writeFragment({
            id: graphFragmentId,
            fragment: PortalGraphNameFragment,
            data: graph
          });
        }
      } catch {
        // Nothing to do here, as this means that the graph is in the cache, but
        // does not have the name loaded yet.
      }

      const portalGraphInstanceRefId = `${KN_PORTAL_GRAPH}/${buildInstanceRefId(
        workspaceId,
        id
      )}`;
      updateInstanceNameFromInstanceRef(portalGraphInstanceRefId, name, store);

      break;

    case KN_PORTAL_GRAPH:
      store.writeQuery({
        query: PortalGraphNameQuery,
        variables: { id: id },
        data: {
          portalGraph: {
            id: id,
            name: name,
            __typename: KN_PORTAL_GRAPH
          }
        }
      });

      // get the instance ref for the function connected with the workspace
      updateInstanceNameFromInstanceRef(
        buildInstanceRefId(workspaceId, id),
        name,
        store
      );

      // Update the name on the function object
      const funcFragmentId = `${KN_FUNCTION}:${id}`;
      const func = store.readFragment({
        id: funcFragmentId,
        fragment: FunctionNameFragment
      });
      if (func) {
        func.name = name;
        store.writeFragment({
          id: funcFragmentId,
          fragment: FunctionNameFragment,
          data: func
        });
      }

      break;

    case KN_FILE:
      store.writeQuery({
        query: FileNameQuery,
        variables: { id: id },
        data: {
          file: {
            id: id,
            name: name,
            __typename: KN_FILE
          }
        }
      });

      break;

    default:
  }
}
