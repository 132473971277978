import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledListItem = styled(ListItem)`
  margin: 0;
  padding: 0;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${p => p.theme.spacing(1)}px;
`;

/**
 * Simple list item for showing an icon and instanceRef's name.
 */
export function BasicListItem(props) {
  const { instanceRef, iconName, ...other } = props;

  return (
    <StyledListItem button title={instanceRef.name} {...other}>
      <ListItemIcon>
        <StyledIcon kindName={iconName} />
      </ListItemIcon>
      <ListItemText primary={instanceRef.name} />
    </StyledListItem>
  );
}

BasicListItem.propTypes = {
  instanceRef: PropTypes.object.isRequired,
  iconName: PropTypes.string
};
