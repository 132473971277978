import React, { useCallback, useReducer } from 'react';

import { AssistantState } from 'util/defines';

/**
 * The React Context for the Assistant information
 */
export const AssistantContext = React.createContext(null);

const MAX_REJECTED_PROMISES = 10;

const AssistantStates = Object.freeze({
  IDLE: 1,
  WORKING: 2
});

const Actions = Object.freeze({
  IDLE: 1,
  ERROR: 2,
  WORKING: 3,
  CLEAR_ERRORS: 4
});

function getDefaultAssistantState() {
  return {
    state: AssistantStates.IDLE,
    errors: [],
    isWorking() {
      return this.state === AssistantStates.WORKING;
    }
  };
}

function reducer(state, action) {
  const updatedState = new Map(state);

  const assistantState = {
    ...(updatedState.get(action.id) ?? getDefaultAssistantState())
  };

  switch (action.type) {
    case Actions.IDLE: {
      assistantState.state = AssistantStates.IDLE;
      break;
    }
    case Actions.ERROR: {
      // Add the error with the date
      const newErrors = [
        { date: Date.now(), error: action.error },
        ...assistantState.errors
      ];
      if (newErrors.length > MAX_REJECTED_PROMISES) {
        newErrors.pop();
      }
      assistantState.errors = newErrors;
      break;
    }
    case Actions.WORKING: {
      assistantState.state = AssistantStates.WORKING;
      break;
    }
    case Actions.CLEAR_ERRORS: {
      const { indices } = action;
      if (indices) {
        // Clear specified errors
        assistantState.errors = assistantState.errors.filter(
          (e, index) => !indices.includes(index)
        );
      } else {
        // Clear all errors
        assistantState.errors = [];
      }
      break;
    }
    default:
      throw new Error(`Assistant Context: Invalid action '${action}'`);
  }

  updatedState.set(action.id, assistantState);
  return updatedState;
}

/**
 * The provider used to set the updated Assistant information.
 */
export function AssistantContextProvider({ children }) {
  const [assistantStates, dispatch] = useReducer(reducer, new Map());

  const setAssistantState = useCallback((id, state) => {
    switch (state) {
      case AssistantState.WORKING:
        dispatch({ type: Actions.WORKING, id });
        break;
      case AssistantState.IDLE:
        dispatch({ type: Actions.IDLE, id });
        break;
      default:
        throw new Error(`Assistant State: invalid state ${state}`);
    }
  }, []);

  const addAssistantError = useCallback((id, error) => {
    dispatch({ type: Actions.ERROR, id, error });
  }, []);

  const clearAssistantErrors = useCallback((id, indices) => {
    dispatch({ type: Actions.CLEAR_ERRORS, id, indices });
  }, []);

  return (
    <AssistantContext.Provider
      value={{
        assistantStates,
        setAssistantState,
        addAssistantError,
        clearAssistantErrors
      }}
    >
      {children}
    </AssistantContext.Provider>
  );
}
