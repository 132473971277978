/* eslint-disable flowtype/require-valid-file-annotation */

import './fonts.css';

import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider
} from '@material-ui/styles';
import React, { Component } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import createContext from './createContext';
import { createGlobalStyle } from 'styled-components';
import wrapDisplayName from 'recompose/wrapDisplayName';

const GlobalStyle = createGlobalStyle`
  html: {
    -webkit-font-smoothing: antialiased; /* Antialiasing. */
    -moz-osx-font-smoothing: grayscale; /* Antialiasing. */
    }
    
  body {
    margin: 0;
    background-color: ${props => props.theme.palette.background.default};
    color: ${props => props.theme.palette.text.primary};
    font-size: ${props => props.theme.typography.fontSize}px;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background-color 99999s, color 99999s;
    transition-delay: 999999s;
  }
  
  /* Style all the scroll bars */
  div::-webkit-scrollbar {
    width: 12px;
    background: ${props => props.theme.custom.scrollbar.background};
  }
  ::-webkit-scrollbar-corner { background: ${props =>
    props.theme.custom.scrollbar.background};}

  div::-webkit-scrollbar-track {
    background: ${props => props.theme.custom.scrollbar.track};
  }

  div::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: ${props => props.theme.custom.scrollbar.thumb};
  }

  div::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.custom.scrollbar.thumbHover};
  }
`;

const context = createContext();

function withRoot(BaseComponent) {
  class WithRoot extends Component {
    render() {
      return (
        <StylesProvider injectFirst>
          <StyledComponentsThemeProvider theme={context.theme}>
            <MuiThemeProvider theme={context.theme}>
              <CssBaseline />
              <GlobalStyle />
              <BaseComponent {...this.props} />
            </MuiThemeProvider>
          </StyledComponentsThemeProvider>
        </StylesProvider>
      );
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    WithRoot.displayName = wrapDisplayName(BaseComponent, 'withRoot');
  }

  return WithRoot;
}

export default withRoot;
