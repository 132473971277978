import { KPORTAL_TITLE } from 'util/defines';
import { useEffect } from 'react';

/**
 * Updates the document title with provided string.
 * Suffixed with the product name.
 *
 * @param {string} title The page title
 */
export function setTitle(title) {
  document.title = title ? `${title} - ${KPORTAL_TITLE}` : KPORTAL_TITLE;
}

/**
 * Hook that updates the document title with provided string, only if it has changed.
 * Suffixed with the product name.
 *
 * @param {string} title The page title
 */

export function useTitle(title) {
  useEffect(() => {
    setTitle(title);
  }, [title]);
}
