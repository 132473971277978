import { CenteredError } from './Error';
import { CenteredSpinner } from './Spinner';
import React from 'react';

export const DataRenderingWrapper = ({
  children,
  loading,
  loadingLabel,
  error,
  errorMessage,
  location
}) => {
  return (
    <>
      {error && (
        <CenteredError
          message={errorMessage}
          location={location}
          error={error}
        />
      )}

      {!error && loading && <CenteredSpinner label={loadingLabel} />}

      {!error &&
        !loading &&
        (typeof children === 'function' ? children() : children)}
    </>
  );
};
