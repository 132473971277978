import { BaseUserAuth, NonRecoverableAuthError } from './BaseUserAuth';

import Auth0Lock from 'auth0-lock';
import { AuthUserReLoginErrors } from './defines';
import UserContext from 'util/UserContext';
import history from 'util/history';

export default class Auth0UserAuth extends BaseUserAuth {
  onInactivityListeners = [];
  onLogoutListeners = [];

  constructor(client) {
    super(client);

    const audience =
      window.MAANA_ENV.AUTH_AUDIENCE ||
      window.MAANA_ENV.REACT_APP_PORTAL_AUTH_IDENTIFIER;

    this.auth0 = new Auth0Lock(
      window.MAANA_ENV.REACT_APP_PORTAL_AUTH_CLIENT_ID,
      window.MAANA_ENV.REACT_APP_PORTAL_AUTH_DOMAIN,
      {
        avatar: null,
        theme: {
          logo: process.env.PUBLIC_URL + '/logo-sm.png',
          primaryColor: '#2195f3'
        },
        autoClose: true,
        closable: false,
        rememberLastLogin: false,
        languageDictionary: {
          title: 'Maana Knowledge Portal'
        },
        auth: {
          redirectUrl: this.buildRedirectURI(),
          responseType: 'token id_token',
          audience,
          params: {
            scope: 'openid profile email'
          }
        }
      }
    )
      .on('authenticated', this.handleAuthenticated)
      .on('authorization_error', this.handleError);

    this._initAuthState();
  }

  login(startingUrl) {
    super.login(startingUrl);
    this.auth0.show();
  }

  logout() {
    super.logout();
    this.auth0.logout({
      returnTo: `${window.location.origin}/login`
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = String(authResult.expiresIn * 1000 + Date.now());
    return super.setSession(
      expiresAt,
      authResult.accessToken,
      authResult.idToken
    );
  }

  _renewTokenInternal() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, result) => {
        if (err) {
          // Check to see if it is an error that requires the user to login
          // again.
          if (AuthUserReLoginErrors.includes(err.code)) {
            reject(new NonRecoverableAuthError(err.description));
          } else {
            reject(new Error(err.description));
          }
        } else {
          this.setSession(result);
          resolve();
        }
      });
    });
  }

  handleAuthenticated = authResult => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      this.addVisibilityCheck();
      this.onActivity();
      this.setSession(authResult);
      this.getProfile((err, profile) => {
        if (profile) {
          this.setUserData(profile);
        } else if (err) {
          this.handleError(err);
          history.replace('/');
        }
      });
    }
  };

  getProfile(cb) {
    let accessToken = UserContext.getAccessToken();
    this.auth0.getUserInfo(accessToken, (err, profile) => {
      cb(err, profile);
    });
  }
}
