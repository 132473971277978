import { LogoAbsolute } from 'components/Logo';
import React from 'react';
import { Spinner } from 'components';
import styled from 'styled-components';
import { useTitle } from 'hooks';

const Root = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export default function Callback() {
  useTitle();

  return (
    <Root>
      <LogoAbsolute />
      <Spinner />
    </Root>
  );
}
