import {
  FieldValueDetailsFragment,
  FunctionNameFragment,
  KindDetailsGraphFragment,
  PortalGraphNameFragment,
  WorkspaceNameFragment
} from 'graphql/Portal';
import {
  HTTP_ERROR_CODES_START,
  HTTP_PROCESSING,
  KN_FILE,
  KN_FUNCTION,
  KN_INSTANCE,
  KN_KIND,
  KN_PORTAL_GRAPH,
  KN_WORKSPACE,
  KindUUID,
  OperationType
} from 'util/defines';

import Path from 'path';
import React from 'react';
import _ from 'lodash';
import buildUrl from 'build-url';
import gql from 'graphql-tag';
import uuidv4 from 'uuid/v4';
import warning from 'warning';

const InstanceDetailsFragment = gql`
  fragment HelpersInstanceDetails on Instance {
    id
    kindId
    fieldIds
    fieldValues {
      ...FieldValueDetails
    }
  }
  ${FieldValueDetailsFragment}
`;

const KindDetailsFragment = gql`
  fragment HelpersKindDetails on Kind {
    id
    name
    nameField
    schema {
      id
      name
      type
    }
  }
`;

/**
 * Possible error codes returned from the server
 */
export const ErrorCodes = Object.freeze({
  // Codes representing errors that happen around the resolvers
  UNKNOWN_ERROR: 0,
  UNKNOWN_GRAPHQL_ERROR: 1,

  // Codes that represent generic issues
  MISSING_PARAMS: 100,
  INVALID_PARAMS: 101,
  DATA_NOT_FOUND: 102,
  GRAPHQL: 103,
  NOT_IMPLEMENTED: 104
});

export function capitalizeFirstLetter(string) {
  warning(
    typeof string === 'string',
    'Material-UI: capitalizeFirstLetter(string) expects a string argument.'
  );

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const mkUrl = input => {
  const { baseUrl, basePath, fileName } = input;
  const encodedFileName = encodeURIComponent(fileName);
  const path = Path.join(basePath, encodedFileName);
  const url = buildUrl(baseUrl, { path });
  return url;
};

export const fmtDateTime = timestamp => {
  const date = new Date(timestamp);
  const dateText = date.toLocaleDateString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
  return dateText;
};

/**
 * Cross browser version of getBoundingClientRect.
 * In Edge, the returned object lacks x & y values,
 * though existing top & left values can be used instead.
 *
 * @param {Object} domNode DOM node to get the client rectangle for
 */
export function getBoundingClientRect(domNode) {
  const boundingRect = domNode.getBoundingClientRect();
  boundingRect.x = boundingRect.x || boundingRect.left;
  boundingRect.y = boundingRect.y || boundingRect.top;
  return boundingRect;
}

/**
 * Returns the Instance ID given an InstanceRef ID
 *
 * @param {String} instanceRefId The InstanceRef ID
 */
export function parseInstanceIdFromInstanceRefId(instanceRefId) {
  return instanceRefId.substring(instanceRefId.lastIndexOf('/') + 1);
}

/**
 * Returns the ID of the InstanceRef for a given Instance
 *
 * @param {String} workspaceId The ID of the Workspace the InstanceRef is in
 * @param {String} instanceId The ID of the Instance
 */
export function buildInstanceRefId(workspaceId, instanceId) {
  return `instanceRefs/${workspaceId}/${instanceId}`;
}

/**
 * Moves an element in an array from one index to the next
 *
 * @param {Array} arr is the array to move the element around in
 * @param {number} previousIndex the starting index of the element to move
 * @param {number} newIndex the new index of the element to move
 * @return {Array} with the index at the new position
 */
export const arrayMove = (arr, previousIndex, newIndex) => {
  const array = arr.slice(0);
  if (newIndex >= array.length) {
    let k = newIndex - array.length;
    while (k-- + 1) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
  return array;
};

/**
 * Used with `Array.sort` to compare two different nodes, so that the nodes can
 * be displayed alphabetically (ignoring case) by a given string property.
 * @param {Object} left   Left side of the comparison
 * @param {Object} right  Right side of the comparison
 * @param {String} propName   Name of the String property on each object to compare by.
 */
export const sortComparisonByStringProp = (left, right, propName) => {
  const leftName = left[propName] ? left[propName].toUpperCase() : null;
  const rightName = right[propName] ? right[propName].toUpperCase() : null;
  if (leftName < rightName) return -1;
  if (leftName > rightName) return 1;
  return 0;
};

/**
 * Used with `Array.sort` to compare two different nodes, specifically by a 'title' property,
 * so that the nodes can be displayed alphabetically to the user (ignores case).
 * @param {Object} left   Left side of the comparison
 * @param {Object} right  Right side of the comparison
 */
export const sortComparisonByTitle = (left, right) => {
  return sortComparisonByStringProp(left, right, 'title');
};

function getNameByFieldInstance(instance, fieldInstance) {
  if (instance && instance.fieldIds && fieldInstance) {
    let valueIndex = instance.fieldIds.indexOf(fieldInstance.id);
    if (valueIndex >= 0) {
      return instance.fieldValues[valueIndex][fieldInstance.type];
    }
  }

  return null;
}

export function getNameForInstance({ apolloClient, kindId, kindName, id }) {
  let instance, kind;
  try {
    if (kindId === KindUUID || kindName === KN_KIND) {
      kind = apolloClient.readFragment({
        id: `${KN_KIND}:${id}`,
        fragment: KindDetailsFragment,
        fragmentName: 'HelpersKindDetails'
      });

      return kind ? kind.name : '';
    } else if (kindId) {
      kind = apolloClient.readFragment({
        id: `${KN_KIND}:${kindId}`,
        fragment: KindDetailsFragment,
        fragmentName: 'HelpersKindDetails'
      });

      if (kind) {
        instance = apolloClient.readFragment({
          id: `${kindId}:${KN_INSTANCE}:${id}`,
          fragment: InstanceDetailsFragment,
          fragmentName: 'HelpersInstanceDetails'
        });
      }
    } else if (kindName) {
      if (kindName === KN_WORKSPACE) {
        const workspace = apolloClient.readFragment({
          id: `${KN_WORKSPACE}:${id}`,
          fragment: WorkspaceNameFragment
        });
        return workspace ? workspace.name : '';
      } else if (kindName === KN_PORTAL_GRAPH) {
        const portalGraph = apolloClient.readFragment({
          id: `${KN_PORTAL_GRAPH}:${id}`,
          fragment: PortalGraphNameFragment
        });
        return portalGraph ? portalGraph.name : '';
      } else if (kindName === KN_FUNCTION) {
        const func = apolloClient.readFragment({
          id: `${KN_FUNCTION}:${id}`,
          fragment: FunctionNameFragment
        });
        return func ? func.name : '';
      }
    }
  } catch {
    // There is no need to do anything with this error, as it just means that
    // not all of the requested fragment is available in the cache.
  }

  return kind && instance ? getKindInstanceName(kind, instance) : '';
}

/**
 * Returns the name of an instance using a set of conditions.
 *
 * @param {Object} kind The Kind the instance is an instance of
 * @param {Object} instance The instance which will have its name returned
 * @return {String} The name of the instance
 */
export function getKindInstanceName(kind, instance) {
  let name;
  // first try to grab the name using the nameField
  if (kind) {
    if (instance) {
      // First try nameField
      if (kind.nameField) {
        let fieldInstance = kind.schema.find(
          field => field.name === kind.nameField
        );
        name = getNameByFieldInstance(instance, fieldInstance);
      }

      // Next try instance.name
      if (!name) {
        name = instance.name;
      }

      // Next try to find a 'name' field or else use the second field
      // (first field after the "id" field) in the schema
      if (!name && kind.schema.length > 1) {
        let fieldInstance =
          kind.schema.find(field => field.name === 'name') || kind.schema[1];

        name = getNameByFieldInstance(instance, fieldInstance);
      }

      // If a name still hasn't been found, use the ID.
      // Blank names are allowed on files because of annotations.
      if (!name && kind.name !== KN_FILE) {
        name = instance.id;
      }
    } else {
      // Use the Kind name or ID if there isn't an instance
      name = kind.name || kind.id;
    }
  }

  // make sure that the returned name is a string
  if (name && typeof name !== 'string') {
    name = String(name);
  }

  return name;
}

/**
 * Adds the name of the service this kind or instance belongs to when it is
 * available and not equal to the model service's ID
 *
 * @param {string} name of the kind or instance
 * @param {Object} kind that represents the kind or instance
 * @param {Array<string>} serviceIds of the services to check if the the kind or instance is in
 */
export function getServiceKindName(name, kind, serviceIds) {
  if (!serviceIds) {
    serviceIds = [];
  } else if (!Array.isArray(serviceIds)) {
    serviceIds = [serviceIds];
  }

  if (kind && kind.service && !serviceIds.includes(kind.service.id)) {
    return `${kind.service.name}: ${name}`;
  }
  return name;
}

/**
 * Returns the appropriate URL for accessing a service.
 *
 * @param {string} serviceId The ID of the service
 * @param {string} serviceUrl The URL to the service
 */
export function getServiceUrl(serviceId, serviceUrl) {
  if (
    serviceUrl &&
    serviceUrl.startsWith(window.MAANA_ENV.PUBLIC_BACKEND_URI)
  ) {
    return serviceUrl;
  } else {
    return `${window.MAANA_ENV.PUBLIC_BACKEND_URI}/service/${serviceId}/graphql`;
  }
}

/**
 * Runs a function with the option to retry based on specified error codes. There is also the option to specify a function that
 * modifies the array of function arguments between retries.
 *
 * @param {Function} func The function to call
 * @param {Array<any>} funcArgs The list of arguments to pass to the function
 * @param {Array<number>} retryErrorCodes The error codes that will cause a retry. If this is undefined or empty then
 *                                        the mutation will be retried.
 * @param {Function} updateArgsFunc Optional function to call between retries that will be passed the current function args array.
 *                                          It should return the updated function args.
 * @param {number} numRetries The number of retries to attempt before giving up. Defaults to 5.
 *
 * @returns {Promise} A promise that resolves with the result of the passed in function
 */
export async function functionWithRetry(
  func,
  funcArgs,
  retryErrorCodes,
  updateArgsFunc,
  numRetries = 5
) {
  if (numRetries > 0) {
    try {
      return await func(...funcArgs);
    } catch (error) {
      const errorCode = error.graphQLErrors.length
        ? error.graphQLErrors[0].code
        : null;
      if (
        !retryErrorCodes ||
        !retryErrorCodes.length ||
        retryErrorCodes.includes(errorCode)
      ) {
        const newArgs = updateArgsFunc ? updateArgsFunc(funcArgs) : funcArgs;

        return functionWithRetry(
          func,
          newArgs,
          retryErrorCodes,
          updateArgsFunc,
          numRetries - 1
        );
      }
      throw error;
    }
  }

  return func(...funcArgs);
}

/**
 * Returns the Function kind with basic information.
 *
 * @param {ApolloClient} client An Apollo client instance
 * @return {Kind} The Function kind.
 */
export function getFunctionKind(client) {
  const { functionKind } = client.readQuery({
    query: gql`
      query FunctionKind {
        functionKind: kind(tenantId: 0, name: "Function") {
          ...KindDetailsGraph
        }
      }
      ${KindDetailsGraphFragment}
    `
  });

  return functionKind;
}

/**
 * Returns the ID of the Function kind.
 *
 * @param {ApolloClient} client An Apollo client instance
 * @return {string} The ID of the Function kind.
 */
export function getFunctionKindId(client) {
  const functionKind = getFunctionKind(client);
  return functionKind && functionKind.id;
}

/** Query variables to query KindDB for the Service Kind.  */
export const serviceKindQueryVariables = {
  kindQuery: {
    fieldFilters: [
      { fieldName: 'name', op: '==', value: { STRING: 'Service' } },
      {
        fieldName: 'serviceId',
        op: '==',
        value: { ID: 'io.maana.catalog.model' }
      }
    ]
  }
};

/**
 * Returns the Service kind id.
 *
 * @param {ApolloClient} client An Apollo client instance
 * @return {string} The Service kind id.
 */
export function getServiceKindId(client) {
  const { serviceKind } = client.readQuery({
    query: gql`
      query ServiceKind($kindQuery: KindQueryInput!) {
        serviceKind: kindDBKindQuery(kindQuery: $kindQuery) {
          id
        }
      }
    `,
    variables: serviceKindQueryVariables
  });
  return serviceKind && serviceKind[0] && serviceKind[0].id;
}

/**
 * Returns the File kind with basic information.
 *
 * @param {ApolloClient} client An Apollo client instance
 * @return {Kind} The File kind.
 */
export function getFileKind(client) {
  const { fileKind } = client.readQuery({
    query: gql`
      query FunctionKind {
        fileKind: kind(tenantId: 0, name: "File") {
          ...KindDetailsGraph
        }
      }
      ${KindDetailsGraphFragment}
    `
  });

  return fileKind;
}

/**
 * Builds an Operation from a Function ID
 *
 * @param {Object} functionId The ID of the Function that the operation will contain
 */
export const buildFunctionOperation = functionId => {
  return {
    id: uuidv4(),
    function: functionId,
    type: OperationType.APPLY,
    argumentValues: []
  };
};

/**
 * Omits props from being passed down to components wrapped in a styled component call. This
 * prevents the React warning "React does not recognize the XX prop on a DOM element."
 * This is not needed if using styled with a tag, e.g. styled.div. Taken from the issue here:
 * https://github.com/styled-components/styled-components/pull/2093
 *
 * Usage: styled(withoutProps('myprop')(MyComponent))
 * @param  {...String} omitProps The names of the props to omit
 */
export const withoutProps = (...omitProps) => {
  // HoF
  return C => {
    const WithoutPropsComponent = ({ children, ...props }) => {
      return <C {..._.omit(props, omitProps)}>{children}</C>;
    };
    return WithoutPropsComponent;
  };
};

/**
 * Returns true when the file progress bar needs to be displayed, based on the
 * current HTTP status for the file
 *
 * @param {number} status The current status for the file that needs to be checked
 */
export function showFileProgressBar(status) {
  return (
    status === 0 || // errors without HTTP status codes from file uploader have a status of 0
    status === HTTP_PROCESSING ||
    status >= HTTP_ERROR_CODES_START
  );
}

/**
 * Check the URL to see if it starts with the public backend uri
 *
 * @param {string} url The URL to check
 * @return {boolean} True when the url is pointing to the public backend uri
 */
export function isPlatformUrl(url) {
  return url.startsWith(window.MAANA_ENV.PUBLIC_BACKEND_URI);
}

/**
 * Builds the IDs for the boilerplate kinds and functions for a given kinds.
 *
 * @param {string} serviceId Id of the service the kind lives in
 * @param {Array<string>|string} kindIds Ids of the kinds the boilerplate is for
 * @return {Object} An object containing lists of the kind and function ids
 */
export function getBoilerplateIds(serviceId, kindIds) {
  const ids = Array.isArray(kindIds) ? kindIds : [kindIds];
  return ids.reduce(
    (boilerplate, kindId) => ({
      kinds: boilerplate.kinds.concat([
        `${serviceId}:${kindId}:addinput`,
        `${serviceId}:${kindId}:updateinput`
      ]),
      functions: boilerplate.functions.concat([
        `${serviceId}:${kindId}:queryOne`,
        `${serviceId}:${kindId}:queryMany`,
        `${serviceId}:${kindId}:queryAll`,
        `${serviceId}:${kindId}:filter`,
        `${serviceId}:${kindId}:addOne`,
        `${serviceId}:${kindId}:addMany`,
        `${serviceId}:${kindId}:updateOne`,
        `${serviceId}:${kindId}:updateMany`,
        `${serviceId}:${kindId}:deleteOne`,
        `${serviceId}:${kindId}:deleteMany`
      ])
    }),
    { kinds: [], functions: [] }
  );
}

/**
 * Converts an array to a map, using the valued in keyField as the the key in
 * the map.
 *
 * @param {Array<Object>} array The array of elements to convert
 * @param {function} modifier An optional modifier function to run on each element to the array
 * @param {string} keyField The field of the objects in the array to use as the key in the map
 * @param {Object} modifierParams Optional parameter for the modifier function
 * @return {Map<any, Object>} A map of the elements in the array
 */
export function arrayToMap(
  array,
  modifier,
  keyField = 'id',
  ...modifierParams
) {
  return (
    array &&
    array.reduce((acc, elem) => {
      acc[elem[keyField]] = modifier ? modifier(elem, ...modifierParams) : elem;
      return acc;
    }, {})
  );
}
