import { Centered } from './Centered';
import React from 'react';
import ReactSpinner from 'react-spinkit';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Spinner = styled(ReactSpinner).attrs({
  name: 'ball-scale-ripple-multiple'
})`
  && {
    color: ${({ theme }) => theme.custom.spinner.color};
  }
`;

const Label = styled(Typography)`
  margin-top: 30px;
  color: ${({ theme }) => theme.custom.spinner.color};
`;

export const CenteredSpinner = ({ label, className }) => (
  <Centered className={className}>
    <Spinner />
    {label && <Label variant="subtitle1">{label}</Label>}
  </Centered>
);
