import { Description, IdText, Name, NameContext } from './StyledText';
import React, { useCallback } from 'react';

import { DefaultActionType } from './defines';
import Icon from 'components/Icon';
import { IconButton } from 'components/IconButton';
import PropTypes from 'prop-types';
import { SearchResultType } from './propTypes';
import styled from 'styled-components';
import useOptionDrag from './useOptionDrag';

const Root = styled.div`
  display: flex;
  padding: ${p => p.theme.spacing(0.5)}px;
  align-items: center;
  overflow: hidden;
  border-bottom: 1px solid ${p => p.theme.palette.divider};
  cursor: pointer;
  background-color: ${p =>
    p.selected ? p.theme.custom.search.optionFocusBackground : 'initial'};
`;

const CardText = styled.div`
  margin-left: ${p => p.theme.spacing()}px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const NameDiv = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

const StyledName = styled(Name)`
  flex: 2;
`;

const StyledNameContext = styled(NameContext)`
  flex: 1;
`;

function Option({
  option,
  highlighted,
  onDragStart,
  onDragEnd,
  onAction,
  actionType,
  inputRef,
  ...optionProps
}) {
  const {
    id,
    idSections,
    name,
    nameSections,
    nameContext,
    description,
    descriptionSections,
    kindName
  } = option;
  const selected = optionProps['aria-selected'];

  const dragSource = useOptionDrag({ name, id, kindName });

  const onMouseDown = useCallback(
    event => {
      // Only initiate drag for the primary mouse button, usually the left
      // mouse button.
      if (event.button === 0) {
        onDragStart();
      } else {
        // Otherwise try and keep focus on the search box.
        event.preventDefault();
      }
    },
    [onDragStart]
  );

  const onOptionClicked = useCallback(() => {
    if (actionType !== DefaultActionType.WORKSPACE) {
      onAction(option);
    } else {
      inputRef.current.focus();
    }

    onDragEnd();
  }, [option, onAction, onDragEnd, actionType, inputRef]);

  const onAddClicked = useCallback(() => {
    if (actionType === DefaultActionType.WORKSPACE) {
      onAction(option);
    }
  }, [option, onAction, actionType]);

  return (
    <Root
      ref={dragSource}
      selected={selected || highlighted}
      {...optionProps}
      onMouseDown={onMouseDown}
      onDragEnd={onDragEnd}
      onClick={onOptionClicked}
      aria-label={`Search Result ${name}`}
    >
      <Icon kindName={kindName} />
      <CardText>
        <NameDiv>
          <StyledName text={name} sections={nameSections} noWrap />
          {nameContext && <StyledNameContext text={nameContext} noWrap />}
        </NameDiv>
        <IdText text={id} sections={idSections} noWrap />
        {description && (
          <Description
            text={description}
            sections={descriptionSections}
            noWrap
          />
        )}
      </CardText>
      {actionType === DefaultActionType.WORKSPACE && (
        <IconButton
          onClick={onAddClicked}
          onMouseDown={e => {
            // Make sure that the input does not lose focus, and keep the
            // root level event handler from triggering.  This is needed to keep
            // the pressing of the add button from resetting the scroll position
            // of the results container.
            e.preventDefault();
            e.stopPropagation();
          }}
          title="Add to Workspace"
        >
          <Icon kindName="add" />
        </IconButton>
      )}
    </Root>
  );
}

export default React.memo(Option);

Option.propTypes = {
  option: SearchResultType.isRequired,
  highlighted: PropTypes.bool.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  actionType: PropTypes.oneOf(Object.values(DefaultActionType)).isRequired
};
