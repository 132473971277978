import React, { useContext } from 'react';

import { ApolloConsumer } from 'react-apollo';

// To be replaced with apollo-react hooks when v3.0 is released.

/**
 * The React Context for the ApolloClient information.
 */
const ApolloContext = React.createContext();

/**
 * The provider used to set the updated ApolloClient information.
 */
export const ApolloContextProvider = ({ children }) => (
  <ApolloConsumer>
    {client => (
      <ApolloContext.Provider value={client}>{children}</ApolloContext.Provider>
    )}
  </ApolloConsumer>
);

/**
 * Custom hook for ApolloClient. To be replaced when apollo-react 3.0 is released.
 */
export function useApolloClient() {
  return useContext(ApolloContext);
}
