import gql from 'graphql-tag';

/**
 * Fragments
 *
 * NOTE: fragments must be declared before they are used
 */

export const LinkedOnKind = gql`
  fragment LinkedOnKind on Kind {
    linkedKindsFrom {
      relation {
        id
        name
        inverseName
      }
      kind {
        id
        name
      }
      field {
        id
        name
      }
    }
    linkedKindsTo {
      relation {
        id
        name
        inverseName
      }
      kind {
        id
        name
      }
      field {
        id
        name
      }
    }
  }
`;

export const LinkedOnInstance = gql`
  fragment LinkedOnInstance on Instance {
    linkedKindsFrom {
      relation {
        id
        name
        inverseName
      }
      field {
        id
        name
      }
      kind {
        id
        name
      }
    }
    linkedKindsTo {
      relation {
        id
        name
        inverseName
      }
      kind {
        id
        name
      }
      field {
        id
        name
      }
    }
    linkedInstancesTo {
      relation {
        id
        name
        inverseName
      }
      kind {
        id
        name
      }
      instance {
        id
        name
        kindId
      }
    }
    linkedInstancesFrom {
      relation {
        id
        name
        inverseName
      }
      kind {
        id
        name
      }
      instance {
        id
        name
        kindId
      }
    }
  }
`;

export const FieldValueDetailsFragment = gql`
  fragment FieldValueDetails on FieldValue {
    ID
    STRING
    INT
    FLOAT
    BOOLEAN
    DATE
    TIME
    DATETIME
    JSON
    KIND
    l_ID
    l_STRING
    l_INT
    l_FLOAT
    l_BOOLEAN
    l_DATE
    l_TIME
    l_DATETIME
    l_JSON
    l_KIND
  }
`;

export const FieldDetailsFragment = gql`
  fragment FieldDetails on Field {
    id
    name
    description
    type
    typeKindId
    modifiers
    kind {
      id
      name
      isGenerated
      service {
        id
        name
      }
    }
    hide
    autoFocus
    displayAs
    readonly
  }
`;

export const KindDetailsFragment = gql`
  fragment KindDetails on Kind {
    id
    name
    description
    isPublic
    isGenerated
    nameField
    schema {
      ...FieldDetails
    }
    service {
      id
      name
    }
    serviceId
  }
  ${FieldDetailsFragment}
`;

export const OperationFunctionDetailsFragment = gql`
  fragment OperationFunctionDetails on Function {
    id
    name
    graphqlOperationType
    functionType
    isGenerated
    service {
      id
      name
    }
    arguments {
      id
      name
      type
      # optional
      description
      modifiers
      typeKindId
      kind {
        id
        name
      }
    }
    implementation {
      id
      operations {
        id
        function {
          id
          service {
            id
          }
        }
      }
    }
    # Output
    outputType
    outputKindId
    outputModifiers
    kind {
      id
      name
    }
  }
`;

export const OperationDetailsFragment = gql`
  fragment OperationDetails on Operation {
    id
    type
    function {
      ...OperationFunctionDetails
    }
    argumentValues {
      id
      argument {
        id
      }
      operation {
        id
      }
      argumentRef
    }
  }
  ${OperationFunctionDetailsFragment}
`;

export const ImplementationDetailsFragment = gql`
  fragment ImplementationDetails on Implementation {
    id
    entrypoint {
      id
    }
    operations {
      ...OperationDetails
    }
  }
  ${OperationDetailsFragment}
`;

export const FunctionDetailsFragmentName = 'FunctionDetails';
export const FunctionDetailsFragment = gql`
  fragment FunctionDetails on Function {
    id
    name
    graphqlOperationType
    functionType
    isGenerated
    service {
      id
      name
    }
    # Input arguments
    arguments {
      id
      name
      type
      # optional
      description
      modifiers
      typeKindId
      kind {
        id
        name
        isGenerated
        service {
          id
          name
        }
      }
    }
    # Implementation
    implementation {
      ...ImplementationDetails
    }
    # Output
    outputType
    outputKindId
    outputModifiers
    kind {
      id
      name
      isGenerated
      service {
        id
        name
      }
    }
  }
  ${ImplementationDetailsFragment}
`;

export const BoilerplateFunctionFragment = gql`
  fragment BoilerplateFunction on Function {
    id
    name
    graphqlOperationType
    functionType
    isGenerated
    service {
      id
    }
    implementation {
      id
    }
    # Input arguments
    arguments {
      id
      name
      type
      # optional
      description
      modifiers
      typeKindId
      kind {
        id
      }
    }
    # Output
    outputType
    outputKindId
    outputModifiers
    kind {
      id
    }
  }
`;

export const InstanceDetailsFragment = gql`
  fragment InstanceDetails on Instance {
    id
    kindId
    fieldIds
    fieldValues {
      ...FieldValueDetails
    }
  }
  ${FieldValueDetailsFragment}
`;

export const KindDetailsGraphFragment = gql`
  fragment KindDetailsGraph on Kind {
    id
    name
    isGenerated
    nameField
    schema {
      id
      name
      type
      modifiers
      readonly
      kind {
        id
        name
        isGenerated
        service {
          id
          name
        }
      }
    }
    service {
      id
      name
    }
    serviceId
  }
`;

const KnowledgeGraphNodeDetailsGraphFragment = gql`
  fragment KnowledgeGraphNodeDetailsGraph on KnowledgeGraphNode {
    id
    kind {
      ...KindDetailsGraph
    }
    instance {
      ...InstanceDetails
      ...LinkedOnInstance
    }
    innerKind {
      ...KindDetailsGraph
      ...LinkedOnKind
    }
    innerFunction {
      ...FunctionDetails
    }
  }
  ${LinkedOnKind}
  ${LinkedOnInstance}
  ${KindDetailsGraphFragment}
  ${InstanceDetailsFragment}
  ${FunctionDetailsFragment}
`;

const FunctionGraphNodeDetailsFragment = gql`
  fragment FunctionGraphNodeDetails on FunctionGraphNode {
    id
    operationId
  }
`;

export const PortalGraphNodeDetailsFragment = gql`
  fragment PortalGraphNodeDetails on PortalGraphNode {
    id
    x
    y
    width
    height
    collapsed
    knowledgeGraphNode {
      ...KnowledgeGraphNodeDetailsGraph
    }
    functionGraphNode {
      ...FunctionGraphNodeDetails
    }
  }
  ${KnowledgeGraphNodeDetailsGraphFragment}
  ${FunctionGraphNodeDetailsFragment}
`;

export const WorkspaceLayoutFragment = gql`
  fragment WorkspaceLayoutDetails on WorkspaceLayout {
    id
    explorerOpen
    explorerSize
    contextOpen
    contextSize
    contextMode
    dataVizOpen
    dataVizSize
  }
`;

export const SubscribeToLinksQuery = gql`
  query SubscribeToLinks {
    subscribeToLinks @client
  }
`;

export const PortalGraphFragment = gql`
  fragment PortalGraphDetails on PortalGraph {
    id
    name
    type
    zoom
    offsetX
    offsetY
    lockedBy
    workspace {
      id
    }
    nodes {
      ...PortalGraphNodeDetails
    }
    function {
      ...FunctionDetails
    }
  }
  ${PortalGraphNodeDetailsFragment}
  ${FunctionDetailsFragment}
`;

export const PortalGraphNameFragment = gql`
  fragment PortalGraphName on PortalGraph {
    id
    name
  }
`;

export const PortalGraphTypeFragment = gql`
  fragment PortalGraphType on PortalGraph {
    type
  }
`;

const UserDetailsFragment = gql`
  fragment UserDetails on User {
    id
    name
    givenName
    familyName
    email
    picture
    theme
  }
`;

export const KindNameServiceFragment = gql`
  fragment KindNameService on Kind {
    id
    name
    isGenerated
    serviceId
    service {
      id
      name
    }
  }
`;

export const FunctionNameServiceFragment = gql`
  fragment FunctionNameService on Function {
    id
    name
    isGenerated
    service {
      id
      name
    }
    functionType
  }
`;

export const FunctionNameFragment = gql`
  fragment FunctionName on Function {
    id
    name
  }
`;

export const InventoryInstanceRefDetailsFragment = gql`
  fragment InventoryInstanceRefDetails on InstanceRef {
    id
    kindId
    kindName
    kind {
      ...KindNameService
      schema {
        id
        name
        type
      }
    }
    instance {
      ...InstanceDetails
    }
    innerKind {
      ...KindNameService
    }
    innerFunction {
      ...FunctionNameService
    }
  }

  ${FunctionNameServiceFragment}
  ${KindNameServiceFragment}
  ${InstanceDetailsFragment}
`;

/**
 * Kinds
 */

export const UpdateKindMutation = gql`
  mutation UpdateKindNameAndSchema(
    $tenantId: ID!
    $kindInput: UpdateKindInput!
  ) {
    updateKind(tenantId: $tenantId, input: $kindInput) {
      id
      name
      schema {
        ...FieldDetails
      }
    }
  }
  ${FieldDetailsFragment}
`;

/**
 * KindDB Query
 */

export const KindDBQuery = gql`
  query KindDB($kindQuery: KindQueryInput!) {
    kindDBQuery(kindQuery: $kindQuery) {
      fieldIds
      records {
        ...FieldValueDetails
      }
      token
    }
  }
  ${FieldValueDetailsFragment}
`;

/**
 * Portal Graphs
 */

export const CanvasPortalGraphQuery = gql`
  query CanvasPortalGraph($id: ID!) {
    portalGraph(id: $id) {
      ...PortalGraphDetails
    }
  }
  ${PortalGraphFragment}
`;

export const PortalGraphNameQuery = gql`
  query PortalGraphName($id: ID!) {
    portalGraph(id: $id) {
      id
      name
    }
  }
`;

export const RemoveNodesFromPortalGraphMutation = gql`
  mutation RemoveNodesFromPortalGraph($graphId: ID!, $nodeIds: [ID!]!) {
    removeNodesFromPortalGraph(graphId: $graphId, nodeIds: $nodeIds) {
      ...PortalGraphNodeDetails
    }
  }
  ${PortalGraphNodeDetailsFragment}
`;

export const FunctionResultsQuery = gql`
  query FunctionResults($id: ID!) {
    functionResults(id: $id) @client {
      results
    }
  }
`;

/**
 * Users
 */

export const UserProfileQuery = gql`
  query UserProfile($id: ID!) {
    user(id: $id) {
      ...UserDetails
    }
  }
  ${UserDetailsFragment}
`;

/**
 * Classification
 */

export const ClassificationQuery = gql`
  query Classification($id: ID!) {
    classification(id: $id) {
      id
      weight
      fromField {
        id
        name
      }
      toKind {
        id
        name
      }
    }
  }
`;

/**
 * Functions
 */

export const UpdateFunctionMutation = gql`
  mutation UpdateFunction($input: UpdateFunctionInput!) {
    updateFunction(input: $input) {
      updatedFunction {
        ...FunctionDetails
        graph {
          id
          name
          nodes {
            ...PortalGraphNodeDetails
          }
        }
      }
      updatedOperations {
        id
        argumentValues {
          id
        }
      }
    }
  }
  ${FunctionDetailsFragment}
  ${PortalGraphNodeDetailsFragment}
`;

/**
 * Services
 */
export const AllNonSystemServicesQuery = gql`
  query AllNonSystemServices($take: Int) {
    allNonSystemServices(take: $take) {
      id
      name
      serviceType
      thumbnailUrl
      endpointUrl
      isSystem
      isReadOnly
    }
  }
`;

export const ServiceDetailsFragment = gql`
  fragment ServiceDetails on Service {
    kinds {
      ...KindDetails
      ...LinkedOnKind
    }
    functions {
      ...FunctionDetails
    }
  }
  ${LinkedOnKind}
  ${KindDetailsFragment}
  ${FunctionDetailsFragment}
`;

/**
 * Workspaces
 */

export const WorkspaceNameFragment = gql`
  fragment WorkspaceName on Workspace {
    name
  }
`;

export const WorkspaceModelServiceQuery = gql`
  query WorkspaceModelService($id: ID!) {
    workspace(id: $id) {
      id
      modelServiceId
    }
  }
`;

export const WorkspaceInternalServicesIdsFragment = gql`
  fragment WorkspaceInternalServicesIds on Workspace {
    workspaceServiceId
    modelServiceId
    logicServiceId
  }
`;

export const AllUserWorkspacesQuery = gql`
  query AllUserWorkspaces($userId: ID!) {
    user(id: $userId) {
      id
      workspaces {
        id
        name
        thumbnailUrl
        lastOpenedOn
        lockedBy
      }
    }
  }
`;

export const WorkspaceServicesQuery = gql`
  query WorkspaceServices($id: ID!) {
    workspace(id: $id) {
      id
      services {
        id
        name
        serviceType
        endpointUrl
      }
    }
  }
`;

/**
 * Files
 */

export const FileQuery = gql`
  query File($id: ID!) {
    file(id: $id) {
      id
      name
      url
      thumbnailUrl
      mimeType
      size
      progress
      status
    }
  }
`;
