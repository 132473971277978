import {
  KN_ASSISTANT,
  KN_FUNCTION,
  KN_KIND,
  KN_SERVICE,
  KN_WORKSPACE
} from 'util/defines';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';
import React from 'react';

const Root = styled(ToggleButtonGroup)`
  display: flex;
  flex-direction: row;
  background-color: ${p => p.theme.custom.panel.headerBackground};
`;

const StyledToggleButton = styled(ToggleButton)`
  flex: 1;

  :first-child {
    border-bottom-left-radius: 0;
  }
  :last-child {
    border-bottom-right-radius: 0;
  }
  :hover {
    background-color: ${p => p.theme.custom.search.categoryHoverBackground};
  }

  ${p =>
    p.selected &&
    css`
      && {
        background-color: ${p.theme.custom.search.categorySelectedBackground};
        :hover {
          background-color: ${p.theme.custom.search.categorySelectedBackground};
        }
      }
    `}
`;

function CategoryButton({ value, label, ...toggleProps }) {
  return (
    <StyledToggleButton
      value={value}
      {...toggleProps}
      aria-label={`Search Category ${label}`}
    >
      {label}
    </StyledToggleButton>
  );
}

CategoryButton.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default function Categories({ currentCategory, setCategory }) {
  return (
    <Root
      value={currentCategory}
      exclusive
      onChange={(e, newCategory) => newCategory && setCategory(newCategory)}
      aria-label="Search Categories"
      onMouseDown={e => e.preventDefault()}
    >
      <CategoryButton value={KN_SERVICE} label="Services" />
      <CategoryButton value={KN_ASSISTANT} label="Assistants" />
      <CategoryButton value={KN_WORKSPACE} label="Workspaces" />
      <CategoryButton value={KN_FUNCTION} label="Functions" />
      <CategoryButton value={KN_KIND} label="Kinds" />
    </Root>
  );
}

Categories.propTypes = {
  currentCategory: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired
};
