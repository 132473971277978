import {
  GraphType,
  KN_FUNCTION,
  KN_PORTAL_GRAPH,
  KN_SELECTED,
  KN_SELECTION,
  KN_WORKSPACE
} from 'util/defines';
import { findLastIndex, isEmpty, isEqual, last, pick } from 'lodash';
import {
  getActiveGraphIdFromCache,
  getActiveGraphIdFromPath,
  getActiveGraphPathFromCache,
  getGraphTypeFromCache,
  isGraphInWorkspace
} from 'util/graph/portalGraph';

import Logger from 'util/Logger';
import assistantAPI from 'util/assistantAPI';
import { buildInstanceRefForStore } from 'util/storeDataHelpers';
import { getNameForInstance } from 'util/helpers';
import { getWorkspaceLockedBy } from './permissions';
import gql from 'graphql-tag';
import { handleError } from './snackUtils';

const HISTORY_MAX_DEPTH = 1000;
const SelectionFields = ['id', 'kindName', 'kindId'];

export const SelectionQuery = gql`
  query Selection($id: ID!) {
    selection(id: $id) @client {
      id
      selected {
        id
        kindId
        kindName
      }
      graphPathHistory
    }
  }
`;

const SelectedDetailsFragment = gql`
  fragment SelectionChange on Selection {
    id
    selected {
      id # Instance ID
      kindId
      kindName
    }
    graphPathHistory
  }
`;

const ActiveGraphMutation = gql`
  mutation ActiveGraph($workspaceId: ID!, $graphPath: [ID!]!) {
    activeGraphPath(workspaceId: $workspaceId, graphPath: $graphPath)
  }
`;

const WorkspaceActiveGraphFragment = gql`
  fragment WorkspaceActiveGraph on Workspace {
    activeGraphPath
  }
`;

/**
 * The collection of query resolvers used for dealing with the selection
 */
export const SelectionQueryResolver = {
  selection: (_, { id }, { cache }) => {
    let fragment = cache.readFragment({
      id: `${KN_SELECTION}:${id}`,
      fragment: SelectedDetailsFragment
    });

    if (!fragment) {
      fragment = {
        id,
        selected: [],
        graphPathHistory: [],
        __typename: KN_SELECTION
      };
    }

    return fragment;
  }
};

/**
 * Get the current selection information for the workspace.
 *
 * @param {string} workspaceId The workspace id
 * @param {Object} client The apollo client
 */
export const getSelection = (workspaceId, client) => {
  let selection = { selected: [], graphPathHistory: [] };
  try {
    ({ selection } = client.readQuery({
      query: SelectionQuery,
      variables: { id: workspaceId }
    }));
  } catch {
    // Error means that there is no selection in the cache for this workspace
  }
  return selection;
};

/**
 * Updated the information in the workspace about what items are currently
 * selected
 *
 * The selectedInstances objects have the following properties:
 * * id (string): The ID of the Instance being selected
 * * kindId (string): The ID of the Kind the Instance is an instance of. Optional.
 * * kindName (string): The name of the Kind the Instance is an instance of
 *
 * @param {Object} state The Workspace state
 * @param {Array<Object>} selectedInstances Array of items that are selected.
 */
export const updateSelection = ({ workspaceId, client, selectedInstances }) => {
  const selection = getSelection(workspaceId, client);

  // A list is expected by the data model, so update the input if a single element was passed in
  let newSelection = selectedInstances
    ? Array.isArray(selectedInstances)
      ? selectedInstances
      : [selectedInstances]
    : [];

  // If the selection has not changed, then don't update the apollo store
  if (
    selection &&
    isEqual(
      selection.selected.map(s => pick(s, SelectionFields)),
      newSelection.map(ir => pick(ir, SelectionFields))
    )
  ) {
    return;
  }

  const updatedSelection = newSelection.map(ir => {
    let updatedInstanceRef = buildInstanceRefForStore(ir, SelectionFields);
    if (ir.instance) {
      // Selection saves the instance id as its ID
      updatedInstanceRef.id = ir.instance.id;
    }
    return updatedInstanceRef;
  });

  try {
    saveSelectionUpdate({
      workspaceId,
      client,
      selectedInstances: updatedSelection,
      graphPathHistory: selection.graphPathHistory
    });
  } catch (e) {
    Logger.info('Failed to update the selection', e);
  }
};

const saveSelectionUpdate = ({
  workspaceId,
  client,
  selectedInstances,
  graphPathHistory
}) => {
  let updatedSelection = selectedInstances.map(selectedInstanceRef => {
    return {
      ...selectedInstanceRef,
      __typename: KN_SELECTED
    };
  });
  assistantAPI.selectionChanged(selectedInstances);

  const fragmentId = `${KN_SELECTION}:${workspaceId}`;

  const data = {
    id: workspaceId,
    selected: updatedSelection,
    graphPathHistory,
    __typename: KN_SELECTION
  };

  client.writeFragment({
    id: fragmentId,
    fragment: SelectedDetailsFragment,
    data
  });
};

/**
 * Sets the active graph to the provided ID, if a PortalGraph exists in the Workspace with the given ID.
 *
 * @param {Array<string>} selectedGraphPath The path to the active graph
 */
const saveActiveGraphUpdate = async ({
  workspaceId,
  client,
  selectedGraphPath
}) => {
  const activeGraphId = getActiveGraphIdFromCache(workspaceId, client);

  if (!activeGraphId || last(selectedGraphPath) !== activeGraphId) {
    const { canEdit } = await getWorkspaceLockedBy(workspaceId, client);

    if (canEdit) {
      try {
        await client.mutate({
          mutation: ActiveGraphMutation,
          variables: {
            workspaceId: workspaceId,
            graphPath: selectedGraphPath
          }
        });
      } catch (error) {
        handleError(client, 'Unable to save the Active Graph Path', error);
      }
    }

    client.writeFragment({
      id: `${KN_WORKSPACE}:${workspaceId}`,
      fragment: WorkspaceActiveGraphFragment,
      data: {
        activeGraphPath: selectedGraphPath,
        __typename: KN_WORKSPACE
      }
    });
  }
};

/**
 * Removes any graphs that no longer exist from the graph path history.
 * Only the portion of a path that occurs after a non-existent graphId is kept.
 * Also, removes consecutive duplicates that may occur.
 *
 * EXAMPLE
 * This sample graph path history indicates that the user navigated from
 * function "one" to its child "two" and then to "three" and so on until they
 * got to function "five". Then, the user clicked function "three" in the
 * Explorer to select it (or used the breadcrumbs to go to it).
 * Next, if they delete function "three", we have a lot of clean up to do.
 *
 *    const history = [
 *      ['one'],
 *      ['one', 'two'],
 *      ['one', 'two', 'three'],
 *      ['one', 'two', 'three', 'four'],
 *      ['one', 'two', 'three', 'four', 'five'],
 *      ['three']
 *    ];
 *
 * AFTER deleting function "three" the history should look like this:
 *    const history = [
 *      ['one'],
 *      ['one', 'two'],
 *      ['four'],
 *      ['four', 'five']
 *    ];
 *
 * @param {Array<Array<string>>} graphPathHistory The history of active graph paths
 * @param {string} workspaceId The Workspace id
 * @param {Object} client The apollo client
 * @returns {Array<Array<string>>} The cleaned history of active graph paths
 */
const cleanGraphPathHistory = (graphPathHistory, workspaceId, client) => {
  // Figure out which graphIds still exist.
  // Each graphId will appear many times in the history.
  // This will make a simple map for faster lookups.
  let all = {};
  graphPathHistory.forEach(pathArr =>
    pathArr.forEach(graphId => {
      if (!all[graphId]) {
        all[graphId] = isGraphInWorkspace(workspaceId, graphId, client);
      }
    })
  );

  // Remove items in each path prior to a graph that has been removed
  const updatedHistory = graphPathHistory.reduce((acc, pathArr) => {
    const end = pathArr.length;
    const begin = findLastIndex(pathArr, id => !all[id]) + 1;
    if (begin < end) {
      acc.push(begin === 0 ? pathArr : pathArr.slice(begin, end));
    }
    return acc;
  }, []);

  // Remove consecutive duplicates
  const dedupedHistory = updatedHistory.filter((path, idx) => {
    if (idx > 0) {
      return !isEqual(path, updatedHistory[idx - 1]);
    }
    return true;
  });

  return dedupedHistory;
};

/**
 * Cleans the graph path history and then saves it.
 * Also, ensures the currently selected graph is valid.
 *
 * @param {string} workspaceId ID of the workspace
 * @param {Object} client Apollo client
 */
export const cleanAndSaveGraphPathHistory = (workspaceId, client) => {
  const selection = getSelection(workspaceId, client);

  let graphPathHistory = selection?.graphPathHistory;

  // Ensure paths are valid
  if (graphPathHistory) {
    const cleanedHistory = cleanGraphPathHistory(
      graphPathHistory,
      workspaceId,
      client
    );

    // If the graph history has been modified by this filtering,
    // save it so subsequent calls (before another navigation)
    // don't have to refilter all the same things.
    if (!isEqual(graphPathHistory, cleanedHistory)) {
      graphPathHistory = cleanedHistory;

      const selectedGraphPath = isEmpty(cleanedHistory)
        ? []
        : last(cleanedHistory);

      saveGraphNavigation({
        workspaceId,
        client,
        selectedGraphPath,
        graphPathHistory
      });
    }
  }
};

/**
 * Gets the graph path history and returns only valid paths.
 *
 * @param {string} workspaceId ID of the workspace
 * @param {Object} client Apollo client
 * @returns {Array<Array<string>>} The history of active graph paths
 */
const getGraphPathHistory = (workspaceId, client) => {
  const selection = getSelection(workspaceId, client);
  return selection?.graphPathHistory ?? [];
};

/**
 * Get the name to be displayed for a given graph path.
 */
export const getGraphPathName = ({ graphPath, client }) => {
  if (graphPath && !isEmpty(graphPath)) {
    return getNameForInstance({
      apolloClient: client,
      kindName: KN_PORTAL_GRAPH,
      id: last(graphPath)
    });
  }
  return null;
};

/**
 * Returns the graph path history where the graph name is used insted of the id.
 * This should not be used in the real code, but is very useful for debugging
 * graph path history. Therefore, leaving this in so it doesn't have to be recreated.
 *
 * @param {Array<Array<string>>} graphPathHistory The history of active graph paths
 * @param {Object} client The apollo client
 * @returns {Array<Array<string>>} The named history of active graph paths
 */
/* eslint-disable no-unused-vars */
const getNamesForGraphPathHistory = (graphPathHistory, client) => {
  // Get the names for each graphId.
  // Each graphId will appear many times in the history.
  // This will make a simple map for faster lookups.
  let all = {};
  graphPathHistory.forEach(pathArr =>
    pathArr.forEach(graphId => {
      if (!all[graphId]) {
        all[graphId] = getNameForInstance({
          apolloClient: client,
          kindName: KN_PORTAL_GRAPH,
          id: graphId
        });
      }
    })
  );

  const namedHistory = graphPathHistory.map(pathArr => {
    return pathArr.map(graphId => all[graphId]);
  });

  return namedHistory;
};
/* eslint-enable no-unused-vars */

/**
 * Get the name to be displayed for a navigating back in
 * the history of active graph paths.
 */
export const getNavigateBackName = ({ workspaceId, client }) => {
  const graphPathHistory = getGraphPathHistory(workspaceId, client);

  if (graphPathHistory && graphPathHistory.length > 1) {
    const graphPath = graphPathHistory[graphPathHistory.length - 2];
    return getGraphPathName({ graphPath, client });
  }
};

/**
 * Indicates if it is possible to navigate back in the history of
 * active graph paths.
 *
 * @param {string} workspaceId The Workspace id
 * @param {Object} client The apollo client
 */
export const canNavigateBack = ({ workspaceId, client }) => {
  const graphPathHistory = getGraphPathHistory(workspaceId, client);
  return graphPathHistory && graphPathHistory.length > 1;
};

/**
 * Navigate back one in the history of active graph paths.
 *
 * @param {string} workspaceId The Workspace id
 * @param {Object} client The apollo client
 */
export const navigateBack = ({ workspaceId, client }) => {
  const graphPathHistory = getGraphPathHistory(workspaceId, client);

  if (graphPathHistory && graphPathHistory.length > 1) {
    const selectedGraphPath = graphPathHistory[graphPathHistory.length - 2];

    const updatedHistory = graphPathHistory.slice(
      0,
      graphPathHistory.length - 1
    );

    try {
      saveGraphNavigation({
        workspaceId,
        client,
        selectedGraphPath,
        graphPathHistory: updatedHistory
      });
    } catch (e) {
      Logger.info('Failed to navigate to previous graph', e);
    }
  }
};

/**
 * Navigate to a specific graph path. Adds new path to the history.
 *
 * @param {string} workspaceId The Workspace id
 * @param {Object} client The apollo client
 * @param {Array<string>} graphPath The path to set as the active graph
 * @param {Object}  selectedChild The selected item within the new graph
 */
export const navigateToGraph = ({
  workspaceId,
  client,
  graphPath,
  selectedChild
}) => {
  let graphPathHistory = getGraphPathHistory(workspaceId, client);

  const activeGraphPath = getActiveGraphPathFromCache(workspaceId, client);
  const activeGraphId = getActiveGraphIdFromPath(activeGraphPath);
  const nextActiveGraphId = getActiveGraphIdFromPath(graphPath);

  if (activeGraphId === nextActiveGraphId && !selectedChild) {
    // Don't update anything if the active graph is the same and there's no child selection to make
    return;
  }

  try {
    // Ensure the current active path is in the history.
    if (isEmpty(graphPathHistory) && activeGraphPath) {
      graphPathHistory.push(activeGraphPath);
    }

    if (activeGraphId !== nextActiveGraphId) {
      // Add path to history.
      graphPathHistory.push(graphPath);

      //Only keep the max depth number of active paths.
      if (graphPathHistory.length > HISTORY_MAX_DEPTH) {
        graphPathHistory = graphPathHistory.slice(0, HISTORY_MAX_DEPTH);
      }
    }

    saveGraphNavigation({
      workspaceId,
      client,
      selectedGraphPath: graphPath,
      graphPathHistory,
      selectedChild
    });
  } catch (e) {
    Logger.info('Failed to navigate to another graph', e);
  }
};

/**
 * Navigate to a specific graph path.
 *
 * @param {string} workspaceId The Workspace id
 * @param {Object} client The apollo client
 * @param {Array<string>} selectedGraphPath The path to set as the active graph
 * @param {Array<Array<string>>} graphPathHistory The history of active graph paths
 * @param {Object}  selectedChild The selected item within the new graph
 */
const saveGraphNavigation = ({
  workspaceId,
  client,
  selectedGraphPath,
  graphPathHistory,
  selectedChild
}) => {
  const nextActiveGraphId = last(selectedGraphPath);
  const graphType = getGraphTypeFromCache(nextActiveGraphId, client);

  const selectedInstances = [
    selectedChild
      ? buildInstanceRefForStore(selectedChild, SelectionFields)
      : buildInstanceRefForStore(
          {
            id: nextActiveGraphId,
            kindId: null,
            kindName:
              graphType === GraphType.FUNCTION ? KN_FUNCTION : KN_PORTAL_GRAPH
          },
          SelectionFields
        )
  ];

  saveSelectionUpdate({
    workspaceId,
    client,
    selectedInstances,
    graphPathHistory
  });

  saveActiveGraphUpdate({ workspaceId, client, selectedGraphPath });
};
