import { IconButton as MuiIconButton, Tooltip } from '@material-ui/core';

import React from 'react';
import styled from 'styled-components';

const TooltipChild = styled.div`
  display: flex;
`;

/**
 * IconButton which also encapsulates the styling and tooltip.
 *
 * @param {Object} props Component props
 * @param {string} props.title Title for the icon button
 * @param {Icon} props.ActionIcon A MUI icon for the button.
 * @param {bool} props.useMuiTooltip Indicates if the MUI tooltip should be used.
 *    This is important because within Canvas/Graphs MUI tooltips cause significant
 *    performance issues.
 * @param {Object} props.children Child react components. Intended for when ActionIcon can't be used.
 * @param {Object} props.other Any other props to be passed on to the MUI IconButton.
 */
export function IconButton(props) {
  const { title, ActionIcon, useMuiTooltip = true, children, ...other } = props;

  return (
    <>
      {useMuiTooltip && (
        <Tooltip title={title}>
          <TooltipChild>
            <MuiIconButton aria-label={title} {...other}>
              {ActionIcon && <ActionIcon fontSize="inherit" />}
              {children}
            </MuiIconButton>
          </TooltipChild>
        </Tooltip>
      )}

      {!useMuiTooltip && (
        <MuiIconButton aria-label={title} title={title} {...other}>
          {ActionIcon && <ActionIcon fontSize="inherit" />}
          {children}
        </MuiIconButton>
      )}
    </>
  );
}
