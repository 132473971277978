// A Collection of functions to help with updating Functions

import * as defs from 'util/defines';

import { ImplementationDetailsFragment } from 'graphql/Portal';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { isListField } from 'util/instanceUtils';

const UpdateFunctionImplementationMutation = gql`
  mutation UpdateFunctionImplementation(
    $functionId: ID!
    $implementation: UpdateImplementationInput!
  ) {
    updateFunctionImplementation(
      functionId: $functionId
      implementation: $implementation
    ) {
      id
      implementation {
        ...ImplementationDetails
      }
    }
  }
  ${ImplementationDetailsFragment}
`;

/**
 * Cleans up the connections going from the output of an operation
 *
 * @param {Object} implementation The implementation to look through
 * @param {string} operationId The ID of the operation to clean connections from
 */
export function cleanOperationConnections(implementation, operationId) {
  if (
    implementation?.entrypoint &&
    implementation.entrypoint.id === operationId
  ) {
    implementation.entrypoint = null;
  }
  if (implementation?.operations) {
    implementation.operations.forEach(op => {
      if (op.argumentValues) {
        op.argumentValues = op.argumentValues.filter(
          av =>
            !av.operation ||
            (av.operation !== operationId && av.operation.id !== operationId)
        );
      }
    });
  }
}

/**
 * Figures out the OperationType to use based on the two connected fields
 *
 * @param {Object} fromField The field that the information is coming from
 * @param {Object} toField The field that the information if going to
 * @param {Object} toFunction The Function that the toField lives in
 * @return {string} The OutputType to use based on the given fields.
 */
export function getOperationType(fromField, fromOpType, toField, toFunction) {
  const isFromList = !!(fromField && isListField(fromField.modifiers));
  const isToList = !!(toField && isListField(toField.modifiers));

  if (
    (isFromList || fromOpType === defs.OperationType.MAP) &&
    !isToList &&
    toFunction.arguments.length === 1
  ) {
    return defs.OperationType.MAP;
  }

  return defs.OperationType.APPLY;
}

export function updateOperationType(
  op,
  opCollection,
  parentFunction,
  ancestors = []
) {
  // the list of information we need to test the function
  const toFunction = op.function;
  let toField = null;
  let fromOperationType = null;
  let fromField = null;

  // make sure we don't have an infinite loop
  if (ancestors.includes(op)) {
    throw new Error(
      `Circular references are not allowed in a Function's implementation`
    );
  }

  // update any operations farther down the chain first
  if (op.argumentValues) {
    const updatedAncestors = ancestors.concat(op);

    op.argumentValues.forEach(av => {
      // save our field information
      toField = toFunction.arguments.find(
        a => a.id === av.argument || a.id === av.argument.id
      );

      // see if we have the operation we need to update
      if (av.operation) {
        const fromOperation = opCollection.find(
          op => op.id === av.operation || op.id === av.operation.id
        );
        if (fromOperation && op !== fromOperation) {
          // update the operation
          updateOperationType(
            fromOperation,
            opCollection,
            parentFunction,
            updatedAncestors
          );

          // save the important data
          fromOperationType = fromOperation.type;
          fromField = { modifiers: fromOperation.function.outputModifiers };
        }
      } else if (av.argumentRef) {
        // Build up the from field based on the parent function
        fromField = parentFunction.arguments.find(a => a.id === av.argumentRef);
      }
    });
  }

  // update the operation type
  op.type = getOperationType(fromField, fromOperationType, toField, toFunction);
}

export function autoUpdateOperationTypes(implementation, parentFunction) {
  // make sure there is an implementation with operations in it, a valid
  // entry point, and the parent function is passed in.
  if (
    !implementation ||
    isEmpty(implementation.operations) ||
    !implementation.entrypoint ||
    !parentFunction
  ) {
    return;
  }

  const entryOp = implementation.operations.find(
    op =>
      op.id === implementation.entrypoint ||
      op.id === implementation.entrypoint.id
  );
  if (!entryOp) return;

  // start updating the operation types
  updateOperationType(entryOp, implementation.operations, parentFunction);
}

/**
 * Sends an updated function implementation over to the server
 *
 * @param {ApolloClient} apolloClient The current apollo client
 * @param {string} functionId The ID of the function we are working with
 * @param {Object} implementation The Implementation updates we are sending over
 * @param {Array<Object>} nodeLayouts The nodes to add to the layout
 * @return {Promise<Object>} Contains the response from the GraphQL mutation
 */
export const updateFunctionImplementation = (
  apolloClient,
  functionId,
  implementation
) => {
  return apolloClient.mutate({
    mutation: UpdateFunctionImplementationMutation,
    variables: {
      functionId,
      implementation: cleanFunctionImplementation(implementation)
    }
  });
};

/**
 * Cleans up a function implementation for sending as a ImplementationInput.
 * Removed `__typename` and converts objects to ids where needed.
 *
 * @param {Object} implementation The function implementation to clean
 * @return {Object} a cleaned copy of the function implementation
 */
export function cleanFunctionImplementation(implementation) {
  let cleanImple = { ...implementation };

  delete cleanImple.__typename;

  if (cleanImple.entrypoint && cleanImple.entrypoint.id) {
    cleanImple.entrypoint = cleanImple.entrypoint.id;
  }

  if (cleanImple.operations) {
    cleanImple.operations = cleanImple.operations.map(op => {
      const cleanOp = { ...op };

      delete cleanOp.__typename;

      if (cleanOp.function && cleanOp.function.id) {
        cleanOp.function = cleanOp.function.id;
      }

      if (cleanOp.argumentValues) {
        cleanOp.argumentValues = cleanOp.argumentValues.map(av => {
          const cleanAv = { ...av };

          delete cleanAv.__typename;

          if (cleanAv.argument && cleanAv.argument.id) {
            cleanAv.argument = cleanAv.argument.id;
          }

          if (cleanAv.operation && cleanAv.operation.id) {
            cleanAv.operation = cleanAv.operation.id;
          }

          return cleanAv;
        });
      }

      return cleanOp;
    });
  }

  return cleanImple;
}

/**
 * Removes a list of operations from the supplied function implementation.
 *
 * @param {ApolloClient} apolloClient The current apollo client
 * @param {string} functionId The id of the function we are working in
 * @param {Object} implementation The implementation to update
 * @param {Array<string>} removedOperationIds The ids of the operations to remove
 * @param {Array<string>} removedOperationConnectionIds The ids of the operation connections to remove
 * @param {boolean} persistChanges When set to true, this will send the changes to the server
 * @returns {Promise<object>} The promise of the graphql mutation to update the Operations
 */
export function removeOperationsFromImplementation({
  client,
  func,
  implementation,
  removedOperationIds,
  removedOperationConnectionIds,
  persistChanges = true
}) {
  if (implementation) {
    if (removedOperationIds) {
      implementation.operations = implementation.operations.filter(
        op => !removedOperationIds.includes(op.id)
      );
      removedOperationIds.forEach(ro =>
        cleanOperationConnections(implementation, ro)
      );
    }

    if (removedOperationConnectionIds) {
      removedOperationConnectionIds.forEach(conn => {
        if (conn.id === implementation.id) {
          implementation.entrypoint = null;
        } else {
          implementation.operations.forEach(op => {
            op.argumentValues = op.argumentValues.filter(
              av => av.id !== conn.id
            );
          });
        }
      });
    }

    // update the operation types
    autoUpdateOperationTypes(implementation, func);

    // save the implementation changes
    if (persistChanges) {
      return updateFunctionImplementation(client, func.id, implementation);
    }
  }
}
