import HighlightedText from './HighlightedText';
import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export function Name({ className, text, sections, noWrap }) {
  return (
    <HighlightedText
      text={text}
      sections={sections}
      component={Typography}
      noWrap={noWrap}
      variant="body1"
      className={className}
    />
  );
}

const DisabledTypography = styled(Typography)`
  color: ${p => p.theme.palette.text.disabled};
`;

export function NameContext({ className, text, noWrap }) {
  return (
    <DisabledTypography className={className} noWrap={noWrap} variant="caption">
      {text}
    </DisabledTypography>
  );
}

export function IdText({ text, sections, noWrap }) {
  return (
    <HighlightedText
      text={text}
      sections={sections}
      component={DisabledTypography}
      noWrap={noWrap}
      variant="caption"
    />
  );
}

export function Description({ text, sections, noWrap }) {
  return (
    <HighlightedText
      text={text}
      sections={sections}
      component={Typography}
      noWrap={noWrap}
      variant="caption"
      color="textSecondary"
    />
  );
}
