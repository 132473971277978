import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const FEEDBACK_MAX_LENGTH = 5000;

export const HelpText = styled(Typography).attrs({
  variant: 'caption',
  component: 'p'
})`
  color: ${p => p.theme.palette.text.disabled};
`;

export const ErrorText = styled(Typography).attrs({
  variant: 'caption',
  component: 'p'
})`
  color: ${p => p.theme.palette.error.main};
`;
