import PropTypes from 'prop-types';

/**
 * The shape of a sections of text to display for a search result. Allows for
 * easily adding additional styling on text that was the hit for the search
 * results.
 */
export const SearchTextSections = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    isHighlighted: PropTypes.bool.isRequired
  }).isRequired
);

/**
 * The shape of a single result that comes back from a search.
 */
export const SearchResultType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  idSections: SearchTextSections.isRequired,
  name: PropTypes.string.isRequired,
  nameSections: SearchTextSections.isRequired,
  nameContext: PropTypes.string,
  description: PropTypes.string,
  descriptionSections: SearchTextSections.isRequired
});
