import * as defs from 'util/defines';

import { toIdValue } from 'apollo-utilities';

export function getRedirects(getCache) {
  return {
    /**
     * Resolves a Query for a File from the cache using the passed in ID.
     */
    file: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_FILE,
        id: args.id
      }),
    /**
     * Resolves a Query for Files from the cache using the passed in IDs.
     */
    files: (_, args) =>
      args.ids.map(id =>
        toIdValue(
          getCache().config.dataIdFromObject({
            __typename: defs.KN_FILE,
            id
          })
        )
      ),
    /**
     * Resolves a Query for a Function from the cache using the passed in ID.
     */
    function: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_FUNCTION,
        id: args.id
      }),
    // This is needed to correctly resolve FunctionExecutionArguments that are stored as part of the
    // arguments collection in a FunctionExecution object.
    functionExecutionArgument: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_FUNCTION_EXECUTION_ARGUMENT,
        id: args.id
      }),
    /**
     * Resolves a Query for a Instance from the cache using the passed in id and kindId.
     */
    instance: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_INSTANCE,
        ...args.instanceRef
      }),
    /**
     * Resolves a Query for a Kind from the cache using the passed in ID.
     */
    kind: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_KIND,
        id: args.id
      }),
    /**
     * Resolves a Query for an Operation from the cache using the passed in ID.
     */
    operation: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_OPERATION,
        id: args.id
      }),
    /**
     * Resolves a Query for populating an InstanceRef from the cache using the passed in input.
     */
    populateInstanceRef: (_, args, { getCacheKey }) =>
      args.instanceRef &&
      getCacheKey({
        __typename: defs.KN_INSTANCE_REF,
        id: args.instanceRef.id
      }),
    /**
     * Resolves a Query for a Portal Graph from the cache using the passed in ID.
     */
    portalGraph: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_PORTAL_GRAPH,
        id: args.id
      }),
    /**
     * Resolves a Query for Portal Graphs from the cache using the passed in IDs.
     */
    portalGraphs: (_, args) =>
      args.ids.map(id =>
        toIdValue(
          getCache().config.dataIdFromObject({
            __typename: defs.KN_PORTAL_GRAPH,
            id
          })
        )
      ),

    /**
     * Resolves a Query for a portal-graph node from the cache using the passed in ID.
     */
    portalGraphNode: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_PORTAL_GRAPH_NODE,
        id: args.id
      }),
    /**
     * Resolves a Query for a Service from the cache using the passed in ID.
     */
    service: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_SERVICE,
        id: args.id
      }),
    /**
     * Resolves a Query for a Workspace from the cache using the passed in ID.
     */
    workspace: (_, args, { getCacheKey }) =>
      getCacheKey({
        __typename: defs.KN_WORKSPACE,
        id: args.id
      })
  };
}
