import {
  FieldValueDetailsFragment,
  FunctionDetailsFragment,
  FunctionDetailsFragmentName,
  ImplementationDetailsFragment,
  PortalGraphNodeDetailsFragment
} from 'graphql/Portal';
import {
  FunctionType,
  IconOverlayName,
  KN_FUNCTION,
  KN_IMPLEMENTATION,
  KN_INSTANCE,
  KN_OPERATION
} from './defines';
import {
  addReferencesToCreatedEntities,
  removeReferencesToDeletedEntities
} from './entity';
import {
  getInstanceFieldOrdinalByName,
  updateInstanceNameInCache
} from './instanceUtils';

import { getFunctionKind } from 'util/helpers';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import uuidv4 from 'uuid/v4';

const LAMBDA_SERVICE_ID_TAIL = '_lambda';

const FunctionQuery = gql`
  query Function($id: ID!) {
    function(id: $id) {
      id
      name
    }
  }
`;

const FunctionsQuery = gql`
  query FunctionNames($ids: [ID!]!) {
    functions(ids: $ids) {
      id
      name
    }
  }
`;

const FunctionValidateQuery = gql`
  query FunctionValidate($id: ID!) {
    function(id: $id) {
      id
      isGenerated
      service {
        id
      }
    }
  }
`;

export const AddFunctionFunctionGraphFragment = gql`
  fragment AddFunctionFunctionGraph on PortalGraph {
    id
    name
    type
    lockedBy
    workspace {
      id
    }
    function {
      id
    }
    nodes {
      id
      knowledgeGraphNode {
        id
      }
      functionGraphNode {
        id
        operationId
      }
    }
  }
`;

const AddFunctionsMutation = gql`
  mutation AddFunctions($input: [FunctionInput!]!) {
    addFunctions(input: $input) {
      newFunctions {
        ...FunctionDetails
        graph {
          ...AddFunctionFunctionGraph
        }
      }
      workspaceServiceReferences {
        serviceId
        workspaceIds
      }
      updatedServices {
        id
      }
      updatedWorkspaces {
        id
        modelServiceId
        logicServiceId
        workspaceServiceId
      }
    }
  }
  ${FunctionDetailsFragment}
  ${AddFunctionFunctionGraphFragment}
`;

const DeleteFunctionMutation = gql`
  mutation DeleteFunction($functionId: ID!) {
    deleteFunction(id: $functionId) {
      updatedPortalGraphs {
        id
      }
      updatedWorkspaces {
        id
      }
      updatedImplementations {
        id
      }
      updatedOperations {
        id
      }
      updatedServices {
        id
      }
      workspaces {
        id
      }
      deletedPortalGraphNodeIds
      deletedInstanceRefIds
      deletedArgumentValueIds
      deletedOperationIds
      workspaceServiceReferences {
        serviceId
        workspaceIds
      }
      existingEntityIds
    }
  }
`;

const UpdateFunctionsMutation = gql`
  mutation UpdateFunctions($input: [UpdateFunctionInput!]!) {
    updateFunctions(input: $input) {
      updatedFunctions {
        id
        name
        graphqlOperationType
        functionType
        isGenerated
        service {
          id
          name
        }
        arguments {
          id
          name
          type
          description
          modifiers
          typeKindId
          kind {
            id
            name
          }
        }
        outputType
        outputKindId
        outputModifiers
        kind {
          id
          name
        }
        implementation {
          ...ImplementationDetails
        }
        graph {
          id
          name
          type
          nodes {
            ...PortalGraphNodeDetails
          }
        }
      }
      updatedOperations {
        id
        argumentValues {
          id
        }
      }
    }
  }

  ${ImplementationDetailsFragment}
  ${PortalGraphNodeDetailsFragment}
`;

const FunctionInstanceUpdateName = 'FunctionInstanceUpdate';
const FunctionInstanceUpdateFragment = gql`
  fragment FunctionInstanceUpdate on Instance {
    id
    kindId
    fieldIds
    fieldValues {
      ...FieldValueDetails
    }
  }
  ${FieldValueDetailsFragment}
`;

const FunctionIsDeletedFragment = gql`
  fragment FunctionIsDeleted on Function {
    isDeleted
  }
`;

const DeleteOperationsFragment = gql`
  fragment DeleteOperations on Implementation {
    id
    entrypoint {
      id
    }
    operations {
      id
    }
  }
`;

const DeleteArgumentValuesFragment = gql`
  fragment DeleteArgumentValues on Operation {
    id
    argumentValues {
      id
    }
  }
`;

const LambdaFunctionTestFragment = gql`
  fragment LambdaFunctionTest on Function {
    id
    implementation {
      id
      operations {
        id
        function {
          id
          service {
            id
          }
        }
      }
    }
  }
`;

/**
 * Checks to see that the function exists, is from the logic service, and is not
 * a generated function.
 *
 * @param {string} id The ID of the function to check
 * @param {string} logicServiceId The ID of the logic service the function should be from
 * @param {ApolloClient} client The client to use to access the cache
 */
async function isFunctionEditable(id, logicServiceId, client) {
  const func = await getFunctionById(id, client, FunctionValidateQuery);
  if (!func) {
    throw new Error('Invalid Function ID supplied.');
  }

  if (!func.service || func.service.id !== logicServiceId) {
    throw new Error('Only Functions in the current workspace can be updated.');
  }

  if (func.isGenerated) {
    throw new Error('Generated Functions cannot be updated.');
  }
}

/**
 * Prepares a function for being passed to the addFunction or addFunctions
 * resolvers.
 *
 * @param {AddFunctionInput} func The function input to prepare
 * @param {string} logicServiceId The logic service the function will live in.
 */
function prepareAddFunctionInput(func, logicServiceId) {
  return {
    graphqlOperationType: 'QUERY',
    functionType: 'CKG',
    outputType: 'STRING',
    outputModifiers: [],
    ...func,
    arguments: func.arguments
      ? func.arguments.map(arg => ({
          ...arg,
          id: arg.id || uuidv4()
        }))
      : [],
    service: logicServiceId
  };
}

/**
 * Creates a function.
 *
 * @param {Object} func The function object.
 * @param {string} logicServiceId Backing logic service ID.
 * @param {Object} client Graphql client.
 * @return {Promise<Function>} The created function.
 */
export async function createFunction(func, logicServiceId, client) {
  return (await createFunctions([func], logicServiceId, client))[0];
}

/**
 * Creates a group of functions.
 *
 * @param {Array<AddFunctionInput>} functions The functions to create.
 * @param {string} logicServiceId Backing logic service ID.
 * @param {Object} client Graphql client.
 * @return {Promise<Array<Function>>} The created functions.
 */
export async function createFunctions(functions, logicServiceId, client) {
  const input = functions.map(func =>
    prepareAddFunctionInput(func, logicServiceId)
  );

  const { data: returnData } = await client.mutate({
    mutation: AddFunctionsMutation,
    variables: { input },
    update: (store, { data }) => {
      const { newFunctions } = data.addFunctions;

      addReferencesToCreatedEntities(data.addFunctions, store);

      // Update function queries in the cache in case they have been stored as null.
      for (const func of newFunctions) {
        store.writeQuery({
          query: FunctionQuery,
          variables: { id: func.id },
          data: {
            function: {
              id: func.id,
              name: func.name,
              __typename: KN_FUNCTION
            }
          }
        });
      }
    }
  });

  return returnData.addFunctions.newFunctions;
}

/**
 * Updates a group of functions.
 *
 * @param {Array<Object>} functionInputs The updated information about the functions.
 * @param {string} workspaceId The ID of the workspace.
 * @param {string} logicServiceId The ID of the backing logic service.
 * @param {Object} client Graphql client instance.
 * @return {Promise<Array<Function>>} The updated functions.
 */
export async function updateFunctions(
  functionInputs,
  workspaceId,
  logicServiceId,
  client
) {
  // If the service id is specified, make sure that it is set to the
  // logicServiceId.
  for (const functionInput of functionInputs) {
    if (
      functionInput.service !== undefined &&
      functionInput.service !== logicServiceId
    ) {
      throw new Error(
        'Only functions in the current workspace can be updated.'
      );
    }

    // Make sure we have a valid function to update
    await isFunctionEditable(functionInput.id, logicServiceId, client);
  }

  // Call the update mutation
  const { data, errors } = await client.mutate({
    mutation: UpdateFunctionsMutation,
    variables: { tenantId: 0, input: functionInputs },
    update: (store, { data }) => {
      if (data.updateFunctions) {
        functionInputs.forEach(functionInput => {
          if (functionInput.name) {
            updateInstanceNameInCache(
              store,
              workspaceId,
              functionInput.id,
              functionInput.name
            );
          }
        });
      }
    }
  });

  // Throw any GraphQL errors that the server returned
  if (errors) {
    throw new Error(
      errors.reduce(
        (acc, cur) => acc.concat(acc.length ? ', ' : '', cur.message),
        ''
      )
    );
  }

  return data.updateFunctions;
}

/**
 * Deletes a function and removes references to it.
 *
 * @param {string} functionId Id of the function to be deleted
 * @param {string} workspaceId Id of the current Workspace
 * @param {string} logicServiceId Id of the workspace logic service
 * @param {Object} client The apollo client to access the store through
 * @throws Will throw an exception if deleting the function fails
 */
export async function deleteFunction(
  functionId,
  workspaceId,
  logicServiceId,
  client
) {
  // Make sure we have a valid function to update
  await isFunctionEditable(functionId, logicServiceId, client);

  return client.mutate({
    mutation: DeleteFunctionMutation,
    variables: { functionId },
    update: (store, { data }) => {
      const { existingEntityIds } = data.deleteFunction;
      removeReferencesToDeletedEntities(data.deleteFunction, store, {
        functionIds: [functionId]
      });

      store.writeFragment({
        id: `${KN_FUNCTION}:${functionId}`,
        fragment: FunctionIsDeletedFragment,
        data: { isDeleted: true, __typename: KN_FUNCTION }
      });

      if (!existingEntityIds.length) {
        // Function does not exist on the server.
        // Remove any references out of the current Workspace.
        // TODO QP-483: Use a call to removeReferencesToDeletedEntities
      }
      //TODO QP-483: Update selection to remove deleted Function
    }
  });
}

export function getFunctionDetailsFromCache(id, client) {
  return client.readFragment({
    id: `${KN_FUNCTION}:${id}`,
    fragment: FunctionDetailsFragment,
    fragmentName: FunctionDetailsFragmentName
  });
}

/**
 * Returns a function matching the supplied ID.
 *
 * @param {string} id The function ID.
 * @param {Object} client The graphql client.
 * @param {Object} query The GraphQL query, defaults to a basic function query
 * @return {Promise<Function>} Returned function.
 */
export async function getFunctionById(id, client, query = FunctionQuery) {
  const func = await client.query({
    query,
    variables: { id }
  });
  return func.data.function;
}

/**
 * Returns a group of function matching the supplied IDs.
 *
 * @param {Array<string>} ids The function IDs.
 * @param {Object} client The graphql client.
 * @param {Object} query The GraphQL query, defaults to a basic function query
 * @return {Promise<Array<Function>>} Returned functions.
 */
export async function getFunctionsById(ids, client, query = FunctionsQuery) {
  const func = await client.query({
    query,
    variables: { ids }
  });
  return func.data.functions;
}

/**
 * Updates the instance form of the function for display in the context panel.
 *
 * @param {Function} func The function with the information to update from
 * @param {ApolloClient} client The client to use to access the cache
 */
export function updateFunctionInstance(func, client) {
  if (!func) return;

  const funcKind = getFunctionKind(client);
  const fragmentId = `${funcKind.id}:${KN_INSTANCE}:${func.id}`;
  try {
    const instance = client.readFragment({
      id: fragmentId,
      fragment: FunctionInstanceUpdateFragment,
      fragmentName: FunctionInstanceUpdateName
    });
    if (!instance) return;

    const nameIndex = getInstanceFieldOrdinalByName(funcKind, instance, 'name');
    const descriptionIndex = getInstanceFieldOrdinalByName(
      funcKind,
      instance,
      'description'
    );
    const gqlOpTypeIndex = getInstanceFieldOrdinalByName(
      funcKind,
      instance,
      'graphqlOperationType'
    );

    const fieldValues = instance.fieldValues.map((fv, index) => {
      switch (index) {
        case nameIndex:
          return func.name ? { ...fv, STRING: func.name } : fv;
        case descriptionIndex:
          return func.description ? { ...fv, STRING: func.description } : fv;
        case gqlOpTypeIndex:
          return func.graphqlOperationType
            ? { ...fv, STRING: func.graphqlOperationType }
            : fv;
        default:
          return fv;
      }
    });

    client.writeFragment({
      id: fragmentId,
      fragment: FunctionInstanceUpdateFragment,
      fragmentName: FunctionInstanceUpdateName,
      data: {
        ...instance,
        fieldValues
      }
    });
  } catch (e) {
    // Nothing to fo here, as this just means that we don't have the data
    // available in cache to update.
  }
}

/**
 * Checks to see if the function is controlled by the lambda assistant.  This
 * check is done by making sure that there is an implementation with only one
 * operation, and the function in the operation belongs to a service with an ID
 * ending with '_lambda'.
 *
 * @param {Function} functionId The ID of the Function to check.
 * @returns {boolean} True if its a lambda, false if it isn't or there is not
 * enough information.
 */
export function isLambdaFunctionById(functionId, client) {
  try {
    const func = client.readFragment({
      id: `${KN_FUNCTION}:${functionId}`,
      fragment: LambdaFunctionTestFragment
    });

    return isLambdaFunction(func);
  } catch {
    return false;
  }
}

/**
 * Checks to see if the function is controlled by the lambda assistant.  This
 * check is done by making sure that there is an implementation with only one
 * operation, and the function in the operation belongs to a service with an ID
 * ending with '_lambda'.
 *
 * @param {Function} func The function to check.
 * @returns {boolean} True if its a lambda, false if it isn't or there is not
 * enough information.
 */
export function isLambdaFunction(func) {
  if (func?.implementation?.operations.length === 1) {
    const op = func.implementation.operations[0];
    if (op?.function?.service?.id?.endsWith(LAMBDA_SERVICE_ID_TAIL)) {
      return true;
    }
  }

  return false;
}

/**
 * Checks to see if there is an overlay to use with displaying an icon next to
 * the passed in function's name.  Returns the name of the overlay to pass into
 * the Icon component if there is one, otherwise it will return undefined.
 *
 * @param {Object} func The Function to get the overlay for.
 * @param {string} serviceId ID of the service the function should be in.
 * @returns {string|undefined} The name of the overlay to display if there is one.
 */
export function getOverlayForFunction(func, serviceId) {
  // Only add overlays to the function if it is from the correct service
  if (func?.service?.id !== serviceId) {
    return;
  }

  // Use the overlay for Lambdas on Lambda Functions
  if (isLambdaFunction(func)) {
    return IconOverlayName.LAMBDA;
  }

  // Use the overlay for Function Graphs on CKG Functions
  if (func?.functionType === FunctionType.CKG) {
    return IconOverlayName.FUNCTION_GRAPH;
  }
}

/**
 * Removes Operations from Implementations in the cache.
 *
 * @param {Array<string>} operationIds The IDs of the Operations to remove
 * @param {Array<string>} implementationIds The IDs of the Implementations to remove the Operations from
 * @param {ApolloClient} client The apollo client to use
 */
export function removeOperationsFromImplementations(
  operationIds,
  implementationIds,
  client
) {
  if (isEmpty(operationIds)) {
    return;
  }

  for (const implementationId of implementationIds) {
    try {
      const implementation = client.readFragment({
        id: `${KN_IMPLEMENTATION}:${implementationId}`,
        fragment: DeleteOperationsFragment
      });

      implementation.operations = implementation.operations.filter(
        op => !operationIds.includes(op.id)
      );

      if (operationIds.includes(implementation.entrypoint.id)) {
        implementation.entrypoint = null;
      }

      client.writeFragment({
        id: `${KN_IMPLEMENTATION}:${implementationId}`,
        fragment: DeleteOperationsFragment,
        data: implementation
      });
    } catch {
      // Ignore errors thrown because the Implementation is not in the cache.
    }
  }
}

/**
 * Removes Argument Values from Operations in the cache.
 *
 * @param {Array<string>} argumentValueIds The IDs of the Argument Values to remove
 * @param {Array<string>} operationIds The IDs of the Operations to remove the Argument Values from
 * @param {ApolloClient} client The apollo client to use
 */
export function removeArgumentValuesFromOperations(
  argumentValueIds,
  operationIds,
  client
) {
  if (isEmpty(argumentValueIds)) {
    return;
  }

  for (const operationId of operationIds) {
    try {
      const operation = client.readFragment({
        id: `${KN_OPERATION}:${operationId}`,
        fragment: DeleteArgumentValuesFragment
      });

      operation.argumentValues = operation.argumentValues.filter(
        argVal => !argumentValueIds.includes(argVal.id)
      );

      client.writeFragment({
        id: `${KN_OPERATION}:${operationId}`,
        fragment: DeleteArgumentValuesFragment,
        data: operation
      });
    } catch {
      // Ignore errors thrown because the Operation is not in the cache.
    }
  }
}
