import PropTypes from 'prop-types';
import React from 'react';

/**
 * The React Context for the Graph information
 */
export const GraphContext = React.createContext(null);

/**
 * The provider used to set the updated Graph information.
 */
export function GraphContextProvider({
  children,
  engine,
  getViewportDimensions,
  activeGraphId
}) {
  return (
    <GraphContext.Provider
      value={{
        engine,
        getViewportDimensions,
        activeGraphId
      }}
    >
      {children}
    </GraphContext.Provider>
  );
}

GraphContextProvider.propTypes = {
  engine: PropTypes.object.isRequired,
  getViewportDimensions: PropTypes.func.isRequired,
  activeGraphId: PropTypes.string
};
