// Styles used to color the console logs
const BaseStyle = 'color: lightgray';
const TextStyle = 'color: white';
const DebugLabelStyle = 'color: blueviolet';
const InfoLabelStyle = 'color: green';
const WarnLabelStyle = 'color: lightbrown';
const ErrLabelStyle = 'color: red';

// Possible Log Levels
const LevelDebug = 3;
const LevelInfo = 2;
const LevelWarning = 1;
const LevelError = 0;
const LevelNone = -1;

/**
 * Log levels are set with the MAANA_ENV property LOGGING_LEVEL. Defaults to
 * none in production builds and warning in development builds. Possible values
 * are: debug, info, warn, warning, err, error, and none.
 */
const LogLevel = (() => {
  switch (window.MAANA_ENV?.LOGGING_LEVEL?.toLocaleLowerCase()) {
    case 'debug':
      return LevelDebug;
    case 'info':
      return LevelInfo;
    case 'warn':
    case 'warning':
      return LevelWarning;
    case 'err':
    case 'error':
      return LevelError;
    case 'none':
      return LevelNone;
    default:
      return process.env.NODE_ENV === 'production' ? LevelNone : LevelWarning;
  }
})();

/**
 * Returns true when debug logging is enabled.
 */
export function isDebugEnabled() {
  return LogLevel >= LevelDebug;
}

/* eslint-disable no-console */

/**
 * Reports some debug information, these statements should not be left in code
 * pushed up on git.  Does nothing in Prod.
 */
export const debug = (...args) => {
  if (process.env.NODE_ENV !== 'production' && LogLevel >= LevelDebug) {
    console.debug(
      `%c[%cDebug%c]%c`,
      BaseStyle,
      DebugLabelStyle,
      BaseStyle,
      TextStyle,
      ...args
    );
  }
};

/**
 * Reports information about the state of the system.  Does nothing in Prod.
 */
export const info = (...args) => {
  if (process.env.NODE_ENV !== 'production' && LogLevel >= LevelInfo) {
    console.info(
      `%c[%cInfo%c]%c`,
      BaseStyle,
      InfoLabelStyle,
      BaseStyle,
      TextStyle,
      ...args
    );
  }
};

/**
 * Reports a warning in the system
 */
const warn = (...args) => {
  if (LogLevel >= LevelWarning) {
    console.warn(
      `%c[%cWarning%c]%c`,
      BaseStyle,
      WarnLabelStyle,
      BaseStyle,
      TextStyle,
      ...args
    );
  }
};

/**
 * Reports an error in the system
 */
const error = (...args) => {
  if (LogLevel >= LevelError) {
    console.error(
      `%c[%cError%c]%c`,
      BaseStyle,
      ErrLabelStyle,
      BaseStyle,
      TextStyle,
      ...args
    );
  }
};

/* eslint-enable no-console */

/**
 * Export an object with all of the functions on it as the default, so modules
 * importing this don't have to worry about the function names conflicting with
 * anything.
 */
export default {
  debug,
  isDebugEnabled,
  info,
  warn,
  error
};
