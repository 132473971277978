import { Divider, List, Typography } from '@material-ui/core';

import { DataRenderingWrapper } from 'components';
import { InstanceRefListItem } from './InstanceRefListItem';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  padding: 10px;
`;

/**
 * Displays a list of instanceRefs. Used by the home page.
 */
export function KindList(props) {
  const {
    title,
    isLoading,
    isError,
    error,
    instanceRefs,
    onClick,
    useDividers,
    className
  } = props;

  return (
    <Root className={className}>
      <Typography variant="h6">{title} </Typography>
      <DataRenderingWrapper
        loading={isLoading || !(instanceRefs || isError)}
        error={error}
        errorMessage={`Failed to load ${title}`}
        location={`KindList | ${title}`}
      >
        {instanceRefs && instanceRefs.length && (
          <List component="nav">
            {instanceRefs.map((instanceRef, index) => (
              <React.Fragment key={instanceRef.id ?? index}>
                {useDividers && index > 0 && <Divider />}
                <InstanceRefListItem
                  instanceRef={instanceRef}
                  iconName={instanceRef.iconName}
                  onClick={onClick}
                />
              </React.Fragment>
            ))}
          </List>
        )}
        {(!instanceRefs || !instanceRefs.length) && (
          <Typography variant="subtitle1">none</Typography>
        )}
      </DataRenderingWrapper>
    </Root>
  );
}

KindList.propTypes = {
  className: PropTypes.string,
  instanceRef: PropTypes.string,
  iconName: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string,
  instanceRefs: PropTypes.array,
  onClick: PropTypes.func,
  useDividers: PropTypes.bool
};
