import {
  ANNOTATION_MIME_TYPE,
  FieldTypes,
  FunctionType,
  GraphNodes,
  GraphType,
  GraphqlOperationType,
  ICON_DIR,
  KN_FUNCTION,
  KN_IMPLEMENTATION,
  KN_KIND,
  KN_OPERATION,
  KN_PORTAL_GRAPH,
  KN_PORTAL_GRAPH_NODE,
  KN_WORKSPACE
} from 'util/defines';
import {
  BoilerplateFunctionFragment,
  FunctionDetailsFragment,
  InstanceDetailsFragment,
  KindDetailsFragment,
  KindNameServiceFragment,
  LinkedOnInstance,
  LinkedOnKind,
  OperationFunctionDetailsFragment,
  PortalGraphFragment,
  PortalGraphNodeDetailsFragment,
  PortalGraphTypeFragment,
  RemoveNodesFromPortalGraphMutation,
  WorkspaceInternalServicesIdsFragment
} from 'graphql/Portal';
import {
  addInstanceRefsToWorkspaceCache,
  addToWorkspaceInventories,
  getOperationById,
  handleAddItemsToInventory,
  isBoilerplate
} from 'util/workspace';
import {
  getNonGeneratedWorkspaceFunctions,
  getWorkspaceKinds
} from 'functions/inventory';
import { isEmpty, pick, uniqBy } from 'lodash';
import { navigateToGraph, updateSelection } from 'util/selectionUtils';

import { AddFunctionFunctionGraphFragment } from 'util/function';
import Logger from 'util/Logger';
import { addBoilerplateForKinds } from 'util/kind';
import { addKindsAndFunctionsToServices } from 'util/service';
import { calculateNodePositions } from 'util/graph/layout';
import { functionWithRetry } from 'util/helpers';
import { getNewName } from 'util/name';
import gql from 'graphql-tag';
import { handleError } from 'util/snackUtils';
import { setAreNodesInEditModeOn } from 'util/graph';
import uuidv4 from 'uuid/v4';

const PortalGraphsOnWorkspaceFragment = gql`
  fragment PortalGraphsOnWorkspace on Workspace {
    activeGraphPath
    portalGraphs {
      id
      type
    }
  }
`;

const RemoveNodesFromPortalGraphFragment = gql`
  fragment RemoveNodesFromPortalGraph on PortalGraph {
    id
    type
    nodes {
      id
    }
    function {
      id
      implementation {
        id
        entrypoint {
          id
        }
        operations {
          id
          argumentValues {
            id
            operation {
              id
            }
          }
        }
      }
    }
  }
`;

const WorkspaceActiveGraphPathFragment = gql`
  fragment WorkspaceActiveGraphPath on Workspace {
    activeGraphPath
  }
`;

const WorkspaceMainServicesIdsFragment = gql`
  fragment WorkspaceMainServicesIds on Workspace {
    modelServiceId
    logicServiceId
  }
`;

const AddInstanceRefToWorkspaceDetailsFragment = gql`
  fragment AddInstanceRefToWorkspaceDetails on InstanceRef {
    id
    name
    kindId
    kindName
    kind {
      ...KindNameService
      schema {
        id
        name
        type
      }
    }
    instance {
      ...InstanceDetails
    }
    innerKind {
      ...KindNameService
    }
    innerFunction {
      ...OperationFunctionDetails
      isGenerated
    }
  }

  ${OperationFunctionDetailsFragment}
  ${KindNameServiceFragment}
  ${InstanceDetailsFragment}
`;

const AddNodesToPortalGraphMutation = gql`
  mutation AddNodesToPortalGraph(
    $wsId: ID!
    $graphId: ID!
    $nodes: [PortalGraphNodeInput!]!
    $instanceRefs: [InstanceRefInput!]!
  ) {
    addNodesToPortalGraph(graphId: $graphId, nodes: $nodes) {
      ...PortalGraphNodeDetails
    }
    addInstanceRefsToWorkspace(wsId: $wsId, instanceRefs: $instanceRefs) {
      ...AddInstanceRefToWorkspaceDetails
    }
  }
  ${AddInstanceRefToWorkspaceDetailsFragment}
  ${PortalGraphNodeDetailsFragment}
`;

const AddNodesToPortalGraphFragment = gql`
  fragment AddNodesToPortalGraph on PortalGraph {
    id
    type
    nodes {
      id
    }
    function {
      id
      implementation {
        id
        entrypoint {
          id
        }
        operations {
          id
        }
      }
    }
  }
`;

const AddNodesToPortalGraphSlimFragment = gql`
  fragment AddNodesToPortalGraphSlim on PortalGraph {
    id
    type
    nodes {
      id
    }
    function {
      id
      implementation {
        id
        operations {
          id
        }
      }
    }
  }
`;

const OperationSaveFragment = gql`
  fragment OperationSave on Operation {
    id
    type
    function {
      id
    }
    argumentValues {
      id
    }
  }
`;

const DeleteNodesFragment = gql`
  fragment DeleteNodes on PortalGraph {
    id
    nodes {
      id
    }
  }
`;

const WorkspaceSpecialAddPortalGraphQuery = gql`
  query WorkspaceSpecialAddPortalGraph($id: ID!) {
    workspace(id: $id) {
      id
      activeGraphPath
      portalGraphs {
        id
      }
    }
  }
`;

const AddPortalGraphMutation = gql`
  mutation AddPortalGraph($input: AddPortalGraphInput!) {
    addPortalGraph(input: $input) {
      ...PortalGraphDetails
    }
  }
  ${PortalGraphFragment}
`;

const PortalGraphNodeSubTypeDetailsFragment = gql`
  fragment PortalGraphNodeSubTypeDetails on PortalGraphNode {
    id
    knowledgeGraphNode {
      id
      kind {
        id
        name
      }
      instance {
        id
      }
      innerFunction {
        id
        functionType
      }
    }
    functionGraphNode {
      id
      operationId
    }
  }
`;

const WorkspacePortalGraphsFragment = gql`
  fragment WorkspacePortalGraphs on Workspace {
    portalGraphs {
      id
      workspace {
        id
      }
    }
  }
`;

const UpdateGraphMutation = gql`
  mutation UpdateGraph($input: UpdateGraphInput!) {
    updateGraph(input: $input) {
      newKinds {
        id
      }
      addedKinds {
        id
      }
      newFunctions {
        id
        graph {
          ...AddFunctionFunctionGraph
        }
      }
      addedFunctions {
        id
      }
      addedInstances {
        ...InstanceDetails
      }
      files {
        id
        name
        url
        thumbnailUrl
        mimeType
        size
        progress
        status
      }
      updatedGraph {
        id
        function {
          id
          implementation {
            id
            entrypoint {
              id
            }
            operations {
              id
            }
          }
        }
      }
      newGraphNodes {
        id
        x
        y
        width
        height
        collapsed
        knowledgeGraphNode {
          id
          kind {
            ...KindDetails
          }
          instance {
            ...InstanceDetails
            ...LinkedOnInstance
          }
          innerKind {
            ...KindDetails
            ...LinkedOnKind
          }
          innerFunction {
            ...FunctionDetails
          }
        }
        functionGraphNode {
          id
          operationId
          operation {
            id
            type
            function {
              ...FunctionDetails
            }
            argumentValues {
              id
              argument {
                id
              }
              operation {
                id
              }
              argumentRef
            }
          }
        }
      }
      workspaceServiceReferences {
        serviceId
        workspaceIds
      }
      newBoilerplateKinds {
        ...KindDetails
      }
      newBoilerplateFunctions {
        ...BoilerplateFunction
      }
    }
  }

  ${LinkedOnKind}
  ${LinkedOnInstance}
  ${KindDetailsFragment}
  ${FunctionDetailsFragment}
  ${InstanceDetailsFragment}
  ${BoilerplateFunctionFragment}
  ${AddFunctionFunctionGraphFragment}
`;

const DeletePortalGraphMutation = gql`
  mutation DeletePortalGraph($graphId: ID!) {
    deletePortalGraph(graphId: $graphId) {
      id
      portalGraphs {
        id
      }
    }
  }
`;

/**
 * Pulls the workspace with the active graph and the id and type of its portal
 * graphs.
 *
 * @param {string} workspaceId The ID of the workspace to work with
 * @param {ApolloClient} client The client to use to access the cache
 * @return {Object} The workspace data
 */
function getWorkspacePortalGraphsFromCache(workspaceId, client) {
  return client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: PortalGraphsOnWorkspaceFragment
  });
}

/**
 * Checks to see if the graph is the in the workspace
 *
 * @param {string} workspaceId The ID of the workspace to check in
 * @param {string} graphId The ID of the graph to look for
 * @param {ApolloClient} client The client to use to access the cache
 * @return {boolean} True id the graph in the workspace, otherwise false
 */
export function isGraphInWorkspace(workspaceId, graphId, client) {
  const workspace = client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: WorkspacePortalGraphsFragment
  });
  return workspace && workspace.portalGraphs.some(pg => pg.id === graphId);
}

function createInstanceRefInputFromPortalGraphNodeInput(node) {
  // Only one of these should every be set since they are specific to the type of graph
  const typedNode = node.knowledgeGraphNode || node.functionGraphNode;
  if (node.knowledgeGraphNode) {
    return {
      id: typedNode.instanceId,
      kindId: typedNode.kindId,
      kindName: typedNode.kindName,
      instance: typedNode.instanceId
    };
  } else if (node.functionGraphNode) {
    return {
      id: typedNode.operation.id,
      instance: typedNode.operation.function,
      kindName: KN_FUNCTION
    };
  }
}
/**
 * Adds a list of nodes to a portal graph, and updated it's function
 * implementation in the case of a function graph.
 *
 * @param {string} graphId ID of the portal graph to add the nodes to
 * @param {Array<Object>} nodes The list of nodes to add
 * @param {boolean} areNewNodes Makes sure that the nodes get marked as new
 * @param {Object} store A transactional wrapper around direct cache updates
 */
function addNodesToPortalGraphAndImplementationCache(
  graphId,
  nodes,
  areNewNodes,
  store
) {
  try {
    const graphFragmentId = `${KN_PORTAL_GRAPH}:${graphId}`;
    const graph = store.readFragment({
      id: graphFragmentId,
      fragment: AddNodesToPortalGraphFragment
    });

    // Make sure that the nodes have their state set properly
    if (areNewNodes) {
      setAreNodesInEditModeOn(
        graphId,
        nodes.map(node => node.id),
        store
      );
    }

    // Add the new nodes to the graph
    graph.nodes = graph.nodes.concat(
      nodes.map(node => pick(node, ['id', '__typename']))
    );

    // Add the operations to the function graph's function's implementation
    if (graph.type === GraphType.FUNCTION) {
      const operations = nodes
        .filter(
          node => node.functionGraphNode && node.functionGraphNode.operation
        )
        .map(node => node.functionGraphNode.operation);

      if (!graph.function.implementation) {
        // note that using a random ID for the implementation here will work,
        // as maana-portal is designed to deal with this scenario.
        graph.function.implementation = {
          id: uuidv4(),
          entrypoint: null,
          operations: [],
          __typename: KN_IMPLEMENTATION
        };
      }

      const implementation = graph.function.implementation;

      implementation.operations = (implementation.operations || []).concat(
        operations
          .map(operation => {
            if (operation) {
              store.writeFragment({
                id: `${KN_OPERATION}:${operation.id}`,
                fragment: OperationSaveFragment,
                data: {
                  ...operation,
                  function: {
                    id: operation.function,
                    __typename: KN_FUNCTION
                  },
                  argumentValues: [],
                  __typename: KN_OPERATION
                }
              });

              return {
                id: operation.id,
                __typename: KN_OPERATION
              };
            }

            return null;
          })
          .filter(n => !!n)
      );
    }

    store.writeFragment({
      id: graphFragmentId,
      fragment: AddNodesToPortalGraphFragment,
      data: graph
    });
  } catch (e) {
    // The error being thrown here means that the cache is being updated before
    // the portal graph is loaded.  This happens when the Assistant API is
    // adding a node to a Portal graph that is not visible in the UI.
    Logger.debug(
      `Failed to add nodes ${nodes
        .map(n => n.id)
        .join(', ')} Portal graph in cache with error`,
      e
    );
  }
}

/**
 * Adds a list of nodes to a portal graph in the cache.
 *
 * @param {string} graphId ID of the portal graph to add the nodes to
 * @param {Array<Object>} nodes The list of nodes to add
 * @param {Object} store Apollo store
 */
function addNodesToPortalGraphCache(graphId, nodes, store) {
  try {
    const graphFragmentId = `${KN_PORTAL_GRAPH}:${graphId}`;
    const graph = store.readFragment({
      id: graphFragmentId,
      fragment: AddNodesToPortalGraphSlimFragment
    });

    // Add the new nodes to the graph
    graph.nodes = graph.nodes.concat(
      nodes.map(node => pick(node, ['id', '__typename']))
    );

    store.writeFragment({
      id: graphFragmentId,
      fragment: AddNodesToPortalGraphSlimFragment,
      data: graph
    });
  } catch (e) {
    // The error being thrown here means that the cache is being updated before
    // the portal graph is loaded.  This happens when the Assistant API is
    // adding a node to a Portal graph that is not visible in the UI.
  }
}

/**
 * Add multiple nodes to a graph in a workspace
 *
 * @param {Object} workspaceId The ID of the current workspace
 * @param {Object} client The client for Apollo store.
 * @param {string} graphId for the query graph
 * @param {Array<Object>} nodes The nodes to add to the graph
 * @param {boolean} changeSelection Selects the new nodes
 * @param {boolean} areNewNodes Makes sure that the nodes get marked as new
 * @return {Promise<Object>} Contains the response from the GraphQL mutation
 */
function addNodesToPortalGraph({
  workspaceId,
  client,
  graphId,
  nodes,
  changeSelection,
  areNewNodes
}) {
  let instanceRefs = nodes.map(n =>
    createInstanceRefInputFromPortalGraphNodeInput(n)
  );

  return client
    .mutate({
      mutation: AddNodesToPortalGraphMutation,
      variables: {
        wsId: workspaceId,
        graphId,
        nodes,
        instanceRefs
      },
      update: (store, { data }) => {
        // Add instanceRef to the workspace inventory
        if (data.addInstanceRefsToWorkspace) {
          addInstanceRefsToWorkspaceCache(
            workspaceId,
            data.addInstanceRefsToWorkspace,
            store
          );
        }

        // Add the nodes to the graph
        if (data.addNodesToPortalGraph) {
          addNodesToPortalGraphAndImplementationCache(
            graphId,
            nodes.map(node => ({ ...node, __typename: KN_PORTAL_GRAPH_NODE })),
            areNewNodes,
            store
          );

          if (changeSelection) {
            updateSelection({
              workspaceId: workspaceId,
              client,
              selectedInstances: data.addNodesToPortalGraph.map(n => ({
                id: n.id,
                kindId: null,
                kindName: KN_PORTAL_GRAPH_NODE
              }))
            });
          }
        }
      }
    })
    .catch(error => {
      handleError(client, 'Failed to add nodes to the graph', error);
    });
}

/**
 * Adds a list of graphIds to a workspace in the cache.
 *
 * @param {Object} store The store used to interact with the cache
 * @param {string} workspaceId The workspace to add the graphs to
 * @param {Array<string>} graphIds The collection of graph Ids to add
 */
export function cacheAddedPortalGraphs(store, workspaceId, graphIds) {
  if (isEmpty(graphIds)) return;

  try {
    // get the workspace
    const workspaceFragmentId = `${KN_WORKSPACE}:${workspaceId}`;
    const workspace = store.readFragment({
      id: workspaceFragmentId,
      fragment: WorkspacePortalGraphsFragment
    });

    // Add the portal graphs to the workspace
    workspace.portalGraphs = uniqBy(
      workspace.portalGraphs.concat(
        graphIds.map(id => ({
          id,
          __typename: KN_PORTAL_GRAPH,
          workspace: { id: workspaceId, __typename: KN_WORKSPACE }
        }))
      ),
      g => g.id
    );

    // Write the updated workspace
    store.writeFragment({
      id: workspaceFragmentId,
      fragment: WorkspacePortalGraphsFragment,
      data: workspace
    });
  } catch (e) {
    // The error being thrown here means that the cache is being updated before
    // the Workspace Portal Graph's information is in the cache.  This happens
    // when the Assistant API is moving Kinds or Functions to a Workspace that
    // is not visible in the UI
    Logger.debug(
      `Failed to update Portal Graphs for  Workspace ${workspaceId} with error`,
      e
    );
  }
}

/**
 * Removes nodes from Portal Graphs in the cache.
 *
 * @param {Array<string>} nodeIds The IDs of the nodes to remove
 * @param {Array<string>} portalGraphIds The IDs of the Portal Graphs to remove the nodes from
 * @param {ApolloClient} client The apollo client to use
 */
export function removeNodesFromPortalGraphs(nodeIds, portalGraphIds, client) {
  if (isEmpty(nodeIds)) {
    return;
  }

  for (const portalGraphId of portalGraphIds) {
    try {
      const portalGraph = client.readFragment({
        id: `${KN_PORTAL_GRAPH}:${portalGraphId}`,
        fragment: DeleteNodesFragment
      });

      portalGraph.nodes = portalGraph.nodes.filter(
        node => !nodeIds.includes(node.id)
      );

      client.writeFragment({
        id: `${KN_PORTAL_GRAPH}:${portalGraphId}`,
        fragment: DeleteNodesFragment,
        data: portalGraph
      });
    } catch {
      // Ignore errors thrown because the Portal Graph is not in the cache.
    }
  }
}

const getFunctionNodeFunction = (nodeId, apolloClient) => {
  let func = null;
  const node = getPortalGraphNodeById(nodeId, apolloClient);

  if (node.functionGraphNode) {
    const operation = getOperationById(
      node.functionGraphNode.operationId,
      apolloClient
    );

    func = operation && operation.function;
  } else if (node.knowledgeGraphNode && node.knowledgeGraphNode.innerFunction) {
    func = node.knowledgeGraphNode.innerFunction;
  }

  return func;
};

/**
 * Opens a function graph based off of a Portal Graph Node's id. If the graph is
 * in the workspace, it will be selected and made active, otherwise the graph
 * will be added to the workspace.
 *
 * @param {string} workspaceId The ID of the workspace to work with
 * @param {ApolloClient} client The client to use to access the cache
 * @param {string} functionNodeId Id of the function node that is being composed
 */
export async function handleOpenFunctionGraph(
  workspaceId,
  client,
  functionNodeId
) {
  const func = getFunctionNodeFunction(functionNodeId, client);
  const workspace = getWorkspacePortalGraphsFromCache(workspaceId, client);

  // Make sure that the workspace and function are available.  Only CKG
  // functions can be composed.
  if (!workspace || !func || func.functionType !== 'CKG') return;

  const activeGraph = getActiveGraph(workspace);

  if (activeGraph.type === GraphType.FUNCTION) {
    // If the current active graph is a function graph, then save the path
    navigateToGraph({
      workspaceId,
      client,
      graphPath: workspace.activeGraphPath.concat(func.id)
    });
  } else {
    // Otherwise, this function graph will be the only graph in the active graph path
    navigateToGraph({
      workspaceId,
      client,
      graphPath: [func.id]
    });
  }
}

/**
 * Removes a list of nodes from the active graph, and does any other updates
 * that may need to happen for that to work.
 *
 * @param {Object} workspaceId The ID of the current workspace
 * @param {Object} client The client for Apollo store.
 * @param {Array<Object>} nodes The nodes that are being removed from the graph
 * @return {Promise<Object>} Returns the promise from the graphql call to remove the nodes from the portal graph
 */
export function removeNodesFromPortalGraph(workspaceId, client, nodes) {
  // get the active graph
  const activeGraphId = getActiveGraphIdFromCache(workspaceId, client);
  if (!activeGraphId) {
    return;
  }

  // remove the nodes
  return client.mutate({
    mutation: RemoveNodesFromPortalGraphMutation,
    variables: {
      graphId: activeGraphId,
      nodeIds: nodes.map(e => e.id)
    },
    // update the store while we remove it from the kg
    optimisticResponse: {
      removeNodesFromPortalGraph: []
    },
    update: async (store, { data }) => {
      if (data.removeNodesFromPortalGraph) {
        let portalGraph = store.readFragment({
          id: `${KN_PORTAL_GRAPH}:${activeGraphId}`,
          fragment: RemoveNodesFromPortalGraphFragment
        });

        // clean up the graphs connected function
        if (portalGraph.function) {
          // get the operations to remove
          const operationIds = nodes
            .map(
              node =>
                node.functionGraphNode && node.functionGraphNode.operationId
            )
            .filter(Boolean);

          // clean up the implementation
          const { implementation } = portalGraph.function;
          if (operationIds.length && implementation) {
            implementation.operations = implementation.operations.filter(
              op => !operationIds.includes(op.id)
            );
            if (
              implementation.entrypoint &&
              operationIds.includes(implementation.entrypoint.id)
            ) {
              implementation.entrypoint = null;
            }
            implementation.operations.forEach(op => {
              op.argumentValues = op.argumentValues.filter(
                av => !av.operation || !operationIds.includes(av.operation.id)
              );
            });
          }
        }

        portalGraph.nodes = portalGraph.nodes.filter(
          node => !nodes.some(r => node.id === r.id)
        );

        store.writeFragment({
          id: `${KN_PORTAL_GRAPH}:${activeGraphId}`,
          fragment: RemoveNodesFromPortalGraphFragment,
          data: portalGraph
        });
      }
    }
  });
}

/**
 * Get the default node size and position for new nodes.
 *
 * @param {Object} graphEngine The graph layout engine
 * @param {Object} graphDimensions The graph dimensions
 * @returns {Object} Oject containing nodeSize and nodePosition properties
 */
function getDefaultNodeSizeAndPosition(graphEngine, graphDimensions) {
  // Get size and position
  const nodeSize = {
    width: GraphNodes.EDIT_MODE_WIDTH,
    height: GraphNodes.MIN_HEIGHT
  };
  const nodePosition = calculateNodePositions(
    [nodeSize],
    graphDimensions,
    graphEngine
  )[0];

  return { ...nodeSize, ...nodePosition };
}

/**
 * Create a new kind and add it to the active graph
 *
 * @param {string} workspaceId The ID of the current workspace
 * @param {Object} graphEngine The graph layout engine
 * @param {Object} graphDimensions The graph dimensions
 * @param {Object} client The client for Apollo store
 * @param {string} name The name for the new kind
 * @returns {Promise<void>}
 */
export function createKindInGraph(
  workspaceId,
  graphEngine,
  graphDimensions,
  client,
  name = 'NewKind'
) {
  const activeGraph = getActiveGraphFromCache(workspaceId, client);
  if (!activeGraph) return;

  const { modelServiceId } = client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: WorkspaceMainServicesIdsFragment
  });

  const names = getWorkspaceKinds(workspaceId, client).map(x => x.name);
  const createKindInput = {
    id: uuidv4(),
    name: getNewName({ names, baseName: name }),
    serviceId: modelServiceId,
    nodeInfo: {
      pgNodeId: uuidv4(),
      innerNodeId: uuidv4(),
      collapsed: false,
      ...getDefaultNodeSizeAndPosition(graphEngine, graphDimensions)
    }
  };

  return functionWithRetry(
    updateGraph,
    [
      { graphId: activeGraph.id, workspaceId, createKinds: [createKindInput] },
      client,
      true /* changeSelection */
    ],
    null,
    args => updateGraphCreateNodeFailureArgsUpdate({ names, args })
  );
}

/**
 * Create a new function and add it to the active graph if there is one
 *
 * @param {Object} workspaceId The ID of the current workspace
 * @param {Object} graphEngine The graph layout engine
 * @param {Object} graphDimensions The graph dimensions
 * @param {Object} client The client for Apollo store
 * @param {string} name The name for the new function
 * @returns {Promise<void>}
 */
export function createFunctionInGraph(
  workspaceId,
  graphEngine,
  graphDimensions,
  client,
  name = 'newFunction'
) {
  const activeGraph = getActiveGraphFromCache(workspaceId, client);
  if (!activeGraph) return;

  const { logicServiceId } = client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: WorkspaceMainServicesIdsFragment
  });

  const names = getNonGeneratedWorkspaceFunctions(workspaceId, client).map(
    x => x.name
  );

  const createFunctionInput = {
    id: uuidv4(),
    name: getNewName({ names, baseName: name }),
    service: logicServiceId,
    arguments: [],
    functionType: FunctionType.CKG,
    graphqlOperationType: GraphqlOperationType.QUERY,
    outputModifiers: [],
    outputType: FieldTypes.STRING,
    nodeInfo: {
      pgNodeId: uuidv4(),
      innerNodeId: uuidv4(),
      collapsed: false,
      operationId:
        activeGraph.type === GraphType.FUNCTION ? uuidv4() : undefined,
      ...getDefaultNodeSizeAndPosition(graphEngine, graphDimensions)
    }
  };

  return functionWithRetry(
    updateGraph,
    [
      {
        graphId: activeGraph.id,
        workspaceId,
        createFunctions: [createFunctionInput]
      },
      client,
      true /* changeSelection */
    ],
    null,
    args => updateGraphCreateNodeFailureArgsUpdate({ names, args })
  );
}

/**
 * Create a new annotation (file) and add it to the graph with given node information.
 *
 * @param {string} workspaceId The ID of the current workspace.
 * @param {string} graphId The ID of the graph to add it to.
 * @param {Object} client The client for Apollo store
 * @param {string} name The name for the new annotation
 * @param {string} url The name for the new annotation
 * @param {Object} nodeInfo The information about the node position and size.
 * @param {boolean} changeSelection When true the new node will be selected.
 * @returns {Promise<Object>} The input used for creating the annotation on the graph.
 */
export async function createAnnotationInGraphWithNodeInfo(
  workspaceId,
  graphId,
  client,
  name = 'New Annotation',
  url,
  nodeInfo,
  changeSelection
) {
  const { modelServiceId } = client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: WorkspaceMainServicesIdsFragment
  });

  const createFileInput = {
    file: {
      id: uuidv4(),
      name,
      url,
      mimeType: ANNOTATION_MIME_TYPE,
      thumbnailUrl: `${ICON_DIR}html.svg`,
      serviceId: modelServiceId,
      size: '0',
      progress: '0',
      status: 0
    },
    nodeInfo: {
      pgNodeId: uuidv4(),
      innerNodeId: uuidv4(),
      collapsed: false,
      ...nodeInfo
    }
  };

  await functionWithRetry(
    updateGraph,
    [
      { graphId, workspaceId, createFiles: [createFileInput] },
      client,
      changeSelection
    ],
    null
  );

  return createFileInput;
}

/**
 * Create a new annotation (file) and add it to the active graph
 *
 * @param {string} workspaceId The ID of the current workspace
 * @param {Object} graphEngine The graph layout engine
 * @param {Object} graphDimensions The graph dimensions
 * @param {Object} client The client for Apollo store
 * @param {string} name The name for the new annotation
 * @param {string} url The name for the new annotation
 * @returns {Promise<Object>} The input used for creating the annotation on the graph.
 */
export function createAnnotationInGraph(
  workspaceId,
  graphEngine,
  graphDimensions,
  client,
  name,
  url
) {
  const graphId = getActiveGraphIdFromCache(workspaceId, client);
  if (!graphId) return;

  return createAnnotationInGraphWithNodeInfo(
    workspaceId,
    graphId,
    client,
    name,
    url,
    getDefaultNodeSizeAndPosition(graphEngine, graphDimensions),
    true /* changeSelection */
  );
}

/**
 * A generic function for adding functions to all graphs that use the
 * KnowledgeGraph type for interaction with the server.
 *
 * @param {Object} workspaceId The ID of the current workspace
 * @param {Object} client The client for Apollo store.
 * @param {string} graphId The id of the graph to add the node to
 * @param {Object} node The node to add to the graph
 * @param {Boolean} changeSelection Whether or not to set the added node as selected
 * @param {boolean} isNewNode Defines if the node being added is brand new.
 * @return {Promise<Object>} Contains the response from the GraphQL mutation
 */
export function addNodeToGraph({
  workspaceId,
  client,
  graphId,
  node,
  changeSelection,
  isNewNode
}) {
  return addNodesToGraph({
    workspaceId,
    client,
    graphId,
    nodes: [node],
    changeSelection,
    areNewNodes: isNewNode
  });
}

/**
 * A generic function for adding nodes to all graphs.
 *
 * @param {Object} workspaceId The ID of the current workspace
 * @param {Object} client The client for Apollo store.
 * @param {string} graphId The id of the graph to add the node to
 * @param {Array<Object>} nodes The nodes to add to the graph
 * @param {boolean} changeSelection Selects the new nodes
 * @param {boolean} areNewNodes Makes sure that the nodes get marked as new
 * @return {Promise<Object>} Contains the response from the GraphQL mutation
 */
export function addNodesToGraph({
  workspaceId,
  client,
  graphId,
  nodes,
  changeSelection,
  areNewNodes
}) {
  // don't do anything if the graph does not exist
  if (!isGraphInWorkspace(workspaceId, graphId, client)) return;

  // Set node position defaults
  const updatedNodes = nodes.map(n => {
    const graphNodePosition = getGraphNodePosition();
    return {
      x: n.x || graphNodePosition.x,
      y: n.y || graphNodePosition.y,
      width: n.width || GraphNodes.MIN_WIDTH,
      height: n.height || GraphNodes.MIN_HEIGHT,
      collapsed: false,
      ...n
    };
  });

  return addNodesToPortalGraph({
    workspaceId,
    client,
    graphId,
    nodes: updatedNodes,
    changeSelection,
    areNewNodes
  });
}

/**
 * Adds a new portal graph to the workspace, or copies an existing one into the
 * workspace.
 *
 * @param {Object} data The parameters for this function
 * @param {string} data.workspaceId The ID of the workspace to add the graph to
 * @param {Object} data.graph The data about the graph to add
 * @param {ApolloClient} data.client The ApolloClient to use
 * @return {Object} The added portal graph
 */
export async function addPortalGraph({ workspaceId, graph, client }) {
  const { data } = await client.mutate({
    mutation: AddPortalGraphMutation,
    variables: { input: graph }
  });

  const storeData = client.readQuery({
    query: WorkspaceSpecialAddPortalGraphQuery,
    variables: { id: workspaceId }
  });

  // put the graph at the correct location in the list of graphs
  if (
    graph.index >= 0 &&
    graph.index < storeData.workspace.portalGraphs.length
  ) {
    storeData.workspace.portalGraphs.splice(graph.index, 0, {
      id: data.addPortalGraph.id,
      __typename: KN_PORTAL_GRAPH
    });
  } else {
    storeData.workspace.portalGraphs.push({
      id: data.addPortalGraph.id,
      __typename: KN_PORTAL_GRAPH
    });
  }

  // update active graph
  storeData.workspace.activeGraphPath = [data.addPortalGraph.id];

  // save the data
  client.writeQuery({
    query: WorkspaceSpecialAddPortalGraphQuery,
    variables: { id: workspaceId },
    data: storeData
  });

  // update the selection
  updateSelection({
    workspaceId,
    client,
    selectedInstances: [
      {
        id: data.addPortalGraph.id,
        kindId: null,
        kindName: KN_PORTAL_GRAPH
      }
    ]
  });

  // update the inventory
  await handleAddItemsToInventory(
    data.addPortalGraph.nodes,
    workspaceId,
    client
  );

  // return the new portal graph
  return data.addPortalGraph;
}

/**
 * Returns the ID of the active graph.  Returns null if no active graph.
 *
 * @param {Array<string>} activeGraphPath The active graph path
 * @return {string} The ID of the active graph
 */
export function getActiveGraphIdFromPath(activeGraphPath) {
  return !isEmpty(activeGraphPath)
    ? activeGraphPath[activeGraphPath.length - 1]
    : null;
}

/**
 * Looks for an active graph on the workspace with the given ID.  Returns null
 * if there is no workspace with that ID, or it does not have an active graph.
 *
 * @param {string} workspaceId The ID of the workspace
 * @param {ApolloClient} client The client to use to access the cache
 * @return {Array<string>} The active graph path
 */
export function getActiveGraphPathFromCache(workspaceId, client) {
  const workspace = client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: WorkspaceActiveGraphPathFragment
  });
  return workspace && !isEmpty(workspace.activeGraphPath)
    ? workspace.activeGraphPath
    : null;
}

/**
 * Looks for an active graph on the given workspace, and returns null if there
 * is no active graph
 *
 * @param {Object} workspace The current workspace
 * @return {string} The id of the active graph
 */
export function getActiveGraphId(workspace) {
  return getActiveGraphIdFromPath(workspace.activeGraphPath);
}

/**
 * Looks for an active graph on the workspace with the given ID.  Returns null
 * if there is no workspace with that ID, or it does not have an active graph.
 *
 * @param {string} workspaceId The ID of the workspace
 * @param {ApolloClient} client The client to use to access the cache
 * @return {string} The ID of the active graph
 */
export function getActiveGraphIdFromCache(workspaceId, client) {
  const workspace = client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: WorkspaceActiveGraphPathFragment
  });
  return workspace ? getActiveGraphId(workspace) : null;
}

/**
 * Grabs the active graph object out of the workspace state
 *
 * @param {Object} workspace The current workspace
 * @return {Object} the graph object, null, or undefined
 */
export function getActiveGraph(workspace) {
  const activeGraphId = getActiveGraphId(workspace);
  if (activeGraphId && workspace.portalGraphs) {
    return workspace.portalGraphs.find(pg => pg.id === activeGraphId);
  }
  return null;
}

/**
 * Grabs the active graph object out of the cache
 *
 * @param {string} workspaceId The ID of the workspace
 * @param {ApolloClient} client The client to use to access the cache
 * @return {Object} the graph object, null, or undefined
 */
export function getActiveGraphFromCache(workspaceId, client) {
  const graphId = getActiveGraphIdFromCache(workspaceId, client);
  try {
    return client.readFragment({
      id: `${KN_PORTAL_GRAPH}:${graphId}`,
      fragment: PortalGraphFragment,
      fragmentName: 'PortalGraphDetails'
    });
  } catch {
    // Return null when Apollo throws an error, as that means that the copy of
    // the portal graph in cache does not have all of the requested fields.
    return null;
  }
}

/**
 * Grabs the type for the given graph out of the cache.
 *
 * @param {string} graphId The ID of the graph
 * @param {ApolloClient} client The client to use to access the cache
 * @return {Object} the graph id or null
 */
export function getGraphTypeFromCache(graphId, client) {
  try {
    const { type } = client.readFragment({
      id: `${KN_PORTAL_GRAPH}:${graphId}`,
      fragment: PortalGraphTypeFragment
    });
    return type;
  } catch {
    // Return null when Apollo throws an error, as that means that the copy of
    // the portal graph in cache does not have all of the requested fields.
    return null;
  }
}

export function getPortalGraphNodeById(id, apolloClient) {
  const node = apolloClient.readFragment({
    id: `${KN_PORTAL_GRAPH_NODE}:${id}`,
    fragment: PortalGraphNodeSubTypeDetailsFragment,
    fragmentName: 'PortalGraphNodeSubTypeDetails'
  });

  return node;
}

/**
 * Returns the ID of the Instance that is represented by a Portal graph node.
 * @param {Object} node The Portal graph node for which to get the Instance ID
 */
export function getInstanceIdForGraphNode(node) {
  if (node.functionGraphNode) {
    // For Function graph nodes return the Function ID
    const func = getFunctionNodeFunction(node);
    return func && func.id;
  } else if (node.knowledgeGraphNode) {
    // For Knowledge graph nodes return the Instance ID
    return node.knowledgeGraphNode.instance.id;
  }

  // Unknown
  return null;
}

export function getGraphNodePosition() {
  return { x: GraphNodes.DEFAULT_POSITION_X, y: GraphNodes.DEFAULT_POSITION_Y };
}

/**
 * Returns true if a Portal Graph Node is a Function Graph Input node.
 * @param {Object} node The Function Graph Node to check.
 */
export function isFunctionGraphInputNode(node) {
  return node.id.endsWith('INPUT');
}

/**
 * Returns true if a Portal Graph Node is a Function Graph Output node.
 * @param {Object} node The Function Graph Node to check.
 */
export function isFunctionGraphOutputNode(node) {
  return node.id.endsWith('OUTPUT');
}

/**
 * Returns true if a Portal Graph Node is a Function Graph Args node.
 * @param {Object} node The Function Graph Node to check.
 */
export function isFunctionGraphArgsNode(node) {
  return isFunctionGraphInputNode(node) || isFunctionGraphOutputNode(node);
}

/**
 * Gets the Input node for a Function Graph from a list of nodes.
 * @param {Array<Object>} nodes The list of Function Graph nodes to search.
 */
export function getFunctionGraphInputNode(nodes) {
  return nodes.find(isFunctionGraphInputNode);
}

/**
 * Gets the Output node for a Function Graph from a list of nodes.
 * @param {Array<Object>} nodes The list of Function Graph nodes to search.
 */
export function getFunctionGraphOutputNode(nodes) {
  return nodes.find(isFunctionGraphOutputNode);
}

/**
 * Persists graph updates and updates the Apollo cache.
 *
 * @param {Object} input The input for updating the graph
 * @param {ApolloClient} client The client to use to access the cache
 * @param {boolean} changeSelection True selects newly created nodes
 */
export function updateGraph(input, client, changeSelection = false) {
  const { graphId, workspaceId, duplicateKinds, duplicateFunctions } = input;

  const duplicateKindIds = duplicateKinds?.map(k => k.id) ?? [];
  const duplicateFunctionIds = duplicateFunctions?.map(f => f.id) ?? [];

  const {
    workspaceServiceId,
    modelServiceId,
    logicServiceId
  } = client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: WorkspaceInternalServicesIdsFragment
  });

  return client.mutate({
    mutation: UpdateGraphMutation,
    variables: { input },
    update: (store, { data }) => {
      if (data.updateGraph) {
        const {
          newKinds,
          newFunctions,
          addedKinds,
          addedFunctions,
          workspaceServiceReferences,
          newGraphNodes,
          newBoilerplateKinds,
          newBoilerplateFunctions
        } = data.updateGraph;

        const addedFunctionIds = addedFunctions.map(f => f.id);
        const addedKindIds = addedKinds.map(f => f.id);
        const addedInstances = newGraphNodes
          .filter(
            node =>
              // Instances must be a knowledge graph node that don't have an innerKind or innerFunction
              node.knowledgeGraphNode &&
              !node.knowledgeGraphNode.innerFunction &&
              !node.knowledgeGraphNode.innerKind
          )
          .map(node => {
            const { id, kindId } = node.knowledgeGraphNode.instance;
            return [id, kindId, node.knowledgeGraphNode.kind.name];
          });
        const newFunctionIds = newFunctions.map(f => f.id);
        const newKindIds = newKinds.map(k => k.id);
        const allNewKindIds = [
          ...newKindIds,
          ...newBoilerplateKinds.map(bk => bk.id)
        ];
        const allNewFunctionIds = [
          ...newFunctionIds,
          ...newBoilerplateFunctions.map(bf => bf.id)
        ];

        // Special Case: Add Kinds and Functions that are in this Workspace but missing in the UI.
        // This could happen if a Kind or Function is added outside of the
        // UI or in a different browser tab.
        const missingModelServiceKindIds = newGraphNodes
          .map(node => {
            const innerKind = node.knowledgeGraphNode?.innerKind;
            if (
              innerKind?.serviceId === modelServiceId &&
              !newKindIds.includes(innerKind.id) &&
              !isBoilerplate(innerKind.id, KN_KIND, store)
            ) {
              return innerKind.id;
            }
            return undefined;
          })
          .filter(Boolean);

        const missingLogicServiceFunctionIds = newGraphNodes
          .map(node => {
            const innerFunction =
              node.knowledgeGraphNode?.innerFunction ??
              node.functionGraphNode?.operation?.function;
            if (
              innerFunction?.service.id === logicServiceId &&
              !newFunctionIds.includes(innerFunction.id)
            ) {
              return innerFunction?.id;
            }
            return undefined;
          })
          .filter(Boolean);

        const newAndMissingKindIds = allNewKindIds.concat(
          missingModelServiceKindIds
        );
        const newAndMissingFunctionIds = allNewFunctionIds.concat(
          missingLogicServiceFunctionIds
        );

        const serviceReferences = workspaceServiceReferences.flatMap(
          ref => ref.workspaceIds
        );

        if (newAndMissingFunctionIds.length || newAndMissingKindIds.length) {
          addToWorkspaceInventories(serviceReferences, store, {
            serviceKindIds: newAndMissingKindIds,
            serviceFunctionIds: newAndMissingFunctionIds
          });
        }

        addToWorkspaceInventories([workspaceId], store, {
          serviceKindIds: newAndMissingKindIds,
          serviceFunctionIds: newAndMissingFunctionIds,
          workspaceKindIds: addedKindIds,
          workspaceFunctionIds: addedFunctionIds,
          instances: addedInstances
        });

        if (newAndMissingKindIds.length) {
          addKindsAndFunctionsToServices(
            [modelServiceId],
            newAndMissingKindIds,
            newBoilerplateFunctions.map(bf => bf.id),
            store
          );
        }

        if (newFunctionIds.length || missingLogicServiceFunctionIds.length) {
          addKindsAndFunctionsToServices(
            [logicServiceId],
            null,
            // Don't add boilerplate Functions to the logic service
            newFunctionIds.concat(missingLogicServiceFunctionIds),
            store
          );
        }

        if (newAndMissingFunctionIds.length || newAndMissingKindIds.length) {
          addKindsAndFunctionsToServices(
            [workspaceServiceId],
            newAndMissingKindIds,
            newAndMissingFunctionIds,
            store
          );
        }

        if (newFunctionIds.length) {
          cacheAddedPortalGraphs(store, workspaceId, newFunctionIds);
        }

        if (newKindIds.length || newFunctionIds.length) {
          const editModeGraphNodes = newGraphNodes.filter(node => {
            const kindId = node.knowledgeGraphNode?.innerKind?.id;
            const functionId =
              node.knowledgeGraphNode?.innerFunction?.id ??
              node.functionGraphNode?.operation?.function.id;

            // Put new, but not duplicated, kinds/functions into edit mode.
            return (
              (kindId &&
                newKindIds.includes(kindId) &&
                !duplicateKindIds.includes(kindId)) ||
              (functionId &&
                newFunctionIds.includes(functionId) &&
                !duplicateFunctionIds.includes(functionId))
            );
          });
          setAreNodesInEditModeOn(
            graphId,
            editModeGraphNodes.map(n => n.id),
            store
          );
        }

        if (newGraphNodes.length) {
          addNodesToPortalGraphCache(input.graphId, newGraphNodes, store);

          if (changeSelection) {
            updateSelection({
              workspaceId,
              client: store,
              selectedInstances: newGraphNodes.map(n => ({
                id: n.id,
                kindId: null,
                kindName: KN_PORTAL_GRAPH_NODE
              }))
            });
          }
        }

        if (missingModelServiceKindIds.length) {
          // Missing Model Service Kinds need their boilerplate loaded.
          // Don't await this so that the existing updates can finish as this
          // needs to make an additional query.
          addBoilerplateForKinds(
            missingModelServiceKindIds,
            serviceReferences.concat(workspaceId),
            modelServiceId,
            workspaceServiceId,
            client
          ).catch(e => {
            Logger.error(
              `Failed to load boilerplate for ${missingModelServiceKindIds.join(
                ', '
              )} with error`,
              e
            );
          });
        }
      }
    }
  });
}

/**
 * Delete a portal graph.
 *
 * @param {string} graphId ID of the graph to delete
 * @param {ApolloClient} client The Apollo client
 * @returns {Promise<Object>} Response from the mutation
 */
export function deleteGraph(graphId, client) {
  return client.mutate({
    mutation: DeletePortalGraphMutation,
    variables: { graphId }
  });
}

/**
 * Function that will update the arguments passed to the updateGraph function with a newly generated name. This is created to work
 * around the server returning an error that a newly created Kind or Function name already exists. This should only happen if the client is
 * out of sync with what is on the server.
 *
 * @param {Array<string>} names The array of existing names
 * @param {Array<any>} args The args passed to the updateGraph function
 *
 * @returns {Array<string>} The updated args with a new name
 */
const updateGraphCreateNodeFailureArgsUpdate = ({ names, args }) => {
  const currentName =
    args[0].createKinds?.[0].name ?? args[0].createFunctions?.[0].name;
  let index = currentName.search(/\d+$/);
  let baseName = currentName;

  if (index > 0) {
    baseName = currentName.slice(0, index - 1);
  }

  const startOrdinal =
    index >= 0 ? parseInt(currentName.substring(index), 10) + 1 : 2;

  if (args[0].createKinds) {
    args[0].createKinds[0].name = getNewName({
      names,
      baseName,
      startOrdinal,
      forceAddOrdinal: true
    });
  } else {
    args[0].createFunctions[0].name = getNewName({
      names,
      baseName,
      startOrdinal,
      forceAddOrdinal: true
    });
  }

  return args;
};
