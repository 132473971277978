import { approximateNodeSize, calculateNodePositions } from './layout';

import { GraphNodes } from 'util/defines';
import { getFunctionById } from 'util/function';
import { getKindById } from 'util/kind';
import gql from 'graphql-tag';
import { updateGraph } from 'util/graph/portalGraph';
import uuidv4 from 'uuid';

const NodeStateTypeName = 'NodeState';
const NodeStateDetailsTypeName = 'NodeStateDetails';
export const SNAP_GRID_SIZE = 32;

const GraphNodeState = Object.freeze({
  EditModeOn: 4
});

const GraphNodeStateDetailsFragment = gql`
  fragment GraphNodeStateDetails on NodeState {
    id
    state {
      nodeId
      nodeState
    }
  }
`;

export const GraphNodeStateQuery = gql`
  query GraphNodeState($id: ID!) {
    nodeState(id: $id) @client {
      ...GraphNodeStateDetails
    }
  }
  ${GraphNodeStateDetailsFragment}
`;

const getNodeStateDetailsFragmentId = id => `${NodeStateTypeName}:${id}`;

const readNodeStateDetailsFragment = (id, client) => {
  const fragmentId = getNodeStateDetailsFragmentId(id);
  return client.readFragment({
    id: fragmentId,
    fragment: GraphNodeStateDetailsFragment
  });
};

const writeNodeStateDetailsFragment = (id, state, client) => {
  const fragmentId = getNodeStateDetailsFragmentId(id);
  const data = {
    id,
    state,
    __typename: NodeStateTypeName
  };
  client.writeFragment({
    id: fragmentId,
    fragment: GraphNodeStateDetailsFragment,
    data
  });
};

export const GraphNodeStateQueryResolver = {
  nodeState: (_, { id }, { cache }) => {
    let fragment = readNodeStateDetailsFragment(id, cache);

    if (!fragment) {
      fragment = {
        id,
        state: [],
        __typename: NodeStateTypeName
      };
    }

    return fragment;
  }
};

const setNodesState = (graphId, nodeIds, nodeState, client) => {
  let fragment = readNodeStateDetailsFragment(graphId, client);

  const updatedState = fragment.state
    .filter(node => !nodeIds.includes(node.nodeId))
    .concat(
      nodeIds.map(nodeId => ({
        nodeId,
        nodeState,
        __typename: NodeStateDetailsTypeName
      }))
    );

  writeNodeStateDetailsFragment(graphId, updatedState, client);
};

const getNodeState = (nodesState, nodeState) => {
  return nodesState.state.filter(ns => ns.nodeState === nodeState);
};

/**
 * Sets graph nodes states to EditModeOn
 * @param {String} graphId The id of the portal graph
 * @param {String} nodeIds The ids of the graph nodes
 * @param {Object} client The client for Apollo store
 */
export const setAreNodesInEditModeOn = (graphId, nodeIds, client) => {
  setNodesState(graphId, nodeIds, GraphNodeState.EditModeOn, client);
};

/**
 * Gets a collection of all nodes with nodeType of EditModeOn given a node-state object
 * @param {Object} nodesState The NodeState constant
 */
export const getEditModeOnNodes = nodesState => {
  return getNodeState(nodesState, GraphNodeState.EditModeOn);
};

/**
 * Clears the node state for multiple graph nodes
 * @param {String} graphId The id of the portal graph
 * @param {String} nodeIds The ids of the graph nodes
 * @param {Object} client The client for Apollo store
 */
export const clearNodesState = (graphId, nodeIds, client) => {
  const fragment = readNodeStateDetailsFragment(graphId, client);
  const updatedState = fragment.state.filter(
    node => !nodeIds.includes(node.nodeId)
  );

  writeNodeStateDetailsFragment(graphId, updatedState, client);
};

/**
 * Check to see if a node for an instance already exists on a knowledge graph.
 *
 * @param {PortalGraph} graph The Knowledge Graph to check the nodes to
 * @param {string} instance The ID of the instance to check for
 */
export function isExistingKnowledgeGraphNode(graph, instanceId) {
  return graph.nodes.some(
    node => instanceId === node.knowledgeGraphNode.instance.id
  );
}

/**
 * Adds a knowledge graph node for an existing kind or function.
 * It is expected that this function is used for knowledge graphs only.
 *
 * @param {string} graphId The id for the graph (presumably the active graph).
 * @param {string} workspaceId Workspace Id.
 * @param {Object} client Apollo client instance.
 * @param {string} type The type of the node--'Kind' or 'Function'
 * @param {Object} instance The instance of the kind or function.
 * @param {boolean} changeSelection Boolean indicating whether the new node should be selected.
 * @param {Object} graphDimensions The current dimensions of the graph
 * @param {DiagramEngine} diagramEngine The diagram engine to use when laying out new node
 * @return {string} The ID of the new node
 */
export const addInstanceAsGraphNode = async (
  graphId,
  workspaceId,
  client,
  type,
  instance,
  changeSelection,
  graphDimensions,
  diagramEngine
) => {
  switch (type) {
    case 'Kind': {
      const existingKind = await getKindById(instance.id, client);
      if (!existingKind.id) {
        throw new Error('Cannot create node for kind that does not exist.');
      }
      break;
    }
    case 'Function': {
      const existingFunc = await getFunctionById(instance.id, client);
      if (!existingFunc.id) {
        throw new Error('Cannot create node for function that does not exist.');
      }
      break;
    }
    default: {
      throw new Error('Node type must only be Kind or Function.');
    }
  }

  const position = calculateNodePositions(
    [
      approximateNodeSize({
        client,
        kindId: instance.kindId,
        instanceId: instance.id
      })
    ],
    graphDimensions,
    diagramEngine
  )[0];

  const newNode = {
    kindId: instance.kindId,
    kindName: instance.kindName,
    instanceId: instance.id,
    nodeInfo: {
      ...position,
      pgNodeId: uuidv4(),
      innerNodeId: uuidv4(),
      collapsed: false,
      height: GraphNodes.MIN_HEIGHT,
      width: GraphNodes.MIN_WIDTH
    }
  };

  await updateGraph(
    { workspaceId, graphId, addEntities: [newNode] },
    client,
    changeSelection
  );

  return newNode.nodeInfo.pgNodeId;
};
