import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const GraphNodeIdsQuery = gql`
  query GraphNodeIds($id: ID!) {
    portalGraph(id: $id) {
      id
      type
      lockedBy
      nodes {
        id
        knowledgeGraphNode {
          id
          instance {
            id
          }
        }
        functionGraphNode {
          id
          operationId
        }
      }
    }
  }
`;

/**
 * Hook that performs an Apollo query for the list of graph nodes.
 * Should trigger an update if nodes are added or removed, but
 * not if other details within the nodes are modified.
 *
 * @param {string} graphId The ID of the graph
 * @returns {Object} graph, loading, error
 */
export function useGraphNodesAddedOrRemoved(graphId) {
  const { data, loading, error } = useQuery(GraphNodeIdsQuery, {
    variables: {
      id: graphId
    }
  });

  return { graph: data?.portalGraph, loading, error };
}
