import { SelectionQuery } from 'util/selectionUtils';
import { useQuery } from 'react-apollo';

/**
 * Hook that performs an Apollo query for the selection of a workspace.
 * Uses SelectionQuery.
 *
 * @param {string} workspaceId The ID of the workspace
 */
export function useSelected(workspaceId) {
  const { data, loading, error } = useQuery(SelectionQuery, {
    variables: {
      id: workspaceId
    }
  });

  return { selected: data?.selection?.selected, loading, error };
}
