import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

const SpinnerAdornment = styled(CircularProgress)`
  margin-right: ${p => p.theme.spacing(0.5)}px;
  color: inherit;
`;

const StyledButton = styled(Button)`
  min-width: 80px;
`;

export const SpinnerButton = props => {
  const { children, pending, ...rest } = props;

  return (
    <StyledButton {...rest}>
      {pending && <SpinnerAdornment size={20} />}
      {children}
    </StyledButton>
  );
};
