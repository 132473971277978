import PropTypes from 'prop-types';
import React from 'react';

/**
 * The React Context for the Workspace information
 */
export const WorkspaceContext = React.createContext(null);

/**
 * The provider used to set the updated Workspace information.
 */
export function WorkspaceContextProvider({
  children,
  workspaceId,
  workspaceLayoutId,
  workspaceServiceId,
  modelServiceId,
  logicServiceId
}) {
  return (
    <WorkspaceContext.Provider
      value={{
        workspaceId,
        workspaceLayoutId,
        workspaceServiceId,
        modelServiceId,
        logicServiceId
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
}

WorkspaceContextProvider.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  workspaceLayoutId: PropTypes.string.isRequired,
  workspaceServiceId: PropTypes.string.isRequired,
  modelServiceId: PropTypes.string.isRequired,
  logicServiceId: PropTypes.string.isRequired
};
