import {
  createFunctionForAssistant,
  deleteFunctionForAssistant,
  getFunctionByIdForAssistant,
  getFunctionObject,
  getFunctionsByIdForAssistant,
  updateFunctionForAssistant
} from './function';
import {
  createKindForAssistant,
  deleteKindForAssistant,
  getAllReferencedKinds,
  getKindByIdForAssistant,
  getKindsByIdForAssistant,
  updateKindForAssistant
} from './kind';
import {
  createKnowledgeGraph,
  createKnowledgeGraphs,
  getFunctionGraph
} from './graph';
import {
  createService,
  deleteService,
  getServiceById,
  refreshServiceSchemaForAssistant,
  reloadServiceSchemaForAssistant
} from './service';
import {
  createWorkspaceForAssistant,
  getUserAccessibleWorkspaces,
  getWorkspace,
  moveKindsAndFunctions
} from './workspace';
import {
  executeFunctionForAssistant,
  executeGraphqlForAssistant
} from './execution';
import { getCurrentSelection, prepareSelection } from './selection';

import { AssistantEvents } from './defines';
import Logger from 'util/Logger';
import UserContext from 'util/UserContext';
import { inventoryDiff } from './inventoryDiff';
import { isEmpty } from 'lodash';
import { locksDiff } from './locksDiff';
import { omitTypename } from './omitTypename';
import postRobot from 'post-robot';

/**
 * Helper that wraps creation of post-robot listener.
 *
 * @param {string} callName The string name of the message/call the listener is associated with.
 * @param {Function} cb Callback function associated with the listener.
 */
async function createAPIListener(callName, cb) {
  postRobot.on(callName, cb);
}

const DEPRECATION_WARNING_MESSAGE = `Deprecation Warning [Maana Assistant API]:
An assistant in your workspace is using an old version of the 'q-assistant-client' package.`;

export const RenderModeEnum = Object.freeze({
  DISPLAY: 'DISPLAY',
  BACKGROUND: 'BACKGROUND'
});

/**
 * -----------------Maana Assistant API-------------------------
 * Notes: post-robot uses Zalgo promises under the hood.
 */
class AssistantAPI {
  workspaceId = null;
  workspaceServiceId = null;
  modelServiceId = null;
  logicServiceId = null;
  client = null;
  iframeRefs = new Map();
  loadedAssistants = new Set();
  getGraphDimensions = null;
  diagramEngine = null;
  displayedWindow = null;
  setAssistantState = null;

  constructor() {
    //
    // Initialize listeners for post-robot API calls.
    //

    // User Info
    createAPIListener('getUserInfo', this.getUserInfo);

    // Workspaces
    createAPIListener('getWorkspace', this.getWorkspace);
    createAPIListener(
      'getUserAccessibleWorkspaces',
      this.getUserAccessibleWorkspaces
    );
    createAPIListener('createWorkspace', this.createWorkspace);
    createAPIListener('moveKindsAndFunctions', this.moveKindsAndFunctions);

    // Services
    createAPIListener('getServiceById', this.getServiceById);
    createAPIListener('executeGraphql', this.executeGraphql);
    createAPIListener('createService', this.createService);
    createAPIListener('refreshServiceSchema', this.refreshServiceSchema);
    createAPIListener('reloadServiceSchema', this.reloadServiceSchema);
    createAPIListener('deleteService', this.deleteService);

    // Functions
    createAPIListener('executeFunction', this.executeFunction);
    createAPIListener('createFunction', this.createFunction);
    createAPIListener('updateFunction', this.updateFunction);
    createAPIListener('deleteFunction', this.deleteFunction);
    createAPIListener('getFunctionById', this.getFunctionById);
    createAPIListener('getFunctionsById', this.getFunctionsById);
    createAPIListener('getFunctionGraph', this.getFunctionGraph);

    // Kinds
    createAPIListener('createKind', this.createKind);
    createAPIListener('updateKind', this.updateKind);
    createAPIListener('deleteKind', this.deleteKind);
    createAPIListener('getKindById', this.getKindById);
    createAPIListener('getKindsById', this.getKindsById);
    createAPIListener('getAllReferencedKinds', this.getAllReferencedKinds);

    // Workspace selection
    createAPIListener('getCurrentSelection', this.getCurrentSelection);

    // Render mode
    createAPIListener('getRenderMode', this.getRenderMode);
    createAPIListener('setAssistantState', this.setAssistantState);
    createAPIListener('reportError', this.reportError);

    //
    // Deprecated listeners
    //

    // State management
    createAPIListener('clearState', this.clearState);

    // Function execution
    createAPIListener(
      'addFunctionExecutionListener',
      this.addFunctionExecutionListener
    );

    createAPIListener(
      'removeFunctionExecutionListener',
      this.removeFunctionExecutionListener
    );

    // Selection changed
    createAPIListener(
      'enableSelectionChangedNotification',
      this.enableSelectionChangedNotification
    );
    createAPIListener(
      'disableSelectionChangedNotification',
      this.disableSelectionChangedNotification
    );

    // Inventory changed
    createAPIListener(
      'enableInventoryChangedNotification',
      this.enableInventoryChangedNotification
    );
    createAPIListener(
      'disableInventoryChangedNotification',
      this.disableInventoryChangedNotification
    );
  }

  /**
   * Sets info that is frequently needed by the functions within the assistant API.
   *
   * @param {Object} data The settings being passed in.
   * @param {string} data.workspaceId Workspace ID.
   * @param {string} data.workspaceServiceId ID of the aggregated service backing the workspace.
   * @param {string} data.logicServiceId ID of the underlying logic service.
   * @param {string} data.modelServiceId ID of the underlying model service.
   * @param {Object} data.client An instance of apollo client.
   * @param {function} data.getGraphDimensions Retrieves the dimensions of the graph
   * @param {DiagramEngine} data.diagramEngine The diagram engine to use when laying out new nodes
   * @param {function} data.addAssistantError Function used to add an error that will be reflected
   * in the UI for a particular Assistant
   * @param {function} data.setAssistantState Function used to let the assistant
   * notify kportal about its current state.
   */
  setSettings({
    workspaceId,
    workspaceServiceId,
    modelServiceId,
    logicServiceId,
    client,
    getGraphDimensions,
    diagramEngine,
    addAssistantError,
    setAssistantState
  }) {
    this.workspaceId = workspaceId;
    this.workspaceServiceId = workspaceServiceId;
    this.modelServiceId = modelServiceId;
    this.logicServiceId = logicServiceId;
    this.client = client;
    this.getGraphDimensions = getGraphDimensions;
    this.diagramEngine = diagramEngine;
    this.addAssistantError = addAssistantError;
    this.setAssistantState = setAssistantState;
  }

  //
  // Iframe References and broadcasting
  //

  /**
   * Returns an iterable containing the IDs of all
   * Assistants using the Assistant API in the current Workspace.
   */
  getAllAssistantIds() {
    return this.iframeRefs.keys();
  }

  /**
   * Sets the references to iframe elements containing external Assistants.
   * These are used to interact with Assistants through PostRobot.
   *
   * @param {Map<string, Element>} iframeRefs A map where the key=assistant ID and value=iframe element.
   */
  setIframeRefs(iframeRefs) {
    if (iframeRefs instanceof Map) {
      this.iframeRefs = iframeRefs;
    }
  }

  /**
   * Adds an assistant ID to the loadedAssitants Set.
   *
   * @param {string} assistantId The ID of the assistant.
   */
  addLoadedAssistant(assistantId) {
    this.loadedAssistants.add(assistantId);
  }

  /**
   * Removes an assistant ID from the loadedAssistants Set.
   *
   * @param {string} assistantId
   */
  removeLoadedAssistant(assistantId) {
    this.loadedAssistants.delete(assistantId);
  }

  /**
   * Updates the loadedAssistants Set to only include assistant IDs that are in the parameter.
   *
   * @param {Set<string>} currentIdSet A set containing IDs of the assistants currently in the workspace.
   */
  updateLoadedAssistants(currentIdSet) {
    // Do set intersection on loadedAssistants (a) with currentIdSet (b)
    // Set loadedAssistants with the intersection.
    this.loadedAssistants = new Set(
      [...this.loadedAssistants].filter(x => currentIdSet.has(x))
    );
  }

  /**
   * Broadcasts an event to all assistants.
   *
   * @param {string} eventName The event name to broadcast.
   * @param {Object} arg The event argument as an object.
   * @return {Promise} A promise that will complete once all the assistants are
   * done handling the event.
   */
  broadcastAll(eventName, arg) {
    return this.broadcastTo(
      Array.from(this.iframeRefs.values()),
      eventName,
      arg
    );
  }

  /**
   * Broadcasts to assistants in the frameRefs list. Each event promise will be
   * terminated based on a configurable TTL or its own resolution, which ever occurs first.
   *
   * @param {Array<IFrameRef>} frameRefs The list of iframe references held in the workspace.
   * @param {string} eventName The event name to broadcast.
   * @param {Object} arg The event argument as an object.
   * @return {Promise} A promise that will resolve once all the assistants are
   * done handling the event. This promise will always be resolved and not rejected.
   */
  async broadcastTo(frameRefs, eventName, arg) {
    const activeFrames = frameRefs
      .filter(
        fr =>
          fr?.contentWindow &&
          this.loadedAssistants.has(this.getAssistantId(fr))
      )
      .filter(Boolean);

    const newPromises = [];

    for (const frame of activeFrames) {
      const id = this.getAssistantId(frame);
      const postRobotPromise = postRobot
        .send(frame.contentWindow, eventName, arg)
        .catch(err => {
          // Callers of broadcastTo will not receive errors in the returned promise
          if (
            !(
              err.message &&
              err.message.includes('No handler found for post message')
            )
          ) {
            // Show errors from post robot to the user
            this.addAssistantError(
              id,
              new Error(`Error on event '${eventName}': ${err.message}`)
            );
          }
        });

      newPromises.push(postRobotPromise);
    }

    if (newPromises.length) {
      return Promise.all(newPromises);
    }
  }

  /**
   * Returns the name of an assistant based on its iframe reference.
   *
   * @param {IFrame} frame The iframe reference for the assistant.
   */
  getAssistantName(frame) {
    return frame.getAttribute('data-name');
  }

  /**
   * Returns the ID of an assistant based on its iframe reference.
   *
   * @param {IFrame} frame The iframe reference for the assistant.
   */
  getAssistantId(frame) {
    return frame.getAttribute('data-id');
  }

  /**
   * Given a dom element it will find the iframe that has it as its content
   * window.
   *
   * @param {Element} contentWindow The dom element containing the iframe.
   * @return {string|null} ID of the assistant, or null if it is not found.
   */
  getAssistantIdFromContentWindow(contentWindow) {
    const frame = Array.from(this.iframeRefs.values()).find(
      f => f.contentWindow === contentWindow
    );
    if (frame) {
      return this.getAssistantId(frame);
    }
    return null;
  }

  /**
   * Returns the Iframe of an assistant based on its ID.
   *
   * @param {string} assistantId The ID of the assistant.
   */
  getAssistantIframe(assistantId) {
    return this.iframeRefs.get(assistantId);
  }

  //
  // Render mode
  //

  /**
   * Tells assistant to switch to a mode as specified in the RenderModeEnum.
   *
   * @param {string} assistantId The id of the assistant.
   * @param {RenderModeEnum} renderMode Value in RenderModeEnum.
   */
  setAssistantRenderMode(assistantId, renderMode) {
    const frame = this.getAssistantIframe(assistantId);

    if (frame) {
      // Toggle displayedWindow given incoming renderMode values.
      if (renderMode === RenderModeEnum.DISPLAY) {
        this.displayedWindow = frame.contentWindow;
      } else if (this.displayedWindow === frame.contentWindow) {
        this.displayedWindow = null;
      }
    }

    return this.broadcastTo(
      [frame],
      AssistantEvents.RENDER_MODE_CHANGED,
      renderMode
    );
  }

  /**
   * Returns the render mode enum value that indicates
   * whether an assistant is currently displayed.
   *
   * source parameter is supplied by post-robot. NOT user supplied.
   */
  getRenderMode = async ({ source }) => {
    // If displayed window matches the source from the
    // assistant, then the source is currently displayed.
    if (this.displayedWindow === source) {
      return RenderModeEnum.DISPLAY;
    }
    return RenderModeEnum.BACKGROUND;
  };

  /**
   * Sets the current state of the assistant.
   *
   * @param {Object} params The information from the post message.
   * @param {string} params.data The new state for the assistant.
   * @param {Element} params.source The dom element that sent the post message.
   */
  setAssistantState = ({ data, source }) => {
    const id = this.getAssistantIdFromContentWindow(source);

    if (id) {
      this.setAssistantState(id, data);
    } else {
      Logger.error(
        `Cannot find assistant reference in AssistantAPI.setAssistantState when attempting to change state to ${data}`
      );
    }
  };

  /**
   * Reports an error for an assistant.
   *
   * @param {Object} params The information from the post message.
   * @param {string} params.data The Error object.
   * @param {Element} params.source The dom element that sent the post message.
   */
  reportError = ({ data, source }) => {
    const id = this.getAssistantIdFromContentWindow(source);
    if (id) {
      this.addAssistantError(id, data.message ? data : new Error(data));
    } else {
      Logger.error(
        `Cannot find assistant reference in AssistantAPI.reportError when attempting to report error: ${data}`
      );
    }
  };

  //
  // User Info
  //

  /**
   * Gets the user info object.
   *
   * @return {Object} The user info object containing email and username.
   */
  getUserInfo = async () => {
    try {
      const { email, name } = UserContext.getUserProfile();
      return { email, name };
    } catch (ex) {
      return Promise.reject(
        new Error(`Assistant API error in getUserInfo: ${ex.message}`)
      );
    }
  };

  //
  // Selection
  //

  /**
   * Expected to be called when workspace selection changes.
   * Notifies API client subscribers that the workspace selection has changed, and passes selection.
   * Event data is shaped as: {selection:[{}]}
   *
   * @param {Object} input The current workspace selection.
   */
  selectionChanged = async input => {
    // Only notify proceed if there are assistants in the workspace.
    if (this.iframeRefs.size === 0) return;

    const filteredSelection = await prepareSelection(input, this.client);

    return this.broadcastAll(AssistantEvents.SELECTION_CHANGED, {
      selection: filteredSelection
    });
  };

  /**
   * Returns the current selection in the workspace
   *
   * @return {Array<Object>} Array of selection objects as { selection : [{}] }.
   */
  getCurrentSelection = () =>
    getCurrentSelection(this.workspaceId, this.client);

  //
  // Inventory
  //

  /**
   * Diffs the new and old state of the inventory and then sends the changes
   * over to the assistants.
   *
   * @param {Object} oldState The old state of the inventory
   * @param {Object} newState The new state of the inventory
   */
  inventoryChanged(oldState, newState) {
    // Only notify proceed if there are assistants in the workspace.
    if (this.iframeRefs.size === 0) return;

    const diff = omitTypename(inventoryDiff(oldState, newState));

    if (!isEmpty(diff)) {
      // Prepare the Functions for being passed through the Assistant API.
      if (diff.functions) {
        diff.functions.adds = diff.functions.adds?.map(f =>
          getFunctionObject(f, this.client, this.workspaceId)
        );
        diff.functions.updates = diff.functions.updates?.map(f =>
          getFunctionObject(f, this.client, this.workspaceId)
        );
        diff.functions.deletes = diff.functions.deletes?.map(f =>
          getFunctionObject(f, this.client, this.workspaceId)
        );
      }

      // Broadcast the event to all of the assistants.
      return this.broadcastAll(AssistantEvents.INVENTORY_CHANGED, {
        diff
      });
    }

    return Promise.resolve();
  }

  //
  // Locking
  //

  /**
   * Called when a lock is updated within a workspace, graph,
   * or function. This will broadcast an event to assistants,
   * notifying them of the current locks.
   *
   * LockStatus: { id : string, lockedBy : string }
   * LockObject: {
   *               workspaces : [LockStatus],
   *               knowledgeGraphs : [LockStatus],
   *               functions : [LockStatus]
   *              }
   *
   * @param {LockObject} oldState The object containing the old locks.
   * @param {LockObject} newState The object containing the current locks.
   */
  lockingChanged(oldState, newState) {
    // Only notify proceed if there are assistants in the workspace.
    if (this.iframeRefs.size === 0) return;

    const locks = omitTypename(locksDiff(oldState, newState));

    if (!isEmpty(locks)) {
      return this.broadcastAll(AssistantEvents.LOCKING_CHANGED, { locks });
    }

    return Promise.resolve();
  }

  //
  // Services
  //

  /**
   * Returns a service matching the supplied ID.
   *
   * @param {string} id the ID of the service to be retrieved.
   * @return {Service} An object representing the service.
   */
  getServiceById = ({ data: id }) =>
    getServiceById(
      id,
      this.diagramEngine,
      this.getGraphDimensions,
      this.client
    );

  /**
   * Deletes a service based on the serviceId.
   *
   * @param {string} data The ID of the service to be deleted.
   */
  deleteService = ({ data }) =>
    deleteService(data, this.workspaceId, this.client);

  /**
   * Executes an arbitrary graphql string against a service endpoint.
   *
   * @param {Object} data The input containing the serviceId, query, and variables.
   * @return {Promise<Object>} The result from the execute query.
   */
  executeGraphql = ({ data }) => executeGraphqlForAssistant(data);

  /*
   * Refreshes a services schema.
   *
   * @param {string} data The service id.
   */
  refreshServiceSchema = ({ data }) =>
    refreshServiceSchemaForAssistant(data, this.workspaceId, this.client);

  /**
   * Reloads the service kinds and functions in inventory.
   *
   * @param {string} data The service id.
   */
  reloadServiceSchema = ({ data }) =>
    reloadServiceSchemaForAssistant(data, this.workspaceId, this.client);

  /**
   * Gets the current workspace (the workspace scoped to the assistant).
   *
   * @param {string} data The ID of the Workspace to load. (Optional)
   * @return {Workspace} A composite object representing the workspace.
   */
  getWorkspace = ({ data }) =>
    getWorkspace(
      data || this.workspaceId,
      this.diagramEngine,
      this.getGraphDimensions,
      this.client
    );

  /**
   * Gets the current list of workspaces the user can access, with the option
   * of including public workspaces also.
   *
   * @param {boolean} data When set to true, adds the public workspaces
   * @return {Array<Workspace>} The list of workspaces the user can access.
   */
  getUserAccessibleWorkspaces = ({ data }) =>
    getUserAccessibleWorkspaces(
      data,
      this.diagramEngine,
      this.getGraphDimensions,
      this.client
    );

  /**
   * Creates a new Workspace based on the information passed in from the
   * assistant.
   *
   * @param {Object} data The data about the new Workspace in the shape of
   *                  {id, serviceId, name}
   * @return {Workspace} The new Workspace
   */
  createWorkspace = ({ data }) =>
    createWorkspaceForAssistant(
      data,
      this.diagramEngine,
      this.getGraphDimensions,
      this.client
    );

  /**
   * Moves a collection of Kinds and Functions from the origin Workspace to the
   * target Workspace.
   *
   * @param {string} data.originId The ID of the origin Workspace.
   * @param {string} data.targetId The ID of the target Workspace.
   * @param {Array<string>} data.kindIds An array of the IDs of the kinds to move.
   * @param {Array<string>} data.functionIds An array of the IDs of the functions to move.
   */
  moveKindsAndFunctions = ({ data }) =>
    moveKindsAndFunctions(
      data.originId,
      data.targetId,
      data.kindIds,
      data.functionIds,
      this.client
    );

  //
  // Knowledge Graphs
  //

  /**
   * Creates a new knowledge graph in the current workspace.
   *
   * @param {AddPortalGraphInput} kgInput The data used to create the graph.
   * @return {Object} The Assistant API graph object for for the created graph.
   */
  createKnowledgeGraph = kgInput =>
    createKnowledgeGraph(
      kgInput,
      this.workspaceId,
      this.diagramEngine,
      this.getGraphDimensions,
      this.client
    );

  /**
   * Creates a list of new knowledge graphs in the current workspace.
   *
   * @param {Array<AddPortalGraphInput>} kgInputs The list of graphs to create.
   * @return {Object} The Assistant API graph objects for for the created graphs.
   */
  createKnowledgeGraphs = kgInputs =>
    createKnowledgeGraphs(
      kgInputs,
      this.workspaceId,
      this.diagramEngine,
      this.getGraphDimensions,
      this.client
    );

  //
  // Functions
  //

  /**
   * Executes a function given function and query information.
   *
   * @param {Object} data The information for running the function execution.
   * @return {Promise<Object>} Promise that resolves to executed function's output.
   */
  executeFunction = ({ data }) =>
    executeFunctionForAssistant(data, this.client);

  /**
   * Performs lookup on functionExecution listener and fires if it exists.
   *
   * @param {string} id function ID.
   * @param {Object} result the function execution result.
   */
  functionExecuted = (id, result) => {
    // Only notify proceed if there are assistants in the workspace.
    if (this.iframeRefs.size === 0) return;

    return this.broadcastAll(AssistantEvents.FUNCTION_EXECUTED, {
      result,
      id
    });
  };

  /**
   * Creates a function given function input.
   *
   * @param {Object} data Function input.
   * @return {Promise<Function>} The created function.
   */
  createFunction = ({ data }) =>
    createFunctionForAssistant(data, this.logicServiceId, this.client);

  /**
   * Updates a function.
   *
   * @param {UpdateFunctionInput} data Information about the function to update.
   * @return {Promise<Function>} The updated function.
   */
  updateFunction = ({ data }) =>
    updateFunctionForAssistant(
      data,
      this.workspaceId,
      this.logicServiceId,
      this.client
    );

  /**
   * Deletes a function.
   *
   * @param {string} data The ID of the function to delete.
   */
  deleteFunction = ({ data }) =>
    deleteFunctionForAssistant(
      data,
      this.workspaceId,
      this.logicServiceId,
      this.client
    );

  /**
   * Returns a function matching the supplied ID.
   *
   * @param {string} data The function ID.
   * @return {Promise<Function>} Returned function.
   */
  getFunctionById = ({ data }) =>
    getFunctionByIdForAssistant(data, this.client, this.workspaceId);

  /**
   * Returns a group of functions matching the supplied IDs.
   *
   * @param {Array<string>} data The function IDs.
   * @return {Promise<Array<Function>>} Returned functions.
   */
  getFunctionsById = ({ data }) =>
    getFunctionsByIdForAssistant(data, this.client, this.workspaceId);

  /**
   * Returns the function graph connected to the supplied function id.
   *
   * @param {string} data ID of the function to look up the graph with.
   * @return {Promise<Object>} The graph information.
   */
  getFunctionGraph = ({ data }) =>
    getFunctionGraph(
      data,
      this.workspaceId,
      this.diagramEngine,
      this.getGraphDimensions,
      this.client
    );

  //
  // Kinds
  //

  /**
   * Creates a kind.
   *
   * @param {AddKindInput} data The kind input.
   * @return {Promise<Kind>} The created kind.
   */
  createKind = ({ data }) =>
    createKindForAssistant(data, this.modelServiceId, this.client);

  /**
   * Updates a kind.
   *
   * @param {UpdateKindInput} data The updated information about the kind.
   * @return {Promise<Kind>} The updated kind.
   */
  updateKind = ({ data }) =>
    updateKindForAssistant(
      data,
      this.workspaceId,
      this.modelServiceId,
      this.client
    );

  /**
   * Deletes a kind.
   *
   * @param {Object} id Kind ID to delete.
   */
  deleteKind = ({ data }) =>
    deleteKindForAssistant(
      data,
      this.workspaceId,
      this.modelServiceId,
      this.client
    );

  /**
   * Returns a kind given the supplied ID.
   *
   * @param {string} id ID of the kind to load.
   * @return {Promise<Kind>} The retrieved kind.
   */
  getKindById = ({ data }) => getKindByIdForAssistant(data, this.client);

  /**
   * Returns a group of kinds given the supplied IDs.
   *
   * @param {Array<string>} data IDs of the kinds to load.
   * @return {Promise<Kind>} The retrieved kinds.
   */
  getKindsById = ({ data }) => getKindsByIdForAssistant(data, this.client);

  /**
   * Given an array of input IDs, provide entire kind tree referenced by these kinds.
   *
   * @param {Array<string>} data.ids The ids to traverse.
   * @param {number} data.maxDepth Maximimum traversal depth in schema levels
   * @param {Array<string>} data.idsToSkip IDs not to include.
   * @return {Promise<Array<Kind>>} A promise that resolves to a list of kinds.
   */
  getAllReferencedKinds = ({ data }) => {
    const { ids, maxDepth, idsToSkip } = data;
    return getAllReferencedKinds(ids, maxDepth, idsToSkip, this.client);
  };

  /**
   * Adds a service to the service catalog.
   *
   * @param {Object} input The addService input object.
   * @return {string} The service ID for the added service.
   */
  createService = ({ data: input }) => createService(input, this.client);

  //
  // Repair
  //

  /**
   * Repair operation will send a repair event to a single assistant or all assistants
   * in the workspace.
   *
   * @param {string} assistantId The ID of the assistant to send a repair event to.
   * If it is not specified then all assistants will be sent a repair event.
   */
  repair(assistantId) {
    if (this.iframeRefs.size === 0) return;

    if (assistantId) {
      return this.broadcastTo(
        [this.getAssistantIframe(assistantId)],
        AssistantEvents.REPAIR
      );
    } else {
      return this.broadcastAll(AssistantEvents.REPAIR);
    }
  }

  //
  // Reload
  //

  /**
   * This will reload an assistant in its iFrame.
   *
   * @param {string} assistantId The ID of the assistant to reload.
   */
  reload(assistantId) {
    const iframe = this.getAssistantIframe(assistantId);
    if (iframe) {
      const src = iframe.getAttribute('src');
      iframe.removeAttribute('src');

      // setTimeout is needed for the iFrame to reload the source.
      setTimeout(() => {
        iframe.setAttribute('src', src);

        // Remove loaded assistant to prevent broadcasting.
        // Do this after we set iframe src, as that will cause
        // a premature reload and reenable broadcasting.
        this.removeLoadedAssistant(assistantId);
      });
    }
  }

  //
  // Deprecated surface area
  //

  /**
   * DEPRECATED
   *
   * Clears all state associated with the assistant.
   */
  clearState = async () => {
    Logger.warn(DEPRECATION_WARNING_MESSAGE);
  };

  /**
   * DEPRECATED
   *
   * Enables selection changed notifications to the assistant client.
   *
   * @return {boolean} Returns true on success.
   */
  enableSelectionChangedNotification = async () => {
    Logger.warn(DEPRECATION_WARNING_MESSAGE);
    return true;
  };

  /**
   * DEPRECATED
   *
   * Disables selection changed notifications to the assistant client.
   *
   * @return {boolean} Returns true on success.
   */
  disableSelectionChangedNotification = async () => {
    Logger.warn(DEPRECATION_WARNING_MESSAGE);
    return true;
  };

  /**
   * DEPRECATED
   *
   * Adds a function execution event listener for a given function id.
   *
   * @param {string} data The function ID to be added for function execution eventing.
   * @return {boolean} A boolean value indicating whether the listener was added.
   */
  addFunctionExecutionListener = async ({ data }) => {
    Logger.warn(DEPRECATION_WARNING_MESSAGE);
    return true;
  };

  /**
   * DEPRECATED
   *
   * Removes a function execution event listener for a given function id.
   *
   * @param {string} data The function ID to be removed from function execution eventing.
   * @returns {boolean} A boolean value indicating whether a listener existed and was removed.
   */
  removeFunctionExecutionListener = async ({ data }) => {
    Logger.warn(DEPRECATION_WARNING_MESSAGE);
    return true;
  };
  /**
   * DEPRECATED
   *
   * Enables inventory changed notifications to the assistant client.
   *
   * @return {boolean} Returns true on success.
   */
  enableInventoryChangedNotification = () => {
    Logger.warn(DEPRECATION_WARNING_MESSAGE);
    return true;
  };

  /**
   * DEPRECATED
   *
   * Disables inventory changed notifications to the assistant client.
   *
   * @return {boolean} Returns true on success.
   */
  disableInventoryChangedNotification = () => {
    Logger.warn(DEPRECATION_WARNING_MESSAGE);
    return true;
  };
}

// Export as singleton.
export default new AssistantAPI();
