import {
  createFunction,
  createFunctions,
  deleteFunction,
  getFunctionById,
  getFunctionsById,
  updateFunctions
} from 'util/function';
import { isEmpty, omit } from 'lodash';

import { AssistantFunctionsFragment } from './graphql';
import { cleanAndSaveGraphPathHistory } from 'util/selectionUtils';
import cleanWorkspace from 'util/cleanWorkspace';
import { getGraphLockedBy } from 'util/permissions';
import gql from 'graphql-tag';
import { omitTypename } from './omitTypename';
import uuidv4 from 'uuid/v4';

const FunctionByIdQuery = gql`
  query FunctionById($id: ID!) {
    function(id: $id) {
      ...AssistantFunctions
    }
  }
  ${AssistantFunctionsFragment}
`;

const FunctionsByIdQuery = gql`
  query FunctionsById($ids: [ID!]!) {
    functions(ids: $ids) {
      ...AssistantFunctions
    }
  }
  ${AssistantFunctionsFragment}
`;

const AssistantServiceFunctionsQuery = gql`
  query AssistantServiceFunctions($ids: [ID!]!) {
    services(ids: $ids) {
      id
      functions {
        ...AssistantFunctions
      }
    }
  }
  ${AssistantFunctionsFragment}
`;

/**
 * Preps the Function passed in for the Assistant API
 *
 * @param {Object} func The Function to work with.
 * @param {ApolloClient} client The client used to request for data.
 * @param {string} workspaceId The ID of the active Workspace.
 * @return {Object} A composite object representing the Function.
 */
export function getFunctionObject(func, client, workspaceId) {
  if (!func) return null;

  const cleanedFunc = omitTypename(func);

  // If there is no passed in workspace ID, or it is a boilerplate function,
  // then skip lock checking and return dummy data.
  const skipLockCheck = !workspaceId || func.isGenerated;

  return {
    ...cleanedFunc,

    // Add the locking properties to the Object in a way that each time the
    // assistant calls them they are are returning up to date information about
    // its current locked state.

    async lockedBy() {
      if (skipLockCheck) return null;

      try {
        const { lockedBy } = await getGraphLockedBy(
          func.id,
          workspaceId,
          client
        );
        return lockedBy;
      } catch {
        // Return null if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return null;
      }
    },
    async canEdit() {
      if (skipLockCheck) return false;

      try {
        const { canEdit } = await getGraphLockedBy(
          func.id,
          workspaceId,
          client
        );
        return canEdit;
      } catch {
        // Return false if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return false;
      }
    },
    async setLocked(isLocked) {
      if (skipLockCheck) return null;

      try {
        const { setLockedBy } = await getGraphLockedBy(
          func.id,
          workspaceId,
          client
        );
        return setLockedBy(isLocked);
      } catch {
        // Return null if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return null;
      }
    }
  };
}

/**
 * Creates a function given function input.
 *
 * @param {Object} funcData Function input.
 * @param {string} logicServiceId The ID of the logic service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Function>} The created function.
 */
export async function createFunctionForAssistant(
  funcData,
  logicServiceId,
  client
) {
  try {
    // Persist function.
    const func = await createFunction(funcData, logicServiceId, client);

    // Return full function definition.
    return await getFunctionByIdForAssistant(func.id, client);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'createFunction': ${ex.message}`)
    );
  }
}

/**
 * Creates a group of functions given function inputs.
 *
 * @param {Object} funcData Function input.
 * @param {string} logicServiceId The ID of the logic service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Function>} The created function.
 */
export async function createFunctionsForAssistant(
  funcData,
  logicServiceId,
  client
) {
  try {
    // Persist function.
    const functions = await createFunctions(funcData, logicServiceId, client);

    // Return full function definition.
    return await getFunctionsByIdForAssistant(
      functions.map(func => func.id),
      client
    );
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'createFunctions': ${ex.message}`)
    );
  }
}

/**
 * Updates a group of functions.
 *
 * @param {Array<UpdateFunctionInput>} funcData Information about the functions to update.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {string} logicServiceId The ID of the logic service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Array<Function>>} The updated functions.
 */
async function internalUpdateFunctions(
  funcData,
  workspaceId,
  logicServiceId,
  client
) {
  const cleanedFuncs = funcData.map(f => ({
    ...omit(f, ['canEdit', 'lockedBy', 'setLocked', 'kind']),
    arguments: f?.arguments?.map(a => ({
      ...omit(a, ['kind']),
      // Make sure that all of the arguments have IDs
      id: a.id ?? uuidv4()
    }))
  }));

  await updateFunctions(cleanedFuncs, workspaceId, logicServiceId, client);

  // Return full function definition.
  return getFunctionsByIdForAssistant(
    funcData.map(fi => fi.id),
    client,
    workspaceId
  );
}

/**
 * Updates a function.
 *
 * @param {UpdateFunctionInput} funcData Information about the function to update.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {string} logicServiceId The ID of the logic service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Function>} The updated function.
 */
export async function updateFunctionForAssistant(
  funcData,
  workspaceId,
  logicServiceId,
  client
) {
  try {
    const res = await internalUpdateFunctions(
      [funcData],
      workspaceId,
      logicServiceId,
      client
    );
    return res ? res[0] : res;
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'updateFunction':${ex.message}`)
    );
  }
}

/**
 * Updates a group of functions.
 *
 * @param {Array<UpdateFunctionInput>} funcData Information about the functions to update.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {string} logicServiceId The ID of the logic service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Array<Function>>} The updated functions.
 */
export async function updateFunctionsForAssistant(
  funcData,
  workspaceId,
  logicServiceId,
  client
) {
  try {
    return await internalUpdateFunctions(
      funcData,
      workspaceId,
      logicServiceId,
      client
    );
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'updateFunction':${ex.message}`)
    );
  }
}

/**
 * Deletes a function.
 *
 * @param {string} id The ID of the function to delete.
 * @param {string} workspaceId Id of the current Workspace
 * @param {string} logicServiceId The ID of the logic service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 */
export async function deleteFunctionForAssistant(
  id,
  workspaceId,
  logicServiceId,
  client
) {
  try {
    await deleteFunction(id, workspaceId, logicServiceId, client);

    cleanWorkspace({
      workspaceId: workspaceId,
      kindIds: [],
      functionIds: [id],
      instanceIds: [id],
      graphIds: [],
      serviceIds: [],
      cleanKindsFromFields: false,
      client
    });
    cleanAndSaveGraphPathHistory(workspaceId, client);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'deleteFunction': ${ex.message}`)
    );
  }
}

/**
 * Returns a function matching the supplied ID.
 *
 * @param {string} id The function ID.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Function>} Returned function.
 */
export async function getFunctionByIdForAssistant(id, client, workspaceId) {
  try {
    const func = await getFunctionById(id, client, FunctionByIdQuery);
    if (func?.isDeleted) return null;
    return getFunctionObject(func, client, workspaceId);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'getFunctionById': ${ex.message}`)
    );
  }
}

/**
 * Returns a group of functions matching the supplied IDs.
 *
 * @param {Array<string>} ids The function IDs.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Array<Function>>} Returned functions.
 */
export async function getFunctionsByIdForAssistant(ids, client, workspaceId) {
  try {
    const functions = await getFunctionsById(ids, client, FunctionsByIdQuery);
    return functions
      .filter(func => !func.isDeleted)
      .map(func => {
        return getFunctionObject(func, client, workspaceId);
      });
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'getFunctionsById': ${ex.message}`)
    );
  }
}

/**
 * Returns an array of functions based on an array of service IDs.
 *
 * @param {Array<string>} ids Service Ids.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Array<Function>>} A promise that resolves to an array of functions.
 */
export async function getFunctionsByService(ids, client, workspaceId) {
  try {
    const serviceIds = Array.isArray(ids) ? ids : [ids];
    const { data, errors } = await client.query({
      query: AssistantServiceFunctionsQuery,
      variables: { ids: serviceIds }
    });

    if (errors) {
      throw new Error(`Error/s in client query: ${errors}`);
    }

    if (!data || !data.services) return [];

    return data.services
      .reduce(
        (acc, s) =>
          !s || isEmpty(s.functions) ? acc : acc.concat(s.functions),
        []
      )
      .filter(f => !f.isDeleted)
      .map(f => getFunctionObject(f, client, workspaceId));
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'getFunctionsByService':${ex.message}`)
    );
  }
}
