import { AllNonSystemServicesQuery } from 'graphql/Portal';
import { KN_SERVICE } from './defines';
import gql from 'graphql-tag';

const DeleteServiceMutation = gql`
  mutation DeleteService($id: ID!) {
    deleteService(id: $id)
  }
`;

const ServiceIsDeletedFragment = gql`
  fragment ServiceIsDeleted on Service {
    isDeleted
  }
`;

/**
 * Deletes a service from existence
 *
 * @param {string} serviceId The ID of the service to delete
 * @param {ApolloClient} client The Apollo Client used to make mutation calls and cache updates
 */
export default async function deleteService(serviceId, client) {
  await client.mutate({
    mutation: DeleteServiceMutation,
    variables: { id: serviceId },
    optimisticResponse: { deleteService: true },
    update: (store, { data }) => {
      if (data.deleteService) {
        try {
          const { allNonSystemServices } = store.readQuery({
            query: AllNonSystemServicesQuery,
            variables: { take: -1 }
          });

          store.writeQuery({
            query: AllNonSystemServicesQuery,
            variables: { take: -1 },
            data: {
              allNonSystemServices: allNonSystemServices.filter(
                service => service.id !== serviceId
              )
            }
          });
        } catch {}

        store.writeFragment({
          id: `${KN_SERVICE}:${serviceId}`,
          fragment: ServiceIsDeletedFragment,
          data: { isDeleted: true, __typename: KN_SERVICE }
        });
      }
    }
  });
}
