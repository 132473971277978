import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import { DataRenderingWrapper } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { fmtDateTime } from 'util/helpers';
import gql from 'graphql-tag';
import { parseInstanceIdFromInstanceRefId } from 'util/helpers';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';

const CommentQuery = gql`
  query Comment($id: ID!) {
    comment(id: $id) {
      id
      name
      author {
        id
        name
        picture
      }
      detail
      timestamp
    }
  }
`;

const AuthorDate = styled(Typography).attrs({
  component: 'span'
})`
  color: ${p => p.theme.palette.text.disabled};
`;

const Detail = styled(Typography).attrs({
  component: 'span'
})`
  margin-top: ${p => p.theme.spacing(1)}px;
  color: ${p => p.theme.palette.text.primary};
  display: block;
`;

/**
 * List item to display an instanceRef representing a Comment.
 */
export function CommentListItem(props) {
  const { title, instanceRef } = props;

  const { loading, error, data } = useQuery(CommentQuery, {
    variables: { id: parseInstanceIdFromInstanceRefId(instanceRef.id) }
  });

  const comment = data && data.comment;

  return (
    <DataRenderingWrapper
      loading={loading || !(comment || error)}
      error={error}
      errorMessage={`Failed to load comment for ${title}`}
      location="Comment List Item"
    >
      {comment && (
        <ListItem component="div" alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="avatar" src={comment.author.picture} />
          </ListItemAvatar>
          <ListItemText
            primary={comment.name}
            secondary={
              <>
                <AuthorDate variant="caption">
                  By {comment.author.name} on {fmtDateTime(comment.timestamp)}
                </AuthorDate>
                <Detail variant="body2" color="textSecondary">
                  {comment.detail}
                </Detail>
              </>
            }
          />
        </ListItem>
      )}
    </DataRenderingWrapper>
  );
}

CommentListItem.propTypes = {
  title: PropTypes.string,
  instanceRef: PropTypes.object
};
