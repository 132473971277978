import { useCallback, useState } from 'react';

/**
 * Custom hook to handle a Yes/No confirmation dialog.
 *
 * @param {Object} dialogSettings Object containing settings for the dialog.
 * @param {string} dialogSettings.title The text to display in the title of the dialog.
 * @param {any} dialogSettings.body What is displayed in the body of the dialog.
 * Allows for the body to be sent for a function to generate the body.
 * @param {string} dialogSettings.onClickYes Function to call when the "Yes" button is clicked.
 * @param {string} dialogSettings.onClickNo Function to call when the "No" button is clicked.
 * @param {string} dialogSettings.body The text to display in the body of the dialog.
 * @param {string} dialogSettings.noButtonLabel Sets the text of the secondary action button.
 * Defaults to "No".
 * @param {string} dialogSettings.yesButtonLabel Sets the text of the primary action button.
 * Defaults to "Yes".
 * @returns {Array} The first value is the dialog state to pass into
 * the dialog component and the second is a function to call to open the dialog.
 */
export function useYesNoDialog({
  title,
  body,
  onClickYes,
  onClickNo,
  noButtonLabel = 'No',
  yesButtonLabel = 'Yes'
}) {
  const [dialogState, setDialogState] = useState({
    showDialog: false
  });

  const onCloseDialog = useCallback(event => {
    setDialogState(prevState => ({ ...prevState, showDialog: false }));
  }, []);

  const onOpenDialog = useCallback(
    event => {
      event && event.stopPropagation();
      setDialogState(prevState => ({
        ...prevState,
        title,
        body: typeof body === 'function' ? body() : body,
        showDialog: true,
        buttons: [
          {
            label: noButtonLabel,
            onClick: event => {
              onCloseDialog(event);
              if (onClickNo instanceof Function) {
                onClickNo();
              }
            }
          },
          {
            label: yesButtonLabel,
            onClick: event => {
              onCloseDialog(event);
              if (onClickYes instanceof Function) {
                onClickYes();
              }
            },
            color: 'secondary',
            variant: 'contained'
          }
        ]
      }));
    },
    [
      title,
      body,
      onClickNo,
      onClickYes,
      noButtonLabel,
      yesButtonLabel,
      onCloseDialog
    ]
  );

  return [dialogState, onOpenDialog];
}
