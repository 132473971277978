import { KN_FUNCTION, KN_KIND, KN_SERVICE, KN_WORKSPACE } from './defines';

import { WorkspaceModelServiceQuery } from 'graphql/Portal';
import cleanWorkspace from 'util/cleanWorkspace';
import { getBoilerplateIds } from 'util/helpers';
import gql from 'graphql-tag';

const RemoveKindsFromWorkspaceMutation = gql`
  mutation RemoveKindsFromWorkspace($workspaceId: ID!, $kindIds: [ID!]!) {
    removeKinds(kindIds: $kindIds, workspaceId: $workspaceId)
  }
`;

const RemoveFunctionsFromWorkspaceMutation = gql`
  mutation RemoveFunctionsFromWorkspace(
    $workspaceId: ID!
    $functionIds: [ID!]!
  ) {
    removeFunctions(functionIds: $functionIds, workspaceId: $workspaceId)
  }
`;

const RemoveInstancesFromWorkspaceMutation = gql`
  mutation RemoveInstancesFromWorkspace(
    $workspaceId: ID!
    $instanceIds: [ID!]!
  ) {
    removeInstances(instanceIds: $instanceIds, workspaceId: $workspaceId)
  }
`;

const RemoveServiceFromWorkspaceMutation = gql`
  mutation RemoveServiceFromWorkspace($workspaceId: ID!, $serviceIds: [ID!]!) {
    removeServices(serviceIds: $serviceIds, workspaceId: $workspaceId)
  }
`;

const InventoryKindsFunctionsIdsFragment = gql`
  fragment InventoryKindsFunctionsIds on Workspace {
    id
    services {
      id
      aggregatedServices {
        id
      }
    }
    inventory {
      serviceKinds {
        id
        service {
          id
        }
      }
      functions {
        id
        service {
          id
        }
      }
    }
  }
`;

/**
 * Removes all references to passed in items from the workspace.
 *
 * @param {Array<Object>} items The items to remove
 * @param {string} workspaceId Id of the workspace to remove items from
 * @param {Object} client The apollo client to access the store through
 * @param {boolean} cleanBoilerplate Cleans out the boilerplate kinds and
 *    functions for removed kinds when set the true.  Defaults to false
 * @param {boolean} cleanServiceChildren Cleans out the kinds and functions that
 *    belong to services being removed.  Defaults to false.
 * @param {boolean} data.cleanKindsFromFields When true the fields and arguments
 *    in the kinds and functions will be checked to see if they are a type of
 *    one of the cleaned kinds
 */
export default async function removeFromWorkspace({
  items,
  workspaceId,
  client,
  cleanBoilerplate = false,
  cleanServiceChildren = false,
  cleanKindsFromFields = false
}) {
  let kindIds = [];
  let functionIds = [];
  const serviceIds = [];
  const instanceIds = [];

  items.forEach(item => {
    switch (item.kindName) {
      case KN_KIND:
        kindIds.push(item.id);
        break;
      case KN_FUNCTION:
        functionIds.push(item.id);
        break;
      case KN_SERVICE:
        serviceIds.push(item.id);
        break;
      default:
        instanceIds.push(item.id);
        break;
    }
  });

  if (cleanServiceChildren && serviceIds.length) {
    // make sure to also remove the kinds and functions for this services when
    // it is asked for.
    const { services, inventory } = client.readFragment({
      id: `${KN_WORKSPACE}:${workspaceId}`,
      fragment: InventoryKindsFunctionsIdsFragment
    });

    services.forEach(s => {
      if (serviceIds.includes(s.id)) {
        kindIds = kindIds.concat(
          inventory.serviceKinds
            .filter(
              k =>
                k.service &&
                (k.service.id === s.id ||
                  (s.aggregatedServices &&
                    s.aggregatedServices.some(as => k.service.id === as.id)))
            )
            .map(k => k.id)
        );
        functionIds = functionIds.concat(
          inventory.functions
            .filter(
              f =>
                f.service &&
                (f.service.id === s.id ||
                  (s.aggregatedServices &&
                    s.aggregatedServices.some(as => f.service.id === as.id)))
            )
            .map(k => k.id)
        );
      }
    });
  }

  if (kindIds.length) {
    await client.mutate({
      mutation: RemoveKindsFromWorkspaceMutation,
      variables: {
        workspaceId: workspaceId,
        kindIds
      }
    });
  }

  if (functionIds.length) {
    await client.mutate({
      mutation: RemoveFunctionsFromWorkspaceMutation,
      variables: {
        workspaceId: workspaceId,
        functionIds
      }
    });
  }

  if (instanceIds.length) {
    await client.mutate({
      mutation: RemoveInstancesFromWorkspaceMutation,
      variables: {
        workspaceId: workspaceId,
        instanceIds
      }
    });
  }

  if (serviceIds.length) {
    await client.mutate({
      mutation: RemoveServiceFromWorkspaceMutation,
      variables: {
        workspaceId: workspaceId,
        serviceIds
      }
    });
  }

  if (cleanBoilerplate) {
    const { workspace } = client.readQuery({
      query: WorkspaceModelServiceQuery,
      variables: { id: workspaceId }
    });
    for (const kindId of kindIds) {
      const boilerplateIds = getBoilerplateIds(
        workspace.modelServiceId,
        kindId
      );
      kindIds = kindIds.concat(boilerplateIds.kinds);
      functionIds = functionIds.concat(boilerplateIds.functions);
    }
  }

  cleanWorkspace({
    workspaceId,
    kindIds,
    functionIds,
    instanceIds,
    serviceIds,
    cleanKindsFromFields,
    client
  });
}
