import Logger from 'util/Logger';
import gql from 'graphql-tag';

const SnackNotificationId = 'Snack';
const SnackNotificationType = 'SnackNotification';

export const SnackType = Object.freeze({
  INFO: 'info',
  ERROR: 'error'
});

const SnackNotificationFragment = gql`
  fragment SnackNotificationDetails on SnackNotification {
    id
    notificationState
  }
`;

export const SnackNotificationQuery = gql`
  query SnackNotification {
    snackNotification @client {
      id
      notificationState
    }
  }
`;

export const SnackNotificationDefaults = {
  snackNotification: {
    id: SnackNotificationId,
    notificationState: null,
    __typename: SnackNotificationType
  }
};

export const SnackNotificationQueryResolver = {
  snackNotification: (_, __, { cache }) => {
    return cache.readFragment({
      fragment: SnackNotificationFragment,
      id: `${SnackNotificationType}:${SnackNotificationId}`
    });
  }
};

const setSnackState = (notificationState, client) => {
  client.writeFragment({
    fragment: SnackNotificationFragment,
    id: `${SnackNotificationType}:${SnackNotificationId}`,
    data: {
      id: SnackNotificationId,
      notificationState,
      __typename: SnackNotificationType
    }
  });
};

/**
 * Renders a Snack notification
 * @param {Object} notificationState The object that stores the various state variables for snack notifications
 * @param {Object} client The apollo client to access the store through
 */
export const showSnack = (notificationState, client) => {
  setSnackState({ snackType: SnackType.INFO, ...notificationState }, client);
};

/**
 * Renders a simple info message Snack notification
 *
 * @param {string} message The message text for the snack
 * @param {Object} client The apollo client to access the store through
 */
export const showMessageSnack = (message, client) => {
  showSnack({ message }, client);
};

/**
 * Renders an error Snack notification
 * @param {Object} client The apollo client to access the store through
 * @param {String} message The error message
 * @param {Object} error The error object
 */
export const handleError = (client, message, error) => {
  Logger.error(error);
  setSnackState({ message, error, snackType: SnackType.ERROR }, client);
};

/**
 * Hides the current Snack notification
 * @param {Object} client The apollo client to access the store through
 */
export const handleSnackClose = client => {
  setSnackState(null, client);
};
