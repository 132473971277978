import {
  GraphNodes,
  GraphPositionDefaults,
  KN_FUNCTION,
  KN_INSTANCE,
  KN_KIND,
  KindUUID
} from 'util/defines';

import gql from 'graphql-tag';
import { roundGraphValue } from 'util/graph/handleGraphUpdated';

const KindSchemaFragment = gql`
  fragment KindSchema on Kind {
    id
    schema {
      id
    }
  }
`;

const InstanceFieldsFragment = gql`
  fragment InstanceFields on Instance {
    id
    fieldIds
  }
`;

const FunctionArgumentsFragment = gql`
  fragment FunctionArguments on Function {
    id
    arguments {
      id
    }
  }
`;

function findIntersectingNode(nodes, x, width, startIndex) {
  let i;
  for (i = startIndex; i < nodes.length; i++) {
    const n = nodes[i];
    if (n.x < x + width && n.right > x) {
      return [n, i + 1];
    }
  }
  return [null, i];
}

/**
 * Returns a zoom and offset adjusted position within a graph.
 *
 * @param {number} x The x coordinate to adjust for offset
 * @param {number} y The y coordinate to adjust for offset
 * @param {Object} engine The diagram engine used to calculate the new position
 */
export function getZoomAdjustedPosition(x, y, engine) {
  const graphZoom = engine.diagramModel.zoom / 100;
  return {
    x: (engine.diagramModel.offsetX * -1 + x) / graphZoom,
    y: (engine.diagramModel.offsetY * -1 + y) / graphZoom
  };
}

/**
 * Calculates a list of positions for new nodes being added to a graph. The positions
 * are calculated to put the nodes in an empty location in the viewport (visible graph). If
 * a node does not fit anywhere without overlapping other nodes, it will be positioned
 * in the middle of the viewport. If multiple nodes do not fit in open positions
 * they are placed in a horizontal line across the viewport.
 *
 * @param {Array<Object>} newNodeSizes A list of {width, height} objects representing the sizes of the nodes to add to the graph.
 * @param {Object} viewportDimensions The dimensions of the visible viewport over the graph in the shape {width, height}
 * @param {Object} engine The diagram engine.
 * @return {Array<Object>} A list of {x, y} objects representing the top left corner positions of the nodes.
 */
export function calculateNodePositions(
  newNodeSizes,
  viewportDimensions,
  engine
) {
  const { diagramModel } = engine;
  const graphZoom = diagramModel.zoom / GraphPositionDefaults.ZOOM;

  const existingNodeLayouts = Object.values(diagramModel.nodes).map(node => {
    const { width, height } = engine.getNodeDimensions(node);
    return {
      x: node.x,
      y: node.y,
      width: width / graphZoom,
      height: height / graphZoom
    };
  });

  existingNodeLayouts.forEach(nodeLayout => {
    nodeLayout.bottom = nodeLayout.y + nodeLayout.height;
    nodeLayout.right = nodeLayout.x + nodeLayout.width;
  });

  const adjustedOffsetX = (diagramModel.offsetX * -1) / graphZoom;
  const adjustedOffsetY = (diagramModel.offsetY * -1) / graphZoom;

  const minX = adjustedOffsetX;
  const minY = adjustedOffsetY;
  const maxX = viewportDimensions.width / graphZoom + adjustedOffsetX;
  const maxY = viewportDimensions.height / graphZoom + adjustedOffsetY;
  const nodePadding = GraphNodes.PADDING;

  let currentY = minY;
  let currentX = minX;
  const positions = [];

  // Filter nodes to those in the viewable area and sort by y coordinate
  const nodesInViewableArea = existingNodeLayouts
    .filter(
      node =>
        ((node.bottom > minY && node.bottom < maxY) ||
          (node.y < maxY && node.y > minY) ||
          (node.y <= minY && node.bottom >= maxY)) &&
        ((node.right > minX && node.right < maxX) ||
          (node.x < maxX && node.x > minX) ||
          (node.x <= minX && node.right >= maxX))
    )
    .sort((first, second) => first.y - second.y);

  const newNodeSizesCopy = [...newNodeSizes];
  let currentNodeSize = newNodeSizesCopy.shift();
  let highestBottom, nextIntersectingNode;

  const bottomSort = (first, second) => first.bottom - second.bottom;
  const intersectingNodesFilter = node => node.bottom > currentY;
  const findNextX = n => {
    if (n.right < highestBottom.x && n.right > currentX) {
      currentX = n.right;
    }
  };
  const findSortedXIndex = n => Math.floor(n.right) === Math.floor(currentX);
  const sortByRight = (first, second) => first.right - second.right;

  //These are all the nodes that intersect the current row
  let intersectingNodes = nodesInViewableArea.filter(
    n => n.y < currentY + currentNodeSize.height + 2 * nodePadding
  );

  // Get the index, within all the nodes in the viewable area, of the last node intersecting the current row
  // This index will be used to add to the current intersecting node list
  let intersectingNodeIndex =
    intersectingNodes.length &&
    nodesInViewableArea.indexOf(
      intersectingNodes[intersectingNodes.length - 1]
    ) + 1;

  while (
    currentNodeSize &&
    currentY + currentNodeSize.height + 2 * nodePadding <= maxY
  ) {
    // Sort all nodes that intersect with the current row by x coordinate
    intersectingNodes.sort(sortByRight);

    let currentSortedXIndex = Math.max(
      intersectingNodes.findIndex(findSortedXIndex),
      0
    );

    if (
      currentNodeSize &&
      !intersectingNodes.length &&
      minX + currentNodeSize.width + 2 * nodePadding > maxX
    ) {
      // There are no intersecting nodes but the node doesn't fit width-wise.
      // This can happen if there is not enough horizontal space to fit the node.
      // In this case, just center the node horizontally
      positions.push({
        x: (maxX + minX - currentNodeSize.width) / 2,
        y: currentY + nodePadding
      });

      currentNodeSize = newNodeSizesCopy.shift();
    } else {
      // This is going to place nodes horizontally across the screen
      while (
        currentNodeSize &&
        currentX + currentNodeSize.width + 2 * nodePadding <= maxX
      ) {
        // Check for nodes that intersect the current position
        [nextIntersectingNode, currentSortedXIndex] = findIntersectingNode(
          intersectingNodes,
          currentX,
          currentNodeSize.width + 2 * nodePadding,
          currentSortedXIndex
        );

        if (!nextIntersectingNode) {
          const newLocation = {
            x: currentX + nodePadding,
            y: currentY + nodePadding
          };
          positions.push(newLocation);
          //Push on new node as a possible intersecting node when doing the bottom
          //positioning check below
          intersectingNodes.push({
            ...newLocation,
            ...currentNodeSize,
            bottom: newLocation.y + currentNodeSize.height,
            right: newLocation.x + currentNodeSize.width
          });
          currentX = newLocation.x + currentNodeSize.width;
          currentNodeSize = newNodeSizesCopy.shift();
        } else {
          currentX = nextIntersectingNode.right;
        }
      }
    }

    if (intersectingNodes.length) {
      // Guard against intersecting nodes being empty. There may be an edge condition
      // where the inner while loop above is skipped and intersecting nodes is
      // empty, which would result in an error below.
      // Find the next highest bottom
      intersectingNodes.sort(bottomSort);
      highestBottom = intersectingNodes.shift();

      currentY = highestBottom.bottom;
      // Remove nodes that are no longer in the intersection area
      // We need to remove any nodes where the bottom is equal to the
      // bottom of the highestBottom node. None will be less than it because it is
      // the highest bottom.
      intersectingNodes = intersectingNodes.filter(intersectingNodesFilter);

      // Start a new row. We will start at the minimum x position and then
      // find the minimum start position based on the intersecting nodes
      // that are next to the node we are using as currentY.
      currentX = minX;
      intersectingNodes.forEach(findNextX);
    }

    //Add nodes that are within the new intersection area
    while (
      currentNodeSize &&
      intersectingNodeIndex < nodesInViewableArea.length &&
      nodesInViewableArea[intersectingNodeIndex].y <=
        highestBottom.bottom + 2 * nodePadding + currentNodeSize.height
    ) {
      intersectingNodes.push(nodesInViewableArea[intersectingNodeIndex++]);
    }
  }

  if (currentNodeSize) {
    // Still have some nodes to position
    // Center the nodes within the visible screen. If there are multiple,
    // put them in a horizontal line across the screen.
    let totalWidth = currentNodeSize.width;

    newNodeSizesCopy.forEach(nodeSize => {
      totalWidth += nodeSize.width + nodePadding;
    });

    let currentX = (maxX + minX - totalWidth) / 2;
    const top = (maxY + minY - currentNodeSize.height) / 2;

    newNodeSizesCopy.unshift(currentNodeSize);

    newNodeSizesCopy.forEach(nodeSize => {
      positions.push({ x: currentX, y: top });
      currentX += nodeSize.width + nodePadding;
    });
  }

  return positions.map(loc => ({
    x: roundGraphValue(loc.x),
    y: roundGraphValue(loc.y)
  }));
}

/**
 * Approximates a node size, {width, height}, for a given Kind, Function, or Instance.
 *
 * @param {Object} client Apollo client instance
 * @param {string} kindId ID of the Kind being added or the ID of the Kind the Instance
 * is of if an Instance is being added.
 * @param {string} instanceId The ID of the Instance being added if an Instance
 * is being added.
 * @param {string} functionId The ID of the Function being added to the graph.
 */
export function approximateNodeSize({
  client,
  kindId,
  instanceId,
  functionId
}) {
  const size = {};

  const iId = instanceId ? instanceId : kindId;
  const kId = instanceId ? kindId : KindUUID;
  let minHeight = GraphNodes.MIN_HEIGHT_KIND;
  let multiplier = GraphNodes.HEIGHT_SCHEMA_ROW;
  let numFields = 0;

  try {
    if (kindId) {
      // TODO (UTD-25): Take into account number of links when estimating the height
      // of a node.
      if (kId === KindUUID) {
        // Estimate the size of a Kind node based on schema
        const { schema } = client.readFragment({
          fragment: KindSchemaFragment,
          id: `${KN_KIND}:${iId}`
        });
        numFields = schema.length - 1;
      } else {
        // Estimate the size of an Instance node based on fields
        const { fieldIds } = client.readFragment({
          fragment: InstanceFieldsFragment,
          id: `${kId}:${KN_INSTANCE}:${iId}`
        });
        numFields = fieldIds.length;
      }
    } else {
      const func = client.readFragment({
        fragment: FunctionArgumentsFragment,
        id: `${KN_FUNCTION}:${functionId}`
      });
      numFields = func.arguments.length;
      minHeight = GraphNodes.MIN_HEIGHT_FUNCTION;
    }

    // If the Kind has fields split the difference between max and min width
    size.width =
      numFields > 1
        ? (GraphNodes.MAX_WIDTH + GraphNodes.MIN_WIDTH) / 2
        : GraphNodes.MIN_WIDTH;

    size.height = minHeight + numFields * multiplier;
  } catch (ex) {
    // Use default Kind size
    size.width = GraphNodes.MIN_WIDTH;
    size.height = GraphNodes.MIN_HEIGHT_KIND;
  }

  return size;
}
