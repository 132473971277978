import {
  GraphType,
  KN_FUNCTION,
  KN_KIND,
  KN_PORTAL_GRAPH,
  KN_SERVICE,
  KN_WORKSPACE
} from 'util/defines';

import { PortalGraphTypeAndFunctionQuery } from './graphql';
import { SelectionQuery } from 'util/selectionUtils';
import { getFunctionKindId } from 'util/helpers';
import { getSelectedInstanceInfo } from 'util/workspace';
import { isEmpty } from 'lodash';

const validSelectionType = [
  KN_KIND,
  KN_FUNCTION,
  KN_PORTAL_GRAPH,
  KN_WORKSPACE,
  KN_SERVICE
];

/**
 * Filters the current selection to be only items that should be sent over the
 * the Assistant.
 *
 * Event data is shaped as: {selection:[{}]}
 *
 * @param {Object} input The current workspace selection.
 * @param {ApolloClient} client The client used to access the Apollo Cache.
 * @return {Object} The filtered selection
 */
export async function prepareSelection(input, client) {
  const selection = input
    .map(selected => getSelectedInstanceInfo(selected, client))
    .filter(Boolean);

  if (isEmpty(selection)) return [];

  // Filter selected items.
  let filteredSelection = (
    await Promise.all(
      selection.map(selected => selectedItemFilter(selected, client))
    )
  ).filter(Boolean);

  return filteredSelection;
}

/**
 * Returns the current selection in the workspace
 *
 * @param {string} workspaceId The ID of the workspace.
 * @param {ApolloClient} client The client used to access the Apollo Cache.
 * @return {Array<Object>} Array of selection objects as { selection : [{}] }.
 */
export async function getCurrentSelection(workspaceId, client) {
  try {
    const result = client.readQuery({
      query: SelectionQuery,
      variables: { id: workspaceId }
    });

    const filteredSelection = await prepareSelection(
      result.selection.selected,
      client
    );

    return { selection: filteredSelection };
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'getCurrentSelection': ${ex.message}`)
    );
  }
}

async function selectedItemFilter(selected, client) {
  // Check to make sure the selection is valid.
  // Valid means: Kind, Function, PortalGraph, Workspace, Service.
  // Graphs and services have their own cases below.
  if (validSelectionType.includes(selected.kindName)) {
    try {
      // Filter PortalGraphs that are not KNOWLEDGE or FUNCTION
      if (selected.kindName === KN_PORTAL_GRAPH) {
        const { data } = await client.query({
          query: PortalGraphTypeAndFunctionQuery,
          variables: { id: selected.id }
        });

        // For Function graphs, return the Function as the selection.
        if (data.portalGraph.type === GraphType.FUNCTION) {
          // Get the kind ID of the Function kind to create object.
          const kindId = await getFunctionKindId(client);
          return {
            kindName: KN_FUNCTION,
            id: data.portalGraph.function.id,
            kindId
          };
        }
        // Knowledge graphs have no special case.
        else if (data.portalGraph.type === GraphType.KNOWLEDGE) {
          return selected;
        }
        // Anything else is not valid.
        else {
          return undefined;
        }
      }

      return selected;
    } catch {
      // Ignore with the assumption that the item is invalid or
      // cannot be processed.
    }
  }

  return selected;
}
