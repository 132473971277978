import {
  IdRegex,
  KN_FILE,
  KN_FUNCTION,
  KN_FUNCTION_ARGUMENTS,
  KN_FUNCTION_OUTPUT,
  KN_KIND,
  KN_OPERATION,
  KN_PORTAL_GRAPH_NODE,
  KN_SERVICE,
  KN_WORKSPACE
} from 'util/defines';
import {
  InventoryInstanceRefDetailsFragment,
  WorkspaceNameFragment
} from 'graphql/Portal';
import {
  buildFunctionInstanceRefForStore,
  buildInstanceRef,
  buildKindInstanceRefForStore
} from './storeDataHelpers';
import {
  getInstanceIdForGraphNode,
  isFunctionGraphArgsNode,
  isFunctionGraphInputNode
} from 'util/graph/portalGraph';
import { isEmpty, pick, uniqBy } from 'lodash';

import Logger from './Logger';
import gql from 'graphql-tag';
import uuidv4 from 'uuid/v4';

const KindIsGeneratedFragment = gql`
  fragment KindIsGenerated on Kind {
    isGenerated
  }
`;

const FunctionIsGeneratedFragment = gql`
  fragment FunctionIsGenerated on Function {
    isGenerated
  }
`;

const PortalGraphNodeForSelectedInstanceFragment = gql`
  fragment PortalGraphNodeForSelectedInstance on PortalGraphNode {
    id
    knowledgeGraphNode {
      id
      instance {
        id
      }
      kind {
        id
        name
      }
    }
    functionGraphNode {
      id
      operationId
    }
  }
`;

const WorkspaceInstanceRefsAndInventoryQuery = gql`
  query WorkspaceInstanceRefsAndInventory($id: ID!) {
    workspace(id: $id) {
      id
      instanceRefs {
        ...InventoryInstanceRefDetails
      }
      inventory {
        workspaceKinds {
          ...KindNameService
        }
      }
    }
  }
  ${InventoryInstanceRefDetailsFragment}
`;

const WorkspaceInstanceRefsIdFragment = gql`
  fragment WorkspaceInstanceRefsId on Workspace {
    id
    instanceRefs {
      id
    }
  }
`;

const WorkspaceAddInstanceRefsFragment = gql`
  fragment WorkspaceAddInstanceRefs on Workspace {
    id
    instanceRefs {
      id
    }
    inventory {
      workspaceKinds {
        id
      }
      serviceKinds {
        id
      }
      functions {
        id
      }
    }
  }
`;

const WorkspaceInventoryRemoveKindsAndFunctionsFragment = gql`
  fragment WorkspaceInventoryRemoveKindsAndFunctions on Workspace {
    id
    inventory {
      workspaceKinds {
        id
      }
      serviceKinds {
        id
      }
      functions {
        id
      }
    }
  }
`;

const WorkspaceInventoryKindsFragment = gql`
  fragment WorkspaceInventoryKinds on Workspace {
    id
    inventory {
      workspaceKinds {
        id
      }
      serviceKinds {
        id
      }
    }
  }
`;

const WorkspaceInventoryFunctionsFragment = gql`
  fragment WorkspaceInventoryFunctions on Workspace {
    id
    inventory {
      functions {
        id
      }
    }
  }
`;

const AddInstanceRefsToWorkspaceMutation = gql`
  mutation AddInstanceRefsToWorkspace(
    $wsId: ID!
    $instanceRefs: [InstanceRefInput!]!
  ) {
    addInstanceRefsToWorkspace(wsId: $wsId, instanceRefs: $instanceRefs) {
      ...InventoryInstanceRefDetails
    }
  }
  ${InventoryInstanceRefDetailsFragment}
`;

const OperationFunctionInformationFragment = gql`
  fragment OperationFunctionInformation on Operation {
    id
    function {
      id
      functionType
    }
  }
`;

const WorkspaceServiceFunctionsFragment = gql`
  fragment WorkspaceServiceFunctions on Service {
    id
    functions {
      id
    }
  }
`;

const WorkspaceServiceKindsFragment = gql`
  fragment WorkspaceServiceKinds on Service {
    id
    kinds {
      id
    }
  }
`;

export const WorkspaceIdQuery = gql`
  query WorkspaceIdQuery($id: ID!) {
    workspace(id: $id) {
      id
    }
  }
`;

function getInventoryUpdateFragment(
  addKinds,
  addFunctions,
  referenceKinds,
  referenceFunctions,
  addInstances
) {
  let inventoryGql = '';
  let instanceRefGql = '';
  let name = 'WorkspaceInventory';

  if (addKinds) {
    name += 'AddKinds';
    inventoryGql = `
    serviceKinds {
      id
    }
    `;
  }

  if (addFunctions || referenceFunctions) {
    if (addFunctions) name += 'AddFunctions';
    if (referenceFunctions) name += 'ReferenceFunctions';

    inventoryGql += `
    functions {
      id
    }
    `;
  }

  if (referenceKinds) {
    name += 'ReferenceKinds';
    inventoryGql += `
    workspaceKinds {
      id
    }
    `;
  }

  if (addInstances) {
    name += 'AddInstances';
  }

  if (referenceKinds || referenceFunctions || addInstances) {
    instanceRefGql = `
    instanceRefs {
      id
      kindId
      kindName
      innerFunction {
        id
      }
      innerKind {
        id
      }
      instance {
        id
        kindId
      }
      kind {
        id
      }
    }
    `;
  }

  const finalGql = `
  fragment ${name} on Workspace {
    id
    ${instanceRefGql}
    ${
      inventoryGql
        ? `inventory {
      ${inventoryGql}
    }`
        : ''
    }
  }
  `;

  return gql(finalGql);
}

/**
 * Gets the name of the specified workspace.
 *
 * @param {string} workspaceId ID of the workspace
 * @param {Object} client Apollo client
 */
export const getWorkspaceName = (workspaceId, client) => {
  const workspaceName = client.readFragment({
    fragment: WorkspaceNameFragment,
    id: `${KN_WORKSPACE}:${workspaceId}`
  });
  return workspaceName && workspaceName.name;
};

/**
 * Returns the instance information based on the selection.  When a portal graph
 * node is selected, then you will get the information about the underlying
 * instance back.
 *
 * @note This can return null when there are issues with loading the portal
 * graph node information out of cache.
 *
 * @param {Object} selectedInstance The selected instance, with the shape of
 *    {kindName, id, kindId}.
 * @param {ApolloClient} apolloClient The client to access the cache with.
 * @return {Object} Returns the information about the selected instance, with
 *    the shape {kindName, id, kindId}, or null.
 */
export const getSelectedInstanceInfo = (selectedInstance, apolloClient) => {
  let node;
  if (selectedInstance.kindName === KN_PORTAL_GRAPH_NODE) {
    node = apolloClient.readFragment({
      id: `${KN_PORTAL_GRAPH_NODE}:${selectedInstance.id}`,
      fragment: PortalGraphNodeForSelectedInstanceFragment
    });
  }

  return node
    ? getPortalGraphNodeInstanceInfo(node, apolloClient)
    : pick(selectedInstance, ['id', 'kindId', 'kindName']);
};

export const getOperationById = (operationId, apolloClient) => {
  try {
    const operation = apolloClient.readFragment({
      id: `${KN_OPERATION}:${operationId}`,
      fragment: OperationFunctionInformationFragment
    });

    return operation;
  } catch {
    // When the operation can't be loaded properly from cache return null, as
    // that is similar to the operation not being there at all.  This case is
    // seen usually when a deleted item is in the process of being cleaned up.
    return null;
  }
};

export const getPortalGraphNodeInstanceInfo = (node, apolloClient) => {
  if (node.knowledgeGraphNode) {
    const kgNode = node.knowledgeGraphNode;
    if (kgNode.kind && kgNode.instance) {
      return {
        id: kgNode.instance.id,
        kindId: kgNode.kind.id,
        kindName: kgNode.kind.name
      };
    }
  } else if (node.functionGraphNode) {
    const operation = getOperationById(
      node.functionGraphNode.operationId,
      apolloClient
    );
    if (operation && operation.function) {
      return {
        id: operation.function.id,
        kindId: null,
        kindName: KN_FUNCTION
      };
    }
  } else if (isFunctionGraphArgsNode(node)) {
    const isInput = isFunctionGraphInputNode(node);
    return {
      id: node.id,
      kindId: null,
      kindName: isInput ? KN_FUNCTION_ARGUMENTS : KN_FUNCTION_OUTPUT
    };
  }

  return null;
};

export function copyGraphNode(node) {
  const newNode = {
    ...node,
    id: uuidv4()
  };

  if (newNode.knowledgeGraphNode) {
    newNode.knowledgeGraphNode = {
      ...newNode.knowledgeGraphNode,
      id: uuidv4()
    };
  }
  if (newNode.functionGraphNode) {
    newNode.functionGraphNode = {
      ...newNode.functionGraphNode,
      id: uuidv4()
    };
  }
}

const createInstanceRefInputFromPortalGraphNode = node => {
  // Only one of these should every be set since they are specific to the type of graph
  const typedNode = node.knowledgeGraphNode || node.functionGraphNode;
  if (node.knowledgeGraphNode) {
    return {
      id: typedNode.instance.id,
      kindId: typedNode.kind.id,
      kindName: typedNode.kind.name,
      instance: typedNode.instance.id
    };
  } else if (node.functionGraphNode) {
    return {
      id: typedNode.operationId,
      instance: typedNode.operationId,
      kindName: KN_OPERATION
    };
  }
};

/**
 * Adds functions to the workspace inventory.
 *
 * @param {string} workspaceId Workspace ID.
 * @param {Array<string>} funcIds Array of Function ids.
 * @param {Object} client Apollo client instance.
 */
export function addFunctionsToWorkspace(workspaceId, funcIds, client) {
  // Map functions to instanceRefs.
  const instanceRefs = funcIds.map(id => {
    return {
      id,
      instance: id,
      kindName: KN_FUNCTION
    };
  });

  return addInstanceRefsToWorkspace(workspaceId, instanceRefs, client);
}

/**
 * Adds Kinds to the workspace inventory.
 *
 * @param {string} workspaceId Workspace ID.
 * @param {Array<string>} kindIds Array of Kind ids.
 * @param {Object} client Apollo client instance.
 */
export function addKindsToWorkspace(workspaceId, kindIds, client) {
  // Map kinds to instanceRefs.
  const instanceRefs = kindIds.map(id => {
    return {
      id,
      instance: id,
      kindName: KN_KIND
    };
  });

  return addInstanceRefsToWorkspace(workspaceId, instanceRefs, client);
}

/**
 * Adds Files to the workspace inventory.
 *
 * @param {string} workspaceId Workspace ID.
 * @param {Array<string>} fileIds Array of File ids.
 * @param {Object} client Apollo client instance.
 */
export function addFilesToWorkspace(workspaceId, fileIds, client) {
  // Map files to instanceRefs.
  const instanceRefs = fileIds.map(id => {
    return {
      id,
      instance: id,
      kindName: KN_FILE
    };
  });

  return addInstanceRefsToWorkspace(workspaceId, instanceRefs, client);
}

/**
 * Adds a list of instanceRefs to a Workspace.
 *
 * @param {string} workspaceId The ID of the Workspace to update
 * @param {Array<InstanceRefInput>} instanceRefs The instanceRefs to add
 * @param {ApolloClient} client The client used persist changes.
 */
export function addInstanceRefsToWorkspace(workspaceId, instanceRefs, client) {
  return client.mutate({
    mutation: AddInstanceRefsToWorkspaceMutation,
    variables: {
      wsId: workspaceId,
      instanceRefs
    },
    update: (store, { data }) => {
      // Add instanceRefs to the workspace inventory
      if (data.addInstanceRefsToWorkspace) {
        addInstanceRefsToWorkspaceCache(
          workspaceId,
          data.addInstanceRefsToWorkspace,
          store
        );
      }
    }
  });
}

/**
 * Adds a list of instanceRefs to the workspace, and makes sure that the
 * inventory is also up to date.
 *
 * @param {string} workspaceId ID of the workspace to add the instanceRefs to
 * @param {Array<Object>} instanceRefs The list of instanceRefs to add
 * @param {ApolloClient} client The apollo client to use to access the cache
 */
export function addInstanceRefsToWorkspaceCache(
  workspaceId,
  instanceRefs,
  client
) {
  try {
    const workspaceFragmentId = `${KN_WORKSPACE}:${workspaceId}`;
    const workspace = client.readFragment({
      id: workspaceFragmentId,
      fragment: WorkspaceAddInstanceRefsFragment
    });

    // Add the new instance refs to the workspace
    workspace.instanceRefs = uniqBy(
      workspace.instanceRefs.concat(
        instanceRefs.map(ir => pick(ir, ['id', '__typename']))
      ),
      ir => ir.id
    );

    // Add the new kinds to the inventory
    const kinds = instanceRefs
      .filter(ir => !!ir.innerKind)
      .map(ir => pick(ir.innerKind, ['id', '__typename']));
    if (kinds.length) {
      workspace.inventory.workspaceKinds = uniqBy(
        workspace.inventory.workspaceKinds.concat(kinds),
        k => k.id
      );
      workspace.inventory.serviceKinds = uniqBy(
        workspace.inventory.serviceKinds.concat(kinds),
        k => k.id
      );
    }

    // Add the new functions to the inventory
    const functions = instanceRefs
      .filter(ir => !!ir.innerFunction)
      .map(ir => pick(ir.innerFunction, ['id', '__typename']));
    if (functions.length) {
      workspace.inventory.functions = uniqBy(
        workspace.inventory.functions.concat(functions),
        f => f.id
      );
    }

    client.writeFragment({
      id: workspaceFragmentId,
      fragment: WorkspaceAddInstanceRefsFragment,
      data: workspace
    });
  } catch (e) {
    // The error being thrown here means that the cache is being updated before
    // the inventory is loaded.  This happens when the Assistant API is creating
    // a Kind or a Function on a Workspace that is not visible in the UI
    Logger.debug(
      `Failed to update workspace inventory with ${instanceRefs
        .map(ir => ir.id)
        .join(', ')} with error`,
      e
    );
  }
}

/**
 * Adds an array of items to the workspace inventory, making sure to filter
 * out the ones that are already in there.
 *
 * @param {Array<Object>} items The items to add to the workspace inventory
 * @param {string} workspaceId The ID of the workspace to work with
 * @param {ApolloClient} client The apollo client to use
 * @return {Promise<Object>} the response from the graphQL call
 */
export function handleAddItemsToInventory(items, workspaceId, client) {
  if (!Array.isArray(items)) {
    return;
  }
  const workspace = client.readFragment({
    id: `${KN_WORKSPACE}:${workspaceId}`,
    fragment: WorkspaceInstanceRefsIdFragment
  });

  let newItems = items
    .filter(item => {
      const instanceId = getInstanceIdForGraphNode(item);
      return (
        instanceId &&
        !workspace.instanceRefs.some(
          ir => ir.instance && ir.instance.id === instanceId
        )
      );
    })
    .map(item => createInstanceRefInputFromPortalGraphNode(item));

  if (newItems.length === 0) {
    return;
  }

  return client.mutate({
    mutation: AddInstanceRefsToWorkspaceMutation,
    variables: { wsId: workspace.id, instanceRefs: newItems },
    update: (store, { data }) => {
      if (data.addInstanceRefsToWorkspace) {
        const storeData = store.readQuery({
          query: WorkspaceInstanceRefsAndInventoryQuery,
          variables: { id: workspace.id }
        });

        storeData.workspace.instanceRefs = (
          storeData.workspace.instanceRefs || []
        ).concat(data.addInstanceRefsToWorkspace);

        storeData.workspace.inventory.workspaceKinds = (
          storeData.workspace.inventory.workspaceKinds || []
        ).concat(
          data.addInstanceRefsToWorkspace
            .map(ir => ir.innerKind)
            .filter(k => !!k)
        );

        store.writeQuery({
          query: WorkspaceInstanceRefsAndInventoryQuery,
          variables: { id: workspace.id },
          data: storeData
        });
      }
    }
  });
}

/**
 * Checks to see if the specified item is a generated boilerplate code or not.
 *
 * @param {string} id Id of the item to check
 * @param {string} kindName Name of the kind the item lives in
 * @param {ApolloClient} client The client to use to access the cache
 */
export function isBoilerplate(id, kindName, client) {
  if (kindName === KN_KIND) {
    const kind = client.readFragment({
      id: `${KN_KIND}:${id}`,
      fragment: KindIsGeneratedFragment
    });
    return kind && kind.isGenerated;
  } else if (kindName === KN_FUNCTION) {
    const func = client.readFragment({
      id: `${KN_FUNCTION}:${id}`,
      fragment: FunctionIsGeneratedFragment
    });
    return func && func.isGenerated;
  } else {
    return false;
  }
}

/**
 * Update the workspace service so that the the queries for its kinds and
 * functions will be updated with any new kinds and functions added.
 *
 * @param {string} workspaceServiceId ID of the workspace service to update
 * @param {Array<string>} kindIds IDs of the kinds to add
 * @param {Array<string>} functionIds IDS of the functions to add
 * @param {ApolloClient} client The apollo client to use to access the cache
 */
export function updateWorkspaceService(
  workspaceServiceId,
  kindIds,
  functionIds,
  client
) {
  try {
    const workspaceServiceFragmentId = `${KN_SERVICE}:${workspaceServiceId}`;

    if (kindIds?.length) {
      const workspaceService = client.readFragment({
        id: workspaceServiceFragmentId,
        fragment: WorkspaceServiceKindsFragment
      });

      if (workspaceService) {
        client.writeFragment({
          id: workspaceServiceFragmentId,
          fragment: WorkspaceServiceKindsFragment,
          data: {
            id: workspaceServiceId,
            kinds: uniqBy(
              workspaceService.kinds.concat(
                (kindIds ?? []).map(id => ({ id, __typename: KN_KIND }))
              ),
              k => k.id
            ),
            __typename: KN_SERVICE
          }
        });
      }
    }
    if (functionIds?.length) {
      const workspaceService = client.readFragment({
        id: workspaceServiceFragmentId,
        fragment: WorkspaceServiceFunctionsFragment
      });

      if (workspaceService) {
        client.writeFragment({
          id: workspaceServiceFragmentId,
          fragment: WorkspaceServiceFunctionsFragment,
          data: {
            id: workspaceServiceId,
            functions: uniqBy(
              workspaceService.functions.concat(
                (functionIds ?? []).map(id => ({ id, __typename: KN_FUNCTION }))
              ),
              f => f.id
            ),
            __typename: KN_SERVICE
          }
        });
      }
    }
  } catch {
    // No need to do anything here, this just means the kinds and functions have
    // not been loaded yet
  }
}

/**
 * Validate a workspace name to ensure it contains only proper characters.
 *
 * @param {string} name Workspace name to be validated.
 */
export function validateWorkspaceName(name) {
  return !isEmpty(name.trim())
    ? null
    : 'Workspace name is required and may not contain only spaces.';
}

/**
 * Check to see if the given Workspace ID already exists.
 *
 * @param {String} id Workspace ID to check for
 * @param {Object} client Apollo Client.
 */
export async function isExistingWorkspaceId(id, client) {
  return await client
    .query({
      query: WorkspaceIdQuery,
      variables: { id },
      fetchPolicy: 'no-cache'
    })
    .then(({ data }) => data?.workspace?.id);
}

/**
 * Validate Workspace ID, if provided. Check that is contains only allowed
 * characters and does not already exist.
 *
 * @param {String} id Workspace ID to validate
 * @param {Object} client Apollo Client.
 * @returns {String} Error message if not valid.
 */
export async function validateWorkspaceId(id, client) {
  if (id) {
    if (!IdRegex.test(id)) {
      return 'Workspace ID must start with a letter or number, and can contain only numbers, letters, dashes, and periods.';
    }
    const res = await isExistingWorkspaceId(id, client);
    if (res) {
      return 'Workspace ID already exists.';
    }
  }
  return null;
}

/**
 * Removes references to Kinds and Functions from Workspace inventories.
 *
 * @param {Array<string>} workspaceIds Array of IDs of Workspaces from which to remove the Kinds and Functions
 * @param {Array<string>} kindIds IDs of the Kinds to remove
 * @param {Array<string>} functionIds IDs of the Functions to remove
 * @param {Object} store Apollo store
 */
export function removeKindsAndFunctionsFromWorkspaceInventories(
  workspaceIds,
  kindIds,
  functionIds,
  store
) {
  if (isEmpty(kindIds) && isEmpty(functionIds)) return;

  let fragment;
  if (kindIds) {
    if (functionIds) {
      fragment = WorkspaceInventoryRemoveKindsAndFunctionsFragment;
    } else {
      fragment = WorkspaceInventoryKindsFragment;
    }
  } else {
    fragment = WorkspaceInventoryFunctionsFragment;
  }

  for (const workspaceId of workspaceIds) {
    try {
      const workspace = store.readFragment({
        id: `${KN_WORKSPACE}:${workspaceId}`,
        fragment
      });

      if (workspace) {
        if (kindIds) {
          workspace.inventory.workspaceKinds = workspace.inventory.workspaceKinds.filter(
            kind => !kindIds.includes(kind.id)
          );
          workspace.inventory.serviceKinds = workspace.inventory.serviceKinds.filter(
            kind => !kindIds.includes(kind.id)
          );
        }
        if (functionIds) {
          workspace.inventory.functions = workspace.inventory.functions.filter(
            func => !functionIds.includes(func.id)
          );
        }

        store.writeFragment({
          id: `${KN_WORKSPACE}:${workspaceId}`,
          fragment,
          data: workspace
        });
      }
    } catch (e) {
      // Ignore errors pulling from the cache. This just means one of the Workspaces
      // isn't in cache and thus there is nothing to update.
    }
  }
}

/**
 * Adds references to Kinds, Functions, and Instances to Workspace inventories.
 *
 * @param {Array<string>} workspaceIds Array of IDs of Workspaces to which to add
 * @param {Object} store Apollo store
 * @param {Object} options Additional options
 * @param {Array<string>} options.serviceKindIds IDs of Kinds to add to the Workspace inventory service Kinds
 * @param {Array<string>} options.serviceFunctionIds IDs of Functions to add to the Workspace inventory Functions
 * @param {Array<string>} options.workspaceKindIds IDs of Kinds to add to the Workspace inventory workspace Kinds
 * @param {Array<string>} options.workspaceFunctionIds IDs of Functions to add to the Workspace Instance Refs
 * @param {Array<[string]>} options.instances Triples of [ID, kindID, kindName] for Instances to add to the Workspace Instance Refs
 */
export function addToWorkspaceInventories(workspaceIds, store, options = {}) {
  const {
    serviceKindIds = [],
    serviceFunctionIds = [],
    workspaceKindIds = [],
    workspaceFunctionIds = [],
    instances = []
  } = options;

  if (
    !serviceKindIds.length &&
    !serviceFunctionIds.length &&
    !workspaceKindIds.length &&
    !workspaceFunctionIds.length &&
    !instances.length
  ) {
    return;
  }

  const fragment = getInventoryUpdateFragment(
    serviceKindIds.length,
    serviceFunctionIds.length,
    workspaceKindIds.length,
    workspaceFunctionIds.length,
    instances.length
  );

  for (const workspaceId of workspaceIds) {
    try {
      const workspace = store.readFragment({
        id: `${KN_WORKSPACE}:${workspaceId}`,
        fragment
      });

      let changed = false;

      if (workspace) {
        const newServiceKindIds = serviceKindIds.filter(
          id => !workspace.inventory.serviceKinds.some(k => k.id === id)
        );
        if (newServiceKindIds.length) {
          workspace.inventory.serviceKinds = workspace.inventory.serviceKinds.concat(
            newServiceKindIds.map(id => ({ id, __typename: KN_KIND }))
          );
          changed = true;
        }

        const newServiceFunctionIds = serviceFunctionIds.filter(
          id => !workspace.inventory.functions.some(f => f.id === id)
        );
        if (newServiceFunctionIds.length) {
          workspace.inventory.functions = workspace.inventory.functions.concat(
            newServiceFunctionIds.map(id => ({ id, __typename: KN_FUNCTION }))
          );
          changed = true;
        }

        if (workspaceFunctionIds.length) {
          const newWorkspaceFunctionIds = workspaceFunctionIds.filter(
            id => !workspace.inventory.functions.some(f => f.id === id)
          );
          if (newWorkspaceFunctionIds.length) {
            workspace.inventory.functions = workspace.inventory.functions.concat(
              newWorkspaceFunctionIds.map(id => ({
                id,
                __typename: KN_FUNCTION
              }))
            );
            changed = true;
          }

          const newInstanceRefFunctionIds = workspaceFunctionIds.filter(
            id => !workspace.instanceRefs.some(ir => ir.instance.id === id)
          );

          if (newInstanceRefFunctionIds.length) {
            const newInstanceRefs = newInstanceRefFunctionIds.map(id =>
              buildFunctionInstanceRefForStore(id, workspaceId, store)
            );

            workspace.instanceRefs = workspace.instanceRefs.concat(
              newInstanceRefs
            );
            changed = true;
          }
        }

        const newWorkspaceKindIds = workspaceKindIds.filter(
          id => !workspace.inventory.workspaceKinds.some(k => k.id === id)
        );
        if (newWorkspaceKindIds.length) {
          workspace.inventory.workspaceKinds = workspace.inventory.workspaceKinds.concat(
            newWorkspaceKindIds.map(id => ({ id, __typename: KN_KIND }))
          );

          const newInstanceRefs = newWorkspaceKindIds.map(id =>
            buildKindInstanceRefForStore(id, workspaceId)
          );

          workspace.instanceRefs = workspace.instanceRefs.concat(
            newInstanceRefs
          );
          changed = true;
        }

        const newInstances = instances.filter(
          ([id, kindId]) =>
            !workspace.instanceRefs.some(
              ir => ir.instance.id === id && ir.instance.kindId === kindId
            )
        );
        if (newInstances.length) {
          const newInstanceRefs = newInstances.map(([id, kindId, kindName]) =>
            buildInstanceRef(id, kindId, kindName, workspaceId)
          );

          workspace.instanceRefs = workspace.instanceRefs.concat(
            newInstanceRefs
          );
          changed = true;
        }

        if (changed) {
          store.writeFragment({
            id: `${KN_WORKSPACE}:${workspaceId}`,
            fragment,
            data: workspace
          });
        }
      }
    } catch (e) {
      // Ignore errors pulling from the cache. This just means one of the Workspaces
      // isn't in cache and thus there is nothing to update.
    }
  }
}

/**
 * Removes instance refs from a list of Workspaces.
 *
 * @param {Array<string>} instanceRefIds The IDs of the instance refs to remove
 * @param {Array<string>} workspaceIds The IDs of the workspace to remove the instance refs from
 * @param {ApolloClient} client The apollo client to use
 */
export function removeInstanceRefsFromWorkspaces(
  instanceRefIds,
  workspaceIds,
  client
) {
  if (isEmpty(instanceRefIds)) {
    return;
  }

  for (const workspaceId of workspaceIds) {
    try {
      const workspace = client.readFragment({
        id: `${KN_WORKSPACE}:${workspaceId}`,
        fragment: WorkspaceInstanceRefsIdFragment
      });

      workspace.instanceRefs = workspace.instanceRefs.filter(
        ir => !instanceRefIds.includes(ir.id)
      );

      client.writeFragment({
        id: `${KN_WORKSPACE}:${workspaceId}`,
        fragment: WorkspaceInstanceRefsIdFragment,
        data: workspace
      });
    } catch {
      // Ignore errors thrown because the Workspace is not in the cache.
    }
  }
}
