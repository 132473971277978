import React, { useCallback } from 'react';

import { Box } from '@material-ui/core';
import styled from 'styled-components';

const Root = styled(Box)`
  margin: 0;
  padding: 0px;
  overflow: auto;
  flex: 1;
`;

function ListBox(props, ref) {
  const { loadMoreResults, forwardedRef, children, ...additionalProps } = props;

  const onLoadMore = useCallback(
    e => {
      if (e.target) {
        const { scrollTop, scrollHeight, offsetHeight } = e.target;

        if (scrollTop > scrollHeight - offsetHeight * 2) {
          loadMoreResults();
        }
      }
    },
    [loadMoreResults]
  );

  return (
    <Root {...additionalProps} role="listbox" onScroll={onLoadMore} ref={ref}>
      {children}
    </Root>
  );
}

export default React.forwardRef(ListBox);
