import { InstanceDetailsFragment } from 'graphql/Portal';
import gql from 'graphql-tag';

export const AssistantPortalGraphFragment = gql`
  fragment AssistantPortalGraph on PortalGraph {
    id
    name
    type
    lockedBy
  }
`;

export const AssistantServicesFragment = gql`
  fragment AssistantServices on Service {
    id
    name
    description
    serviceType
    endpointUrl
    isDeleted
    isSystem
    aggregatedServices {
      id
    }
    workspace {
      id
    }
  }
`;

export const AssistantKindsFragment = gql`
  fragment AssistantKinds on Kind {
    id
    name
    description
    isDeleted
    service {
      id
      name
    }
    isGenerated
    schema {
      id
      name
      type
      typeKindId
      modifiers
      kind {
        id
        name
        service {
          id
          name
        }
      }
    }
  }
`;

export const AssistantFunctionsFragment = gql`
  fragment AssistantFunctions on Function {
    id
    name
    description
    isDeleted
    service {
      id
      name
    }
    arguments {
      id
      name
      type
      modifiers
      typeKindId
      kind {
        id
        name
        service {
          id
          name
        }
      }
    }
    implementation {
      id
      entrypoint {
        id
      }
      operations {
        id
        type
        function {
          id
          name
          service {
            id
            name
          }
        }
        argumentValues {
          id
          argument {
            id
            name
          }
          operation {
            id
          }
          argumentRef
        }
      }
    }
    isGenerated
    functionType
    graphqlOperationType
    outputType
    outputKindId
    outputModifiers
    kind {
      id
      name
      service {
        id
        name
      }
    }
    graph {
      id
      lockedBy
      nodes {
        id
        functionGraphNode {
          id
          operationId
        }
        knowledgeGraphNode {
          id
        }
      }
    }
  }
`;

export const PortalGraphTypeAndFunctionQuery = gql`
  query PortalGraphTypeAndFunction($id: ID!) {
    portalGraph(id: $id) {
      ...AssistantPortalGraph
      function {
        id
      }
    }
  }
  ${AssistantPortalGraphFragment}
`;

export const AssistantWorkspaceServicesFragmentName =
  'AssistantWorkspaceServices';
export const AssistantWorkspaceServicesFragment = gql`
  fragment AssistantWorkspaceServices on Workspace {
    services {
      ...AssistantServices
    }
  }
  ${AssistantServicesFragment}
`;

export const AssistantServiceQuery = gql`
  query AssistantService($id: ID!) {
    service(id: $id) {
      ...AssistantServices
    }
  }
  ${AssistantServicesFragment}
`;

export const AssistantPortalGraphNodeFragmentName = 'AssistantPortalGraphNode';
export const AssistantPortalGraphNodeFragment = gql`
  fragment AssistantPortalGraphNode on PortalGraphNode {
    id
    x
    y
    collapsed
    knowledgeGraphNode {
      id
      innerKind {
        ...AssistantKinds
      }
      innerFunction {
        ...AssistantFunctions
      }
      instance {
        ...InstanceDetails
      }
    }
    functionGraphNode {
      id
      operationId
    }
  }
  ${AssistantKindsFragment}
  ${AssistantFunctionsFragment}
  ${InstanceDetailsFragment}
`;
