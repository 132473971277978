import React, { useCallback, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getUserAuthClient } from 'util/Auth';
import { useLocation } from 'react-router-dom';
import { useRef } from 'react';

const ENDING_TIME_DEFAULT = 0;
const TIME_REMAINING_DEFAULT = 0;

export default function AuthDialog() {
  const [showDialog, setShowDialog] = useState(false);
  const [endingTime, setEndingTime] = useState(ENDING_TIME_DEFAULT);
  const [timeRemaining, setTimeRemaining] = useState(TIME_REMAINING_DEFAULT);
  const [errorMessage, setErrorMessage] = useState(null);
  const timeRemainingRef = useRef(0);
  const showDialogRef = useRef(false);
  const location = useLocation();

  const onAuthError = useCallback(error => {
    setShowDialog(true);
    showDialogRef.current = true;
    setErrorMessage(error?.message ?? error);
    setEndingTime(ENDING_TIME_DEFAULT);
    setTimeRemaining(TIME_REMAINING_DEFAULT);
    timeRemainingRef.current = TIME_REMAINING_DEFAULT;
  }, []);

  const onShowInactivity = useCallback(displayLength => {
    setShowDialog(true);
    showDialogRef.current = true;
    setEndingTime(displayLength + performance.now());
    setTimeRemaining(displayLength);
    timeRemainingRef.current = displayLength;
  }, []);

  const onHideDialog = useCallback(() => {
    setShowDialog(false);
    showDialogRef.current = false;
  }, []);

  const logout = useCallback(() => getUserAuthClient().logout(), []);

  const onAnimationFrame = useCallback(
    timestamp => {
      if (endingTime && showDialogRef.current) {
        let timeRemaining = endingTime - timestamp;
        if (timeRemaining > 0) {
          timeRemaining = Math.floor(timeRemaining);
          if (timeRemaining !== timeRemainingRef.current) {
            setTimeRemaining(timeRemaining);
            timeRemainingRef.current = timeRemainingRef;
          }
          requestAnimationFrame(onAnimationFrame);
        } else {
          onHideDialog();
          logout();
        }
      }
    },
    [endingTime, logout, onHideDialog]
  );

  useEffect(() => {
    if (endingTime) {
      requestAnimationFrame(onAnimationFrame);
    }
  }, [endingTime, onAnimationFrame]);

  useEffect(() => {
    const userAuth = getUserAuthClient();
    userAuth.onInactivityListeners.push(onShowInactivity);
    userAuth.onLogoutListeners.push(onHideDialog);
    userAuth.onErrorListeners.push(onAuthError);
  }, [onShowInactivity, onHideDialog, onAuthError]);

  let timeDisplay = '';
  if (timeRemaining && !errorMessage) {
    const time = new Date(timeRemaining);
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    timeDisplay = `${minutes}:`;
    if (seconds === 0) {
      timeDisplay += '00';
    } else if (seconds < 10) {
      timeDisplay += `0${seconds}`;
    } else {
      timeDisplay += String(seconds);
    }
  }

  // Make sure to not display the dialog on the login page.  This keeps the
  // dialog from showing during the Auth0 login screen, preventing the user from
  // actually being able to login.
  const shouldShowDialog =
    showDialog && !location.pathname.startsWith('/login');

  return (
    <Dialog
      open={shouldShowDialog}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {errorMessage ? 'Something Went Wrong' : 'Stay signed in?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {errorMessage ??
            `You will be signed out in ${timeDisplay} minutes for inactivity.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!errorMessage && (
          <Button onClick={onHideDialog} variant="outlined">
            Stay signed in
          </Button>
        )}
        <Button onClick={logout} color="primary" variant="contained">
          {errorMessage ? 'Go to Login' : 'Sign out'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
