import PropTypes from 'prop-types';
import React from 'react';
import { SearchTextSections } from './propTypes';
import styled from 'styled-components';

const Highlight = styled.span`
  color: ${p => p.theme.palette.primary.main};
  text-decoration: underline;
`;

function HighlightedText({ text, sections, component, ...componentProps }) {
  const RootComponent = component ?? 'div';

  return (
    <RootComponent {...componentProps}>
      {!sections?.length && text}
      {!!sections?.length &&
        sections.map((i, key) => {
          if (i.isHighlighted) {
            return <Highlight key={key}>{i.text}</Highlight>;
          } else {
            return <React.Fragment key={key}>{i.text}</React.Fragment>;
          }
        })}
    </RootComponent>
  );
}

export default React.memo(HighlightedText);

HighlightedText.propTypes = {
  text: PropTypes.string.isRequired,
  sections: SearchTextSections,
  component: PropTypes.elementType
};
