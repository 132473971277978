import assistantAPI from './assistantAPI';

// Only export the graphql fragments and queries used outside of the assistant API
export {
  AssistantServicesFragment,
  AssistantKindsFragment,
  AssistantFunctionsFragment
} from './graphql';

export * from './assistantAPI';

export default assistantAPI;
