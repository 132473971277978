import React, { useCallback, useRef, useState } from 'react';

import { KN_SERVICE } from 'util/defines';
import SearchContent from './search/SearchContent';
import { useAutocomplete } from '@material-ui/lab';
import useSearchQuery from './search/useSearchQuery';

export default function Search({ className }) {
  const [inputValue, setInputValue] = useState('');
  const [currentCategory, setCategory] = useState(KN_SERVICE);
  const [isDragging, setIsDragging] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isVisible = isDragging || isPopupOpen;

  // Search and SearchContext are separated so that the useAutocomplete hook is
  // run as few times as possible.  The reason behind this is that every time
  // the useAutocomplete hook returns new values, it causes the re-rendering
  // of everything in SearchContext.
  const setHighlightedRef = useRef(null);

  const handleInputChange = useCallback((e, value) => {
    setInputValue(value);
  }, []);

  const handleHighlightChange = useCallback((e, value) => {
    // Update the highlighted result, ignoring undefined and null values, as
    // they cause the highlight to be cleared when it should not be.
    if (setHighlightedRef.current && value) {
      setHighlightedRef.current(value);
    }
  }, []);

  const startDragging = useCallback(e => {
    setIsDragging(true);
  }, []);

  const endDragging = useCallback(e => {
    setIsDragging(false);
  }, []);

  const handleCategoryChanged = useCallback(newCategory => {
    setCategory(c => {
      if (newCategory !== c && setHighlightedRef.current) {
        setHighlightedRef.current(null);
      }
      return newCategory;
    });
  }, []);

  const handleOpenPopup = useCallback(() => {
    setIsPopupOpen(true);
  }, []);

  const handleClosePopup = useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  const { loading, error, results, loadMoreResults } = useSearchQuery(
    inputValue,
    currentCategory,
    isVisible
  );

  const {
    getRootProps,
    getInputProps,
    getInputLabelProps,
    getListboxProps,
    getOptionProps,
    anchorEl,
    setAnchorEl
  } = useAutocomplete({
    id: 'maana-q-search',
    autoSelect: false,
    openOnFocus: true,
    blurOnSelect: false,
    clearOnEscape: true,
    clearOnBlur: false,
    disableCloseOnSelect: true,
    disabledItemsFocusable: true,
    filterOptions: o => o,
    options: results ?? [],
    getOptionLabel: o => o.name,
    onHighlightChange: handleHighlightChange,
    onInputChange: handleInputChange,
    onOpen: handleOpenPopup,
    onClose: handleClosePopup
  });

  return (
    <SearchContent
      className={className}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      getInputLabelProps={getInputLabelProps}
      getListboxProps={getListboxProps}
      getOptionProps={getOptionProps}
      isVisible={isVisible}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      setHighlightedRef={setHighlightedRef}
      loading={loading}
      error={error}
      results={results}
      loadMoreResults={loadMoreResults}
      startDragging={startDragging}
      endDragging={endDragging}
      currentCategory={currentCategory}
      handleCategoryChanged={handleCategoryChanged}
    />
  );
}
