import {
  KN_ASSISTANT,
  KN_FUNCTION,
  KN_KIND,
  KN_SERVICE,
  KN_WORKSPACE
} from 'util/defines';
import { addFunctionsToWorkspace, addKindsToWorkspace } from 'util/workspace';
import {
  addServiceFromWorkspaceToInventory,
  addServiceToInventory
} from 'util/service';
import { useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { DefaultActionType } from './defines';
import { handleError } from 'util/snackUtils';
import { useApolloClient } from 'react-apollo';

/**
 * Takes in the selected result and does the default action with it.
 *
 * The default actions are:
 * - When a Workspace is open the selected result is added to the inventory.
 * - When on a other pages:
 *   - Open workspaces, services, and assistants.
 *   - Ignore action attempts on kinds and functions.
 *
 * @param {Object} selection A search result to do the default action with.
 * @param {string|undefined} workspaceId The ID of the currently visible workspace.
 * @param {Object} history The browser history to navigate to a new page with.
 * @param {ApolloClient} client Client used add the result to the workspace.
 */
async function handleDefaultAction(selection, workspaceId, history, client) {
  const { id, kindName, name } = selection;
  if (workspaceId) {
    try {
      switch (kindName) {
        case KN_WORKSPACE:
          await addServiceFromWorkspaceToInventory(id, workspaceId, client);
          break;
        case KN_ASSISTANT:
        case KN_SERVICE:
          await addServiceToInventory(id, workspaceId, client);
          break;
        case KN_KIND:
          await addKindsToWorkspace(workspaceId, [id], client);
          break;
        case KN_FUNCTION:
          await addFunctionsToWorkspace(workspaceId, [id], client);
          break;
        default:
        // Nothing to do here, as anything that hits here can't be added to a
        // workspace.
      }
    } catch (e) {
      handleError(client, `Failed to add ${name} to the Workspace`, e);
    }
  } else {
    switch (kindName) {
      case KN_WORKSPACE:
        history.push(`/workspace/${id}`);
        break;
      case KN_ASSISTANT:
      case KN_SERVICE:
        history.push(`/service/${id}`);
        break;
      default:
      // Nothing to do here, as anything that hits here has no "open" option.
    }
  }
}

/**
 * Creates callback function used for doing the default action on a result.
 *
 * @returns {function} Callback function to initiate the action.
 */
export default function useDefaultAction() {
  const history = useHistory();
  const workspaceRouteMatch = useRouteMatch('/workspace/:id');
  const workspaceId = workspaceRouteMatch?.params?.id;
  const client = useApolloClient();

  const onAction = useCallback(
    target => {
      if (!target) return;
      handleDefaultAction(target, workspaceId, history, client);
    },
    [workspaceId, history, client]
  );

  const actionType = useMemo(() => {
    if (workspaceId) {
      return DefaultActionType.WORKSPACE;
    }

    return DefaultActionType.OPEN;
  }, [workspaceId]);

  return { onAction, actionType };
}
