import React, { useContext } from 'react';

import { ApolloConsumer } from 'react-apollo';
import { WorkspaceContext } from 'components/context';
import { useWorkspaceLockedBy } from 'hooks/useLockedBy';
import workspaceLayout from 'util/workspaceLayout';

/**
 * Gathers the settings used by the workspace layout handler.
 */
export function WorkspaceLayoutSettings({ children }) {
  const { workspaceId, workspaceLayoutId } = useContext(WorkspaceContext);
  const { canEdit } = useWorkspaceLockedBy({ workspaceId });
  return (
    <ApolloConsumer>
      {client => {
        workspaceLayout.setClientAndIds(
          client,
          workspaceId,
          workspaceLayoutId,
          canEdit
        );
        return children;
      }}
    </ApolloConsumer>
  );
}
