import {
  AssistantPortalGraphFragment,
  AssistantWorkspaceServicesFragment
} from './graphql';
import {
  FunctionDetailsFragment,
  InstanceDetailsFragment,
  InventoryInstanceRefDetailsFragment,
  KindDetailsFragment,
  LinkedOnInstance,
  LinkedOnKind
} from 'graphql/Portal';
import {
  addReferencesToCreatedEntities,
  removeReferencesToDeletedEntities
} from 'util/entity';
import {
  createFunctionForAssistant,
  createFunctionsForAssistant,
  deleteFunctionForAssistant,
  getFunctionsByService,
  updateFunctionForAssistant,
  updateFunctionsForAssistant
} from './function';
import {
  createKindForAssistant,
  createKindsForAssistant,
  deleteKindForAssistant,
  getKindsByService,
  updateKindForAssistant,
  updateKindsForAssistant
} from './kind';
import {
  createKnowledgeGraph,
  createKnowledgeGraphs,
  getFunctionGraph,
  getGraphObject
} from './graph';
import {
  getAssistantObject,
  getServiceObject,
  importService,
  importServices
} from './service';
import { getBoilerplateIds, getServiceUrl } from 'util/helpers';
import { isEmpty, uniqBy } from 'lodash';

import { AssistantEvents } from './defines';
import { KN_SERVICE } from 'util/defines';
import UserContext from 'util/UserContext';
import { addInstanceRefsToWorkspaceCache } from 'util/workspace';
import { addOpenWorkspace } from 'util/cache/user';
import assistantAPI from './assistantAPI';
import { cleanAndSaveGraphPathHistory } from 'util/selectionUtils';
import { createWorkspace } from 'services/workspaceService';
import { getWorkspaceLockedBy } from 'util/permissions';
import gql from 'graphql-tag';
import removeFromWorkspace from 'util/removeFromWorkspace';

const AssistantsWorkspaceInfoFragment = gql`
  fragment AssistantWorkspaceInfo on Workspace {
    id
    name
    thumbnailUrl
    workspaceServiceId
    modelServiceId
    logicServiceId
    activeGraphPath
    lockedBy
  }
`;

export const AssistantWorkspaceQuery = gql`
  query AssistantWorkspace($id: ID!) {
    workspace(id: $id) {
      ...AssistantWorkspaceInfo
      isPublic
      owner {
        id
      }
    }
  }
  ${AssistantsWorkspaceInfoFragment}
`;

const AssistantWorkspaceGraphsQuery = gql`
  query AssistantWorkspaceGraphs($id: ID!) {
    workspace(id: $id) {
      id
      portalGraphs {
        ...AssistantPortalGraph
      }
    }
  }
  ${AssistantPortalGraphFragment}
`;

const AssistantPortalGraphQuery = gql`
  query AssistantPortalGraph($id: ID!) {
    portalGraph(id: $id) {
      ...AssistantPortalGraph
    }
  }
  ${AssistantPortalGraphFragment}
`;

const AssistantAllUserWorkspacesQuery = gql`
  query AssistantAllUserWorkspaces($userId: ID!) {
    user(id: $userId) {
      id
      workspaces {
        ...AssistantWorkspaceInfo
      }
    }
  }
  ${AssistantsWorkspaceInfoFragment}
`;

const AssistantAllPublicWorkspacesQuery = gql`
  query AssistantAllPublicWorkspaces($userId: ID!) {
    allPublicWorkspaces(userId: $userId) {
      ...AssistantWorkspaceInfo
    }
  }
  ${AssistantsWorkspaceInfoFragment}
`;

const AssistantWorkspaceServicesQuery = gql`
  query AssistantWorkspaceServices($id: ID!) {
    workspace(id: $id) {
      id
      ...AssistantWorkspaceServices
    }
  }
  ${AssistantWorkspaceServicesFragment}
`;

const AssistantMoveKindsAndFunctionsMutation = gql`
  mutation AssistantMoveKindsAndFunctions(
    $originId: ID!
    $targetId: ID!
    $kindIds: [ID!]!
    $functionIds: [ID!]!
  ) {
    moveKindsAndFunctions(
      originId: $originId
      targetId: $targetId
      kindIds: $kindIds
      functionIds: $functionIds
    ) {
      kinds {
        id
        serviceId
        service {
          id
          name
        }
      }
      boilerplateKinds {
        ...KindDetails
      }
      functions {
        id
        service {
          id
          name
        }
        graph {
          id
          workspace {
            id
          }
        }
      }
      boilerplateFunctions {
        ...FunctionDetails
      }
      knowledgeGraphNodes {
        id
        instance {
          ...InstanceDetails
          ...LinkedOnInstance
        }
        innerKind {
          id
          ...LinkedOnKind
        }
        innerFunction {
          id
        }
      }
      operations {
        id
        function {
          id
        }
      }
      arguments {
        id
        typeKindId
        kind {
          id
        }
      }
      argumentValues {
        id
        argument {
          id
        }
      }
      instanceRefs {
        id
        instance {
          ...InstanceDetails
        }
        innerKind {
          id
        }
        innerFunction {
          id
        }
      }
      workspaceInstanceRefs {
        ...InventoryInstanceRefDetails
      }
      workspaceServiceReferences {
        serviceId
        workspaceIds
      }
    }
  }
  ${KindDetailsFragment}
  ${FunctionDetailsFragment}
  ${LinkedOnKind}
  ${LinkedOnInstance}
  ${InstanceDetailsFragment}
  ${InventoryInstanceRefDetailsFragment}
`;

/**
 * Gets the Workspace that is requested and preps it for the Assistant API.
 *
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to request for data.
 * @return {Workspace} A composite object representing the workspace.
 */
export async function getWorkspace(
  workspaceId,
  diagramEngine,
  getGraphDimensions,
  client
) {
  try {
    const result = await client.query({
      query: AssistantWorkspaceQuery,
      variables: { id: workspaceId }
    });
    if (!result?.data?.workspace) return null;

    const { workspace } = result.data;
    if (
      !(workspace.owner?.id === UserContext.getUserId() || workspace.isPublic)
    ) {
      throw new Error(
        `Workspace ${workspaceId} is not owned by the current user and is not marked as public.`
      );
    }

    return getWorkspaceObject(
      workspace,
      diagramEngine,
      getGraphDimensions,
      client
    );
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API error in getWorkspace: ${ex.message}`)
    );
  }
}

/**
 * Preps the Workspace passed in for the Assistant API
 *
 * @param {string} workspace The Workspace to work with.
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to request for data.
 * @return {Workspace} A composite object representing the workspace.
 */
function getWorkspaceObject(
  workspace,
  diagramEngine,
  getGraphDimensions,
  client
) {
  const {
    id: workspaceId,
    name,
    workspaceServiceId,
    modelServiceId,
    logicServiceId,
    activeGraphPath
  } = workspace;

  return {
    id: workspaceId,
    name,
    endpointUrl: getServiceUrl(workspaceServiceId),
    workspaceServiceId: workspaceServiceId,
    modelServiceId: modelServiceId,
    logicServiceId: logicServiceId,

    // Add the locking properties to the Object in a way that each time the
    // assistant calls them they are are returning up to date information about
    // its current locked state.

    async lockedBy() {
      try {
        const { lockedBy } = await getWorkspaceLockedBy(workspaceId, client);
        return lockedBy;
      } catch {
        // Return null if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return null;
      }
    },
    async canEdit() {
      try {
        const { canEdit } = await getWorkspaceLockedBy(workspaceId, client);
        return canEdit;
      } catch {
        // Return false if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return false;
      }
    },
    async setLocked(isLocked) {
      try {
        const { setLockedBy } = await getWorkspaceLockedBy(workspaceId, client);
        return setLockedBy(isLocked);
      } catch {
        // Return null if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return null;
      }
    },

    // Knowledge Graphs
    getActiveGraph: async () => {
      // Return null if there is no active graph for this workspace
      if (isEmpty(activeGraphPath)) return null;

      try {
        // The graph object sanitizes its output.
        const { data } = await client.query({
          query: AssistantPortalGraphQuery,
          variables: { id: activeGraphPath[activeGraphPath.length - 1] }
        });
        return data?.portalGraph
          ? getGraphObject(
              data.portalGraph,
              workspaceId,
              diagramEngine,
              getGraphDimensions,
              client
            )
          : null;
      } catch (e) {
        return Promise.reject(
          new Error(
            `Assistant API error in workspace.getActiveGraph: ${e.message}`
          )
        );
      }
    },
    getKnowledgeGraphs: async () => {
      try {
        const { data } = await client.query({
          query: AssistantWorkspaceGraphsQuery,
          variables: { id: workspaceId }
        });

        if (isEmpty(data?.workspace?.portalGraphs)) return [];

        // Graph objects sanitize their output.
        return (
          await Promise.all(
            data.workspace.portalGraphs.map(pg =>
              getGraphObject(
                pg,
                workspaceId,
                diagramEngine,
                getGraphDimensions,
                client
              )
            )
          )
        ).filter(Boolean);
      } catch (e) {
        return Promise.reject(
          new Error(
            `Assistant API error in workspace.getKnowledgeGraphs: ${e.message}`
          )
        );
      }
    },
    createKnowledgeGraph: input =>
      createKnowledgeGraph(
        input,
        workspaceId,
        diagramEngine,
        getGraphDimensions,
        client
      ),
    createKnowledgeGraphs: input =>
      createKnowledgeGraphs(
        input,
        workspaceId,
        diagramEngine,
        getGraphDimensions,
        client
      ),

    // Services
    getImportedServices: async () => {
      try {
        const { data } = await client.query({
          query: AssistantWorkspaceServicesQuery,
          variables: { id: workspaceId }
        });

        if (isEmpty(data?.workspace?.services)) return [];

        // Service objects sanitize their output.
        return data.workspace.services
          .map(s => getServiceObject(s, diagramEngine, getGraphObject, client))
          .filter(Boolean);
      } catch (e) {
        return Promise.reject(
          new Error(
            `Assistant API error in workspace.getImportedServices: ${e.message}`
          )
        );
      }
    },
    getImportedAssistants: async () => {
      try {
        const { data } = await client.query({
          query: AssistantWorkspaceServicesQuery,
          variables: { id: workspaceId }
        });

        if (isEmpty(data?.workspace?.services)) return [];

        // Service objects sanitize their output.
        return data.workspace.services.map(getAssistantObject).filter(Boolean);
      } catch (e) {
        return Promise.reject(
          new Error(
            `Assistant API error in workspace.getImportedAssistants: ${e.message}`
          )
        );
      }
    },
    importService: serviceId => importService(serviceId, workspaceId, client),
    importServices: serviceIds =>
      importServices(serviceIds, workspaceId, client),
    removeServices: serviceIds => {
      const items = serviceIds.map(id => ({ id, kindName: KN_SERVICE }));
      return removeFromWorkspace({
        items,
        workspaceId,
        client,
        cleanBoilerplate: false,
        cleanServiceChildren: false
      });
    },
    removeService: serviceId => {
      return removeFromWorkspace({
        items: [{ id: serviceId, kindName: KN_SERVICE }],
        workspaceId,
        client,
        cleanBoilerplate: false,
        cleanServiceChildren: false
      });
    },

    // Functions
    getFunctions: async () => {
      try {
        return await getFunctionsByService(
          workspaceServiceId,
          client,
          workspaceId
        );
      } catch (ex) {
        return Promise.reject(
          new Error(
            `Assistant API error in workspace.getFunctions: ${ex.message}`
          )
        );
      }
    },
    createFunction: funcData =>
      createFunctionForAssistant(funcData, logicServiceId, client),
    createFunctions: funcData =>
      createFunctionsForAssistant(funcData, logicServiceId, client),
    updateFunction: funcData =>
      updateFunctionForAssistant(funcData, workspaceId, logicServiceId, client),
    updateFunctions: funcData =>
      updateFunctionsForAssistant(
        funcData,
        workspaceId,
        logicServiceId,
        client
      ),
    deleteFunction: funcId =>
      deleteFunctionForAssistant(funcId, workspaceId, logicServiceId, client),
    getFunctionGraph: funcId =>
      getFunctionGraph(
        funcId,
        workspaceId,
        diagramEngine,
        getGraphDimensions,
        client
      ),

    // Kinds
    getKinds: async () => {
      try {
        return getKindsByService(workspaceServiceId, client);
      } catch (ex) {
        return Promise.reject(
          new Error(`Assistant API error in workspace.getKinds: ${ex.message}`)
        );
      }
    },
    createKind: kindData =>
      createKindForAssistant(kindData, modelServiceId, client),
    createKinds: kindData =>
      createKindsForAssistant(kindData, modelServiceId, client),
    updateKind: kindData =>
      updateKindForAssistant(kindData, workspaceId, modelServiceId, client),
    updateKinds: kindData =>
      updateKindsForAssistant(kindData, workspaceId, modelServiceId, client),
    deleteKind: kindId =>
      deleteKindForAssistant(kindId, workspaceId, modelServiceId, client),

    // Event Triggers
    triggerRepairEvent: () =>
      assistantAPI
        .broadcastAll(AssistantEvents.REPAIR, workspaceId)
        // Prevent sending the response to the assistants, as this will cause a
        // CORS issue when trying to pass data from one assistant to another.
        .then(() => true)
  };
}

/**
 * Gets the current list of workspaces the user can access, with the option
 * of including public workspaces also.
 *
 * @param {boolean} includePublic When true includes all of the public workspaces
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to request for data.
 * @return {Array<Workspace>} The list of available Workspaces.
 */
export async function getUserAccessibleWorkspaces(
  includePublic = false,
  diagramEngine,
  getGraphDimensions,
  client
) {
  try {
    const userWorkspaces = await client.query({
      query: AssistantAllUserWorkspacesQuery,
      variables: { userId: UserContext.getUserId() }
    });
    const publicWorkspaces = includePublic
      ? await client.query({
          query: AssistantAllPublicWorkspacesQuery,
          variables: { userId: UserContext.getUserId() }
        })
      : null;

    return uniqBy(
      (userWorkspaces?.data?.user?.workspaces || []).concat(
        publicWorkspaces?.data?.allPublicWorkspaces || []
      ),
      w => w.id
    ).map(w =>
      getWorkspaceObject(w, diagramEngine, getGraphDimensions, client)
    );
  } catch (ex) {
    return Promise.reject(
      new Error(
        `Assistant API error in getUserAccessibleWorkspaces: ${ex.message}`
      )
    );
  }
}

/**
 * Creates a new Workspace based on the information passed in from the
 * assistant.
 *
 * @param {Object} workspaceData The data about the new Workspace in the shape
 *                  of {id, serviceId, name}
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to request for data.
 * @return {Workspace} The new Workspace
 */
export async function createWorkspaceForAssistant(
  workspaceData,
  diagramEngine,
  getGraphDimensions,
  client
) {
  try {
    const { name, id, serviceId } = workspaceData;

    if (!name) {
      throw new Error(
        `Workspace name not provided in ${JSON.stringify(workspaceData)}`
      );
    }

    const {
      isValid,
      validationErrors,
      isCreated,
      createdWorkspaceId,
      error
    } = await createWorkspace({
      name,
      workspaceId: id,
      workspaceServiceId: serviceId,
      userId: UserContext.getUserId(),
      client
    });

    // Validation failed, send the error messages.
    if (!isValid && validationErrors) {
      throw new Error(Object.values(validationErrors).join(' '));
    }

    // An error was caught and returned, pass it along
    if (error) {
      throw error;
    }

    // All is good, return the Workspace
    if (isCreated && createdWorkspaceId) {
      addOpenWorkspace(client, createdWorkspaceId);
      return await getWorkspace(
        createdWorkspaceId,
        diagramEngine,
        getGraphDimensions,
        client
      );
    }
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API error in createWorkspace: ${ex.message}`)
    );
  }
}

/**
 * Moves a collection of Kinds and Functions from the origin Workspace to the
 * target Workspace.
 *
 * @param {string} originId The ID of the origin Workspace.
 * @param {string} targetId The ID of the target Workspace.
 * @param {Array<string>} kindIds An array of the IDs of the kinds to move.
 * @param {Array<string>} functionIds An array of the IDs of the functions to move.
 * @param {ApolloClient} client The client used to request for data.
 */
export async function moveKindsAndFunctions(
  originId,
  targetId,
  kindIds,
  functionIds,
  client
) {
  try {
    // Load the Workspaces
    const owPromise = getWorkspace(originId, null, null, client);
    const twPromise = getWorkspace(targetId, null, null, client);
    const originWorkspace = await owPromise;
    const targetWorkspace = await twPromise;

    // Validate that the Workspaces exist
    if (!(originWorkspace && targetWorkspace)) {
      const errors = [];
      if (!originWorkspace) {
        errors.push(`Failed to load origin workspace with ID '${originId}'.`);
      }
      if (!targetWorkspace) {
        errors.push(`Failed to load target workspace with ID '${targetId}'.`);
      }
      return Promise.reject(
        new Error(
          `Assistant API errors in moveKindsAndFunctions: ${errors.join(' ')}`
        )
      );
    }

    await client.mutate({
      mutation: AssistantMoveKindsAndFunctionsMutation,
      variables: {
        originId,
        targetId,
        kindIds: kindIds ?? [],
        functionIds: functionIds ?? []
      },
      update: (store, { data }) => {
        const {
          boilerplateKinds,
          boilerplateFunctions,
          kinds,
          functions,
          workspaceServiceReferences,
          workspaceInstanceRefs
        } = data?.moveKindsAndFunctions ?? {};

        if (workspaceInstanceRefs) {
          addInstanceRefsToWorkspaceCache(
            originId,
            workspaceInstanceRefs,
            store
          );
        }

        // Update the information about the boilerplate for the Kinds
        const oldBoilerplate = kindIds
          ? getBoilerplateIds(originWorkspace.modelServiceId, kindIds)
          : {};

        removeReferencesToDeletedEntities(
          {
            updatedWorkspaces: [originWorkspace],
            workspaces: [originWorkspace],
            deletedBoilerplateKindIds: oldBoilerplate.kinds,
            deletedBoilerplateFunctionIds: oldBoilerplate.functions,
            updatedServices: [
              originWorkspace.modelServiceId,
              originWorkspace.logicServiceId,
              originWorkspace.workspaceServiceId
            ].map(id => ({ id })),
            workspaceServiceReferences: workspaceServiceReferences.filter(
              ref => ref.serviceId === originWorkspace.workspaceServiceId
            )
          },
          store,
          { kindIds, functionIds }
        );

        addReferencesToCreatedEntities(
          {
            newKinds: kinds,
            newFunctions: functions,
            newBoilerplateFunctions: boilerplateFunctions,
            newBoilerplateKinds: boilerplateKinds,
            updatedWorkspaces: [targetWorkspace],
            workspaceServiceReferences: workspaceServiceReferences.filter(
              ref => ref.serviceId === targetWorkspace.workspaceServiceId
            )
          },
          store
        );

        if (!isEmpty(functionIds)) {
          // Clean origin workspace graph path history.
          // This must be done after cleanWorkspace as it clears selection and for
          // graphs we'd like to select the last valid graph from the history.
          cleanAndSaveGraphPathHistory(originId, store);
        }
      }
    });

    // Make sure that the target workspace service is added to the origin services
    // list
    await originWorkspace.importService(targetWorkspace.workspaceServiceId);
  } catch (e) {
    return Promise.reject(
      `Assistant API errors in moveKindsAndFunctions: ${e.message}`
    );
  }
}
