import {
  DragAndDropType,
  KN_ASSISTANT,
  KN_SERVICE,
  KN_WORKSPACE
} from 'util/defines';

import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';
import { useEffect } from 'react';

function getDragAndDropType(kindName) {
  switch (kindName) {
    case KN_SERVICE:
      return DragAndDropType.Service;
    case KN_ASSISTANT:
      return DragAndDropType.Service;
    case KN_WORKSPACE:
      return DragAndDropType.Workspace;
    default:
      return DragAndDropType.SearchNode;
  }
}

export default function useOptionDrag(option) {
  const { id, kindName, name } = option;
  const type = getDragAndDropType(kindName);

  const [, dragSource, dragPreview] = useDrag({
    item: {
      id,
      kindName,
      title: name,
      iconName: kindName,
      type
    }
  });

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  return dragSource;
}
