import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { SubscribeToLinksQuery, UserProfileQuery } from 'graphql/Portal';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useDialog, useMenu } from 'hooks';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { FeedbackDialog } from 'components/Feedback/FeedbackDialog';
import FeedbackIcon from '@material-ui/icons/Feedback';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton } from 'components';
import { KPORTAL_VERSION } from 'util/defines';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Logo } from './Logo';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import Search from './Search';
import SubscribeToLinksIcon from '@material-ui/icons/Loop';
import Switch from '@material-ui/core/Switch';
import SystemInfoIcon from '@material-ui/icons/Info';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ThemeIcon from '@material-ui/icons/FormatPaint';
import Toolbar from '@material-ui/core/Toolbar';
import UserContext from 'util/UserContext';
import gql from 'graphql-tag';
import { handleError } from 'util/snackUtils';
import path from 'path';
import styled from 'styled-components';

const SetThemeMutation = gql`
  mutation SetTheme($userId: ID!, $theme: Theme!) {
    setTheme(userId: $userId, theme: $theme)
  }
`;

const StyledHeader = styled(AppBar)`
  position: initial; /* override AppBar's 'fixed' */
`;

const StyledTabs = styled(Tabs)`
  flex-shrink: 0;
  padding-top: ${p => p.theme.spacing(2)}px;
`;

const StyledSearch = styled(Search)`
  flex: 1;
`;

const HeaderButtons = styled.div`
  display: flex;
`;

const HeaderIconButton = styled(IconButton)`
  height: 48px;
  width: 48px;
`;

const AvatarIconButton = styled(HeaderIconButton)`
  padding: 0;
`;

const pages = [
  { id: '/', name: 'Home' },
  { id: 'workspace', name: 'Workspaces' },
  { id: 'service', name: 'Services' }
];

const NavLinkWrapper = React.forwardRef((itemProps, ref) => (
  <NavLink {...itemProps} />
));

function getCurrentPage(pathname) {
  return pathname.split('/').filter(x => x.length > 0)[0] ?? '/';
}

export default function Header({ className }) {
  const client = useApolloClient();
  const history = useHistory();
  const location = useLocation();
  const { menuProps, onMenuOpen, onMenuClose } = useMenu();
  const [activePageId, setActivePageId] = useState(() =>
    getCurrentPage(location.pathname)
  );

  useEffect(() => {
    // Make sure the correct page is highlighted when the user changes the
    // displayed page.
    setActivePageId(getCurrentPage(location.pathname));
  }, [location.pathname]);

  const [setTheme] = useMutation(SetThemeMutation, {
    refetchQueries: [
      {
        query: UserProfileQuery,
        variables: {
          id: UserContext.getUserId()
        }
      }
    ]
  });

  const handleToggleTheme = useCallback(() => {
    // Toggle theme and ensure name is uppercase because server side enum is upper
    const newTheme = UserContext.toggleTheme().toUpperCase();
    setTheme({
      variables: {
        userId: UserContext.getUserId(),
        theme: newTheme
      }
    })
      .then(res => {
        window.location.reload();
      })
      .catch(error => {
        handleError(client, 'Failed to update theme on server', error);
      });
  }, [client, setTheme]);

  const handleToggleLinkSubscriptions = useCallback(
    (client, subscribeToLinks) => {
      // Update local storage
      UserContext.setSubscribeToLinks(!subscribeToLinks);

      // Update Apollo cache
      client.writeQuery({
        query: SubscribeToLinksQuery,
        data: {
          subscribeToLinks: !subscribeToLinks
        }
      });
    },
    []
  );

  const {
    isDialogOpen: isFeedbackDialogOpen,
    onDialogOpen: onFeedbackDialogOpen,
    onDialogClose: onFeedbackDialogClose
  } = useDialog();

  const userProfile = UserContext.getUserProfile();

  return (
    <StyledHeader className={className} color="default">
      <Toolbar>
        <Link to="/">
          <Logo />
        </Link>
        <StyledTabs
          value={activePageId}
          textColor="primary"
          indicatorColor="primary"
          scrollButtons="off"
        >
          {pages.map(p => (
            <Tab
              key={`page-${p.id}`}
              value={p.id}
              label={p.name}
              component={NavLinkWrapper}
              to={path.normalize(`/${p.id}/`)}
            />
          ))}
        </StyledTabs>
        <StyledSearch />

        <HeaderButtons>
          <HeaderIconButton
            title={
              window.MAANA_ENV.ENABLE_FEEDBACK
                ? 'Send Feedback'
                : 'Feedback is disabled by system configuration'
            }
            ActionIcon={FeedbackIcon}
            onClick={onFeedbackDialogOpen}
            disabled={!window.MAANA_ENV.ENABLE_FEEDBACK}
          />
          <FeedbackDialog
            open={isFeedbackDialogOpen}
            onClose={onFeedbackDialogClose}
          />

          <HeaderIconButton
            title="System Information"
            ActionIcon={SystemInfoIcon}
            onClick={() => history.push('/systemInformation')}
          />
          <HeaderIconButton
            title="System Help"
            ActionIcon={HelpIcon}
            component="a"
            href={`${window.MAANA_ENV.REACT_APP_PORTAL_HELP_URI}/v/${KPORTAL_VERSION}/`}
            target="_blank"
          />
          <AvatarIconButton title="User Actions" onClick={onMenuOpen}>
            <Avatar src={userProfile.picture} />
          </AvatarIconButton>
          <Menu {...menuProps}>
            <MenuItem component={Link} to="/user" onClick={onMenuClose}>
              <ListItemIcon>
                <ProfileIcon />
              </ListItemIcon>
              <ListItemText inset primary="Profile" />
            </MenuItem>
            <MenuItem onClick={handleToggleTheme}>
              <ListItemIcon>
                <ThemeIcon />
              </ListItemIcon>
              <ListItemText inset primary="Dark Theme" />
              <Switch checked={UserContext.isDarkTheme()} color="primary" />
            </MenuItem>
            <Query query={SubscribeToLinksQuery}>
              {({ data, client }) => (
                <MenuItem
                  onClick={() =>
                    handleToggleLinkSubscriptions(client, data.subscribeToLinks)
                  }
                >
                  <ListItemIcon>
                    <SubscribeToLinksIcon />
                  </ListItemIcon>
                  <ListItemText inset primary="Subscribe to Links" />
                  <Switch checked={data.subscribeToLinks} color="primary" />
                </MenuItem>
              )}
            </Query>
            <MenuItem
              onClick={() => {
                history.push('/logout');
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText inset primary="Logout" />
            </MenuItem>
          </Menu>
        </HeaderButtons>
      </Toolbar>
    </StyledHeader>
  );
}

Header.propTypes = {
  className: PropTypes.string
};
