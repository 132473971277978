import { useCallback, useState } from 'react';

/**
 * Custom hook to handle hovering.
 *
 * @returns {Object} isHovering boolean, onHoverStart & onHoverEnd event handlers
 */
export function useHover() {
  const [isHovering, setIsHovering] = useState(false);

  const onHoverStart = useCallback(() => {
    setIsHovering(true);
  }, [setIsHovering]);

  const onHoverEnd = useCallback(() => {
    setIsHovering(false);
  }, [setIsHovering]);

  return [isHovering, onHoverStart, onHoverEnd];
}
