import Header from 'components/Header';
import React from 'react';
import styled from 'styled-components';

const StyledPageLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledHeader = styled(Header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
`;
const Content = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const PageLayout = ({ children }) => {
  return (
    <StyledPageLayout>
      <StyledHeader />
      <Content>{children}</Content>
    </StyledPageLayout>
  );
};

export default PageLayout;
