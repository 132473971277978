import BaseCLIAuth from './BaseCLIAuth';
import UserContext from 'util/UserContext';

export default class KeyCloakCLIAuth extends BaseCLIAuth {
  async createAuth() {
    const authDomain = window.MAANA_ENV.REACT_APP_CLI_AUTH_DOMAIN;
    const authIdentifier = window.MAANA_ENV.REACT_APP_PORTAL_AUTH_IDENTIFIER;

    // create some data needed for PKCE
    let randomVals = new Uint8Array(43);
    window.crypto.getRandomValues(randomVals);
    let verifier = this.base64URLEncode(randomVals);
    this.challenge = this.base64URLEncode(await this.sha256(verifier));

    UserContext.setVerifier(btoa(verifier));

    this.authorizationUri = `${authDomain}/auth/realms/${authIdentifier}/protocol/openid-connect/auth`;
  }

  getToken() {
    const audience =
      window.MAANA_ENV.AUTH_AUDIENCE ||
      window.MAANA_ENV.REACT_APP_PORTAL_AUTH_IDENTIFIER;

    const url = new URL(this.authorizationUri);
    url.searchParams.set(
      'client_id',
      window.MAANA_ENV.REACT_APP_CLI_AUTH_CLIENT_ID
    );
    url.searchParams.set('redirect_uri', this.buildRedirectURI());
    url.searchParams.set('scope', audience);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('state', UserContext.getVerifier());
    url.searchParams.set('code_challenge', this.challenge);
    url.searchParams.set('code_challenge_method', 'S256');

    window.location.replace(url.toString());
  }

  getDataPackage() {
    const authDomain = window.MAANA_ENV.REACT_APP_CLI_AUTH_DOMAIN;
    const authIdentifier = window.MAANA_ENV.REACT_APP_PORTAL_AUTH_IDENTIFIER;
    const accessTokenUri = `${authDomain}/auth/realms/${authIdentifier}/protocol/openid-connect/token`;
    return this.buildBaseDataPackage(accessTokenUri);
  }
}
