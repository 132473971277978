import { CircularProgress, Tooltip } from '@material-ui/core';

import React from 'react';
import styled from 'styled-components';

const DEFAULT_PROGRESS_SIZE = 40;

const StyledProgress = styled(CircularProgress)`
  color: ${p => p.theme.custom.spinner.color};
  && {
    margin: 0;
  }
`;

/**
 * Progress indicator. Encapsulates styling as well as a tooltip.
 */
export function Progress({ value, variant, title, size, className, ...other }) {
  // When the value is small, show indeterminate, unless variant is specified.
  // Switch to determinate once the value is large enough to easily see.
  const swapThreshhold = 10;
  const belowThreshhold = value && value < swapThreshhold;
  const displayVariant = belowThreshhold ? 'indeterminate' : 'determinate';
  // Also, adjust small values to they are easier to see.
  const displayValue = belowThreshhold ? swapThreshhold : value;

  return (
    <div className={className}>
      <Tooltip title={title}>
        <StyledProgress
          size={size || DEFAULT_PROGRESS_SIZE}
          variant={variant || displayVariant}
          value={displayValue}
          {...other}
        />
      </Tooltip>
    </div>
  );
}
