import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import AuthDialog from 'components/AuthDialog';
import Callback from 'pages/Callback';
import { CenteredSpinner } from 'components';
import { CustomDragLayer } from './components/CustomDragLayer';
import { DataRenderingWrapper } from 'components';
import { KindDetailsGraphFragment } from 'graphql/Portal';
import Notifications from 'components/Notifications';
import PageLayout from 'pages/PageLayout';
import UserContext from 'util/UserContext';
import { flowRight as compose } from 'lodash';
import { getUserAuthClient } from 'util/Auth';
import gql from 'graphql-tag';
import { serviceKindQueryVariables } from 'util/helpers';
import { useQuery } from 'react-apollo';
import withRoot from 'util/withRoot';

// Code split on the Routes. All new Routes should be added here as a lazy import.
const Workspaces = lazy(() => import('pages/Workspaces'));
const Services = lazy(() => import('pages/Services'));
const AddService = lazy(() => import('pages/Services/AddService'));
const EditService = lazy(() => import('pages/Services/EditService'));
const Home = lazy(() => import('pages/Home'));
const User = lazy(() => import('pages/User'));
const Login = lazy(() => import('pages/Login'));
const SystemInformation = lazy(() => import('components/SystemInformation'));

// Default styles

const Root = styled.div`
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: ${({ theme }) => theme.palette.background.image};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const SystemKindsQuery = gql`
  query SystemKinds($kindQuery: KindQueryInput!) {
    kindKind: kind(tenantId: 0, name: "Kind") {
      ...KindDetailsGraph
    }

    functionKind: kind(tenantId: 0, name: "Function") {
      ...KindDetailsGraph
    }

    serviceKind: kindDBKindQuery(kindQuery: $kindQuery) {
      id
    }

    fileKind: kind(tenantId: 0, name: "File") {
      ...KindDetailsGraph
    }
  }
  ${KindDetailsGraphFragment}
`;

// Standard component class
const Portal = props => {
  const { theme, location } = props;

  const userAuth = getUserAuthClient();
  const isActive = userAuth.isActive();
  const isAuthenticated = userAuth.isAuthenticated();

  const {
    loading: systemKindsLoading,
    error: systemKindsError,
    data: systemKindsData
  } = useQuery(SystemKindsQuery, {
    skip: !(isActive && isAuthenticated),
    variables: serviceKindQueryVariables
  });

  const userTheme = UserContext.getTheme();
  if (userTheme) {
    if (theme.palette.type !== userTheme.toLowerCase()) {
      window.location.reload();
      return <div />;
    }
  }

  return (
    <Root>
      {!(isActive && isAuthenticated) ? (
        <Suspense fallback={<CenteredSpinner />}>
          <Switch>
            {/* special route for auth callback */}
            <Route
              path="/callback"
              render={props => {
                return <Callback {...props} />;
              }}
            />

            {/* if the user has requested login, then log them in */}
            <Route path="/login" render={props => <Login {...props} />} />

            {/* if the user is trying to logout, then log them out */}
            <Route
              path="/logout"
              exact
              render={() => {
                userAuth.logout();
                return null;
              }}
            />

            {/* if the user is not logged in and they've requested something else,
          then log them in */}
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location.pathname }
              }}
            />
          </Switch>
        </Suspense>
      ) : (
        <>
          <PageLayout>
            <DataRenderingWrapper
              loading={systemKindsLoading || !systemKindsData}
              error={systemKindsError}
              errorMessage="Failed to load system kinds"
              location="Portal"
            >
              <Suspense fallback={<CenteredSpinner />}>
                <Switch>
                  {/* process normal (secure) router */}
                  <Route path="/" exact render={props => <Home {...props} />} />
                  <Route
                    path="/logout"
                    exact
                    render={() => {
                      userAuth.logout();
                      return null;
                    }}
                  />
                  <Route
                    path="/workspace/:id?"
                    render={props => <Workspaces {...props} />}
                  />
                  <Route path="/service/new" render={() => <AddService />} />
                  <Route
                    path="/service/:id"
                    render={props => <EditService id={props.match.params.id} />}
                  />
                  <Route path="/service" render={() => <Services />} />
                  <Route path="/user" render={props => <User {...props} />} />
                  <Route
                    path="/systemInformation"
                    render={() => <SystemInformation />}
                  />
                  <Redirect to="/" />
                </Switch>
              </Suspense>
            </DataRenderingWrapper>
          </PageLayout>
          <Notifications />
        </>
      )}
      <CustomDragLayer />
      <AuthDialog />
    </Root>
  );
};

const enhancers = compose(withRoot, withRouter, withTheme);

export default enhancers(Portal);
