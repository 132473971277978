import Auth0CLIAuth from './Auth0CLIAuth';
import Auth0UserAuth from './Auth0UserAuth';
import { AuthProvider } from '../defines';
import KeyCloakCLIAuth from './KeyCloakCLIAuth';
import KeyCloakUserAuth from './KeyCloakUserAuth';
import { getPortalClient } from 'graphql/clients';

let authClient = null;
let cliAuth = null;

/**
 * Returns the user authentication client, and will create it if it has not been
 * created yet.
 */
export function getUserAuthClient() {
  if (!authClient) {
    switch (window.MAANA_ENV.AUTH_PROVIDER) {
      case AuthProvider.KeyCloak: {
        authClient = new KeyCloakUserAuth(getPortalClient());
        break;
      }
      case AuthProvider.Auth0:
      default: {
        authClient = new Auth0UserAuth(getPortalClient());
        break;
      }
    }
  }
  return authClient;
}

/**
 * Returns the CLI authentication client, and will create it if it has not been
 * created yet.
 */
export function getCLIAuthClient() {
  if (!cliAuth) {
    switch (window.MAANA_ENV.AUTH_PROVIDER) {
      case AuthProvider.KeyCloak: {
        cliAuth = new KeyCloakCLIAuth(getPortalClient());
        break;
      }
      case AuthProvider.Auth0:
      default: {
        cliAuth = new Auth0CLIAuth(getPortalClient());
        break;
      }
    }
  }
  return cliAuth;
}
