import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import React from 'react';
import SavedIcon from '@material-ui/icons/CheckCircle';
import { SnackType } from 'util/snackUtils';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { withoutProps } from 'util/helpers';

const withoutIsError = withoutProps(['isError']);

const SnackbarRoot = styled(Snackbar)`
  > div {
    background-color: ${props => props.theme.custom.snack.background};
    flex-wrap: unset;
  }
`;

const SnackbarMessage = styled.div`
  display: flex;
  align-items: center;
`;

const SnackbarText = styled(withoutIsError(Typography))`
  margin-left: 1em;
  color: ${props => getTextColor(props.theme, props.isError)};
`;
const IconCointainer = styled.div`
  align-self: flex-start;
  margin-top: 1.2em;
  min-height: 3em;
  max-height: 3em;
`;

const StyledErrorIcon = styled(withoutIsError(ErrorIcon))`
  color: ${props => getIconColor(props.theme, props.isError)};
`;

const StyledSavedIcon = styled(withoutIsError(SavedIcon))`
  color: ${props => getIconColor(props.theme, props.isError)};
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-left: 1em;
  color: ${props => props.theme.custom.snack.closeIcon};
`;

const getTextColor = (theme, isError) => {
  return isError ? theme.palette.error.dark : theme.palette.primary.dark;
};

const getIconColor = (theme, isError) => {
  return isError ? theme.palette.error.main : theme.palette.primary.main;
};

const Snack = ({
  text,
  snackType,
  onSnackClose,
  autoHideDuration = 3000,
  action,
  ClickAwayListenerProps,
  open,
  className
}) => {
  const isError = snackType === SnackType.ERROR;

  const snackBarOnClose = function(event, reason) {
    if (!isError || (isError && reason === 'clickaway')) {
      onSnackClose(event, reason);
    }
  };

  return (
    <SnackbarRoot
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={snackBarOnClose}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      action={action}
      ClickAwayListenerProps={ClickAwayListenerProps}
      className={className}
      message={
        <SnackbarMessage>
          <IconCointainer>
            {isError ? (
              <StyledErrorIcon isError={isError} />
            ) : (
              <StyledSavedIcon isError={isError} />
            )}
          </IconCointainer>
          <SnackbarText isError={isError} variant="subtitle1">
            {text}
          </SnackbarText>
          {isError ? (
            <StyledCloseIcon
              onClick={event => snackBarOnClose(event, 'clickaway')}
            />
          ) : (
            ''
          )}
        </SnackbarMessage>
      }
    />
  );
};

Snack.propTypes = {
  text: PropTypes.node.isRequired,
  snackType: PropTypes.oneOf(Object.values(SnackType)).isRequired,
  onSnackClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  authHideDuration: PropTypes.number,
  action: PropTypes.node,
  ClickAwayListenerProps: PropTypes.object,
  className: PropTypes.string
};

export default Snack;
