import UserContext, { THEME_DEFAULT } from 'util/UserContext';

import Logger from 'util/Logger';
import { UserProfileQuery } from 'graphql/Portal';
import gql from 'graphql-tag';
import { handleError } from 'util/snackUtils';
import history from 'util/history';

export const RENEW_TOKEN_TIMER_OFFSET = 60000; // 1 minute
const CHECK_ACTIVITY_TIMER = 60000; // 1 minute
const SHOW_INACTIVITY_DIALOG_TIMER = 300000; // 5 minutes
const INACTIVITY_TIMER =
  (window.MAANA_ENV && window.MAANA_ENV.INACTIVITY_TIMER) || 3600000; // 1 hour
const MAX_RENEW_ATTEMPTS = 2;
const RENEWAL_ATTEMPT_BACKOFF = 1000; // 1 second

const DISPLAY_INACTIVITY_TIMER =
  INACTIVITY_TIMER - SHOW_INACTIVITY_DIALOG_TIMER;

const USER_AUTH_ERROR_MESSAGE = 'An issue happened during authentication';
const DEFAULT_USER_ICON = '/icons/user.svg';

const AddUserMutation = gql`
  mutation AddUser($input: AddUserInput!) {
    addUser(input: $input)
  }
`;
const UserQuery = gql`
  query User($email: String!) {
    userByEmail(email: $email) {
      id
      theme
    }
  }
`;

export class NonRecoverableAuthError extends Error {}

export class BaseUserAuth {
  onInactivityListeners = [];
  onLogoutListeners = [];
  onTokenChangeListeners = [];
  onErrorListeners = [];
  promise = null;

  constructor(client) {
    this.client = client;
  }

  _initAuthState() {
    if (this.isAuthenticated()) {
      if (this.isActive()) {
        this.onActivity();
        this.scheduleRenewal();
        this.addVisibilityCheck();
      } else {
        this.logout();
      }
    }
  }

  /**
   * Checks to see if the user has recently been active.
   *
   * @returns {boolean} True of the user is active, false if the user has not been active
   */
  isActive() {
    // if the inactivity timer is zero or lower, then this feature is disabled
    if (INACTIVITY_TIMER <= 0) return true;

    // check to see if the users has been active
    let lastActivity = UserContext.getUserActivity();
    return (
      lastActivity &&
      Date.now() - parseInt(lastActivity, 10) <= INACTIVITY_TIMER
    );
  }

  /**
   * Checks to see if there is a valid user token available
   */
  isAuthenticated() {
    let expiresAt = parseInt(UserContext.getIdTokenExpiry(), 10);
    const isAuth = Date.now() < expiresAt;
    return isAuth;
  }

  /**
   * Starts the login process for the user
   *
   * Note:  Child classes need to have their own version of this function
   *
   * @param {string} startingUrl The URL that the browser started at before login was initiated.
   */
  login(startingUrl) {
    UserContext.setStartingUrl(startingUrl);
  }

  /**
   * Internal part of the renew token logic, called for every attempt.
   *
   * Note:  Child classes need to have their own version of this function
   */
  _renewTokenInternal() {}

  _attemptRenewToken(onComplete, onFailure, count = 0) {
    setTimeout(async () => {
      try {
        await this._renewTokenInternal();
        this.promise = null;
        onComplete();
        return;
      } catch (e) {
        Logger.error('Failed to renew auth token with error', e);
        if (e instanceof NonRecoverableAuthError) {
          onFailure();
          return;
        }
      }

      if (count < MAX_RENEW_ATTEMPTS) {
        this._attemptRenewToken(onComplete, onFailure, count + 1);
      } else {
        onFailure();
      }
    }, RENEWAL_ATTEMPT_BACKOFF * count);
  }

  /**
   * Will get a new authentication token for the user in the background for the
   * user, and will log the user out if there is an error that required them to
   * go through the normal sign-in process again.
   */
  renewToken() {
    if (this.promise) return this.promise;

    this.promise = new Promise(async (resolve, reject) => {
      this._attemptRenewToken(resolve, () => {
        this.showSessionExpiredDialog();
        this.promise = null;
        reject();
      });
    });

    return this.promise;
  }

  /**
   * Displays the sessions expired message to the user.
   */
  showSessionExpiredDialog() {
    this.handleError('Your session has expired, please log back in.');
  }

  /**
   * Error handler used for user authentication
   */
  handleError = err => {
    Logger.error(USER_AUTH_ERROR_MESSAGE, err);
    this.onErrorListeners.forEach(f => f(err));
  };

  /**
   * Checks to see if the token is still valid, and then updates it if it needs
   * to be renewed
   */
  async checkTokenValidity() {
    if (!this.isAuthenticated() && UserContext.getAccessToken()) {
      // make sure to clean up any old token renewal information
      if (this.tokenRenewalTimeout) {
        clearTimeout(this.tokenRenewalTimeout);
        this.tokenRenewalTimeout = null;
      }

      await this.renewToken();
    }
  }

  /**
   * Saves the user authentication information, and token renewal timer.
   *
   * @param {string} expiresAt Time in milliseconds when the token will expire
   * @param {string} accessToken The token used to authenticate the user
   * @param {string} idToken The token containing additional information about the user
   */
  setSession(expiresAt, accessToken, idToken) {
    UserContext.setAccessToken(accessToken);
    UserContext.setIdToken(idToken);
    UserContext.setIdTokenExpiry(expiresAt);

    // schedule a token renewal
    this.scheduleRenewal();

    // notify the token change listeners
    this.onTokenChangeListeners.forEach(l =>
      l({ token: accessToken, expiresAt })
    );
  }

  /**
   * Adds a new listener for when the token changes.
   *
   * @param {Function} listener A function that is listening for auth updates
   */
  addTokenChangeListener(listener) {
    if (listener && !this.onTokenChangeListeners.includes(listener)) {
      this.onTokenChangeListeners.push(listener);
    }
  }

  /**
   * Removes a specified listener function, or all listener functions if one is
   * not specified.
   *
   * @param {Function} listener The listener function to remove
   */
  removeTokenChangeListener(listener) {
    if (listener) {
      this.onTokenChangeListeners = this.onTokenChangeListeners.filter(
        l => l !== listener
      );
    } else {
      this.onTokenChangeListeners = [];
    }
  }

  /**
   * Sets the users profile data in local store, and on the servers if needed.
   *
   * @param {Object} profile The users profile data
   */
  setUserData(profile) {
    // set a default user icon if it was not pulled from the auth provider
    if (!profile.picture) {
      profile.picture = DEFAULT_USER_ICON;
    }

    const redirect = UserContext.getStartingUrl() || '/';
    UserContext.setStartingUrl('/');

    UserContext.setUserProfile(profile);
    const { email } = profile;

    this.client
      .query({
        query: UserQuery,
        variables: { email }
      })
      .then(res => {
        UserContext.setTheme(THEME_DEFAULT);
        if (!res.data || !res.data.userByEmail) {
          const input = {
            id: email,
            name: profile.name,
            givenName: profile.given_name,
            familyName: profile.family_name,
            email,
            picture: profile.picture,
            theme: THEME_DEFAULT
          };
          this.client
            .mutate({
              mutation: AddUserMutation,
              variables: { input },
              update: (store, { data }) => {
                const id = data.addUser;
                UserContext.setUserId(id);
                store.writeQuery({
                  query: UserProfileQuery,
                  data: { user: { __typename: 'user', ...input, id } }
                });
              }
            })
            .then(data => history.replace(redirect))
            .catch(this.handleError);
        } else {
          const { theme, id } = res.data.userByEmail;
          UserContext.setUserId(id);
          UserContext.setTheme(theme.toUpperCase());
          history.replace(redirect);
        }
      })
      .catch(this.handleError);
  }

  /**
   * Adds a check to see if the authentication token needs to renewed when the
   * browser tab goes from hidden to visible.
   */
  addVisibilityCheck() {
    document.addEventListener(
      'visibilitychange',
      this.checkTokenOnVisibilityChange
    );
  }

  /**
   * Removes the visibility check for authentication token renewal
   */
  removeVisibilityCheck() {
    document.removeEventListener(
      'visibilitychange',
      this.checkTokenOnVisibilityChange
    );
  }

  /**
   * Checks to see if the authentication token needs to be renewed when the
   * document is visibile.
   */
  checkTokenOnVisibilityChange = () => {
    if (!document.hidden) {
      this.checkTokenValidity();
    }
  };

  /**
   * Sets a timeout to renew the users authentication token
   */
  scheduleRenewal() {
    const storedData = UserContext.getIdTokenExpiry();
    if (storedData) {
      const expiresAt = parseInt(storedData, 10);
      const remainingMilliseconds = expiresAt - Date.now();
      const delay = remainingMilliseconds - RENEW_TOKEN_TIMER_OFFSET;
      if (delay > 0) {
        this.tokenRenewalTimeout = setTimeout(() => {
          this.tokenRenewalTimeout = null;
          this.renewToken();
        }, delay);
      } else {
        const remainingSeconds = Math.floor(remainingMilliseconds / 1000);

        // Display a message to the user that their session is about to expire.
        handleError(
          this.client,
          `Your Session will expire in ${remainingSeconds} seconds or less, please save your work.`
        );

        // When the session expires show the dialog saying that it has expired.
        setTimeout(() => {
          this.showSessionExpiredDialog();
        }, remainingMilliseconds);
      }
    }
  }

  /**
   * Logs the user out of the application
   */
  logout() {
    const alreadyCleared = !UserContext.getAccessToken();
    UserContext.clear();
    this.removeVisibilityCheck();

    if (this.activityCheckTimeout) {
      clearTimeout(this.activityCheckTimeout);
      this.activityCheckTimeout = null;
    } else {
      window.removeEventListener('click', this.onActivity);
      window.removeEventListener('keypress', this.onActivity);
    }

    if (this.inactivityDisplayTimeout) {
      clearTimeout(this.inactivityDisplayTimeout);
      this.inactivityDisplayTimeout = null;
    }

    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
      this.tokenRenewalTimeout = null;
    }

    // let our listeners know that the user is being logged out
    this.onLogoutListeners.forEach(f => f());

    // Only run this the first time, as it can cause a logout loop when Apollo
    // tries to rerun the queries.
    if (!alreadyCleared) {
      this.client.resetStore().catch(() => {
        // we have the catch because reset store will causes queries to be rerun,
        // and that causes 401 errors...
      });
    }
  }

  /**
   * Builds the redirect URI used by the different auth providers to return to
   * out application with the authentication information
   */
  buildRedirectURI() {
    return `${window.location.origin}/callback`;
  }

  /**
   * Starts timers to check for user activity, so that they can be auto-logged
   * out when they walk away from their computer for too long
   */
  startCheckingForActivity() {
    this.activityCheckTimeout = setTimeout(() => {
      this.activityCheckTimeout = null;
      window.addEventListener('click', this.onActivity);
      window.addEventListener('keypress', this.onActivity);
    }, CHECK_ACTIVITY_TIMER);

    this.inactivityDisplayTimeout = setTimeout(() => {
      this.inactivityDisplayTimeout = null;

      // before actually showing the dialog we should check to make sure that
      // activity is not happening in another window.  We do not bother
      // restarting this timer for displaying inactivity, as we should be able
      // to expect the active window to handle that.
      let lastActivity = UserContext.getUserActivity();
      if (
        !lastActivity ||
        Date.now() - lastActivity >= DISPLAY_INACTIVITY_TIMER
      ) {
        this.onInactivityListeners.forEach(f =>
          f(SHOW_INACTIVITY_DIALOG_TIMER)
        );
      }
    }, DISPLAY_INACTIVITY_TIMER);
  }

  /**
   * Updates the auto-logout timers when there is user activity with the
   * application.  It listens for click and keypress activity on the window
   * object to check for user activity.
   */
  onActivity = () => {
    if (INACTIVITY_TIMER > 0) {
      window.removeEventListener('click', this.onActivity);
      window.removeEventListener('keypress', this.onActivity);
      UserContext.setUserActivity(Date.now());

      if (this.inactivityDisplayTimeout) {
        clearTimeout(this.inactivityDisplayTimeout);
        this.inactivityDisplayTimeout = null;
      }

      this.startCheckingForActivity();
    }
  };
}
