import { BasicListItem } from './BasicListItem';
import { CommentListItem } from './CommentListItem';
import { KN_WORKSPACE } from 'util/defines';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { isNil } from 'lodash';

const DefaultRenderersByKindName = {
  comment: CommentListItem
};

const getItemRenderer = kindName => {
  if (!isNil(DefaultRenderersByKindName[kindName])) {
    return DefaultRenderersByKindName[kindName];
  }
  return BasicListItem;
};

/**
 * List item for an InstanceRef.
 * Delegates display to either BasicListItem or CommentListItem.
 */
export function InstanceRefListItem(props) {
  const { instanceRef, iconName, onClick } = props;

  const rootProps = {};
  if (instanceRef.url && instanceRef.kindName !== KN_WORKSPACE) {
    if (instanceRef.url.search(/https?:\/\//) === 0) {
      rootProps.component = 'a';
      rootProps.href = instanceRef.url;
      rootProps.target = '_blank';
      rootProps.rel = 'noopener';
    } else {
      rootProps.component = Link;
      rootProps.to = instanceRef.url;
    }
  } else if (onClick) {
    rootProps.onClick = () => onClick(instanceRef);
    rootProps.style = { cursor: 'pointer' };
  }

  const myProps = {
    instanceRef,
    iconName: iconName || instanceRef.kindName
  };

  const normKind = instanceRef.kindName
    ? instanceRef.kindName.toLowerCase()
    : '';

  const ItemRenderer = getItemRenderer(normKind);

  return <ItemRenderer {...myProps} {...rootProps} />;
}

InstanceRefListItem.propTypes = {
  instanceRef: PropTypes.object,
  iconName: PropTypes.string,
  onClick: PropTypes.func
};
