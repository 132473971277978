import { KN_USER, KN_WORKSPACE } from 'util/defines';

import UserContext from 'util/UserContext';
import gql from 'graphql-tag';

const UserWorkspacesFragment = gql`
  fragment UserWorkspaces on User {
    workspaces {
      id
    }
  }
`;

const UserOpenWorkspacesFragment = gql`
  fragment UserOpenWorkspaces on User {
    openWorkspaces {
      id
    }
  }
`;

export const UserRecentItemsFragment = gql`
  fragment UserRecentItems on User {
    recentItems {
      id
    }
  }
`;

export const UserFavoriteItemsFragment = gql`
  fragment UserFavoriteItems on User {
    favoriteItems {
      id
    }
  }
`;

const getUserFragmentId = () => `${KN_USER}:${UserContext.getUserId()}`;

/**
 * Removes a workspace from the list of user workspaces for the current user.
 * @param {ApolloClient} client Apollo client
 * @param {string} workspaceId ID of the workspace
 */
export function removeUserWorkspace(client, workspaceId) {
  const userFragmentId = getUserFragmentId();
  // Clean out the users workspaces
  try {
    const userWorkspaces = client.readFragment({
      id: userFragmentId,
      fragment: UserWorkspacesFragment
    });

    if (userWorkspaces) {
      userWorkspaces.workspaces = userWorkspaces.workspaces.filter(
        ws => ws.id !== workspaceId
      );
      client.writeFragment({
        id: userFragmentId,
        fragment: UserWorkspacesFragment,
        data: userWorkspaces
      });
    }
  } catch {
    // Throwing an error here just means that this information is not in the
    // cache yet, so there is no worry about trying to update it.
  }
}

/**
 * Adds a workspace to the list of user workspaces for the current user.
 * @param {ApolloClient} client Apollo client
 * @param {string} workspaceId ID of the workspace
 */
export function addUserWorkspace(client, workspaceId) {
  const userFragmentId = getUserFragmentId();
  // Add a users workspaces
  try {
    const userWorkspaces = client.readFragment({
      id: userFragmentId,
      fragment: UserWorkspacesFragment
    });

    if (userWorkspaces) {
      userWorkspaces.workspaces.push({
        id: workspaceId,
        __typename: KN_WORKSPACE
      });
      client.writeFragment({
        id: userFragmentId,
        fragment: UserWorkspacesFragment,
        data: userWorkspaces
      });
    }
  } catch {
    // Throwing an error here just means that this information is not in the
    // cache yet, so there is no worry about trying to update it.
  }
}

/**
 * Removes a workspace from the list of open workspaces for the current user.
 * @param {ApolloClient} client Apollo client
 * @param {string} workspaceId ID of the workspace
 */
export function removeOpenWorkspace(client, workspaceId) {
  const userFragmentId = getUserFragmentId();
  // Clean out the users open workspaces
  try {
    const userOpenWorkspaces = client.readFragment({
      id: userFragmentId,
      fragment: UserOpenWorkspacesFragment
    });

    if (userOpenWorkspaces) {
      userOpenWorkspaces.openWorkspaces = userOpenWorkspaces.openWorkspaces.filter(
        ws => ws.id !== workspaceId
      );
      client.writeFragment({
        id: userFragmentId,
        fragment: UserOpenWorkspacesFragment,
        data: userOpenWorkspaces
      });
    }
  } catch {
    // Throwing an error here just means that this information is not in the
    // cache yet, so there is no worry about trying to update it.
  }
}

/**
 * Adds a workspace to the list of open workspaces for the current user.
 *
 * @param {ApolloClient} client Apollo client
 * @param {string} workspaceId ID of the workspace
 */
export function addOpenWorkspace(client, workspaceId) {
  const userFragmentId = getUserFragmentId();
  // Add the Workspace to the  Users open Workspaces
  try {
    const userOpenWorkspaces = client.readFragment({
      id: userFragmentId,
      fragment: UserOpenWorkspacesFragment
    });

    if (
      userOpenWorkspaces &&
      !userOpenWorkspaces.openWorkspaces.some(w => w.id === workspaceId)
    ) {
      userOpenWorkspaces.openWorkspaces = [
        ...userOpenWorkspaces.openWorkspaces,
        {
          id: workspaceId,
          __typename: KN_WORKSPACE
        }
      ];
      client.writeFragment({
        id: userFragmentId,
        fragment: UserOpenWorkspacesFragment,
        data: userOpenWorkspaces
      });
    }
  } catch {
    // Throwing an error here just means that this information is not in the
    // cache yet, so there is no worry about trying to update it.
  }
}

/**
 * Removes an item from the list of recent items for the current user.
 * @param {ApolloClient} client Apollo client
 * @param {string} itemId ID of the item to remove
 */
export function removeRecentItem(client, itemId) {
  const userFragmentId = getUserFragmentId();
  // Clean out the users recent items
  try {
    const userRecentItems = client.readFragment({
      id: userFragmentId,
      fragment: UserRecentItemsFragment
    });

    if (userRecentItems) {
      userRecentItems.recentItems = userRecentItems.recentItems.filter(
        ri => !ri.id.endsWith(itemId)
      );
      client.writeFragment({
        id: userFragmentId,
        fragment: UserRecentItemsFragment,
        data: userRecentItems
      });
    }
  } catch {
    // Throwing an error here just means that this information is not in the
    // cache yet, so there is no worry about trying to update it.
  }
}

/**
 * Removes an item from the list of favorite items for the current user.
 * @param {ApolloClient} client Apollo client
 * @param {string} itemId ID of the item to remove
 */
export function removeFavoriteItem(client, itemId) {
  const userFragmentId = getUserFragmentId();
  // Clean out the users favorite items
  try {
    const userFavoriteItems = client.readFragment({
      id: userFragmentId,
      fragment: UserFavoriteItemsFragment
    });

    if (userFavoriteItems) {
      userFavoriteItems.favoriteItems = userFavoriteItems.favoriteItems.filter(
        fi => !fi.id.endsWith(itemId)
      );
      client.writeFragment({
        id: userFragmentId,
        fragment: UserFavoriteItemsFragment,
        data: userFavoriteItems
      });
    }
  } catch {
    // Throwing an error here just means that this information is not in the
    // cache yet, so there is no worry about trying to update it.
  }
}
