import { Description, IdText, Name, NameContext } from './StyledText';

import { Box } from '@material-ui/core';
import Icon from 'components/Icon';
import React from 'react';
import { SearchResultType } from './propTypes';
import styled from 'styled-components';

const Root = styled(Box)`
  width: 275px;
  padding: ${p => p.theme.spacing()}px;
  border-left: 1px solid ${p => p.theme.palette.divider};
  display: flex;
  flex-direction: column;
  word-break: break-word;
  overflow: auto;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function Details({ item }) {
  if (!item) return null;

  const {
    name,
    nameSections,
    nameContext,
    id,
    idSections,
    kindName,
    description,
    descriptionSections
  } = item;

  return (
    <Root>
      <NameContainer>
        <Icon kindName={kindName} />
        <Name text={name} sections={nameSections} />
      </NameContainer>
      {nameContext && <NameContext text={nameContext} />}
      <IdText text={id} sections={idSections} />
      {description && (
        <Description text={description} sections={descriptionSections} />
      )}
    </Root>
  );
}

Details.propTypes = {
  item: SearchResultType
};
