import { useCallback, useState } from 'react';

/**
 * Custom hook to handle a menu.
 *
 * @param {function} param.menuCloseCallback Called when the menu is closed.
 * Useful for doing things like hiding the menu button. It gets passed the
 * DOM event triggering the menu to close, if one exists.
 * @returns {Object} menuProps object, onMenuOpen & onMenuClose event handlers
 */
export function useMenu({ menuCloseCallback } = {}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const onMenuOpen = useCallback(
    event => {
      event.stopPropagation(); //prevent selection
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const onMenuClose = useCallback(
    event => {
      event && event.stopPropagation(); //prevent selection
      setAnchorEl(null);
      menuCloseCallback && menuCloseCallback(event);
    },
    [setAnchorEl, menuCloseCallback]
  );

  const menuProps = {
    anchorEl,
    open: isOpen,
    onClose: onMenuClose,
    keepMounted: true,
    getContentAnchorEl: null,
    anchorReference: 'anchorEl',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  };

  return { menuProps, isOpen, onMenuOpen, onMenuClose };
}
