import {
  KN_ASSISTANT,
  KN_FUNCTION,
  KN_KIND,
  KN_SERVICE,
  KN_WORKSPACE
} from 'util/defines';
import { useCallback, useEffect, useState } from 'react';

import UserContext from 'util/UserContext';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const SEARCH_LOAD_LIMIT = 50;

const SearchQuery = gql`
  query Search($text: String!, $scope: Scope!, $userId: ID!) {
    search(term: $text, scopes: [$scope], userId: $userId) {
      kinds {
        id
        name
        description
        service {
          id
          name
        }
      }
      functions {
        id
        name
        description
        service {
          id
          name
        }
      }
      services {
        id
        name
        description
      }
      assistants {
        id
        name
        description
      }
      workspaces {
        id
        name
      }
    }
  }
`;

function normalizeResults(results, category) {
  switch (category) {
    case KN_KIND:
      return (
        results.kinds?.map(k => ({
          id: k.id,
          name: k.name,
          description: k.description,
          nameContext: k.service?.name,
          kindName: KN_KIND
        })) ?? []
      );
    case KN_FUNCTION:
      return (
        results.functions?.map(f => ({
          id: f.id,
          name: f.name,
          description: f.description,
          nameContext: f.service?.name,
          kindName: KN_FUNCTION
        })) ?? []
      );
    case KN_SERVICE:
      return results.services?.map(s => ({ ...s, kindName: KN_SERVICE })) ?? [];
    case KN_ASSISTANT:
      return (
        results.assistants?.map(a => ({ ...a, kindName: KN_SERVICE })) ?? []
      );
    case KN_WORKSPACE:
      return (
        results.workspaces?.map(w => ({ ...w, kindName: KN_WORKSPACE })) ?? []
      );
    default:
      return [];
  }
}

function findSections(searchTerm, target) {
  if (!searchTerm || !target) return [];

  let startIndex = 0;
  const targetLC = target.toLowerCase();
  const sections = [];

  for (const char of searchTerm) {
    const index = targetLC.indexOf(char, startIndex);
    if (index === -1) return [];

    if (startIndex !== index) {
      sections.push({
        text: target.substring(startIndex, index),
        isHighlighted: false
      });
    }
    startIndex = index + 1;
    sections.push({
      text: target.substring(index, startIndex),
      isHighlighted: true
    });
  }

  if (startIndex < target.length) {
    sections.push({ text: target.substring(startIndex), isHighlighted: false });
  }

  return sections;
}

function processResults(results, category, searchTerm) {
  const normRes = normalizeResults(results, category);

  return normRes.map(res => {
    return {
      ...res,
      idSections: findSections(searchTerm, res.id),
      nameSections: findSections(searchTerm, res.name),
      descriptionSections: findSections(searchTerm, res.description)
    };
  });
}

/**
 * Prepares the search term for sending to the search server.
 *
 * The big item here is that we need to escape the '\' character as the search
 * service's libraries try to use it as an escape character.
 *
 * @param {string} term The term to prepare
 * @returns {string} The prepared term
 */
function prepareTermForSending(term) {
  return term?.replace(/\\/g, '\\\\');
}

/**
 * Searches for a specific term in the chosen category and prepares the results
 * for display.
 *
 * @param {string} term The term to search for
 * @param {string} category The category to search in.
 * @param {boolean} isVisible If the search results are currently visible.
 */
export default function useSearchQuery(term, category, isVisible) {
  const [loading, setLoading] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [results, setResults] = useState([]);
  const trimmedTerm = term.trim();

  // Prepare to load new results when the search term or category change
  useEffect(() => {
    setHasMoreResults(false);
    setResults([]);
    setLoading(!!trimmedTerm && isVisible);
  }, [trimmedTerm, category, isVisible]);

  // Query for the search results
  const { error, loading: queryLoading, data, fetchMore } = useQuery(
    SearchQuery,
    {
      skip: !trimmedTerm || !isVisible,
      variables: {
        text: prepareTermForSending(trimmedTerm),
        scope: `${category}s`,
        userId: UserContext.getUserId(),
        take: SEARCH_LOAD_LIMIT,
        offset: 0
      },
      fetchPolicy: 'network-only'
    }
  );

  // Grab out the search results, so that hook dependency arrays can contain
  // only the information that is needed.
  const searchData = data?.search;

  // Update the state based off of the current search results
  useEffect(() => {
    if (queryLoading) return;

    setLoading(false);

    if (isVisible) {
      // TODO: QP-1322 - Update to be able to load more once the search service
      // is setup to do paging.  Use (data?.search?.length ?? 0) % SEARCH_LOAD_LIMIT === 0
      setHasMoreResults(false);
      setResults(
        processResults(searchData ?? [], category, trimmedTerm.toLowerCase())
      );
    } else {
      setHasMoreResults(false);
      setResults([]);
    }
  }, [category, searchData, error, queryLoading, trimmedTerm, isVisible]);

  // Load additional results when needed for displaying to the user
  const loadMoreResults = useCallback(() => {
    if (loading || !hasMoreResults) return;
    setLoading(true);

    fetchMore({
      variables: {
        text: prepareTermForSending(trimmedTerm),
        scope: `${category}s`,
        take: SEARCH_LOAD_LIMIT,
        offset: searchData?.length ?? 0
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          search: [...prev.search, ...fetchMoreResult.search]
        });
      }
    });
  }, [category, searchData, fetchMore, hasMoreResults, loading, trimmedTerm]);

  return {
    loading,
    error,
    results,
    loadMoreResults
  };
}
