import { CurrentUserLockIcon, LockIcon, UnlockIcon } from 'components/Icon';

import { LockedByType } from './defines';
import UserContext from './UserContext';
import gql from 'graphql-tag';
import { handleError } from './snackUtils';
import { isBoolean } from 'lodash';

const PortalGraphLockedByQuery = gql`
  query PortalGraphLockedByQuery($id: ID!) {
    portalGraph(id: $id) {
      id
      lockedBy
    }
  }
`;

const PortalGraphLockMutation = gql`
  mutation PortalGraphLockMutation($input: UpdateGraphInput!) {
    updateGraph(input: $input) {
      updatedGraph {
        id
        lockedBy
      }
    }
  }
`;

const WorkspaceLockedByQuery = gql`
  query WorkspaceLockedByQuery($id: ID!) {
    workspace(id: $id) {
      id
      lockedBy
    }
  }
`;

const WorkspaceLockMutation = gql`
  mutation WorkspaceLockMutation($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      updatedWorkspace {
        id
        lockedBy
      }
    }
  }
`;

/**
 * Returns true if the current user can edit a locked entity.
 *
 * @param {string} userEmail The current user's email
 * @param {string} lockedBy The email of the user who locked the entity.
 */
function canEditLockedEntity(userEmail, lockedBy) {
  return !lockedBy || userEmail === lockedBy;
}

/**
 * Gets the locked state of the passed in entity.
 *
 * @param {Object} [entity] The entity to check the locked state of.
 * @param {string} [lockedBy] The e-mail of the person who locked the entity.
 */
export function getLockedState(entity) {
  const lockedBy = entity?.lockedBy;
  const { email } = UserContext.getUserProfile();
  const canEdit = canEditLockedEntity(email, lockedBy);
  const updatedLockedBy = lockedBy ? null : email;

  let Icon;
  if (lockedBy) {
    if (canEdit) {
      Icon = CurrentUserLockIcon;
    } else {
      Icon = LockIcon;
    }
  } else {
    Icon = UnlockIcon;
  }

  return { lockedBy, canEdit, email, updatedLockedBy, Icon };
}

/**
 * Builds the input for a mutation that will update the locked state of a entity.
 *
 * @param {string} id Id of the entity to build the input for.
 * @param {string} updatedLockedBy The new locked value.
 * @param {string} lockedByType The type of entity for the lock to be updated on.
 * @param {string} activeWorkspaceId ID of the current workspace.
 */
export function getLockMutationInput(
  id,
  updatedLockedBy,
  lockedByType,
  activeWorkspaceId
) {
  if (lockedByType === LockedByType.GRAPH) {
    return {
      graphId: id,
      workspaceId: activeWorkspaceId,
      lockedBy: updatedLockedBy
    };
  } else {
    return {
      id,
      lockedBy: updatedLockedBy
    };
  }
}

/**
 * Gets important information for query and updating lock information for a
 * Graph or Workspace.
 *
 * @param {string} graphId ID of the Graph to get the data for.
 * @param {string} workspaceId ID of the Workspace to get the data for.
 */
export function getLockQueryMutationData(graphId, workspaceId) {
  const lockedByType =
    graphId !== undefined ? LockedByType.GRAPH : LockedByType.WORKSPACE;

  // Determine conditional properties based on values passed in to parameters
  let id, query, mutation, errorMessage;
  if (lockedByType === LockedByType.GRAPH) {
    id = graphId;
    query = PortalGraphLockedByQuery;
    mutation = PortalGraphLockMutation;
    errorMessage = 'Error locking Graph';
  } else {
    id = workspaceId;
    query = WorkspaceLockedByQuery;
    mutation = WorkspaceLockMutation;
    errorMessage = 'Error locking Workspace';
  }

  return {
    lockedByType,
    id,
    query,
    mutation,
    errorMessage
  };
}

/**
 * Gest the user who locked a portal Graph or Workspace, whether the current
 * user can edit the Graph or Workspace, and provides a function for updating
 * the locked by user.
 *
 * @param {Object} ids The IDs object. Only one ID should be specified.
 * @param {string} ids.graphId The ID of the graph.
 * @param {string} ids.workspaceId The ID of the Workspace.
 * @param {Object} client The ApolloClient used to load information.
 * @param {Object} options Optional parameters.
 * @param {Object} options.queryOptions Apollo query option object
 * that is passed to the query.
 * @param {Object} options.mutationOptions Apollo mutation option object
 * that is passed to the mutation.
 * @returns {Promise<Object>} An object containing the following properties:
 *    {string} lockedBy The user locking the graph or Workspace
 *    {boolean} canEdit True if the current user can edit the graph or Workspace
 *    {function} setLockedBy A function to call that sets or toggles the locked state
 *    of the graph or Workspace between unlocked and locked by the current user.
 *    {Object} Icon Returns the appropriate lock icon to display.
 */
async function getLockedBy({ graphId, workspaceId }, client, options = {}) {
  const { queryOptions = {}, mutationOptions = {} } = options;

  const {
    lockedByType,
    id,
    query,
    mutation,
    errorMessage
  } = getLockQueryMutationData(graphId, workspaceId);

  const lockedByData = await client.query({
    query,
    variables: { id },
    skip: !id,
    fetchPolicy: 'cache-only',
    ...queryOptions
  });

  const { canEdit, lockedBy, updatedLockedBy, Icon, email } = getLockedState(
    lockedByData?.data?.portalGraph ?? lockedByData?.data?.workspace
  );

  return {
    lockedBy,
    canEdit,
    setLockedBy: value => {
      let lockedBy = updatedLockedBy;
      if (isBoolean(value)) {
        lockedBy = value ? email : null;
      }
      return client.mutate({
        mutation,
        variables: {
          input: getLockMutationInput(id, lockedBy, lockedByType, workspaceId)
        },
        onError: error => {
          handleError(client, errorMessage, error);
        },
        ...mutationOptions
      });
    },
    Icon
  };
}

/**
 * Merged the results of calling (use/get)LockedBy on a Graph and Workspace to
 * make sure that the results show the correct state of being able to edit a
 * graph.
 *
 * @param {Object} graphLockData Results of calling (use/get)LockedBy on a Graph.
 * @param {Object} workspaceLockData Retults of calling (use/get)LockedBy on a Workspace.
 */
export function mergeLockData(graphLockData, workspaceLockData) {
  let canEdit = graphLockData.canEdit;
  let lockedBy = graphLockData.lockedBy;
  let Icon = graphLockData.Icon;

  // If the Workspace is locked, then it supersedes the lock on the Graph for
  // being able to edit it.
  if (workspaceLockData.lockedBy) {
    canEdit = workspaceLockData.canEdit;
    lockedBy = workspaceLockData.lockedBy;
    Icon = workspaceLockData.Icon;
  }

  return {
    ...graphLockData,
    canEdit,
    lockedBy,
    Icon,
    canEditGraph: graphLockData.canEdit,
    graphLockedBy: graphLockData.lockedBy,
    GraphIcon: graphLockData.Icon,
    canEditWorkspace: workspaceLockData.canEdit,
    workspaceLockedBy: workspaceLockData.lockedBy
  };
}

/**
 * Gets the user who locked a portal graph, whether the current user can edit
 * the graph, and provides a function for updating the locked by user. This also
 * checks to see if the graph should act as locked because the workspace is
 * locked by another user.
 *
 * @param {string} graphId The ID of the graph.
 * @param {string} workspaceId The ID of the Workspace.
 * @param {Object} client The ApolloClient used to load information.
 * @param {Object} options Optional parameters.
 * @param {Object} options.queryOptions Apollo query option object
 * that is passed to the query.
 * @param {Object} options.mutationOptions Apollo mutation option object
 * that is passed to the mutation.
 * @returns {Promise<Object>} An object containing the following properties:
 *    {string} lockedBy The user locking the graph
 *    {boolean} canEdit True if the current user can edit the graph
 *    {function} setLockedBy A function to call that sets or toggles the locked state
 *    of the graph between unlocked and locked by the current user.
 *    {Object} Icon Returns the appropriate lock icon to display.
 */
export async function getGraphLockedBy(
  graphId,
  workspaceId,
  client,
  options = {}
) {
  const graphLockData = await getLockedBy(
    { graphId, workspaceId },
    client,
    options
  );
  const workspaceLockData = await getLockedBy(
    { workspaceId },
    client,
    (options = {})
  );
  return mergeLockData(graphLockData, workspaceLockData);
}

/**
 * Returns information about the current locked state of the workspace.
 *
 * @param {string} workspaceId The ID of the Workspace.
 * @param {Object} client The ApolloClient used to load information.
 * @param {Object} options Optional parameters.
 * @param {Object} options.queryOptions Apollo query option object
 * that is passed to the query.
 * @param {Object} options.mutationOptions Apollo mutation option object
 * that is passed to the mutation.
 * @returns {Promise<Object>} An object containing the following properties:
 *    {string} lockedBy The user locking the Workspace
 *    {boolean} canEdit True if the current user can edit the Workspace
 *    {function} setLockedBy A function to call that sets or toggles the locked state
 *    of the Workspace between unlocked and locked by the current user.
 *    {Object} Icon Returns the appropriate lock icon to display.
 */
export async function getWorkspaceLockedBy(workspaceId, client, options = {}) {
  return await getLockedBy({ workspaceId }, client, options);
}
