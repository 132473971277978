import { ApolloLink, execute, makePromise } from 'apollo-link';
import {
  FieldTypeToGraphQLType,
  FunctionType,
  KN_FUNCTION_RESULTS
} from 'util/defines';
import {
  convertStringToFieldType,
  convertValueToListFieldType,
  isEphemeralField,
  isListField,
  isRequiredField
} from 'util/instanceUtils';

import AssistantAPI from 'util/assistantAPI';
import { FunctionResultsQuery } from 'graphql/Portal';
import _ from 'lodash';
import { createHttpLink } from 'apollo-link-http';
import gql from 'graphql-tag';
import { setContext } from 'apollo-link-context';

const MaxResolveDepth = 4;

export const FunctionExecutionQuery = gql`
  query FunctionExecution($id: ID!) {
    functionExecution(id: $id) @client {
      id
      arguments {
        id
        value
      }
    }
  }
`;

/**
 * Builds the ID for a function execution argument.
 * @param {string} parentId The ID of the arguments parent
 * @param {string} argumentId The base ID of the argument
 * @return {string} The full ID of the argument
 */
export function buildArgumentId(parentId, argumentId) {
  return `${parentId}|${argumentId}`;
}

/**
 * Builds an object from the values saved in the store for each of the fields in
 * a schema. Null is returned if nothing is set.
 *
 * @param {Object} schema The schema to build the object for.
 * @param {Array<Object>} kinds The list of Kinds that are part of this Function.
 * @param {String} parentId The ID of the parent Field. This is used to construct the ID for the Field values saved in the store.
 * @param {Object} args The list of arguments used to build the object.
 */
function buildKindObject(schema, kinds, parentId, args) {
  const kindObject = {};
  schema.forEach(field => {
    let value;
    const argId = buildArgumentId(parentId, field.id);
    const arg = args.find(a => a.id === argId);
    if (field.kind) {
      // Only continue building the object if there are arguments for the fields
      // of this Kind and it is set to be sent
      if (arg && arg.value && args.some(a => a.id.startsWith(argId))) {
        const kind = kinds.find(k => k.id === field.kind.id);
        value = buildKindObject(kind.schema, kinds, argId, args);
      }
    } else {
      if (isListField(field.modifiers)) {
        value = arg ? convertValueToListFieldType(arg.value, field.type) : null;
      } else {
        value = arg ? convertStringToFieldType(arg.value, field.type) : null;
      }

      if (arg && !_.isNil(arg.value) && _.isNil(value)) {
        throw new Error(
          `Failed to parse argument ${field.name}:  can't convert '${arg.value}' to ${field.type}`
        );
      }
    }

    if (!_.isNil(value) && !_.isNaN(value)) {
      kindObject[field.name] = value;
    }
  });

  return Object.keys(kindObject).length ? kindObject : null;
}

/**
 * Builds an Apollo Link object pointing to the given service
 *
 * @param {string} serviceId The ID of the service we need a link to
 */
function buildLink(serviceId) {
  const httpLink = createHttpLink({
    uri: `${window.MAANA_ENV.PUBLIC_BACKEND_URI}/service/${serviceId}/graphql`
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('access_token');

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  return ApolloLink.from([authLink, httpLink]);
}

/**
 * Builds up a string that can be use to resolve the kind as a graphql type
 * when executing a function.
 *
 * @param {string} kindId Id of the kind we are working with
 * @param {Array<Object>} knownKinds List if the kinds we know about
 * @param {int} currentDepth The current depth we are in the resolver
 */
function buildResolveString(kindId, knownKinds, currentDepth = 0) {
  // make sure we don't get into an endless loop
  if (currentDepth > MaxResolveDepth) return '';

  // get the kind and make sure it exists
  const kind = knownKinds.find(k => k.id === kindId);
  if (!kind) return '';

  // build the results
  const results = kind.schema
    .filter(f => !isEphemeralField(f.modifiers))
    .map(field => {
      if (field.kind) {
        // for kind types we need to build their resolve strings
        const subKindResolveString = buildResolveString(
          field.kind.id,
          knownKinds,
          currentDepth + 1
        ).trim();

        // make sure we received a string with data in it
        if (subKindResolveString) {
          return `${field.name} ${subKindResolveString}`;
        } else {
          return '';
        }
      } else {
        return field.name;
      }
    })
    .filter(r => !!r);

  return results.length ? `{ ${results.join('\n')} }` : '';
}

/**
 * Build a graphql query object for the given function and variables that will
 * be passed along.
 *
 * @param {Object} variables The variables we are sending to the query
 * @param {Object} func The Function we are going to be querying
 * @param {string} resolve The resolve string for the query
 */
function buildGraphQLQuery(variables, func, resolve) {
  // type names are a bit different for CKG functions
  const isCKGFunction = func.functionType === FunctionType.CKG;

  // build up the dynamic parameters based on what was passed in
  const queryParams = [];
  const callParams = [];
  if (variables) {
    Object.keys(variables).forEach(fieldName => {
      // get the argument that we are connected to
      const arg = func.arguments.find(arg => arg.name === fieldName);
      if (!arg) return null;

      // get our modifiers
      const isList = arg.modifiers && isListField(arg.modifiers);
      const isNoNull = arg.modifiers && isRequiredField(arg.modifiers);
      const typeName = arg.kind
        ? `${arg.kind.name}${isCKGFunction ? 'AsInput' : ''}`
        : FieldTypeToGraphQLType[arg.type];

      // build the final string
      queryParams.push(
        `$${fieldName}: ${isList ? '[' : ''}${typeName}${
          isList && isNoNull ? '!' : ''
        }${isList ? ']' : ''}${isNoNull ? '!' : ''}`
      );
      callParams.push(`${fieldName}: $${fieldName}`);
    });
  }

  // build the query string
  const queryString = `
    ${func.graphqlOperationType.toLowerCase()}
    ${queryParams.length ? `(${queryParams.join()})` : ''}
    {
      ${func.name}
      ${callParams.length ? `(${callParams.join()})` : ''}
      ${resolve ? resolve : ''}
    }
  `;

  // create the query
  return gql(queryString);
}

/**
 * Executes the function with information in the cache with the given inputs
 *
 * @param {ApolloClient} client The ApolloClient to store the results in
 * @param {Object} func The function to execute
 * @param {Array<Object>} kinds The list of kinds involved with the execution of this function
 * @param {string} graphNodeId The id of the graph node that represents the function
 * @param {boolean} clearCache If true, clear cache prior to running the function
 */
export async function buildAndExecuteFunction(
  client,
  func,
  kinds,
  graphNodeId,
  clearCache
) {
  // Clear cache if requested
  if (func && func.service && clearCache) {
    try {
      await executeGraphql(func.service.id, 'mutation { clearCache }');
    } catch {
      // Ignore error as the clearCache mutation may not exist
    }
  }

  const { functionExecution } = client.readQuery({
    query: FunctionExecutionQuery,
    variables: {
      id: graphNodeId
    }
  });

  const variables =
    buildKindObject(
      func.arguments,
      kinds,
      graphNodeId,
      functionExecution.arguments
    ) || {};

  if (graphNodeId && func) {
    // Build the string that will tell the query what to resolve
    const resolve = func.kind ? buildResolveString(func.kind.id, kinds) : '';

    // run the function execution
    const result = await executeFunction(func, variables, resolve);

    // save the results
    client.writeQuery({
      query: FunctionResultsQuery,
      variables: { id: graphNodeId },
      data: {
        functionResults: {
          results: result,
          __typename: KN_FUNCTION_RESULTS
        }
      }
    });
  }
}

/**
 * Executes the a function with the given inputs
 *
 * @param {Object} func The function to execute
 * @param {Object} variables The variables to pass with the execution
 * @param {string} resolve The fields to resolve on the result of the query
 * @return {Promise<Object>} The result from the execute query.
 */
export async function executeFunction(func, variables, resolve) {
  const link = buildLink(func.service.id);

  // create the query
  const query = buildGraphQLQuery(variables, func, resolve);

  // execute the query
  const result = await makePromise(execute(link, { query, variables })).catch(
    exception => {
      // If it is an exception that has a GraphQL result, return the result
      if (exception.result) {
        // Because the 3rd party component we're using to render the json has an open issue when there is a length property
        // (https://github.com/mac-s-g/react-json-view/issues/252), the results need to be wrapped in something else.
        return !Array.isArray(exception.result) && exception.result.length
          ? { errors: [exception.result] }
          : exception.result;
      }
      // Otherwise throw the exception so it can be handled a catch.
      throw exception;
    }
  );

  // AssistantAPI will decide if assistant needs to be notified.
  AssistantAPI.functionExecuted(func.id, result);

  return result;
}

/**
 * Executes an arbitrary graphql string against a service endpoint.
 *
 * @param {string} serviceId The service against which the query is executed.
 * @param {string} query A well-formed graphql query string.
 * @param {Object} variables The variables to pass with the execution
 * @return {Promise<Obect>} The result from the executed query.
 */
export function executeGraphql(serviceId, query, variables) {
  const link = buildLink(serviceId);

  return makePromise(execute(link, { query: gql(query), variables }));
}
