/* eslint-disable flowtype/require-valid-file-annotation */

import {
  amber,
  blue,
  brown,
  deepOrange,
  green,
  grey,
  lightGreen,
  lime,
  pink,
  red,
  yellow
} from '@material-ui/core/colors';

import UserContext from 'util/UserContext';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { fade } from '@material-ui/core/styles/colorManipulator';

const backgrounds = {
  dark: {
    default: '#303030',
    paper: '#404040',
    hover: '#404040',
    focus: '#494949',
    selected: '#072239'
  },
  light: {
    default: '#f1f1f1',
    paper: grey[200],
    hover: '#E0E0E0',
    focus: '#D0D0D0',
    selected: '#D4E4F2'
  }
};

const gradients = {
  dark: {
    tilebar: `linear-gradient(to bottom, ${fade(
      backgrounds.dark.paper,
      0.65
    )} 0%, ${backgrounds.dark.default} 100%)`
  },
  light: {
    tilebar: `linear-gradient(to bottom, ${fade(
      backgrounds.light.paper,
      0.65
    )} 0%, ${backgrounds.light.default} 100%)`
  }
};

const themes = {
  dark: {
    palette: {
      type: 'dark',
      primary: blue,
      secondary: deepOrange,
      error: {
        light: '#E4AAAA',
        main: red[500],
        dark: '#721111',
        contrastText: grey[50]
      },
      text: {
        primary: grey[50],
        secondary: grey[200],
        disabled: grey[500],
        hint: grey[500]
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      action: {
        active: grey[100],
        hoverOpacity: 0.21,
        disabled: grey[700],
        disabledBackground: grey[500]
      },
      background: {
        default: backgrounds.dark.default,
        paper: backgrounds.dark.paper
      }
    },
    typography: {
      fontFamily: 'PT Sans, display',
      htmlFontSize: 16
    },
    overrides: {
      MuiAppBar: {
        colorDefault: { backgroundColor: '#000' }
      },
      MuiCardActions: {
        root: { margin: 0, padding: '8px 12px' }
      },
      MuiChip: { root: { margin: 8 } },
      MuiFormControlLabel: { root: { marginLeft: '-8px' } },
      MuiFormHelperText: { root: { whiteSpace: 'normal' } },
      MuiGridListTileBar: {
        root: { background: gradients.dark.tilebar },
        titleWrap: { color: grey[50] }
      },
      MuiIconButton: { root: { margin: '0' } },
      MuiInputBase: { input: { height: 'auto' } },
      MuiListItemIcon: {
        root: { minWidth: 0, margin: 0, alignItems: 'center' }
      },
      MUIRichTextEditor: {
        editorContainer: {
          border: '1px solid rgba(255, 255, 255, 0.12)',
          borderRadius: 4,
          margin: 0,
          padding: '0px 8px',
          height: 300,
          width: 800,
          overflow: 'auto'
        }
      },
      MuiTabs: { scrollButtons: { color: grey[100] } }
    },
    custom: {
      breadcrumbs: {
        moreText: grey[400]
      },
      canvas: {
        default: {
          canvasBackground: backgrounds.dark.paper,
          canvasGrid: fade(grey[50], 0.05),
          nodeBorder: grey[900],
          nodeBackground: fade(brown[600], 0.3),
          nodeHeaderBackground: fade(grey[900], 0.3),
          nodePortBarBackground: fade(grey[900], 0.15),
          rowBackground: fade(grey[900], 0),
          rowBackgroundId: fade(grey[900], 0.15),
          rowBackgroundLink: fade(grey[900], 0.3),
          linkDefault: grey[500],
          linkJoin: blue[700],
          linkMapping: amber[900],
          portBackground: fade(grey[100], 0.1),
          portHover: amber[900],
          selected: '#00c0ff'
        },
        annotation: {
          nodeBackground: fade(pink[400], 0.3)
        },
        document: {
          nodeBackground: fade(lime[800], 0.3)
        },
        kind: {
          nodeBackground: fade(blue[700], 0.3)
        },
        file: {
          nodeBackground: fade(lime[800], 0.3)
        },
        function: {
          nodeBackground: fade(green[700], 0.3)
        },
        functionInput: {
          nodeBackground: fade(amber[600], 0.3)
        },
        functionOutput: {
          nodeBackground: fade(red[600], 0.3)
        },
        image: {
          nodeBackground: fade(brown[600], 0.3)
        },
        operation: {
          nodeBackground: fade(lightGreen[700], 0.3)
        },
        service: {
          nodeBackground: fade(grey[600], 0.3)
        }
      },
      codeMirror: { theme: 'material' },
      dialog: { overlay: 'rgba(0, 0, 0, 0.15)' },
      dragging: {
        validTarget: 'lightblue',
        invalidTarget: '#e6a8ad',
        handleIcon: grey[300],
        handleHoverBackground: 'rgba(0, 0, 0, 0.1)',
        handleHover: '#0b6fcc'
      },
      iconOverlay: { color: amber[300] },
      jsonViewer: { background: '#000', theme: 'bright' },
      kindBrowser: {
        chipBackground: backgrounds.dark.paper,
        tileHoverShadow: `0px 6px 6px -3px ${fade(
          blue[700],
          0.2
        )},0px 10px 14px 1px ${fade(blue[700], 0.14)},0px 4px 18px 3px ${fade(
          blue[700],
          0.12
        )}`
      },
      kindList: {
        icon: grey[500],
        iconHover: grey[50],
        hoverBackground: grey[700]
      },
      menu: {
        itemHover: grey[700]
      },
      panel: {
        headerBackground: '#242424',
        headerZIndex: 900
      },
      scrollbar: {
        background: fade(grey[100], 0.05),
        track: fade(grey[100], 0.12),
        thumb: fade(grey[100], 0.25),
        thumbHover: fade(grey[100], 0.5)
      },
      search: {
        categorySelectedBackground: backgrounds.dark.focus,
        categoryHoverBackground: backgrounds.dark.hover,
        optionFocusBackground: backgrounds.dark.hover
      },
      snack: {
        background: grey[100],
        closeIcon: grey[900]
      },
      spinner: { color: grey[300] },
      splitPane: {
        resizerColor: '#000',
        resizerHover: fade(grey[600], 0.5)
      },
      tabBar: {
        background: backgrounds.dark.default,
        zIndex: 1000
      },
      toggle: {
        backgroundOn: fade(grey[400], 0.3),
        backgroundOff: 'transparent'
      },
      treeView: {
        hoverBackground: backgrounds.dark.hover,
        focusBackground: backgrounds.dark.focus,
        selectedBackground: backgrounds.dark.selected
      },
      warning: { background: yellow['A700'] }
    }
  },
  light: {
    palette: {
      type: 'light',
      primary: { main: blue[700] },
      secondary: deepOrange,
      error: {
        light: '#E4AAAA',
        main: red[500],
        dark: '#721111',
        contrastText: grey[50]
      },
      text: {
        primary: '#000',
        secondary: grey[800],
        disabled: grey[600],
        hint: grey[600]
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      action: {
        active: grey[900],
        hoverOpacity: 0.17,
        disabled: grey[400],
        disabledBackground: grey[700]
      },
      background: {
        default: backgrounds.light.default,
        paper: backgrounds.light.paper
      }
    },
    typography: {
      fontFamily: 'PT Sans, display',
      htmlFontSize: 16
    },
    overrides: {
      MuiAppBar: {
        colorDefault: { backgroundColor: grey[300] }
      },
      MuiCardActions: {
        root: { margin: 0, padding: '8px 12px' }
      },
      MuiChip: { root: { margin: 8 } },
      MuiFormHelperText: { root: { whiteSpace: 'normal' } },
      MuiGridListTileBar: {
        root: { background: gradients.light.tilebar },
        titleWrap: { color: grey[900] }
      },
      MuiIconButton: { root: { margin: 0 } },
      MuiInputBase: { input: { height: 'auto' } },
      MuiListItemIcon: {
        root: { minWidth: 0, margin: 0, alignItems: 'center' }
      },
      MUIRichTextEditor: {
        editorContainer: {
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 4,
          margin: 0,
          padding: '0px 8px',
          height: 300,
          width: 800,
          overflow: 'auto'
        }
      },
      MuiTabs: { scrollButtons: { color: grey[900] } }
    },
    custom: {
      breadcrumbs: {
        moreText: grey[600]
      },
      canvas: {
        default: {
          canvasBackground: grey[200],
          canvasGrid: fade('#000', 0.05),
          nodeBorder: grey[700],
          nodeBackground: fade(brown[400], 0.3),
          nodeHeaderBackground: fade(grey[700], 0.2),
          nodePortBarBackground: fade(grey[700], 0.1),
          rowBackground: fade(grey[700], 0),
          rowBackgroundId: fade(grey[700], 0.1),
          rowBackgroundLink: fade(grey[700], 0.2),
          linkDefault: grey[400],
          linkJoin: blue[600],
          linkMapping: amber[700],
          portBackground: fade(grey[700], 0.1),
          portHover: amber[700],
          selected: '#00c0ff'
        },
        annotation: {
          nodeBackground: fade(pink[100], 0.3)
        },
        document: {
          nodeBackground: fade(lime[400], 0.3)
        },
        kind: {
          nodeBackground: fade(blue[400], 0.3)
        },
        file: {
          nodeBackground: fade(lime[400], 0.3)
        },
        function: {
          nodeBackground: fade(green[400], 0.3)
        },
        functionInput: {
          nodeBackground: fade(lime[400], 0.3)
        },
        functionOutput: {
          nodeBackground: fade(red[400], 0.3)
        },
        image: {
          nodeBackground: fade(brown[400], 0.3)
        },
        operation: {
          nodeBackground: fade(green[400], 0.3)
        },
        service: {
          nodeBackground: fade(grey[400], 0.3)
        }
      },
      codeMirror: { theme: 'default' },
      dialog: { overlay: 'rgba(255, 255, 255, 0.15)' },
      dragging: {
        validTarget: 'lightblue',
        invalidTarget: '#e6a8ad',
        handleIcon: grey[600],
        handleHoverBackground: 'rgba(0, 0, 0, 0.1)',
        handleHover: '#0b6fcc'
      },
      iconOverlay: { color: amber[900] },
      jsonViewer: { background: '#fff', theme: 'bright:inverted' },
      kindBrowser: {
        chipBackground: backgrounds.light.paper,
        tileHoverShadow: `0px 6px 6px -3px ${fade(
          blue[400],
          0.2
        )},0px 10px 14px 1px ${fade(blue[400], 0.14)},0px 4px 18px 3px ${fade(
          blue[400],
          0.12
        )}`
      },
      kindList: {
        icon: grey[600],
        iconHover: grey[900],
        hoverBackground: grey[300]
      },
      menu: {
        itemHover: grey[300]
      },
      panel: {
        headerBackground: grey[300],
        headerZIndex: 900
      },
      scrollbar: {
        background: fade(grey[900], 0.05),
        track: fade(grey[900], 0.12),
        thumb: fade(grey[900], 0.25),
        thumbHover: fade(grey[900], 0.5)
      },
      search: {
        categorySelectedBackground: backgrounds.light.focus,
        categoryHoverBackground: backgrounds.light.hover,
        optionFocusBackground: backgrounds.light.hover
      },
      snack: {
        background: grey[100],
        closeIcon: grey[900]
      },
      spinner: { color: grey[700] },
      splitPane: {
        resizerColor: grey[800],
        resizerHover: fade(grey[500], 0.5)
      },
      tabBar: {
        background: grey[200],
        zIndex: 1000
      },
      toggle: {
        backgroundOn: fade(grey[700], 0.3),
        backgroundOff: 'transparent'
      },
      treeView: {
        hoverBackground: backgrounds.light.hover,
        focusBackground: backgrounds.light.focus,
        selectedBackground: backgrounds.light.selected
      },
      warning: { background: yellow['A200'] }
    }
  }
};

export const sheetsManager = new Map();

export default function createContext() {
  // Get theme and ensure lower case because that's how its defined in the palette
  const theme = createMuiTheme(themes[UserContext.getTheme().toLowerCase()]);
  return {
    theme
  };
}
