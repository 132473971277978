import {
  AssistantContext,
  GraphContext,
  WorkspaceContext
} from 'components/context';
import { FieldNames, GraphType } from 'util/defines';
import React, { useContext, useEffect, useRef } from 'react';
import assistantAPI, {
  AssistantFunctionsFragment,
  AssistantKindsFragment,
  AssistantServicesFragment
} from 'util/assistantAPI';
import { groupBy, pick, uniqBy } from 'lodash';
import { useApolloClient, useQuery } from 'react-apollo';

import gql from 'graphql-tag';

const ITEM_LOCKED_STATE_FIELDS = [FieldNames.ID, FieldNames.LOCKED_BY];

/**
 * Query used to figure out what changes have happened to the inventory.  The
 * only fields pulled are IDs and fields with a chance of meaningful change.
 */
const InventoryQuery = gql`
  query Inventory($id: ID!) {
    workspace(id: $id) {
      services {
        ...AssistantServices
      }
      inventory {
        serviceKinds {
          ...AssistantKinds
        }
        workspaceKinds {
          ...AssistantKinds
        }
        functions {
          ...AssistantFunctions
        }
      }
      instanceRefs {
        innerKind {
          ...AssistantKinds
        }
        innerFunction {
          ...AssistantFunctions
        }
      }
    }
  }
  ${AssistantKindsFragment}
  ${AssistantFunctionsFragment}
  ${AssistantServicesFragment}
`;

const LockingQuery = gql`
  query Locking($id: ID!) {
    workspace(id: $id) {
      id
      lockedBy
      portalGraphs {
        id
        lockedBy
        type
      }
    }
  }
`;

function buildInventoryObject(workspace) {
  const {
    inventory: { functions, serviceKinds, workspaceKinds } = {
      functions: [],
      serviceKinds: [],
      workspaceKinds: []
    },
    instanceRefs,
    services
  } = workspace;

  const instanceRefKinds = instanceRefs
    ? instanceRefs.map(ref => ref.innerKind).filter(Boolean)
    : [];
  const instanceRefFunctions = instanceRefs
    ? instanceRefs.map(ref => ref.innerFunction).filter(Boolean)
    : [];

  return {
    kinds: uniqBy(
      serviceKinds.concat(workspaceKinds).concat(instanceRefKinds),
      k => k.id
    ),
    functions: uniqBy(functions.concat(instanceRefFunctions), f => f.id),
    services
  };
}

function buildLockingObject(workspace) {
  const {
    [GraphType.FUNCTION]: functions = [],
    [GraphType.KNOWLEDGE]: graphs = []
  } = groupBy(workspace.portalGraphs, g => g.type);

  return {
    workspaces: [pick(workspace, ITEM_LOCKED_STATE_FIELDS)],
    knowledgeGraphs: graphs.map(g => pick(g, ITEM_LOCKED_STATE_FIELDS)),
    functions: functions.map(f => pick(f, ITEM_LOCKED_STATE_FIELDS))
  };
}

/**
 * Runs a diff on the old and new inventory, and notifies the assistants of any
 * changes between them.
 *
 * @param {Object} inventory The new inventory
 * @param {Ref<Object>} inventoryRef A reference to the previous inventory
 */
function runInventoryDiff(inventory, inventoryRef) {
  if (inventoryRef.current) {
    assistantAPI.inventoryChanged(inventoryRef.current, inventory);
  }
  inventoryRef.current = inventory;
}

/**
 * Gathers the settings used by the Assistant API.
 */
function AssistantAPISettings() {
  const {
    workspaceId,
    workspaceServiceId,
    modelServiceId,
    logicServiceId
  } = useContext(WorkspaceContext);
  const { engine, getViewportDimensions } = useContext(GraphContext);
  const { assistantStates, addAssistantError, setAssistantState } = useContext(
    AssistantContext
  );
  const client = useApolloClient();
  const inventoryRef = useRef(null);
  const heldInventoryRef = useRef(null);
  const locksRef = useRef(null);

  const { data: inventoryData } = useQuery(InventoryQuery, {
    variables: { id: workspaceId },
    returnPartialData: true,
    fetchPolicy: 'cache-only'
  });

  const { data: lockingData } = useQuery(LockingQuery, {
    variables: { id: workspaceId },
    returnPartialData: true,
    fetchPolicy: 'cache-only'
  });

  // Update the assistant API settings when one of them changes
  useEffect(() => {
    assistantAPI.setSettings({
      workspaceId,
      workspaceServiceId,
      modelServiceId,
      logicServiceId,
      client,
      getGraphDimensions: getViewportDimensions,
      diagramEngine: engine,
      addAssistantError,
      setAssistantState
    });
  }, [
    workspaceId,
    workspaceServiceId,
    modelServiceId,
    logicServiceId,
    client,
    engine,
    getViewportDimensions,
    addAssistantError,
    setAssistantState
  ]);

  const holdInventoryDiff = Array.from(assistantStates.values()).some(as =>
    as.isWorking()
  );

  // Run the inventory diff once the hold is removed
  useEffect(() => {
    if (!holdInventoryDiff && heldInventoryRef.current) {
      runInventoryDiff(heldInventoryRef.current, inventoryRef);
      heldInventoryRef.current = null;
    }
  }, [holdInventoryDiff]);

  // Process the inventory from the query, and every time it changes
  // let the assistant API know the old and new states.
  useEffect(() => {
    if (inventoryData?.workspace) {
      const inventory = buildInventoryObject(inventoryData.workspace);
      if (holdInventoryDiff) {
        heldInventoryRef.current = inventory;
      } else {
        runInventoryDiff(inventory, inventoryRef);
      }
    }
  }, [inventoryData, holdInventoryDiff]);

  // Process the locks from the query, and every time they change let the
  // assistant API know the old and new states.
  useEffect(() => {
    if (lockingData?.workspace) {
      const locks = buildLockingObject(lockingData.workspace);
      if (locksRef.current) {
        assistantAPI.lockingChanged(locksRef.current, locks);
      }
      locksRef.current = locks;
    }
  }, [lockingData]);
}

export default React.memo(AssistantAPISettings);
