import { executeFunction, executeGraphql } from 'util/functionExecution';

import { getFunctionByIdForAssistant } from './function';
import gql from 'graphql-tag';

const FunctionForExecutionQuery = gql`
  query FunctionForExecution($id: ID!) {
    function(id: $id) {
      id
      name
      graphqlOperationType
      arguments {
        id
        name
        type
        modifiers
        kind {
          id
          name
        }
      }
      service {
        id
      }
    }
  }
`;

/**
 * Executes an arbitrary graphql string against a service endpoint.
 *
 * @param {Object} data The input containing the serviceId, query, and variables.
 * @return {Promise<Object>} The result from the execute query.
 */
export async function executeGraphqlForAssistant({
  serviceId,
  query,
  variables
}) {
  try {
    return await executeGraphql(serviceId, query, variables);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API error in executeGraphql: ${ex.message}`)
    );
  }
}

/**
 * Executes a function given function and query information.
 *
 * @param {Object} data The information for running the function execution containing
 *    functionId, variables, and resolve.
 * @return {Promise<Object>} Promise that resolves to executed function's output.
 */
export async function executeFunctionForAssistant(data, client) {
  try {
    const { functionId, variables, resolve } = data;

    // Load the function
    const func = await getFunctionByIdForAssistant(
      functionId,
      client,
      FunctionForExecutionQuery
    );

    // Execute the function
    return executeFunction(func, variables, resolve);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'executeFunction': ${ex.message}`)
    );
  }
}
