import {
  AuthContextConsumer,
  GraphContext,
  WorkspaceContext
} from 'components/context';
import React, { useContext } from 'react';

import { ApolloConsumer } from 'react-apollo';
import fileUploader from 'util/fileUploader';

/**
 * Gathers the settings used by the file uploader.
 */
export function FileUploaderSettings({ children }) {
  const { workspaceId, modelServiceId } = useContext(WorkspaceContext);
  const { engine, getViewportDimensions } = useContext(GraphContext);

  return (
    <ApolloConsumer>
      {client => {
        fileUploader.setSettings(
          client,
          workspaceId,
          modelServiceId,
          getViewportDimensions,
          engine
        );
        return (
          <AuthContextConsumer>
            {({ authHeader }) => {
              fileUploader.setAuthenticationHeader(authHeader);
              return children;
            }}
          </AuthContextConsumer>
        );
      }}
    </ApolloConsumer>
  );
}
