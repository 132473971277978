import { Paper, Popper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Categories from './Categories';
import { DataRenderingWrapper } from 'components/DataRenderingWrapper';
import Details from './Details';
import Input from './Input';
import InputContainer from './InputContainer';
import ListBox from './ListBox';
import Option from './Option';
import PropTypes from 'prop-types';
import { SearchResultType } from './propTypes';
import styled from 'styled-components';
import useDefaultAction from './useDefaultAction';

const PopperBackground = styled(Paper).attrs({
  elevation: 8
})`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 40vh;
  background-color: ${p => p.theme.palette.background.default};
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
`;

const NoResults = styled(Typography)`
  padding: ${p => p.theme.spacing(1)}px;
  flex: 1;
  font-style: italic;
`;

const Trademark = styled(Typography)`
  padding: ${p => p.theme.spacing(0.5)}px ${p => p.theme.spacing()}px;
  color: ${p => p.theme.palette.text.disabled};
  font-size: 12px;
`;

export default function SearchContent({
  className,
  getRootProps,
  getInputProps,
  getInputLabelProps,
  getListboxProps,
  getOptionProps,
  isVisible,
  anchorEl,
  setAnchorEl,
  setHighlightedRef,
  loading,
  error,
  results,
  loadMoreResults,
  startDragging,
  endDragging,
  currentCategory,
  handleCategoryChanged
}) {
  const [highlighted, setHighlighted] = useState(null);
  const { onAction, actionType } = useDefaultAction();

  setHighlightedRef.current = setHighlighted;

  useEffect(() => {
    // Clear the highlighted result when:
    //   * The search is hidden.
    //   * It is no longer in the list of results.
    if (
      !isVisible ||
      (highlighted && !results.some(r => r.id === highlighted.id))
    ) {
      setHighlighted(null);
    }
  }, [results, highlighted, isVisible]);

  // Get the list box props and remove the mouse down handler, as it prevents
  // the dragging of results from happening.
  const listboxProps = getListboxProps();
  delete listboxProps.onMouseDown;

  const inputProps = getInputProps();

  const showLoading = loading && !results.length;
  const showResults = !error && results.length > 0;

  return (
    <>
      <InputContainer
        {...getRootProps()}
        className={className}
        onAction={onAction}
        currentCategory={currentCategory}
        setCategory={handleCategoryChanged}
        activeOption={highlighted}
      >
        <Input
          InputProps={{ ref: setAnchorEl }}
          inputProps={inputProps}
          InputLabelProps={getInputLabelProps()}
        />
      </InputContainer>
      <Popper
        anchorEl={anchorEl}
        open={isVisible}
        role="presentation"
        placement="bottom-start"
      >
        <PopperBackground>
          <ResultContainer>
            <Categories
              currentCategory={currentCategory}
              setCategory={handleCategoryChanged}
            />
            <DataRenderingWrapper
              loading={showLoading}
              error={error}
              errorMessage="Failed to load search results"
              location="Search"
            >
              {!showResults && <NoResults>No Results</NoResults>}
              {showResults && (
                <ListBox {...listboxProps} loadMoreResults={loadMoreResults}>
                  {results.map((option, index) => {
                    return (
                      <Option
                        key={`${option.id}-${option.kindName}`}
                        option={option}
                        highlighted={highlighted === option}
                        onDragStart={startDragging}
                        onDragEnd={endDragging}
                        onAction={onAction}
                        actionType={actionType}
                        inputRef={inputProps.ref}
                        {...getOptionProps({ option, index })}
                      />
                    );
                  })}
                </ListBox>
              )}
            </DataRenderingWrapper>
            <Trademark>MaanaRank &trade;</Trademark>
          </ResultContainer>
          <Details item={highlighted} />
        </PopperBackground>
      </Popper>
    </>
  );
}

SearchContent.propTypes = {
  className: PropTypes.string,
  getRootProps: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  getInputLabelProps: PropTypes.func.isRequired,
  getListboxProps: PropTypes.func.isRequired,
  getOptionProps: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func.isRequired,
  setHighlightedRef: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  results: PropTypes.arrayOf(SearchResultType.isRequired).isRequired,
  loadMoreResults: PropTypes.func.isRequired,
  startDragging: PropTypes.func.isRequired,
  endDragging: PropTypes.func.isRequired,
  currentCategory: PropTypes.string.isRequired,
  handleCategoryChanged: PropTypes.func.isRequired
};
