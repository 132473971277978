import {
  createKind,
  createKinds,
  deleteKind,
  getKindById,
  getKindsById,
  updateKinds
} from 'util/kind';

import { AssistantKindsFragment } from './graphql';
import gql from 'graphql-tag';
import { omitTypename } from './omitTypename';

const AssistantServiceKindsQuery = gql`
  query AssistantServiceKinds($id: ID!) {
    service(id: $id) {
      id
      kinds {
        ...AssistantKinds
      }
    }
  }
  ${AssistantKindsFragment}
`;

const KindByIdQuery = gql`
  query KindById($id: ID!) {
    kind(tenantId: 0, id: $id) {
      ...AssistantKinds
    }
  }
  ${AssistantKindsFragment}
`;

const KindsByIdQuery = gql`
  query AssistantKindsById($ids: [ID!]!) {
    kinds(tenantId: 0, ids: $ids) {
      ...AssistantKinds
    }
  }
  ${AssistantKindsFragment}
`;

const AssistantAllReferencedKindsQuery = gql`
  query AssistantAllReferencedKinds(
    $ids: [ID!]!
    $maxDepth: Int
    $idsToSkip: [ID!]
  ) {
    allReferencedKinds(
      tenantId: 0
      ids: $ids
      maxDepth: $maxDepth
      idsToSkip: $idsToSkip
    ) {
      ...AssistantKinds
    }
  }
  ${AssistantKindsFragment}
`;

/**
 * Creates a kind.
 *
 * @param {AddKindInput} data The kind input.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {string} modelServiceId The ID of the models service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Kind>} The created kind.
 */
export async function createKindForAssistant(kindData, modelServiceId, client) {
  try {
    // Persist kind.
    // This returns the kind input.
    const kind = await createKind(kindData, modelServiceId, client);

    // Return full kind definition.
    return await getKindByIdForAssistant(kind.id, client);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'createKind': ${ex.message}`)
    );
  }
}

/**
 * Creates a group of kinds.
 *
 * @param {Array<AddKindInput>} data The kinds to create.
 * @param {string} modelServiceId The ID of the models service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Array<Kind>>} The created kinds.
 */
export async function createKindsForAssistant(
  kindData,
  modelServiceId,
  client
) {
  try {
    // Persist kind.
    // This returns the kind input.
    const kinds = await createKinds(kindData, modelServiceId, client);

    // Return full kind definition.
    return await getKindsByIdForAssistant(
      kinds.map(k => k.id),
      client
    );
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'createKinds': ${ex.message}`)
    );
  }
}

/**
 * Updates a kind.
 *
 * @param {UpdateKindInput} data The updated information about the kind.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {string} modelServiceId The ID of the models service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Kind>} The updated kind.
 */
export async function updateKindForAssistant(
  kindData,
  workspaceId,
  modelServiceId,
  client
) {
  try {
    await updateKinds([kindData], workspaceId, modelServiceId, client);
    // Return full kind definition.
    return getKindByIdForAssistant(kindData.id, client);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'updateKind':${ex.message}`)
    );
  }
}

/**
 * Updates a group of kinds.
 *
 * @param {Array<UpdateKindInput>} data The updated information about the kinds.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {string} modelServiceId The ID of the models service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Kind>} The updated kinds.
 */
export async function updateKindsForAssistant(
  kindData,
  workspaceId,
  modelServiceId,
  client
) {
  try {
    await updateKinds(kindData, workspaceId, modelServiceId, client);
    // Return full kind definition.
    return getKindsByIdForAssistant(
      kindData.map(k => k.id),
      client
    );
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'updateKind':${ex.message}`)
    );
  }
}

/**
 * Deletes a kind.
 *
 * @param {Object} id Kind ID to delete.
 * @param {string} workspaceId The ID of the current Workspace.
 * @param {string} modelServiceId The ID of the models service to use.
 * @param {ApolloClient} client The client used to access the Apollo.
 */
export async function deleteKindForAssistant(
  id,
  workspaceId,
  modelServiceId,
  client
) {
  try {
    await deleteKind(id, workspaceId, modelServiceId, client);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'deleteKind': ${ex.message}`)
    );
  }
}

/**
 * Returns a kind given the supplied ID.
 *
 * @param {string} id ID of the kind to load.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Kind>} The retrieved kind.
 */
export async function getKindByIdForAssistant(id, client) {
  try {
    const kind = await getKindById(id, client, KindByIdQuery);
    if (kind?.isDeleted) return null;
    return omitTypename(kind);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'getKindById': ${ex.message}`)
    );
  }
}

/**
 * Returns a group of kinds given the supplied IDs.
 *
 * @param {Array<string>} data IDs of the kinds to load.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Kind>} The retrieved kinds.
 */
export async function getKindsByIdForAssistant(ids, client) {
  try {
    const kinds = await getKindsById(ids, client, KindsByIdQuery);
    return kinds
      .filter(kind => !kind?.isDeleted)
      .map(kind => {
        return omitTypename(kind);
      });
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'getKindsById': ${ex.message}`)
    );
  }
}

/**
 * Returns a service's kinds.
 * Helper: Not exposed via listener.
 *
 * @param {string} id The service ID.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Array<Kind>>} A promise that resolves to an array of kinds. (QP-82)
 */
export async function getKindsByService(id, client) {
  try {
    const { data, errors } = await client.query({
      query: AssistantServiceKindsQuery,
      variables: { id: id }
    });

    if (errors) {
      throw Error(`Error/s in client query: ${errors}`);
    }

    if (!data || !data.service || !data.service.kinds) return [];

    return omitTypename(data.service.kinds.filter(k => !k.isDeleted));
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'getKindsByService': ${ex.message}`)
    );
  }
}

/**
 * Given an array of input IDs, provide entire kind tree referenced by these kinds.
 *
 * @param {Array<string>} ids The ids to traverse.
 * @param {number} maxDepth Maximimum traversal depth in schema levels
 * @param {Array<string>} idsToSkip IDs not to include.
 * @param {ApolloClient} client Apollo client.
 * @return {Promise<Array<Kind>>} A promise that resolves to a list of kinds.
 */
export async function getAllReferencedKinds(ids, maxDepth, idsToSkip, client) {
  try {
    const { data, errors } = await client.query({
      query: AssistantAllReferencedKindsQuery,
      variables: { ids, maxDepth, idsToSkip }
    });

    if (errors) {
      throw Error(
        `Error/s in client query: ${errors.map(e => e.message).join(' ')}`
      );
    }

    if (!data?.allReferencedKinds) return [];

    return omitTypename(data.allReferencedKinds);
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API Error in 'getAllReferencedKinds': ${ex.message}`)
    );
  }
}
