export const KPORTAL_VERSION = process.env.REACT_APP_VERSION;
export const KPORTAL_TITLE = 'Maana Knowledge Portal';

export const HTTP_OK = 200;
export const HTTP_PROCESSING = 102;
export const HTTP_ERROR_CODES_START = 400;

export const ICON_DIR = '/icons/';
export const SERVICE_THUMBNAIL_URL = '/thumbs/workspace.jpg';

export const HEADER_SIZE = 48;
export const LINKBAR_WIDTH = 56;

// Note: Specifying a negative value means a pane can take up to 100% of the
// available width/height, minus the value.
export const EXPLORER_MIN_SIZE = LINKBAR_WIDTH;
export const EXPLORER_MAX_SIZE = -LINKBAR_WIDTH;
export const EXPLORER_CLOSED_SIZE = LINKBAR_WIDTH;
export const CONTEXT_MIN_SIZE = 350;
export const CONTEXT_MAX_SIZE = -LINKBAR_WIDTH;
export const CONTEXT_CLOSED_SIZE = HEADER_SIZE;
export const DATAVIZ_MIN_SIZE = HEADER_SIZE;
export const DATAVIZ_MAX_SIZE = -DATAVIZ_MIN_SIZE;
export const DATAVIZ_CLOSED_SIZE = HEADER_SIZE;

// the key for drag and drop, so we can drag between trees
export const DragAndDropType = Object.freeze({
  SearchNode: 'Search-Node',
  ExplorerNode: 'Explorer-Node',
  Service: 'Service',
  Workspace: 'Workspace',
  Graph: 'Graph'
});

// enum GraphType
export const GraphType = Object.freeze({
  KNOWLEDGE: 'KNOWLEDGE',
  FUNCTION: 'FUNCTION'
});

// The default positions of zoom and offset on graphs
export const GraphPositionDefaults = Object.freeze({
  ZOOM: 100,
  OFFSET_X: 0,
  OFFSET_Y: 0
});

export const GraphNodes = Object.freeze({
  DEFAULT_POSITION_X: 25,
  DEFAULT_POSITION_Y: 25,
  MIN_WIDTH: 165,
  MAX_WIDTH: 380,
  MIN_HEIGHT_KIND: 100,
  MIN_HEIGHT_FUNCTION: 100,
  HEIGHT_SCHEMA_ROW: 19,
  MIN_HEIGHT: 120,
  HEIGHT_FILE: 290,
  WIDTH_FILE: 290,
  EDIT_MODE_WIDTH: 500,
  PADDING: 10 /* TODO: ENG-18344: New graph nodes don't use this */,
  BODY_FONT_SIZE: 12,
  FONT_SIZE: 14,
  ICON_FONT_SIZE: 14,
  ICON_BUTTON_SIZE: 18 /* icon buttons are assumed to be square */
});

export const GraphNodeTables = Object.freeze({
  AUTOCOMPLETE_WIDTH: 290,
  HEADER_FONT_SIZE: 8,
  CELL_FONT_SIZE: GraphNodes.BODY_FONT_SIZE,
  NAME_CELL_WIDTH: 140,
  VALUE_CELL_WIDTH: 210,
  NAME_EDIT_INPUT_WIDTH: 180,
  VALUE_EDIT_INPUT_WIDTH: 210
});

export const OperationType = Object.freeze({
  LET: 'LET',
  APPLY: 'APPLY',
  MAP: 'MAP',
  FOLD: 'FOLD'
});

export const FieldTypeToGraphQLType = Object.freeze({
  ID: 'ID',
  STRING: 'String',
  INT: 'Int',
  FLOAT: 'Float',
  BOOLEAN: 'Boolean',
  DATE: 'Date',
  TIME: 'Time',
  DATETIME: 'DateTime',
  JSON: 'JSON'
});

export const FunctionType = Object.freeze({
  SERVICE: 'SERVICE',
  PROJECTION: 'PROJECTION',
  CKG: 'CKG'
});

export const ServiceCategory = Object.freeze({
  ASSISTANT: 'ASSISTANT',
  EXTERNAL: 'EXTERNAL',
  LOGIC: 'LOGIC',
  MODEL: 'MODEL',
  WORKSPACE: 'WORKSPACE'
});

export const ServiceCategoryLabel = Object.freeze({
  ASSISTANT: 'Assistant',
  EXTERNAL: 'GraphQL',
  LOGIC: 'Logic',
  MODEL: 'Model',
  WORKSPACE: 'Workspace'
});

export const GraphqlOperationType = Object.freeze({
  QUERY: 'QUERY',
  MUTATION: 'MUTATION'
});

export const AuthProvider = Object.freeze({
  KeyCloak: 'keycloak',
  Auth0: 'auth0'
});

export const AssistantState = Object.freeze({
  WORKING: 'WORKING',
  IDLE: 'IDLE'
});

export const IconOverlayName = Object.freeze({
  FUNCTION_GRAPH: 'functiongraph',
  LAMBDA: 'lambda'
});

export const LockedByType = Object.freeze({
  GRAPH: 'Graph',
  WORKSPACE: 'Workspace'
});

/*********
 * FIELD NAMES
 *********/

export const FieldNames = Object.freeze({
  DESCRIPTION: 'description',
  ID: 'id',
  IS_PUBLIC: 'isPublic',
  IS_TEMPLATE: 'isTemplate',
  NAME: 'name',
  THUMBNAIL_URL: 'thumbnailUrl',
  LOCKED_BY: 'lockedBy',
  MIME_TYPE: 'mimeType',
  URL: 'url'
});

/*********
 * KIND TYPES
 ********/
// the different kind names we use and run into
export const KN_WORKSPACE = 'Workspace';
export const KN_WORKSPACE_LAYOUT = 'WorkspaceLayout';
export const KN_PORTAL_GRAPH = 'PortalGraph';
export const KN_PORTAL_GRAPH_NODE = 'PortalGraphNode';
export const KN_KNOWLEDGE_GRAPH_NODE = 'KnowledgeGraphNode';
export const KN_FUNCTION_GRAPH_NODE = 'FunctionGraphNode';
export const KN_SERVICE = 'Service';
export const KN_ASSISTANT = 'Assistant';
export const KN_FUNCTION = 'Function';
export const KN_ARGUMENT = 'Argument';
export const KN_IMPLEMENTATION = 'Implementation';
export const KN_OPERATION = 'Operation';
export const KN_OPERATION_CONNECTION = 'OperationConnection';
export const KN_ARGUMENT_VALUE = 'ArgumentValue';
export const KN_FUNCTION_ARGUMENTS = 'FunctionArguments';
export const KN_FUNCTION_OUTPUT = 'FunctionOutput';
export const KN_KIND = 'Kind';
export const KN_USER = 'User';
export const KN_FILE = 'File';
export const KN_ANNOTATION = 'Annotation';
export const KN_IMAGE = 'Image';
export const KN_DOCUMENT = 'Document';
export const KN_DOCUMENTS = 'Documents';
export const KN_FOLDER = 'Folder';
export const KN_EXPLORER = 'Explorer';
export const KN_INVENTORY = 'Inventory';
export const KN_INSTANCE_REF = 'InstanceRef';
export const KN_INSTANCE = 'Instance';
export const KN_FIELD_VALUE = 'FieldValue';
export const KN_FIELD = 'Field';
export const KN_LINKED_INSTANCE = 'LinkedInstance';
export const KN_LINKED_KIND = 'LinkedKind';

// Client only types
export const KN_FUNCTION_RESULTS = 'FunctionResults';
export const KN_FUNCTION_EXECUTION_ARGUMENT = 'FunctionExecutionArgument';
export const KN_FUNCTION_EXECUTION = 'FunctionExecution';
export const KN_SELECTION = 'Selection';
export const KN_SELECTED = 'Selected';
export const KN_SNACK_NOTIFICATION = 'SnackNotification';

// id for root items in the inventory
export const KNOWLEDGE_GRAPHS_ROOT_ID = 'knowledge_graphs_root';
export const FUNCTION_GRAPHS_ROOT_ID = 'function_graphs_root';
export const SERVICES_ROOT_ID = 'services_root';
export const ASSISTANTS_ROOT_ID = 'assistants_root';
export const FILES_ROOT_ID = 'files_root';
export const ANNOTATIONS_ROOT_ID = 'annotations_root';
export const DOCS_ROOT_ID = 'docs_root';
export const KINDS_ROOT_ID = 'kind_root';
export const INSTANCES_ROOT_ID = 'instances_root';
export const FUNCTION_ROOT_ID = 'function_root';
export const BOILERPLATE_KINDS_ID = 'boilerplate_kinds';
export const BOILERPLATE_FUNCTIONS_ID = 'boilerplate_functions';
export const CATEGORY_ROOT_ID = 'category_root';

export const InventoryRootIds = [
  KNOWLEDGE_GRAPHS_ROOT_ID,
  FUNCTION_GRAPHS_ROOT_ID,
  ASSISTANTS_ROOT_ID,
  BOILERPLATE_KINDS_ID,
  BOILERPLATE_FUNCTIONS_ID,
  DOCS_ROOT_ID,
  FILES_ROOT_ID,
  ANNOTATIONS_ROOT_ID,
  FUNCTION_ROOT_ID,
  INSTANCES_ROOT_ID,
  KINDS_ROOT_ID,
  SERVICES_ROOT_ID,
  CATEGORY_ROOT_ID
];

export const CategoryNodeSuffixes = [
  KINDS_ROOT_ID,
  FUNCTION_ROOT_ID,
  FILES_ROOT_ID,
  ANNOTATIONS_ROOT_ID,
  DOCS_ROOT_ID,
  INSTANCES_ROOT_ID
];

/**
 * Apollo Client Network Statuses
 * Documentation at https://www.apollographql.com/docs/react/api/react-apollo/#datanetworkstatus.
 */
export const ApolloNetworkStatus = {
  LOADING: 1,
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  //5 is Unused.
  POLL: 6,
  READY: 7,
  ERROR: 8
};

export const FieldTypes = Object.freeze({
  l_INT: 'l_INT',
  l_FLOAT: 'l_FLOAT',
  l_BOOLEAN: 'l_BOOLEAN',
  l_DATE: 'l_DATE',
  l_TIME: 'l_TIME',
  l_DATETIME: 'l_DATETIME',
  l_JSON: 'l_JSON',
  l_ID: 'l_ID',
  l_STRING: 'l_STRING',
  l_KIND: 'l_KIND',
  INT: 'INT',
  FLOAT: 'FLOAT',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  JSON: 'JSON',
  ID: 'ID',
  STRING: 'STRING',
  KIND: 'KIND'
});

export const FieldModifiers = Object.freeze({
  LIST: 'LIST',
  NONULL: 'NONULL',
  NOIDX: 'NOIDX',
  EPHEMERAL: 'EPHEMERAL'
});

export const ANNOTATION_MIME_TYPE = 'maana/annotation';
export const PreviewableAnnotationRegex = /^(http|https):/;
export const ClickableAnnotationRegex = /^(arp|dchp|dns|dsn|ftp|http|https|imap|icmp|idmp|ip|irc|mailto|pop3|par|rlogin|smtp|ssl|ssh|tcp|telnet|upd|ups):/;

export const GraphQLNameRegex = /^[_A-Za-z][_0-9A-Za-z]*$/;
export const GraphQLNameRegexKinds = ['Kind', 'Field', 'Function'];
export const GraphQLNameRegexPrefix = /^(?!__)/;

export const IdRegex = /^[0-9A-Za-z][-\\.0-9A-Za-z_]*$/;

export const KindUUID = '00000000-c001-c0de-d00d-000000000000';
export const FieldUUID = '00000000-c001-c0de-d00d-f1e1d0000000';
