import styled, { css } from 'styled-components';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import AddToGraphIcon from '@material-ui/icons/ExitToApp';
import AssistantIcon from '@material-ui/icons/DeveloperBoard';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import CommentIcon from '@material-ui/icons/Comment';
import ComposeIcon from '@material-ui/icons/ZoomOutMap';
import ContactIcon from '@material-ui/icons/Textsms';
import DefaultIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import DocumentsIcon from '@material-ui/icons/Inbox';
import EditIcon from '@material-ui/icons/Edit';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import ExplorerIcon from '@material-ui/icons/Explore';
import FileIcon from '@material-ui/icons/Save';
import FolderIcon from '@material-ui/icons/Folder';
import FunctionGraphIcon from '@material-ui/icons/Timeline';
import FunctionIcon from '@material-ui/icons/Functions';
import HelpIcon from '@material-ui/icons/Help';
import ImageIcon from '@material-ui/icons/PhotoCamera';
import InfoIcon from '@material-ui/icons/Info';
import KindIcon from '@material-ui/icons/LocalFlorist';
import KnowledgeGraphIcon from '@material-ui/icons/DeviceHub';
import LambdaIcon from './LambdaIcon';
import LinkIcon from '@material-ui/icons/Link';
import ListIcon from '@material-ui/icons/List';
import LockIcon from '@material-ui/icons/Lock';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import RequiredIcon from '@material-ui/icons/PriorityHigh';
import RunIcon from '@material-ui/icons/PlayCircleFilled';
import ServiceIcon from '@material-ui/icons/Memory';
import SocialIcon from '@material-ui/icons/Group';
import UndoIcon from '@material-ui/icons/Undo';
import UnlockIcon from '@material-ui/icons/LockOpen';
import WorkspaceIcon from '@material-ui/icons/ViewQuilt';
import { withoutProps } from 'util/helpers';

const CurrentUserLockIcon = styled(LockOutlinedIcon)`
  color: ${props => props.theme.custom.iconOverlay.color};
`;

export { LockIcon, UnlockIcon, CurrentUserLockIcon };

const IconWithOverlay = styled.div`
  display: flex;
  overflow: hidden;
`;

const OverlayIconCss = css`
  position: absolute;
  font-size: calc(1em / 3 * 2);
  background-color: transparent;
  margin-left: 1em;
  color: ${props => props.overlayColor || props.theme.custom.iconOverlay.color};
`;

const PlusOverlay = styled(withoutProps('overlayColor')(AddIcon))`
  ${OverlayIconCss}
`;

const LambdaOverlay = styled(withoutProps('overlayColor')(LambdaIcon))`
  ${OverlayIconCss}
`;

const FunctionGraphOverlay = styled(
  withoutProps('overlayColor')(FunctionGraphIcon)
)`
  ${OverlayIconCss}
`;

const kindIcon = {
  add: AddCircleOutlineIcon,
  addtograph: AddToGraphIcon,
  annotation: NoteOutlinedIcon,
  assistant: AssistantIcon,
  collapse: CollapseIcon,
  comment: CommentIcon,
  compose: ComposeIcon,
  contact: ContactIcon,
  currentuserlock: CurrentUserLockIcon,
  delete: DeleteIcon,
  document: DocumentIcon,
  documents: DocumentsIcon,
  edit: EditIcon,
  expand: ExpandIcon,
  explorer: ExplorerIcon,
  file: FileIcon,
  folder: FolderIcon,
  function: FunctionIcon,
  operation: FunctionIcon,
  help: HelpIcon,
  image: ImageIcon,
  info: InfoIcon,
  kind: KindIcon,
  knowledge: KnowledgeGraphIcon,
  lambda: LambdaIcon,
  link: LinkIcon,
  list: ListIcon,
  lock: LockIcon,
  required: RequiredIcon,
  service: ServiceIcon,
  social: SocialIcon,
  run: RunIcon,
  undo: UndoIcon,
  unlock: UnlockIcon,
  workspace: WorkspaceIcon
};

const iconOverlay = {
  add: PlusOverlay,
  lambda: LambdaOverlay,
  functiongraph: FunctionGraphOverlay
};

function BasicIcon({ kindName, color, className }) {
  const normKind = kindName ? kindName.toLowerCase() : '';
  const KindIcon = kindIcon[normKind];

  return KindIcon ? (
    <KindIcon className={className} color={color} />
  ) : (
    <DefaultIcon className={className} color={color} />
  );
}

const BasicIconWithOverlay = styled(BasicIcon)`
  font-size: inherit;
`;

export default function Icon({
  kindName,
  color,
  className,
  overlay,
  overlayColor
}) {
  const normOverlay = overlay ? overlay.toLowerCase() : '';
  const Overlay = iconOverlay[normOverlay];

  return Overlay ? (
    <IconWithOverlay className={className}>
      <BasicIconWithOverlay kindName={kindName} color={color} />
      <Overlay overlayColor={overlayColor} />
    </IconWithOverlay>
  ) : (
    <BasicIcon kindName={kindName} color={color} className={className} />
  );
}

Icon.propTypes = {
  color: PropTypes.string,
  kindName: PropTypes.string.isRequired,
  className: PropTypes.string,
  overlay: PropTypes.oneOf(Object.keys(iconOverlay)),
  overlayColor: PropTypes.string
};
