import {
  KN_ASSISTANT,
  KN_FUNCTION,
  KN_KIND,
  KN_SERVICE,
  KN_WORKSPACE
} from 'util/defines';

import PropTypes from 'prop-types';
import React from 'react';
import { SearchResultType } from './propTypes';

const CATEGORY_LIST = [
  KN_SERVICE,
  KN_ASSISTANT,
  KN_WORKSPACE,
  KN_FUNCTION,
  KN_KIND
];

/**
 * Handles custom keyboard shortcuts for search, otherwise defers to the passed
 * in default key down event handler passed in.
 *
 * @param {Event} event The event that was triggered
 * @param {function} onAction The function to call to trigger the default action.
 * @param {string} currentCategory The currently selected category.
 * @param {function} setCategory The function to use to set a new category.
 * @param {Object} activeOption The actively selected search result.
 * @param {function} defaultKeyDown The default key down handler passed to the component.
 */
function handleKeyDown(
  event,
  onAction,
  currentCategory,
  setCategory,
  activeOption,
  defaultKeyDown
) {
  switch (event.key) {
    case 'Enter': {
      // Do the default action on the highlighted result.
      if (activeOption) {
        onAction(activeOption);
      }
      break;
    }
    case 'ArrowLeft': {
      if (event.altKey && event.ctrlKey) {
        const newCategory = CATEGORY_LIST.indexOf(currentCategory) - 1;
        if (newCategory < 0) {
          setCategory(CATEGORY_LIST[CATEGORY_LIST.length - 1]);
        } else {
          setCategory(CATEGORY_LIST[newCategory]);
        }
      }
      break;
    }
    case 'ArrowRight': {
      if (event.altKey && event.ctrlKey) {
        const newCategory = CATEGORY_LIST.indexOf(currentCategory) + 1;
        if (newCategory >= CATEGORY_LIST.length) {
          setCategory(CATEGORY_LIST[0]);
        } else {
          setCategory(CATEGORY_LIST[newCategory]);
        }
      }
      break;
    }
    default: {
      return defaultKeyDown?.(event);
    }
  }
}

export default function InputContainer({
  className,
  onAction,
  currentCategory,
  setCategory,
  activeOption,
  onKeyDown,
  children,
  ...rootProps
}) {
  return (
    <div
      {...rootProps}
      className={className}
      onKeyDown={e =>
        handleKeyDown(
          e,
          onAction,
          currentCategory,
          setCategory,
          activeOption,
          onKeyDown
        )
      }
    >
      {children}
    </div>
  );
}

InputContainer.propTypes = {
  onAction: PropTypes.func.isRequired,
  currentCategory: PropTypes.oneOf(CATEGORY_LIST).isRequired,
  setCategory: PropTypes.func.isRequired,
  activeOption: SearchResultType,
  onKeyDown: PropTypes.func
};
