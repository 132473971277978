import { KN_PORTAL_GRAPH, KN_PORTAL_GRAPH_NODE } from 'util/defines';
import { debounce, isEmpty, isEqual, pick } from 'lodash';

import { arrayToMap } from 'util/helpers';
import { getActiveGraphFromCache } from 'util/graph/portalGraph';
import { getGraphLockedBy } from 'util/permissions';
import gql from 'graphql-tag';
import { handleError } from 'util/snackUtils';

const NodePositionFragment = gql`
  fragment NodePosition on PortalGraphNode {
    id
    x
    y
  }
`;

const NodeCollapsedFragment = gql`
  fragment NodeCollapsed on PortalGraphNode {
    collapsed
  }
`;

const GraphLayoutFragment = gql`
  fragment GraphLayoutDetails on PortalGraph {
    id
    zoom
    offsetX
    offsetY
  }
`;

const UpdateGraphLayoutMutation = gql`
  mutation UpdateGraphLayout($input: GraphLayoutUpdateInput!) {
    updateGraphLayout(input: $input)
  }
`;

let pendingGraphLayoutUpdate = null;
let isUpdatingGraphLayout = false;

const NodeLayoutFields = ['x', 'y', 'id'];
const GraphLayoutFields = ['id', 'zoom', 'offsetX', 'offsetY'];

/**
 * Pulls out the layout information from the graph
 *
 * @param {Object} graph The graph to get the data from
 * @return {Object} Just the layout information from the graph
 */
function getLayoutFromGraph(graph) {
  return pick(graph, GraphLayoutFields);
}

/**
 * Persists the changes made to the graph and node layout information.
 *
 * @param {Object} graph The updated graph information to persist
 * @param {string} workspaceId The ID of the active workspace
 * @param {ApolloClient} client The client to use to talk with the backend
 */
function handleGraphLayoutChange(graph, workspaceId, client) {
  const portalGraph = getActiveGraphFromCache(workspaceId, client);

  // No need to update layout if there are no nodes on the graph, there is no
  // portal graph to update, or if the graph and portal graph IDs don't match.
  // The IDs will be mismatched in cases like clicking function compose
  // button, as this causes a graph update event to be triggered.  The debounce
  // that is used for a lot of update event makes it come through here after
  // the graph has already changed.
  if (!portalGraph || !portalGraph.nodes || graph.id !== portalGraph.id) return;

  // Allow zooming of the graph without providing an offset
  graph.offsetX = Number.isFinite(graph.offsetX)
    ? graph.offsetX
    : portalGraph.offsetX;
  graph.offsetY = Number.isFinite(graph.offsetY)
    ? graph.offsetY
    : portalGraph.offsetY;
  // Allow positioning of the graph without providing a zoom
  graph.zoom = Number.isFinite(graph.zoom) ? graph.zoom : portalGraph.zoom;

  const graphLayout = getLayoutFromGraph(graph);
  const currentLayout = getLayoutFromGraph(portalGraph);

  // If the layout is the same as the current one then there is no reason to
  // update
  const isGraphLayoutChanged = !isEqual(currentLayout, graphLayout);

  if (!isGraphLayoutChanged) return;

  // Allow users to move the graph around, zoom in and out, but don't save the
  // changes to the server if the graph is locked by someone else
  getGraphLockedBy(portalGraph.id, workspaceId, client).then(({ canEdit }) => {
    if (canEdit) {
      saveGraphLayoutUpdate([graphLayout], null, client);
    }
  });

  // Update our store with the layout, we don't need to wait for the server response
  updateGraphLayoutInCache(graphLayout, client);
}

/**
 * Updates the copy of the graph layout in the cache
 *
 * @param {Object} graphLayout The new layout information for out graph
 * @param {ApolloClient} client The client to use to access the cache
 */
function updateGraphLayoutInCache(graphLayout, client) {
  const updatedGraph = { ...graphLayout, __typename: KN_PORTAL_GRAPH };

  client.writeFragment({
    id: `${KN_PORTAL_GRAPH}:${graphLayout.id}`,
    fragment: GraphLayoutFragment,
    data: updatedGraph
  });
}

/**
 * Pulls out the layout information from the nodes
 *
 * @param {Array<Object>} nodes The node models to pull the layout information from
 * @return {Object} Just the layout information from the nodes
 */
function getLayoutFromNodes(nodes) {
  const nodeLayouts = nodes.map(node => {
    return {
      ...pick(node, NodeLayoutFields)
    };
  });
  return nodeLayouts;
}

/**
 * Updates the node positions in the cache
 *
 * @param {Array<Object>} nodePositions The new positions for the updated nodes
 * @param {ApolloClient} client The client to use to access the cache
 */
function updateNodePositionsInCache(nodePositions, client) {
  const updatedNodeLayouts = nodePositions.map(nl => ({
    ...nl,
    __typename: KN_PORTAL_GRAPH_NODE
  }));

  // Use a transaction to prevent queries from picking up changes until all
  // updates are saved
  client.cache.performTransaction(transactionClient => {
    updatedNodeLayouts.forEach(nl => {
      transactionClient.writeFragment({
        id: `${KN_PORTAL_GRAPH_NODE}:${nl.id}`,
        fragment: NodePositionFragment,
        data: nl
      });
    });
  });

  // broadcast the changes
  client.queryManager.broadcastQueries();
}

/**
 * Persists the changes made to position information of nodes.
 *
 * @param {Object} nodePositions The updated node positions to persist {id, x, y}
 * @param {string} workspaceId The ID of the active workspace
 * @param {ApolloClient} client The client to use to talk with the backend
 */
function handleNodePositionsChange(nodePositions, workspaceId, client) {
  const portalGraph = getActiveGraphFromCache(workspaceId, client);

  // No need to update positions if there are no nodes on the graph, there is no
  // portal graph to update, or if the graph and portal graph IDs don't match.
  // The IDs will be mismatched in cases like clicking function compose
  // button, as this causes a graph update event to be triggered.  The debounce
  // that is used for a lot of update event makes it come through here after
  // the graph has already changed.
  if (isEmpty(nodePositions) || !portalGraph) return;

  const currentNodePositions = getLayoutFromNodes(portalGraph.nodes);

  // Use existing x and y coordinates if they are not provided
  const updatedPositions = nodePositions
    .filter(node => currentNodePositions.some(cln => cln.id === node.id))
    .map(node => {
      const existingNode = currentNodePositions.find(cln => cln.id === node.id);
      return {
        id: node.id,
        x: Number.isFinite(node.x) ? node.x : existingNode.x,
        y: Number.isFinite(node.y) ? node.y : existingNode.y
      };
    });

  // Check if layout that has changed
  const changedNodePositions = updatedPositions.filter(
    nl =>
      !isEqual(
        currentNodePositions.find(cln => cln.id === nl.id),
        nl
      )
  );

  // If the layouts are the same as the current ones then there is no reason to
  // update
  if (!changedNodePositions.length) return;

  saveGraphLayoutUpdate(null, changedNodePositions, client);
  // Update our store with the layout, we don't need to wait for the server response
  updateNodePositionsInCache(changedNodePositions, client);
}

/**
 * Updates the node collapsed state in the cache
 *
 * @param {string} nodeId The ID of the node to update
 * @param {boolean} isCollapsed The new collapsed state for the updated node
 * @param {ApolloClient} client The client to use to access the cache
 */
function updateNodeCollapsedStateInCache(nodeId, isCollapsed, client) {
  client.writeFragment({
    id: `${KN_PORTAL_GRAPH_NODE}:${nodeId}`,
    fragment: NodeCollapsedFragment,
    data: {
      collapsed: isCollapsed,
      __typename: KN_PORTAL_GRAPH_NODE
    }
  });
}

/**
 * Saved the update, or queues the changes for being persisted once the current
 * save call is done.
 *
 * @param {Map<string, Object>} graphLayouts The updated graph layouts
 * @param {Map<string, Object} nodeLayouts The updated node layouts
 * @param {ApolloClient} client The client to use to talk with the backend
 */
function saveGraphLayoutUpdate(graphLayouts, nodeLayouts, client) {
  if (isUpdatingGraphLayout) {
    // Make sure there is an initial value to work with
    if (!pendingGraphLayoutUpdate) pendingGraphLayoutUpdate = {};

    // Update what is pending to be saved
    pendingGraphLayoutUpdate = {
      graphLayouts: {
        ...pendingGraphLayoutUpdate.graphLayouts,
        ...arrayToMap(graphLayouts || [])
      },
      nodeLayouts: {
        ...pendingGraphLayoutUpdate.nodeLayouts,
        ...arrayToMap(nodeLayouts || [])
      }
    };
  } else {
    persistGraphLayoutUpdate({ graphLayouts, nodeLayouts }, client);
  }
}

/**
 * Handles queued calls to make changes to the graph and node layouts.
 *
 * @param {Object} layoutUpdate The information to update about the layouts
 * @param {ApolloClient} client The client to use to talk with the backend
 */
function persistGraphLayoutUpdate(layoutUpdate, client) {
  isUpdatingGraphLayout = true;
  // Update the server with the new layout
  client
    .mutate({
      mutation: UpdateGraphLayoutMutation,
      variables: { input: layoutUpdate }
    })
    .catch(error =>
      handleError(client, 'Failed to save the Graph layout', error)
    )
    .then(() => {
      isUpdatingGraphLayout = false;
      if (pendingGraphLayoutUpdate) {
        const newLayoutUpdate = {
          graphLayouts: Object.values(pendingGraphLayoutUpdate.graphLayouts),
          nodeLayouts: Object.values(pendingGraphLayoutUpdate.nodeLayouts)
        };
        pendingGraphLayoutUpdate = null;

        if (!isEqual(newLayoutUpdate, layoutUpdate)) {
          persistGraphLayoutUpdate(newLayoutUpdate, client);
        }
      }
    });
}

const GRAPH_DECIMAL_ADJUSTER = 100;

/**
 * Round a value to two decimal places for persisting layout information in the
 * graph and nodes.
 *
 * Note: If the calculations results in a non-finite number, then 0 is returned
 * instead.
 *
 * @param {number} value The value to round
 * @return {number} The input value rounded to 2 decimal places.
 */
export function roundGraphValue(value) {
  const roundedValue =
    Math.round(value * GRAPH_DECIMAL_ADJUSTER) / GRAPH_DECIMAL_ADJUSTER;

  // Make sure to only return finite values
  if (!Number.isFinite(roundedValue)) return 0;

  return roundedValue;
}

/**
 * Prepares changes to a diagram model to persist the changes to the graph and
 * its nodes.
 *
 * @param {DiagramModel} graphLayout The updated graph layout in the shape
 * {id, zoom, offsetX, offsetY}
 * @param {string} workspaceId The ID of the active workspace
 * @param {ApolloClient} client The client to use to talk with the backend
 */
export function handleUpdateGraphLayout(graphLayout, workspaceId, client) {
  if (!graphLayout) {
    return;
  }

  // Update the layout
  let { id, zoom, offsetX, offsetY } = graphLayout;

  let layout = {
    zoom: roundGraphValue(zoom),
    offsetX: offsetX && roundGraphValue(offsetX),
    offsetY: offsetY && roundGraphValue(offsetY),
    id
  };

  handleGraphLayoutChange(layout, workspaceId, client);
}

/**
 * Prepares changes to node layouts to persist the changes to graph
 * nodes.
 *
 * @param {Array<Object>} nodePositions The list of updated layouts in the shape
 * {id, x, y}
 * @param {string} workspaceId The ID of the active workspace
 * @param {ApolloClient} client The client to use to talk with the backend
 */
export function handleUpdateNodePositions(nodePositions, workspaceId, client) {
  if (!nodePositions || !nodePositions.length) {
    return;
  }

  let nodeUpdates = nodePositions.map(({ id, x, y }) => {
    return {
      id,
      x: x && roundGraphValue(x),
      y: y && roundGraphValue(y)
    };
  });

  handleNodePositionsChange(nodeUpdates, workspaceId, client);
}

/**
 * Updates a node's collapsed state on the server and in the Apollo cache.
 *
 * @param {string} nodeId The ID of the node to update
 * @param {boolean} isCollapsed True if the node is collapsed, false if not
 * @param {ApolloClient} client The client to use to talk with the backend
 */
export function handleUpdateNodeCollapsedState(nodeId, isCollapsed, client) {
  saveGraphLayoutUpdate(null, [{ id: nodeId, collapsed: isCollapsed }], client);
  // Update our store with the collapsed state, we don't need to wait for the server response
  updateNodeCollapsedStateInCache(nodeId, isCollapsed, client);
}

/**
 * Debounced version of handleUpdateGraphLayout.
 *
 * @param {DiagramModel} model The diagram model to get the updated information from
 * @param {string} workspaceId The ID of the active workspace
 * @param {ApolloClient} client The client to use to talk with the backend
 */
export const handleUpdateGraphLayoutDebounced = debounce(
  handleUpdateGraphLayout,
  500
);
