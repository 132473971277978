import Logger from 'util/Logger';
import UserContext from 'util/UserContext';
import { handleError } from 'util/snackUtils';

const CLI_AUTH_ERROR_MESSAGE = 'An issue happened during CLI authentication';

export default class BaseCLIAuth {
  constructor(client) {
    this.client = client;
    if (this.isAvailable()) {
      this.createAuth();
    }
  }

  /**
   * Lets the component using the CLI auth know when all the information and
   * APIs are available to use.
   */
  isAvailable() {
    return (
      window.MAANA_ENV.REACT_APP_CLI_AUTH_CLIENT_ID &&
      window.crypto &&
      window.crypto.subtle
    );
  }

  /**
   * Creates what is needed to use a specific auth provider, this is called in
   * the constructor when the information is available.
   *
   * Note:  Child classes need to have their own version of this function
   */
  createAuth() {}

  /**
   * Starts the process for getting the access token that the CLI will need.
   *
   * Note:  Child classes need to have their own version of this function
   */
  getToken() {}

  /**
   * Creates the data package that will be passed to the CLI with the auth
   * information it needs.
   *
   * Note:  Child classes need to have their own version of this function
   */
  getDataPackage() {}

  /**
   * Error handler for dealing with the cli authentication
   */
  handleError = err => {
    Logger.error(CLI_AUTH_ERROR_MESSAGE, err);
    handleError(this.client, CLI_AUTH_ERROR_MESSAGE, err);
  };

  /**
   * Build the generic data package for the CLI
   *
   * @param {string} accessTokenUri The URI to get the access token from
   */
  buildBaseDataPackage(accessTokenUri) {
    let urlParams = new URLSearchParams(window.location.search);
    if (
      urlParams.has('code') &&
      urlParams.has('state') &&
      urlParams.get('state') === UserContext.getVerifier()
    ) {
      return btoa(
        JSON.stringify({
          url: accessTokenUri,
          code: urlParams.get('code'),
          state: UserContext.getVerifier(),
          id: window.MAANA_ENV.REACT_APP_CLI_AUTH_CLIENT_ID,
          ruri: this.buildRedirectURI()
        })
      );
    }
    return null;
  }

  /**
   * Builds the redirect URI used by the different auth providers to return to
   * out application with the authentication information
   */
  buildRedirectURI() {
    return `${window.location.origin}/user`;
  }

  /**
   * Takes an ArrayBuffer and base64 encodes it, then does some special encoding
   * that Auth0 wants.
   *
   * @param {ArrayBuffer} buffer to base64 encode
   */
  base64URLEncode(buffer) {
    return btoa(String.fromCharCode(...buffer))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  /**
   * Dealing with preparing the text for passing into SHA-256.  Some browsers,
   * like Edge, do not support TextEncoder yet and needs to have a work around.
   *
   * @param {string} message The message to prepare for SHA-256.
   */
  encodeTextForSha256(message) {
    if (window.TextEncoder) {
      return new TextEncoder('utf-8').encode(message);
    } else {
      const buff = new Uint8Array(message.length);
      for (let index = 0; index < message.length; ++index) {
        buff[index] = message.charCodeAt(index);
      }
      return buff;
    }
  }

  /**
   * Creates a SHA-256 of the passed in string
   *
   * @param {string} str of which we want to create a SHA-256 hash
   */
  async sha256(str) {
    let buffer = this.encodeTextForSha256(str);
    let hash = await crypto.subtle.digest('SHA-256', buffer);
    return new Uint8Array(hash);
  }
}
