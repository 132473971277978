import * as defs from 'util/defines';

import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import workspaceResolvers, { workspaceDefaults } from 'resolvers/workspace';

import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import Logger from 'util/Logger';
import UserContext from 'util/UserContext';
import { WebSocketLink } from 'apollo-link-ws';
import { createHttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';
import { getRedirects } from './cacheRedirects';
import { getUserAuthClient } from 'util/Auth';
import gql from 'graphql-tag';
import { onError } from 'apollo-link-error';
import { print } from 'graphql';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';

/* eslint-disable */
/**
 * Client side type defs provide apollo plugin support for types stored in the local
 * cache. This includes linting and autocomplete. Note that it doesn't actually
 * enforce this schema.
 */
const typeDefs = gql`
  type Selected {
    id: ID!
    kindId: ID!
    kindName: String!
  }

  type Selection {
    id: ID!
    selected: [Selected!]!
    graphPathHistory: [[ID!]!]!
  }

  type NodeStateDetails {
    nodeId: ID!
    nodeState: Int
  }

  type NodeState {
    id: ID!
    state: [NodeStateDetails!]!
  }

  type SnackNotification {
    id: ID!
    notificationState: JSON
  }

  type FunctionExecutionArgument {
    id: ID
    value: JSON
  }

  type FunctionExecution {
    id: ID
    arguments: [FunctionExecutionArgument]
  }

  type FunctionResults {
    results: JSON
  }

  extend type Query {
    selection(id: ID!): Selection
    functionResults(id: ID!): FunctionResults
    subscribeToLinks: Boolean
    functionExecutionArgument(id: ID!): FunctionExecutionArgument
    functionExecution(id: ID!): FunctionExecution
    nodeState(id: ID!): NodeState
    snackNotification: SnackNotification
  }
`;

/* eslint-enable */

/**
 * Creates an apollo link wrapper for adding authentication headers.
 *
 * @return {ApolloLink} The apollo link auth wrapper
 */
function createAuthLink() {
  return setContext(async (_, { headers }) => {
    // Check to make sure authentication token is up to date
    await getUserAuthClient().checkTokenValidity();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...UserContext.getAuthHeader()
      }
    };
  });
}

/**
 * Creates and returns an apollo link item that will log information about
 * requests.
 */
function createLoggingLink() {
  let requestCount = 0;

  return new ApolloLink((operation, forward) => {
    const startingTime = Date.now();
    const currentRequestCount = requestCount;
    requestCount += 1;
    Logger.debug(`GraphQL Request ${currentRequestCount}:`, {
      query: print(operation.query)
    });

    return forward(operation).map(data => {
      const duration = Date.now() - startingTime;
      Logger.debug(
        `GraphQL Response ${currentRequestCount} with duration ${duration}ms:`,
        { data }
      );
      return data;
    });
  });
}

// Used to save the instance of the portal client for future use
let portalClient = null;

/**
 * Creates the apollo client for interacting with the portal service
 */
function createPortalClient() {
  const HTTPS_MODE = window.MAANA_ENV.PUBLIC_BACKEND_URI.startsWith('https:');

  // Create the http link
  const httpLink = createHttpLink({
    uri: `${window.MAANA_ENV.PUBLIC_BACKEND_URI}/graphql`
  });

  // Create the websocket link
  const wsLink = new WebSocketLink({
    uri: HTTPS_MODE
      ? `${window.MAANA_ENV.PUBLIC_BACKEND_URI.replace(
          'https://',
          'wss://'
        )}/subscriptions`
      : `${window.MAANA_ENV.PUBLIC_BACKEND_URI.replace(
          'http://',
          'ws://'
        ).replace('8040', '8041')}/subscriptions`,
    options: {
      reconnect: true
    }
  });

  // Create the auth link
  const authLink = createAuthLink();

  // Setup the cache used by apollo client
  const cache = new InMemoryCache({
    dataIdFromObject: object => {
      let defaultDataId = defaultDataIdFromObject(object);

      // When dealing with the cache redirects, they interpret undefined and
      // null differently.  A null value has a tendency to make the query get
      // lost in the aether.  More investigation into this is covered by UTD-31.
      if (!defaultDataId) return undefined;

      switch (object.__typename) {
        case defs.KN_INSTANCE:
          if (object.kindId) {
            return getInstanceCacheId(object.kindId, defaultDataId);
          } else if (object.kind && object.kind.id) {
            return getInstanceCacheId(object.kind.id, defaultDataId);
          }
          return defaultDataId;
        default:
          return defaultDataId;
      }
    },
    cacheRedirects: {
      Query: {
        ...getRedirects(() => cache)
      }
    }
  });

  // Catch unauthorized errors and log the user out
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError && networkError.statusCode === 401) {
      getUserAuthClient().showSessionExpiredDialog();
    }
  });

  // Build the base link
  const link = split(
    // Split based on operation type
    // queries and mutations will go over HTTP as normal, but subscriptions will be done over the websocket transport
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const linksToUse = [authLink, errorLink, link];

  if (Logger.isDebugEnabled()) {
    // Add logging to requests
    const loggingLink = createLoggingLink();
    linksToUse.unshift(loggingLink);
  }

  // Create the portal client
  portalClient = new ApolloClient({
    cache,
    resolvers: workspaceResolvers,
    dataIdFromObject: object => object.id,
    link: ApolloLink.from(linksToUse),
    typeDefs
  });

  // Make sure that the client state is reset when the cache is reset
  cache.writeData({ data: workspaceDefaults });
  portalClient.onResetStore(() => cache.writeData({ data: workspaceDefaults }));
}

/**
 * Used to get the Apollo Client for talking to the portal service.  Creates it
 * if it does not exist.
 *
 * @return {ApolloClient} The Apollo Client for talking with the portal service.
 */
export function getPortalClient() {
  if (!portalClient) {
    createPortalClient();
  }

  return portalClient;
}

/**
 * Creates the Apollo Cache ID for an Instance.
 *
 * @param {String} kindId The ID of the Kind the Instance is an Instance of
 * @param {String} instanceId The ID of the Instance
 */
export function getInstanceCacheId(kindId, instanceId) {
  return `${kindId}:${instanceId}`;
}
