import { FieldTypes, KN_FUNCTION, KN_KIND } from 'util/defines';

import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { removeOperationsFromImplementation } from 'functions/function';
import { updateSelection } from 'util/selectionUtils';

const CleanWorkspaceQuery = gql`
  query CleanWorkspace($id: ID!) {
    workspace(id: $id) {
      id
      workspaceServiceId
      modelServiceId
      logicServiceId
      activeGraphPath
      portalGraphs {
        id
        nodes {
          id
          knowledgeGraphNode {
            id
            instance {
              id
              kindId
            }
          }
          functionGraphNode {
            id
            operationId
          }
        }
        function {
          id
          implementation {
            id
            entrypoint {
              id
            }
            operations {
              id
              function {
                id
              }
            }
          }
        }
        workspace {
          id
        }
      }
      services {
        id
        aggregatedServices {
          id
        }
      }
      inventory {
        serviceKinds {
          id
          serviceId
          service {
            id
          }
        }
        workspaceKinds {
          id
        }
        functions {
          id
          service {
            id
          }
        }
      }
      instanceRefs {
        id
        instance {
          id
          kindId
        }
      }
    }
  }
`;

const CleanWorkspaceServiceKindsQuery = gql`
  query CleanWorkspaceServiceKinds($id: ID!) {
    service(id: $id) {
      id
      kinds {
        id
      }
    }
  }
`;

const CleanWorkspaceServiceFunctionsQuery = gql`
  query CleanWorkspaceServiceFunctions($id: ID!) {
    service(id: $id) {
      id
      functions {
        id
      }
    }
  }
`;

const KindSchemaTypesFragment = gql`
  fragment KindSchemaTypes on Kind {
    id
    schema {
      id
      type
      kind {
        id
      }
    }
  }
`;

const FunctionArgumentOutputTypesFragment = gql`
  fragment FunctionArgumentOutputTypes on Function {
    id
    arguments {
      id
      type
      typeKindId
      kind {
        id
      }
    }
    outputType
    outputKindId
    kind {
      id
    }
  }
`;

/**
 * Removed the usage of the given kind IDs from the workspace's kind fields and
 * functions arguments and output.
 *
 * @param {Array<string>} kindIds The IDs of the kinds that are being removed
 * @param {Workspace} workspace The Workspace to clean up the kinds and functions of
 * @param {ApolloClient} client The client to use to interact with the cache
 */
function removeKindsFromServiceKindsAndFunctions(kindIds, workspace, client) {
  // Clean up the fields that are referencing one of the deleted kinds
  workspace.inventory.serviceKinds.forEach(k => {
    // As global kinds can be added to workspace, kinds must be checked for
    // having service identified first.
    if (
      (k.service && k.service.id !== workspace.modelServiceId) ||
      !k.service
    ) {
      return;
    }

    try {
      let updated = false;
      const fragmentId = `${KN_KIND}:${k.id}`;
      const kind = client.readFragment({
        id: fragmentId,
        fragment: KindSchemaTypesFragment
      });

      kind.schema.forEach(field => {
        if (
          field.type === FieldTypes.KIND &&
          field.kind &&
          kindIds.includes(field.kind.id)
        ) {
          updated = true;
          field.type = FieldTypes.STRING;
          field.kind = null;
        }
      });

      if (updated) {
        client.writeFragment({
          id: fragmentId,
          fragment: KindSchemaTypesFragment,
          data: kind
        });
      }
    } catch {
      // Not all kinds have the schema information loaded yet, but
      // readFragment throws when some of the information is missing
    }
  });

  // Clean up function arguments and output that are referencing one of the deleted kinds
  workspace.inventory.functions.forEach(f => {
    if (f.service.id !== workspace.logicServiceId) return;

    try {
      let updated = false;
      const fragmentId = `${KN_FUNCTION}:${f.id}`;
      const func = client.readFragment({
        id: fragmentId,
        fragment: FunctionArgumentOutputTypesFragment
      });

      func.arguments.forEach(arg => {
        if (arg.type === FieldTypes.KIND && kindIds.includes(arg.typeKindId)) {
          updated = true;
          arg.type = FieldTypes.STRING;
          arg.typeKindId = null;
          arg.kind = null;
        }
      });

      if (
        func.outputType === FieldTypes.KIND &&
        kindIds.includes(func.outputKindId)
      ) {
        updated = true;
        func.outputType = FieldTypes.STRING;
        func.outputKindId = null;
        func.kind = null;
      }

      if (updated) {
        client.writeFragment({
          id: fragmentId,
          fragment: FunctionArgumentOutputTypesFragment,
          data: func
        });
      }
    } catch {
      // Not all kinds have the schema information loaded yet, but
      // readFragment throws when some of the information is missing
    }
  });
}

/**
 * Removes references to instances in a Workspace.
 *
 * @param {Array<string>} instanceIds IDs of instances to remove references to.
 * @param {Object} workspace The Workspace object in which to remove references.
 * @param {Object} store The Apollo store.
 */
function removeInstanceReferences(instanceIds, workspace, store) {
  if (!isEmpty(instanceIds)) {
    workspace.instanceRefs = workspace.instanceRefs.filter(
      ir => !instanceIds.includes(ir.instance.id)
    );
    workspace.portalGraphs
      .filter(graph => graph.workspace.id === workspace.id)
      .forEach(pg => {
        let removedFunctionOperations = [];
        pg.nodes = pg.nodes.filter(pgn => {
          if (
            pgn.knowledgeGraphNode &&
            instanceIds.includes(pgn.knowledgeGraphNode.instance.id)
          ) {
            return false;
          }

          if (
            pgn.functionGraphNode &&
            pg.function &&
            pg.function.implementation
          ) {
            const ops = pg.function.implementation.operations.filter(
              op =>
                op.id === pgn.functionGraphNode.operationId &&
                instanceIds.includes(op.function.id)
            );
            if (ops.length) {
              removedFunctionOperations = removedFunctionOperations.concat(
                ops.map(op => op.id)
              );
              return false;
            }
          }

          return true;
        });

        // If nodes are removed from a function graph, then remove them from its
        // function's implementation also.
        const func = pg.function;
        if (removedFunctionOperations.length && func) {
          removeOperationsFromImplementation({
            client: store,
            func,
            implementation: func.implementation,
            removedOperationIds: removedFunctionOperations,
            persistChanges: false
          });
        }
      });
  }
}

/**
 * Cleans out removed Kinds and Functions from the cached Workspace services.
 *
 * @param {Object} workspace The Workspace object with workspaceServiceId, logicServiceId, and modelServiceId.
 * @param {Array<string>} kindIds Ids of Kinds that have been removed
 * @param {Array<string>} functionIds Ids of Functions that have been removed
 * @param {Object} transactionClient The apollo client to access the store through
 */
function cleanWorkspaceServices(
  workspace,
  kindIds,
  functionIds,
  transactionClient
) {
  for (const serviceId of [
    workspace.workspaceServiceId,
    workspace.logicServiceId,
    workspace.modelServiceId
  ]) {
    if (kindIds?.length) {
      try {
        const { service } = transactionClient.readQuery({
          query: CleanWorkspaceServiceKindsQuery,
          variables: {
            id: serviceId
          }
        });
        service.kinds = service.kinds.filter(
          kind => !kindIds.includes(kind.id)
        );
        transactionClient.writeQuery({
          query: CleanWorkspaceServiceKindsQuery,
          variables: { id: service.id },
          data: { service }
        });
      } catch (ex) {
        // Ignore if the service isn't in the cache
      }
    }

    if (functionIds?.length) {
      try {
        const { service } = transactionClient.readQuery({
          query: CleanWorkspaceServiceFunctionsQuery,
          variables: {
            id: serviceId
          }
        });
        service.functions = service.functions.filter(
          func => !functionIds.includes(func.id)
        );
        transactionClient.writeQuery({
          query: CleanWorkspaceServiceFunctionsQuery,
          variables: { id: service.id },
          data: { service }
        });
      } catch (ex) {
        // Ignore if the service isn't in the cache
      }
    }
  }
}

/**
 * Cleans removed or deleted items out of the supplied workspace, and then
 * updates it in the cache.
 *
 * @param {Object} data The data passed into cleanWorkspace
 * @param {string} data.id The id of the workspace to clean
 * @param {Array<string>} data.kindIds Id of kinds that have been removed
 * @param {Array<string>} data.functionIds Id of functions that have been removed
 * @param {Array<string>} data.instanceIds Id of instances that have been removed
 * @param {Array<string>} data.graphIds Id of graphs that have been removed
 * @param {Array<string>} data.serviceIds Id of services that have been removed
 * @param {boolean} data.cleanKindsFromFields When true the fields and arguments
 *    in the kinds and functions will be checked to see if they are a type of
 *    one of the cleaned kinds
 * @param {Object} data.client The apollo client to access the store through
 */
export default function cleanWorkspace({
  workspaceId,
  kindIds,
  functionIds,
  instanceIds,
  graphIds,
  serviceIds,
  cleanKindsFromFields,
  client
}) {
  // Using the perform transaction function in Apollo In-Memory Cache lets the
  // following code perform multiple writes to the cache and not cause a render
  // of the page until it's done.
  client.cache.performTransaction(transactionClient => {
    try {
      const { workspace } = transactionClient.readQuery({
        query: CleanWorkspaceQuery,
        variables: { id: workspaceId }
      });

      // filter out the removed services
      if (!isEmpty(serviceIds)) {
        workspace.services = workspace.services.filter(
          s => !serviceIds.includes(s.id)
        );
      }

      // Get a list of ids for the services in the workspace, including their
      // aggregated services.
      const servicesInWorkspace = workspace.services.flatMap(s =>
        [s.id].concat(s?.aggregatedServices.map(as => as.id) ?? [])
      );

      // filter out the portal graphs
      const graphsToFilter = (graphIds || []).concat(functionIds || []);
      if (!isEmpty(graphsToFilter)) {
        workspace.portalGraphs = workspace.portalGraphs.filter(
          pg => !graphsToFilter.includes(pg.id)
        );

        if (graphsToFilter.some(id => workspace.activeGraphPath.includes(id))) {
          workspace.activeGraphPath = [];
        }
      }

      // kinds to filter from the inventory
      if (!isEmpty(kindIds)) {
        // When filtering the service kinds, only remove the kinds from the Workspace
        // model service. Do not remove them from the service they belong to.
        workspace.inventory.serviceKinds = workspace.inventory.serviceKinds.filter(
          k =>
            !(
              k.service &&
              (k.service.id === workspace.modelServiceId ||
                !servicesInWorkspace.includes(k.service.id)) &&
              kindIds.includes(k.id)
            )
        );

        // workspaceKinds is built based on what kinds are in the workspace's
        // instanceRefs collection, so all removed kinds should be removed from it.
        workspace.inventory.workspaceKinds = workspace.inventory.workspaceKinds.filter(
          k => !kindIds.includes(k.id)
        );

        // When requested clean up the references to the kinds we are removing
        // from the kinds and functions of the workspace
        if (cleanKindsFromFields) {
          removeKindsFromServiceKindsAndFunctions(kindIds, workspace, client);
        }
      }

      // When filtering the functions, only remove the functions from the Workspace
      // model and logic services. Do not remove them from the service they belong to.
      if (!isEmpty(functionIds)) {
        workspace.inventory.functions = workspace.inventory.functions.filter(
          f =>
            !(
              f.service &&
              (f.service.id === workspace.logicServiceId ||
                f.service.id === workspace.modelServiceId ||
                !servicesInWorkspace.includes(f.service.id)) &&
              functionIds.includes(f.id)
            )
        );
      }

      // Filter out the instances
      const instancesToFilter = (kindIds || [])
        .concat(instanceIds || [])
        .concat(functionIds || []);
      removeInstanceReferences(instancesToFilter, workspace, transactionClient);

      // save the cleaned workspace
      transactionClient.writeQuery({
        query: CleanWorkspaceQuery,
        variables: { id: workspaceId },
        data: { workspace }
      });

      cleanWorkspaceServices(
        workspace,
        kindIds,
        functionIds,
        transactionClient
      );

      // update the selection
      updateSelection({ workspaceId, client: transactionClient });
    } catch {
      // The Workspace has not been loaded yet, so it does not need to be
      // cleaned.  This is generally triggered by the Assistant API deleting
      // something in a workspace that is not the current one.
    }
  });

  // This is required to be called with the use of performTransaction to make
  // sure that apollo sends the updated data to the different query components
  // throughout the app.
  client.queryManager.broadcastQueries();
}
