import React from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const SearchTextInput = styled(TextField)`
  display: flex;
  min-width: 180px;
  max-width: 400px;
`;

export default function Input(props) {
  return <SearchTextInput placeholder="Search" {...props} />;
}
