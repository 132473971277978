import { FieldValueDetailsFragment, FileQuery } from 'graphql/Portal';
import {
  GraphNodes,
  GraphType,
  HTTP_OK,
  HTTP_PROCESSING,
  ICON_DIR,
  KN_FILE
} from './defines';
import {
  addNodesToGraph,
  getActiveGraphFromCache
} from 'util/graph/portalGraph';
import { buildInstanceRefId, mkUrl } from 'util/helpers';
import { getGraphLockedBy, getWorkspaceLockedBy } from './permissions';
import { handleError, showSnack } from 'util/snackUtils';

import FineUploaderTraditional from 'fine-uploader-wrappers';
import Logger from './Logger';
import { addFilesToWorkspace } from './workspace';
import { calculateNodePositions } from 'util/graph/layout';
import fetch from 'isomorphic-fetch';
import gql from 'graphql-tag';
import { removeReferencesToDeletedEntities } from './entity';
import uuidv4 from 'uuid/v4';

const FileInstanceRefDetailsQuery = gql`
  query FileInstanceRefDetails(
    $tenantId: ID!
    $instanceRef: InstanceRefInput!
  ) {
    populateInstanceRef(tenantId: $tenantId, instanceRef: $instanceRef) {
      id
      kindId
      kindName
      kind {
        id
        schema {
          id
          name
          type
        }
      }
      instance {
        id
        kindId
        fieldIds
        fieldValues {
          ...FieldValueDetails
        }
      }
    }
  }
  ${FieldValueDetailsFragment}
`;

const UpdateFileMutation = gql`
  mutation UpdateFile($input: UpdateFileInput!) {
    updateFile(input: $input)
  }
`;

const AddFilesMutation = gql`
  mutation AddFiles($input: [AddFileInput!]!) {
    addFiles(input: $input)
  }
`;

const DeleteFileMutation = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id) {
      updatedWorkspaces {
        id
      }
      updatedPortalGraphs {
        id
      }
      deletedInstanceRefIds
      deletedPortalGraphNodeIds
    }
  }
`;

const getIconForFileType = filename => {
  const ext = filename.substr(filename.lastIndexOf('.') + 1);
  const icon = ext || 'bin';
  const thumbnailUrl = `${ICON_DIR}${icon}.svg`;

  return thumbnailUrl;
};

/**
 * Handles uploading files
 */
class FileUploader {
  client = null;
  workspaceId = null;
  modelServiceId = null;
  uploader = null;
  authHeader = null;
  getGraphDimensions = null;
  diagramEngine = null;

  constructor() {
    const endpoint =
      window.MAANA_ENV && window.MAANA_ENV.REACT_APP_PORTAL_UPLOAD_URL;

    // Setup the module that handles uploading the file to the storage server
    this.uploader = new FineUploaderTraditional({
      options: {
        autoUpload: false,
        multiple: true,
        request: { endpoint },
        chunking: { enabled: true },
        resume: { enabled: true },
        validation: {
          itemLimit: 999
        },
        callbacks: {
          onComplete: this.onComplete,
          onAllComplete: this.onAllComplete,
          onError: this.onError,
          onProgress: this.onProgress,
          onStatusChange: this.onStatusChange
        }
      }
    });
  }

  /**
   * Sets the apollo client, workspace ID, and model service ID used when
   * updating the uploading files.
   *
   * @param {ApolloClient} client The client to use to make mutations and check the cache
   * @param {string} workspaceId The ID of the workspace to work with
   * @param {string} modelServiceId The ID of the model service to work with
   * @param {function} getGraphDimensions Retrieves the dimensions of the graph
   * @param {DiagramEngine} diagramEngine The diagram engine to use when laying out new file nodes
   */
  setSettings(
    client,
    workspaceId,
    modelServiceId,
    getGraphDimensions,
    diagramEngine
  ) {
    this.client = client;
    this.workspaceId = workspaceId;
    this.modelServiceId = modelServiceId;
    this.getGraphDimensions = getGraphDimensions;
    this.diagramEngine = diagramEngine;
  }

  /**
   * Handles updating the file when it finishes uploading to the storage server
   *
   * @param {string} id The ID of the file to update
   * @param {string} name The name of the file
   * @param {Object} response The response information for the finished upload
   */
  onComplete = (id, name, response) => {
    if (response.success) {
      this.updateFile({
        id: response.uuid,
        progress: String(this.uploader.methods.getSize(id)),
        status: HTTP_OK,
        mimeType: response.mimeType,
        serviceId: this.modelServiceId
      });
    }
  };

  /**
   * Handles displaying information when all uploads are complete
   *
   * @param {Array<Object>} succeeded The files that were successful
   * @param {Array<Object>} failed The files that failed
   */
  onAllComplete = (succeeded, failed) => {
    showSnack(
      {
        message: `File upload complete: ${succeeded.length} succeeded / ${failed.length} failed`,
        autoHideDuration: 5000
      },
      this.client
    );
  };

  /**
   * Handles updating a file when it fails to upload
   *
   * @param {string} id The ID of the file
   * @param {string} name The name of the file
   * @param {string} errorReason The reason the error happened
   * @param {Object} xhr The XHR object with information about the request that failed
   */
  onError = (id, name, errorReason, xhr) => {
    Logger.error('onError:', id, name, errorReason, xhr);
    this.updateFile({
      id: this.uploader.methods.getUuid(id),
      status: xhr.status,
      serviceId: this.modelServiceId
    });
  };

  /**
   * Handles updating a file with upload progress
   *
   * @param {string} id The ID of the file
   * @param {string} name The name of the file
   * @param {int} uploadedBytes The number of bytes uploaded
   * @param {int} totalBytes The total size of the file in bytes
   */
  onProgress = (id, name, uploadedBytes, totalBytes) => {
    if (uploadedBytes !== totalBytes) {
      this.updateFile(
        {
          id: this.uploader.methods.getUuid(id),
          progress: String(uploadedBytes)
        },
        true
      );
    }
  };

  /**
   * Handles adding the files to the workspace and graph once it has moved from
   * 'submitting' to 'submitted'.  This will also start the upload progress for
   * the file to the storage server.
   *
   * @param {string} id The ID of the file
   * @param {string} oldStatus The old status of the file
   * @param {string} newStatus The new status of the file
   */
  onStatusChange = (id, oldStatus, newStatus) => {
    if (newStatus === 'submitted') {
      const uploads = this.uploader.methods.getUploads({
        status: ['submitting']
      });
      if (uploads.length <= 0) {
        this.handleFileDrop();
      }
    }
  };

  /**
   * Handles sending update information on a file to the server, as well as
   * updating the cache
   *
   * @param {Object} input The changed information in the file
   * @param {boolean} storeOnly When true only the local cache is updated
   */
  updateFile = (input, storeOnly) => {
    const update = store => {
      const storeData = store.readQuery({
        query: FileQuery,
        variables: { id: input.id }
      });
      storeData.file = Object.assign(storeData.file, input);
      store.writeQuery({
        query: FileQuery,
        variables: { id: input.id },
        data: storeData
      });

      try {
        const { populateInstanceRef } = store.readQuery({
          query: FileInstanceRefDetailsQuery,
          variables: {
            tenantId: 0,
            instanceRef: {
              id: buildInstanceRefId(this.workspaceId, input.id),
              kindName: KN_FILE
            }
          }
        });

        let fileInstanceRef = { ...populateInstanceRef };
        if (fileInstanceRef.instance && fileInstanceRef.kind) {
          fileInstanceRef.kind.schema.forEach(field => {
            let fieldIndex = fileInstanceRef.instance.fieldIds.indexOf(
              field.id
            );
            if (fieldIndex >= 0 && input[field.name]) {
              fileInstanceRef.instance.fieldValues[fieldIndex] = {
                ...fileInstanceRef.instance.fieldValues[fieldIndex],
                [field.type]: input[field.name]
              };
            }
          });
        }

        store.writeQuery({
          query: FileInstanceRefDetailsQuery,
          variables: {
            tenantId: 0,
            instanceRef: {
              id: buildInstanceRefId(this.workspaceId, input.id),
              kindName: KN_FILE
            }
          },
          data: { populateInstanceRef: fileInstanceRef }
        });
      } catch (e) {
        Logger.info(`No instanceRef of the file '${input.name}' to update`);
      }
    };

    // if we just want to update the store, then we don't need to worry about
    // sending out the update file mutation (useful to update the display of
    // the progress bar during updating)
    if (storeOnly) {
      update(this.client);
    } else {
      this.client
        .mutate({
          mutation: UpdateFileMutation,
          variables: { input },
          update
        })
        .catch(error =>
          handleError(this.client, 'Failed to update the file', error)
        );
    }
  };

  /**
   * Sets the authentication headers used for file uploading
   *
   * @param {Object} authHeader The authentication header to use
   */
  setAuthenticationHeader(authHeader) {
    this.uploader.methods.setCustomHeaders(authHeader);
    this.authHeader = authHeader;
  }

  /**
   * Handles adding a file to the workspace when it has been dropped on the
   * graph or uploaded through the file upload dialog.
   */
  handleFileDrop = async () => {
    const { workspaceId, client, modelServiceId, uploader } = this;
    if (!(workspaceId && modelServiceId && client)) return;

    const { canEdit: canEditWorkspace } = await getWorkspaceLockedBy(
      workspaceId,
      client
    );
    if (!canEditWorkspace) return;

    const graph = getActiveGraphFromCache(workspaceId, client);
    if (!graph) return;

    const { canEdit: canEditGraph } = await getGraphLockedBy(
      graph.id,
      workspaceId,
      client
    );

    const uploads = uploader.methods.getUploads({ status: ['submitted'] });
    const files = uploads.map(upload => ({
      id: upload.uuid,
      name: upload.name,
      size: String(upload.size),
      mimeType: upload.file.type,
      url: mkUrl({
        baseUrl: `${window.MAANA_ENV.REACT_APP_PORTAL_DOWNLOAD_URL}`,
        basePath: upload.uuid,
        fileName: upload.name
      }),
      thumbnailUrl: getIconForFileType(upload.name),
      status: HTTP_PROCESSING,
      serviceId: modelServiceId
    }));

    try {
      await client.mutate({
        mutation: AddFilesMutation,
        variables: { input: files },
        update: (store, { data }) => {
          files.forEach(input => {
            const storeData = {
              file: {
                __typename: KN_FILE,
                progress: '0',
                mimeType: '',
                ...input
              }
            };
            store.writeQuery({
              query: FileQuery,
              variables: { id: input.id },
              data: storeData
            });
          });
        }
      });
    } catch (error) {
      // clean up when adding files fail.
      uploads.forEach(upload => {
        uploader.methods.cancel(upload.id);
      });
      uploader.methods.clearStoredFiles();
      handleError(client, 'Failed to add the files', error);
      return;
    }

    if (canEditGraph && graph.type === GraphType.KNOWLEDGE) {
      const positions = calculateNodePositions(
        files.map(() => ({
          width: GraphNodes.WIDTH_FILE,
          height: GraphNodes.HEIGHT_FILE
        })),
        this.getGraphDimensions(),
        this.diagramEngine
      );

      const nodes = files.map((file, index) => ({
        id: uuidv4(),
        width: GraphNodes.WIDTH_FILE,
        height: GraphNodes.HEIGHT_FILE,
        knowledgeGraphNode: {
          id: uuidv4(),
          kindName: KN_FILE,
          instanceId: file.id
        },
        ...positions[index]
      }));

      try {
        await addNodesToGraph({
          workspaceId,
          client,
          graphId: graph.id,
          nodes,
          changeSelection: true
        });
      } catch (error) {
        handleError(
          client,
          'Failed to add the Files to the Knowledge Graph',
          error
        );
      }
    } else {
      try {
        await addFilesToWorkspace(
          workspaceId,
          files.map(file => file.id),
          client
        );
      } catch (error) {
        handleError(client, 'Failed to add the Files to the Workspace', error);
      }
    }

    // start uploading them
    uploader.methods.uploadStoredFiles();
  };

  handleCancelFile = async fileId => {
    const { client, uploader } = this;
    // cancel the upload from the client side
    let id = uploader.methods.getUploads({ uuid: fileId }).id;
    uploader.methods.cancel(id);

    // delete the file instance, and remove it from the workspace
    try {
      await client.mutate({
        mutation: DeleteFileMutation,
        variables: {
          id: fileId
        },
        update: (store, { data }) => {
          removeReferencesToDeletedEntities(data.deleteFile, store);
          //TODO QP-483: Update selection to remove deleted File
        }
      });
    } catch (error) {
      handleError(client, 'Failed to cancel the file upload', error);
      return;
    }

    // tell the storage server to delete the file
    let deleteUrl = new URL(
      `${window.MAANA_ENV.REACT_APP_PORTAL_UPLOAD_URL}/${fileId}`
    );
    fetch(deleteUrl, {
      method: 'DELETE',
      headers: this.authHeader
    });
  };
}

export default new FileUploader();
