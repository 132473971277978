import omitDeep from 'omit-deep';

/**
 * Does a deep-omit on an object.
 * This will also omit properties from objects in arrays.
 *
 * @param {Object} obj The object to have __typename fields removed.
 * @returns {Object} Returns a reference to original object that has now
 * been mutated.
 */
export function omitTypename(obj) {
  return omitDeep(obj, ['__typename']);
}
