//
// The base DOM renderer.
// Features:
// - a service worker that ??
// - an Apollo GraphQL client with various capabilities and settings
// - Apollo links to local and remote GraphQL providers
// - the React DOM renderer that does all the magic to build
//   the Knowledge Portal experience
// - (add to this list as appropriate, please)
//
// Add support for ie11 (must be first line in this file!)
import 'react-app-polyfill/ie11';
import 'polyfill';

import { ApolloContextProvider } from 'components/context';
import { ApolloProvider } from 'react-apollo';
import { AuthContextProvider } from 'components/context';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Portal from './Portal'; // main application
import React from 'react';
import ReactDOM from 'react-dom';
import RegisterServiceWorker from './components/RegisterServiceWorker';
import { Router } from 'react-router-dom';
import { getPortalClient } from './graphql/clients';
import { getUserAuthClient } from 'util/Auth';
import history from 'util/history';

const client = getPortalClient();
const authClient = getUserAuthClient();

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <ApolloContextProvider>
        <DndProvider backend={HTML5Backend}>
          <RegisterServiceWorker />
          <AuthContextProvider authClient={authClient}>
            <Router history={history}>
              <Portal />
            </Router>
          </AuthContextProvider>
        </DndProvider>
      </ApolloContextProvider>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);
