import Auth0Lock from 'auth0-lock';
import BaseCLIAuth from './BaseCLIAuth';
import UserContext from 'util/UserContext';

export default class Auth0CLIAuth extends BaseCLIAuth {
  async createAuth() {
    const clientId = window.MAANA_ENV.REACT_APP_CLI_AUTH_CLIENT_ID;
    const authDomain = window.MAANA_ENV.REACT_APP_CLI_AUTH_DOMAIN;
    const authIdentifier = window.MAANA_ENV.REACT_APP_PORTAL_AUTH_IDENTIFIER;
    const audience = window.MAANA_ENV.AUTH_AUDIENCE || authIdentifier;

    // create some data needed for Auth0
    let randomVals = new Uint8Array(43);
    window.crypto.getRandomValues(randomVals);
    let verifier = this.base64URLEncode(randomVals);
    let challenge = this.base64URLEncode(await this.sha256(verifier));

    UserContext.setVerifier(btoa(verifier));

    this.auth0 = new Auth0Lock(clientId, authDomain, {
      theme: {
        logo: process.env.PUBLIC_URL + '/logo-sm.png',
        primaryColor: '#2195f3'
      },
      autoClose: true,
      closable: true,
      rememberLastLogin: false,
      languageDictionary: {
        title: 'Maana Knowledge CLI'
      },
      auth: {
        redirect: true,
        redirectUrl: this.buildRedirectURI(),
        audience: authIdentifier,
        responseType: 'code',
        params: {
          audience,
          response_type: 'code',
          code_challenge: challenge,
          code_challenge_method: 'S256',
          scope: 'offline_access email openid',
          state: btoa(verifier)
        }
      }
    });
  }

  getToken() {
    this.auth0.show();
  }

  getDataPackage() {
    const authDomain = window.MAANA_ENV.REACT_APP_CLI_AUTH_DOMAIN;
    return this.buildBaseDataPackage(`https://${authDomain}/oauth/token`);
  }
}
