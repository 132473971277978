import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  height: 22px;
  margin-top: ${p => p.theme.spacing(1)}px;
  margin-right: ${p => p.theme.spacing(1)}px;
`;

export function Logo({ className }) {
  return (
    <Image
      className={className}
      src={process.env.PUBLIC_URL + '/logo.png'}
      alt="logo"
    />
  );
}

export const LogoAbsolute = styled(Logo)`
  position: absolute;
  top: 0;
  left: 0;
  margin: ${p => p.theme.spacing(3)}px;
`;
