import {
  ANNOTATION_MIME_TYPE,
  FieldNames,
  GraphType,
  KN_ANNOTATION,
  KN_FUNCTION,
  KN_KIND,
  KN_PORTAL_GRAPH,
  KN_PORTAL_GRAPH_NODE,
  KindUUID
} from 'util/defines';
import {
  AssistantPortalGraphNodeFragment,
  AssistantPortalGraphNodeFragmentName,
  PortalGraphTypeAndFunctionQuery
} from './graphql';
import {
  addPortalGraph,
  createAnnotationInGraphWithNodeInfo,
  getActiveGraphIdFromCache,
  removeNodesFromPortalGraph
} from 'util/graph/portalGraph';
import { getFileKind, getFunctionKindId } from 'util/helpers';
import {
  handleUpdateGraphLayout,
  handleUpdateNodeCollapsedState,
  handleUpdateNodePositions
} from 'util/graph/handleGraphUpdated';
import { isBoolean, isEmpty, isNil, omit } from 'lodash';

import { addInstanceAsGraphNode } from 'util/graph';
import { getGraphLockedBy } from 'util/permissions';
import { getInstanceFieldValueByName } from 'util/instanceUtils';
import gql from 'graphql-tag';
import { omitTypename } from './omitTypename';

const INACTIVE_GRAPH_ERROR_MESSAGE =
  'Only the active graph can be manipulated.';

const AssistantGetPortalGraphQuery = gql`
  query AssistantGetPortalGraph($id: ID!) {
    portalGraph(id: $id) {
      id
      name
      type
      zoom
      offsetX
      offsetY
      nodes {
        ...AssistantPortalGraphNode
      }
    }
  }
  ${AssistantPortalGraphNodeFragment}
`;

const AssistantPortalGraphLayoutQuery = gql`
  query AssistantPortalGraphLayout($id: ID!) {
    portalGraph(id: $id) {
      id
      zoom
      offsetX
      offsetY
    }
  }
`;

const AssistantNodeLayoutFragment = gql`
  fragment AssistantNodeLayout on PortalGraphNode {
    id
    x
    y
    collapsed
  }
`;
const AssistantGraphLayoutFragment = gql`
  fragment AssistantGraphLayoutDetails on PortalGraph {
    id
    zoom
    offsetX
    offsetY
  }
`;

const UpdateGraphLayoutMutation = gql`
  mutation AssistantUpdateGraphLayout($input: GraphLayoutUpdateInput!) {
    updateGraphLayout(input: $input)
  }
`;

/**
 * Gets a portal graph by its ID.
 *
 * @param {string} id The graph ID.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {PortalGraph} The portal graph object.
 */
const getPortalGraphById = async (id, client) => {
  const { data } = await client.query({
    query: AssistantGetPortalGraphQuery,
    variables: { id }
  });
  return data.portalGraph;
};

/**
 * Determines if a graph is active based on its graph ID.
 *
 * @param {string} graphId The graph ID.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {boolean} Whether the graph is the active graph.
 *
 */
function isActiveGraph(graphId, workspaceId, client) {
  const activeId = getActiveGraphIdFromCache(workspaceId, client);
  return activeId === graphId;
}

/**
 * Returns the function graph connected to the supplied function id.
 *
 * @param {string} id ID of the function to look up the graph with.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise<Object>} The graph information.
 */
export async function getFunctionGraph(
  id,
  workspaceId,
  diagramEngine,
  getGraphDimensions,
  client
) {
  try {
    const res = await client.query({
      query: PortalGraphTypeAndFunctionQuery,
      variables: { id }
    });

    return getGraphObject(
      res.data.portalGraph,
      workspaceId,
      diagramEngine,
      getGraphDimensions,
      client,
      true
    );
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API error in getFunctionGraph: ${ex.message}`)
    );
  }
}

function isAnnotationNode(kgNode, fileKind) {
  if (!fileKind || kgNode?.instance?.kindId !== fileKind.id) return false;
  const mimeType = getInstanceFieldValueByName(
    fileKind,
    kgNode.instance,
    FieldNames.MIME_TYPE
  );
  return mimeType === ANNOTATION_MIME_TYPE;
}

/**
 * Gets a graph object based on id, name and type properties of an input object.
 * Helper : Not exposed via listener.
 *
 * @param {Object} graph A graph object with id, name, and type properties.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @param {boolean} typeOverride Overrides the graph type check that only
 *    allow KnowledgeGraphs to be set.  This also only gives a subset of the
 *    normal functionality.
 * @return {Object} A composite object.
 */
export async function getGraphObject(
  graph,
  workspaceId,
  diagramEngine,
  getGraphDimensions,
  client,
  typeOverride = false
) {
  const { id, name, type } = graph;

  // Only send over knowledge graphs
  if (type !== GraphType.KNOWLEDGE && !typeOverride) return null;

  // Get graph layout info.
  const { data } = await client.query({
    query: AssistantPortalGraphLayoutQuery,
    variables: { id }
  });

  if (!data.portalGraph) {
    throw Error('Graph does not exist.');
  }

  const { offsetX, offsetY, zoom } = data.portalGraph;

  const graphObject = {
    id,
    name,
    offsetX,
    offsetY,
    zoom,

    // Add the locking properties to the Object in a way that each time the
    // assistant calls them they are are returning up to date information about
    // its current locked state.

    async lockedBy() {
      try {
        const { lockedBy } = await getGraphLockedBy(id, workspaceId, client);
        return lockedBy;
      } catch {
        // Return null if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return null;
      }
    },
    async canEdit() {
      try {
        const { canEdit } = await getGraphLockedBy(id, workspaceId, client);
        return canEdit;
      } catch {
        // Return false if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return false;
      }
    },
    async setLocked(isLocked) {
      try {
        const { setLockedBy } = await getGraphLockedBy(id, workspaceId, client);
        return setLockedBy(isLocked);
      } catch {
        // Return null if the Information is not in the cache yet.  This is
        // generally caused by inventory changed events while the workspace is
        // loading.  When the lock information is updated, an event will be
        // triggered.
        return null;
      }
    },

    /**
     * Returns an array of kind and function nodes on the
     * knowledge graph.
     *
     * @return {Array<Node>} Kind and function nodes.
     */
    getNodes: async () => {
      try {
        const graph = await getPortalGraphById(id, client);
        const fileKind = getFileKind(client);
        if (graph.nodes) {
          return omitTypename(
            graph.nodes
              .filter(
                node =>
                  typeOverride ||
                  node.knowledgeGraphNode.innerFunction ||
                  node.knowledgeGraphNode.innerKind ||
                  isAnnotationNode(node.knowledgeGraphNode, fileKind)
              )
              .map(n => {
                if (
                  typeOverride ||
                  !isAnnotationNode(n.knowledgeGraphNode, fileKind)
                ) {
                  return n;
                }

                const { instance } = n.knowledgeGraphNode;

                const url = getInstanceFieldValueByName(
                  fileKind,
                  instance,
                  FieldNames.URL
                );

                const name = getInstanceFieldValueByName(
                  fileKind,
                  instance,
                  FieldNames.NAME
                );

                return {
                  ...n,
                  knowledgeGraphNode: {
                    ...omit(n.knowledgeGraphNode, 'instance'),
                    innerAnnotation: {
                      name,
                      url
                    }
                  }
                };
              })
          );
        } else {
          return [];
        }
      } catch (ex) {
        return Promise.reject(
          `Assistant API Error in 'getNodes': ${ex.message}`
        );
      }
    },
    /**
     * Adds a node to the active graph for a Kind or Function.
     *
     * @param {string} type Either Kind or Function.
     * @param {Object} instance The Kind or Function object.
     * This must include a service ID property.
     * @param {boolean} changeSelection Whether selection should be changed to this node.
     */
    addNode: typeOverride
      ? null
      : addGraphNode(
          id,
          workspaceId,
          diagramEngine,
          getGraphDimensions,
          client
        ),
    removeNode: typeOverride ? null : removeGraphNode(id, workspaceId, client),
    updateNodeLayout: async (nodeId, updates) => {
      try {
        if (!isActiveGraph(id, workspaceId, client)) {
          throw Error(INACTIVE_GRAPH_ERROR_MESSAGE);
        }
        const node = setNodeLayoutInformation(nodeId, updates, client);

        if (!isNil(node.x) || !isNil(node.y)) {
          handleUpdateNodePositions([node], workspaceId, client);
        }

        const { collapsed } = updates;
        if (isBoolean(collapsed)) {
          handleUpdateNodeCollapsedState(nodeId, collapsed, client);
        } else if (collapsed) {
          throw Error('collapsed value must be a boolean.');
        }
      } catch (ex) {
        return Promise.reject(
          `Assistant API Error in 'updateNodeLayout': ${ex.message}`
        );
      }
    },
    updateGraphLayout: updates => {
      if (type === GraphType.FUNCTION) {
        return updateFunctionGraphLayout(id, updates, client);
      } else {
        return updateKnowledgeGraphLayout(
          id,
          graphObject,
          updates,
          workspaceId,
          client
        );
      }
    }
  };

  return graphObject;
}

/**
 * Returns a closure that is used to add a node to the specified graph.
 *
 * @param {string} graphId ID of the graph.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {function} Function that will add nodes to the graph.
 */
function addGraphNode(
  graphId,
  workspaceId,
  diagramEngine,
  getGraphDimensions,
  client
) {
  return async (type, instance, changeSelection) => {
    try {
      let nodeId;

      if (type === KN_ANNOTATION) {
        const { name, url, nodeInfo } = instance;
        const res = await createAnnotationInGraphWithNodeInfo(
          workspaceId,
          graphId,
          client,
          name,
          url,
          nodeInfo,
          changeSelection
        );
        nodeId = res.nodeInfo.pgNodeId;
      } else {
        // Validate active graph.
        if (!isActiveGraph(graphId, workspaceId, client)) {
          throw Error(INACTIVE_GRAPH_ERROR_MESSAGE);
        }

        let kindId = instance.kindId;

        // Validate instance scoping.
        if (type === KN_FUNCTION) {
          kindId = await getFunctionKindId(client);
        } else if (type === KN_KIND) {
          kindId = KindUUID;
        }

        nodeId = await addInstanceAsGraphNode(
          graphId,
          workspaceId,
          client,
          type,
          { ...instance, kindId, kindName: type },
          changeSelection,
          getGraphDimensions(),
          diagramEngine
        );
      }

      const node = client.readFragment({
        id: `${KN_PORTAL_GRAPH_NODE}:${nodeId}`,
        fragment: AssistantPortalGraphNodeFragment,
        fragmentName: AssistantPortalGraphNodeFragmentName
      });

      // Make sure we omit nested objects.
      return omitTypename(node);
    } catch (ex) {
      return Promise.reject(`Assistant API Error in 'addNode': ${ex.message}`);
    }
  };
}

/**
 * Returns a closure that is used to remove a node from the specified graph.
 *
 * @param {string} graphId ID of the graph.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {function} Function that will remove nodes from the graph.
 */
function removeGraphNode(graphId, workspaceId, client) {
  return async id => {
    try {
      if (!isActiveGraph(graphId, workspaceId, client)) {
        throw Error(INACTIVE_GRAPH_ERROR_MESSAGE);
      }
      await removeNodesFromPortalGraph(workspaceId, client, [{ id }]);
    } catch (ex) {
      return Promise.reject(
        `Assistant API Error in 'removeNode': ${ex.message}`
      );
    }
  };
}

/**
 * Updates the graph layout information.
 *
 * @param {string} graphId ID of the graph to update.
 * @param {Object} updated The updated layout information for the graph.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Object} The graph layout information with the updates merged.
 */
function setGraphLayoutInformation(graphId, updated, client) {
  const { zoom, offsetX, offsetY } = updated;
  const graph = client.readFragment({
    id: `${KN_PORTAL_GRAPH}:${graphId}`,
    fragment: AssistantGraphLayoutFragment
  });

  if (!graph) return null;

  // Since all of these values can possibly evaluate to falsey under normal
  // conditions, or could be out of valid range, do check to ensure they are
  // valid.
  if (Number.isFinite(zoom) && zoom > 0) {
    graph.zoom = zoom;
  } else if (!isNil(zoom)) {
    throw Error('zoom value must be a positive number.');
  }

  if (Number.isFinite(offsetX)) {
    graph.offsetX = offsetX;
  } else if (!isNil(offsetX)) {
    throw Error('offsetX value must be a finite number.');
  }

  if (Number.isFinite(offsetY)) {
    graph.offsetY = offsetY;
  } else if (!isNil(offsetY)) {
    throw Error('offsetY value must be a finite number.');
  }

  return omitTypename(graph);
}

/**
 * Updates the node layout information.
 *
 * @param {string} nodeId ID of the node to update.
 * @param {Object} updates The updated layout information
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Object} The node with the updated layout information merged.
 */
function setNodeLayoutInformation(nodeId, updates, client) {
  const { x, y, collapsed } = updates;
  const node = client.readFragment({
    id: `${KN_PORTAL_GRAPH_NODE}:${nodeId}`,
    fragment: AssistantNodeLayoutFragment
  });

  if (!node) return null;

  // Since all of these values can possibly evaluate to falsey under normal
  // conditions, or could be out of valid range, do check to ensure they are
  // valid.
  if (Number.isFinite(x)) {
    node.x = x;
  } else if (!isNil(x)) {
    throw Error('x value must be a finite number.');
  }

  if (Number.isFinite(y)) {
    node.y = y;
  } else if (!isNil(y)) {
    throw Error('y value must be a finite number.');
  }

  if (isBoolean(collapsed)) {
    node.collapsed = collapsed;
  } else if (!isNil(collapsed)) {
    throw Error('collapsed value must be a boolean.');
  }

  return omitTypename(node);
}

/**
 * Updates the layout information for a function graph.
 *
 * @param {string} graphId The id of the graph to update.
 * @param {Object} updates The updated layout information.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise} A promise that will throw on any errors.
 */
async function updateFunctionGraphLayout(graphId, updates, client) {
  try {
    const graphClone = setGraphLayoutInformation(graphId, updates, client);
    const nodes = updates.nodes.map(n =>
      setNodeLayoutInformation(n.id, n, client)
    );

    // Use a transaction to prevent queries from picking up changes until all
    // updates are saved
    client.cache.performTransaction(transactionClient => {
      // Save the changes into the cache
      const updatedGraph = { ...graphClone, __typename: KN_PORTAL_GRAPH };
      transactionClient.writeFragment({
        id: `${KN_PORTAL_GRAPH}:${updatedGraph.id}`,
        fragment: AssistantGraphLayoutFragment,
        data: updatedGraph
      });

      const updatedNodeLayouts = nodes.map(nl => ({
        ...nl,
        __typename: KN_PORTAL_GRAPH_NODE
      }));

      updatedNodeLayouts.forEach(nl => {
        transactionClient.writeFragment({
          id: `${KN_PORTAL_GRAPH_NODE}:${nl.id}`,
          fragment: AssistantNodeLayoutFragment,
          data: nl
        });
      });
    });

    // Broadcast the changes
    client.queryManager.broadcastQueries();

    // Persist the changes
    await client.mutate({
      mutation: UpdateGraphLayoutMutation,
      variables: { input: { graphLayouts: graphClone, nodeLayouts: nodes } }
    });
  } catch (ex) {
    return Promise.reject(
      `Assistant API Error in 'updateGraphLayout': ${ex.message}`
    );
  }
}

/**
 * Updates the layout information for a knowledge graph.
 *
 * @param {string} graphId ID of the graph to update.
 * @param {Object} graphObject The assistant api graph object for the graph to update.
 * @param {Object} updates The updated layout information for the graph
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Promise} A promise that will throw on any errors.
 */
async function updateKnowledgeGraphLayout(
  graphId,
  graphObject,
  updates,
  workspaceId,
  client
) {
  try {
    if (!isActiveGraph(graphId, workspaceId, client)) {
      throw Error(INACTIVE_GRAPH_ERROR_MESSAGE);
    }
    // Clone graph.
    const graphClone = setGraphLayoutInformation(graphId, updates, client);

    if (
      !isNil(graphClone.offsetY) ||
      !isNil(graphClone.offsetX) ||
      !isNil(graphClone.zoom)
    ) {
      handleUpdateGraphLayout(graphClone, workspaceId, client);
    }

    if (!isEmpty(updates.nodes)) {
      await Promise.all(
        updates.nodes.map(node => graphObject.updateNodeLayout(node.id, node))
      );
    }
  } catch (ex) {
    return Promise.reject(
      `Assistant API Error in 'updateGraphLayout': ${ex.message}`
    );
  }
}

/**
 * Creates a new knowledge graph in the current workspace.
 *
 * @param {AddPortalGraphInput} kgInput The data used to create the graph.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Object} The Assistant API graph object for for the created graph.
 */
export async function createKnowledgeGraph(
  kgInput,
  workspaceId,
  diagramEngine,
  getGraphDimensions,
  client
) {
  try {
    return getGraphObject(
      await addPortalGraph({
        workspaceId: workspaceId,
        graph: {
          ...kgInput,
          type: GraphType.KNOWLEDGE,
          workspaceId: workspaceId
        },
        client: client
      }),
      workspaceId,
      diagramEngine,
      getGraphDimensions,
      client
    );
  } catch (ex) {
    return Promise.reject(
      new Error(`Assistant API error in createKnowledgeGraph: ${ex.message}`)
    );
  }
}

/**
 * Creates a list of new knowledge graphs in the current workspace.
 *
 * @param {Array<AddPortalGraphInput>} kgInputs The list of graphs to create.
 * @param {string} workspaceId The ID of the Workspace to work in.
 * @param {DiagramEngine} diagramEngine The diagram engine currently in use.
 * @param {function} getGraphDimensions A function to get the current dimensions of the graph.
 * @param {ApolloClient} client The client used to access the Apollo.
 * @return {Object} The Assistant API graph objects for for the created graphs.
 */
export async function createKnowledgeGraphs(
  kgInputs,
  workspaceId,
  diagramEngine,
  getGraphDimensions,
  client
) {
  const errors = [];
  const kgs = [];
  for (const kgi of kgInputs) {
    try {
      kgs.push(
        await createKnowledgeGraph(
          kgi,
          workspaceId,
          diagramEngine,
          getGraphDimensions,
          client
        )
      );
    } catch (ex) {
      errors.push(ex);
    }
  }

  if (errors.length) {
    return Promise.reject(errors);
  } else {
    return kgs;
  }
}
