import { SnackNotificationQuery, handleSnackClose } from 'util/snackUtils';

import { Query } from 'react-apollo';
import React from 'react';
import Snack from 'components/Snack';
import { SnackType } from 'util/snackUtils';

class Notifications extends React.Component {
  render() {
    return (
      <Query query={SnackNotificationQuery}>
        {({ data, client }) => {
          // Note: This is a client query that reads from cache, so no loading or error state checks
          if (data && data.snackNotification) {
            const notificationState = data.snackNotification.notificationState;

            const message = notificationState
              ? notificationState.message
              : null;

            const snackType = notificationState
              ? notificationState.snackType
              : SnackType.INFO;

            const error = notificationState
              ? notificationState.error || null
              : null;

            const text =
              message && error
                ? `${message}: ${error.message}`
                : message
                ? message
                : '';

            const autoHideDuration = notificationState
              ? notificationState.autoHideDuration || null
              : null;

            const action = notificationState
              ? notificationState.action || null
              : null;

            const ClickAwayListenerProps = notificationState
              ? notificationState.ClickAwayListenerProps || null
              : null;

            return (
              <Snack
                open={!!notificationState}
                onSnackClose={() => handleSnackClose(client)}
                text={text}
                snackType={snackType}
                action={action}
                autoHideDuration={autoHideDuration}
                ClickAwayListenerProps={ClickAwayListenerProps}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default Notifications;
