// In production, we register a service worker to serve assets from local cache.

import Logger from 'util/Logger';
import React from 'react';

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const hourInMillis = 1000 * 60 * 60;

/**
 * This component wraps the logic for creating a service worker that caches the app.
 * If it detects a new version of the application, a Snack will be displayed
 * asking the user to reload the page.
 */
class RegisterServiceWorker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalId: null
    };

    this.register();
  }

  register() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const swUrl = '/sw.js';
      if (isLocalhost) {
        // This is running on localhost. Lets check if a service worker still exists or not.
        this.checkValidServiceWorker(swUrl);
      } else {
        // Is not local host. Just register service worker
        this.registerValidSW(swUrl);
      }
    }
  }

  registerValidSW(swUrl) {
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        // Track updates to the Service Worker.
        if (!navigator.serviceWorker.controller) {
          // The window client isn't currently controlled so it's a new service
          // worker that will activate immediately
          return;
        }

        // When the user asks to refresh the UI, we'll need to reload the window
        var preventDevToolsReloadLoop;
        navigator.serviceWorker.addEventListener('controllerchange', function(
          event
        ) {
          // Ensure refresh is only called once.
          // This works around a bug in "force update on reload".
          if (preventDevToolsReloadLoop) return;
          preventDevToolsReloadLoop = TextTrackCue;
          window.location.reload();
        });

        this.onNewServiceWorker(registration, () => {
          // Auto refresh when a new service worker is detected.
          // This will cause a reload of the page but since updates to Q happen
          // infrequently and are tightly controlled this should be a minor
          // impact on user experience.
          this.autoReload(registration);
        });

        // set up interval to check for updates to the service worker every hour
        const intervalId = setInterval(function() {
          registration.update().catch(() => {});
        }, hourInMillis);

        this.setState({ registration, intervalId });
      })
      .catch(error => {
        Logger.error('Error during service worker registration:', error);
      });
  }

  checkValidServiceWorker(swUrl) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
      .then(response => {
        // Ensure service worker exists, and that we really are getting a JS file.
        if (
          response.status === 404 ||
          response.headers.get('content-type').indexOf('javascript') === -1
        ) {
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          this.registerValidSW(swUrl);
        }
      })
      .catch(() => {
        Logger.info(
          'No internet connection found. App is running in offline mode.'
        );
      });
  }

  unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
      });
    }
  }

  onNewServiceWorker(registration, callback) {
    if (registration.waiting) {
      // SW is waiting to activate. Can occur if multiple clients open and
      // one of the clients is refreshed.
      return callback();
    }

    function listenInstalledStateChange() {
      registration.installing.addEventListener('statechange', function(event) {
        if (event.target.state === 'installed') {
          // A new service worker is available, inform the user
          callback();
        }
      });
    }

    if (registration.installing) {
      return listenInstalledStateChange();
    }

    // We are currently controlled so a new SW may be found...
    // Add a listener in case a new SW is found,
    registration.addEventListener('updatefound', listenInstalledStateChange);
  }

  autoReload(registration) {
    if (!registration.waiting) {
      // Just to ensure registration.waiting is available before
      // calling postMessage()
      return;
    }

    registration.waiting.postMessage('skipWaiting');
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    return null;
  }
}

export default RegisterServiceWorker;
