// Events that can be triggered on assistants based on things happening with
// the UI or another assistant.
export const AssistantEvents = Object.freeze({
  RENDER_MODE_CHANGED: 'renderModeChanged',
  REPAIR: 'repair',
  SELECTION_CHANGED: 'selectionChanged',
  FUNCTION_EXECUTED: 'functionExecuted',
  INVENTORY_CHANGED: 'inventoryChanged',
  LOCKING_CHANGED: 'lockingChanged'
});
