import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React, { Suspense, lazy, useCallback, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import { CenteredSpinner } from 'components/Spinner';
import { Error } from 'components/Error';
import { FEEDBACK_MAX_LENGTH } from './shared';
import PropTypes from 'prop-types';
import { SpinnerButton } from 'components';
import gql from 'graphql-tag';
import { showMessageSnack } from 'util/snackUtils';
import styled from 'styled-components';

const StyledDialogContent = styled(DialogContent)`
  min-width: 855px;
  min-height: 495px;
`;

// Code split on components with seldom used components.
const FeedbackDialogBody = lazy(() => import('./FeedbackDialogBody'));

const SubmitFeedbackMutation = gql`
  mutation SubmitFeedback(
    $feedback: String!
    $attachments: [AttachmentInput!]!
  ) {
    submitFeedback(feedback: $feedback, attachments: $attachments)
  }
`;

export function FeedbackDialog({ open, onClose }) {
  const [feedback, setFeedback] = useState('');
  const [attachments, setAttachments] = useState([]);

  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);

  const client = useApolloClient();
  const [submitFeedback] = useMutation(SubmitFeedbackMutation);

  const clearForm = useCallback(() => {
    setError(null);
    setFeedback('');
    setAttachments([]);
  }, [setError, setFeedback, setAttachments]);

  const handleClose = useCallback(() => {
    clearForm();
    onClose();
  }, [clearForm, onClose]);

  const changeFeedback = useCallback(
    html => {
      setFeedback(html);
    },
    [setFeedback]
  );

  const changeAttachments = useCallback(
    files => {
      setAttachments(files);
    },
    [setAttachments]
  );

  const sendFeedback = useCallback(() => {
    let isSubscribed = true;

    if (!pending) {
      setPending(true);
      setError(null);

      submitFeedback({
        variables: {
          feedback,
          attachments
        }
      })
        .then(({ data }) => {
          if (isSubscribed) {
            if (data.submitFeedback) {
              clearForm();
              showMessageSnack('Feedback submitted.', client);
              onClose();
            } else {
              setError('Feedback not submitted.');
            }
          }
        })
        .catch(error => {
          isSubscribed && setError(error.message);
        })
        .finally(() => isSubscribed && setPending(false));

      isSubscribed && setPending(false);
    }

    return () => (isSubscribed = false);
  }, [
    pending,
    setPending,
    setError,
    feedback,
    attachments,
    submitFeedback,
    clearForm,
    onClose,
    client
  ]);

  const isTooLong = feedback?.length > FEEDBACK_MAX_LENGTH;

  return (
    <Dialog
      open={open}
      scroll="paper"
      maxWidth="xl"
      onClick={event => {
        // Prevent clicks from going through to whatever opened the dialog
        event.stopPropagation();
      }}
    >
      <DialogTitle>Send Feedback on Maana Q</DialogTitle>
      <StyledDialogContent>
        {error && (
          <Error message={error} location="Customer Feedback" error={error} />
        )}
        <Suspense fallback={<CenteredSpinner />}>
          <FeedbackDialogBody
            feedbackPlaceholder="Enter product feedback here."
            feedback={feedback}
            onFeedbackChange={changeFeedback}
            attachments={attachments}
            onAttachmentsChange={changeAttachments}
          />
        </Suspense>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" disabled={pending}>
          Cancel
        </Button>
        <SpinnerButton
          onClick={sendFeedback}
          variant="contained"
          color="primary"
          disabled={pending || !feedback || isTooLong}
          pending={pending}
        >
          Send Feedback
        </SpinnerButton>
      </DialogActions>
    </Dialog>
  );
}

FeedbackDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};
