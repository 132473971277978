import { isEmpty, isEqual } from 'lodash';

import { arrayToMap } from 'util/helpers';
import { omitTypename } from './omitTypename';

/**
 * Takes two arrays of kinds, functions, or services from
 * an inventory list and computes an add, delete, or update diff between the two.
 *
 * @param {Array} oldArr The old state array.
 * @param {Array} newArr The new state array.
 * @return {Object} An object containing 'adds','deletes',and 'updates'
 * properties, each will either be undefined or have an array.
 */
const objectArrayDiffById = (oldArr = [], newArr = []) => {
  // Sanitize both arrays now since comparisons need to be made
  // and new and old array values will be used in return object.

  // Map old inventory.
  const oldState = arrayToMap(oldArr);

  const adds = [];
  const updates = [];

  // Compute adds and updates.
  newArr.forEach(o => {
    const newObj = omitTypename(o);
    if (!oldState[o.id]) {
      adds.push(o);
    } else {
      // Deep diff.
      const oldObj = omitTypename(oldState[o.id]);

      const updateDiff = !isEqual(newObj, oldObj);

      if (updateDiff) {
        updates.push(newObj);
      }

      // Delete (only deleted will remain in the end)
      delete oldState[o.id];
    }
  });

  // Handle deletes.
  const deletes = Object.values(oldState);

  const fullDiff = {};
  if (!isEmpty(adds)) fullDiff.adds = adds;
  if (!isEmpty(updates)) fullDiff.updates = updates;
  if (!isEmpty(deletes)) fullDiff.deletes = deletes;

  if (!isEmpty(fullDiff)) {
    return fullDiff;
  } else {
    return undefined;
  }
};

/**
 * Computes diff on kinds/functions/servies in inventory.
 *
 * @param {Object} oldInv An old inventory object.
 * @param {Object} newInv A new inventory object.
 * @return {Object} An array containing kinds/function/services properties
 * and their respective diffs.
 */
export const inventoryDiff = (oldInv, newInv) => {
  const {
    kinds: oldKinds,
    functions: oldFunctions,
    services: oldServices
  } = oldInv;
  const {
    kinds: newKinds,
    functions: newFunctions,
    services: newServices
  } = newInv;

  // These will either be assigned a diff object or undefined.

  const diff = {};

  const kinds = objectArrayDiffById(oldKinds, newKinds);
  const functions = objectArrayDiffById(oldFunctions, newFunctions);
  const services = objectArrayDiffById(oldServices, newServices);

  // Only give the object the key if it has a diff.
  // This allows for valid empty check in calling functions.
  if (kinds) diff.kinds = kinds;
  if (functions) diff.functions = functions;
  if (services) diff.services = services;

  return diff;
};
