import {
  KN_FUNCTION,
  KN_INSTANCE,
  KN_INSTANCE_REF,
  KN_KIND,
  KindUUID
} from './defines';
import { buildInstanceRefId, getFunctionKindId } from './helpers';

/**
 * Helpers used to create full data objects to
 */
import _ from 'lodash';

/**
 * Creates an Instance Ref for saving to the Apollo Store with all the fields
 * not passed in set to 'null', and you can request a subset of fields.
 *
 * @param {Object} input that is the important data for this object
 * @param {Array<string>} pick these fields from the final object and return them, if not ser returns entire object
 * @return {Object} a safe object to save to the Apollo Store with the requested fields
 */
export function buildInstanceRefForStore(input, pick) {
  let data = {
    id: null,
    kindId: null,
    kindName: null,
    name: null,
    url: null,
    kind: null,
    instance: null,
    innerKind: null,
    innerFunction: null,
    ...input
  };

  if (pick) return _.pick(data, pick);

  return data;
}

/**
 * Creates an Instance Ref for Kind that can then be saved to the Apollo Store.
 *
 * @param {string} id The ID of the Kind
 * @param {string} workspaceId The ID of the Workspace this belongs to
 * @return {Object} an InstanceRef object to save to the Apollo Store
 */
export function buildKindInstanceRefForStore(id, workspaceId) {
  return Object.assign(buildInstanceRef(id, KindUUID, KN_KIND, workspaceId), {
    innerKind: {
      id,
      __typename: KN_KIND
    }
  });
}

/**
 * Creates an Instance Ref for Function that can then be saved to the Apollo Store.
 *
 * @param {string} id The ID of the Function
 * @param {string} workspaceId The ID of the Workspace this belongs to
 * @param {Object} client The Apollo client
 * @return {Object} an InstanceRef object to save to the Apollo Store
 */
export function buildFunctionInstanceRefForStore(id, workspaceId, client) {
  const functionKindId = getFunctionKindId(client);
  return Object.assign(
    buildInstanceRef(id, functionKindId, KN_FUNCTION, workspaceId),
    {
      innerFunction: {
        id,
        __typename: KN_FUNCTION
      }
    }
  );
}

/**
 * Creates an Instance Ref for an Instance that can then be saved to the Apollo Store.
 *
 * @param {string} id The ID of the Instance
 * @param {string} kindId The ID of the Instance's Kind
 * @param {string} kindName The name of the Instance's Kind
 * @param {string} workspaceId The ID of the Workspace this belongs to
 * @return {Object} an InstanceRef object to save to the Apollo Store
 */
export function buildInstanceRef(id, kindId, kindName, workspaceId) {
  return {
    id: buildInstanceRefId(workspaceId, id),
    kindId,
    kindName,
    innerKind: null,
    innerFunction: null,
    instance: {
      id,
      kindId,
      __typename: KN_INSTANCE
    },
    kind: {
      id: kindId,
      __typename: KN_KIND
    },
    __typename: KN_INSTANCE_REF
  };
}
