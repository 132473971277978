import Icon from 'components/Icon';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { useDragLayer } from 'react-dnd';

const Root = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ItemWrapper = styled.div.attrs(({ x, y }) => ({
  style: {
    transform: `translate(${x}px, ${y}px)`,
    display: !x || !y ? 'none' : 'inherit'
  }
}))`
  position: absolute;
  z-index: 9999;
  opacity: 0.5;
  background: ${p => p.theme.palette.background.default};
  padding-left: ${p => p.theme.spacing()}px;
  padding-right: ${p => p.theme.spacing(2)}px;
  border-top-right-radius: ${p => p.theme.spacing(2)}px;
  border-bottom-right-radius: ${p => p.theme.spacing(2)}px;
`;

const TitleText = styled(Typography)`
  display: inline-block;
  min-width: 250px;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

/**
 * Custom drag layer so we can better customize the drag preview.
 */
export function CustomDragLayer(props) {
  const { isDragging, item, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging()
  }));
  if (!isDragging) {
    return null;
  }

  const { x, y } = currentOffset || {};
  const title = item ? item.title : null;
  const iconName = item ? item.iconName : null;

  // Require a title to show anything. Otherwise, things that use the regular
  // snapshot preview (explorer panels) would show their normal preview and this one.
  return (
    <Root>
      {title && (
        <ItemWrapper x={x} y={y}>
          {iconName && <Icon kindName={iconName} />}
          <TitleText title={title}>{title}</TitleText>
        </ItemWrapper>
      )}
    </Root>
  );
}
