import { useCallback, useState } from 'react';

/**
 * Custom hook to handle a dialog with a single close button.
 *
 * @param {Object} dialogSettings Object containing settings for the dialog.
 * @param {string} dialogSettings.title The text to display in the title of the dialog.
 * @param {any} dialogSettings.body What is displayed in the body of the dialog.
 * @param {Array<Object>} dialogSettings.buttons Array of additional buttons to display
 * to the left of the close button.
 * @returns {Array} The first value is the dialog state to pass into
 * the dialog component and the second is a function to call to open the dialog.
 */
export function useCloseDialog({ title, body, buttons }) {
  const onCloseDialog = useCallback(() => {
    setDialogState(prevState => ({ ...prevState, showDialog: false }));
  }, []);

  const [dialogState, setDialogState] = useState({
    showDialog: false,
    title,
    body,
    buttons: [
      ...(buttons ?? []),
      {
        label: 'Close',
        onClick: onCloseDialog,
        variant: buttons ? 'contained' : 'outlined'
      }
    ]
  });

  const onOpenDialog = useCallback(
    event => {
      event && event.stopPropagation();
      setDialogState(prevState => ({
        ...prevState,
        title,
        body,
        showDialog: true
      }));
    },
    [title, body]
  );

  return [dialogState, onOpenDialog, onCloseDialog];
}
