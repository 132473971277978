import { WorkspaceContext } from 'components/context';
import { getActiveGraphId } from 'util/graph/portalGraph';
import gql from 'graphql-tag';
import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

const WorkspaceActiveGraphPathQuery = gql`
  query WorkspaceActiveGraphPath($id: ID!) {
    workspace(id: $id) {
      id
      activeGraphPath
    }
  }
`;

/**
 * Custom hook to get the active graph ID.
 *
 * @returns {string} The ID for the active graph
 */
export function useActiveGraphId() {
  const { workspaceId } = useContext(WorkspaceContext);
  const { data } = useQuery(WorkspaceActiveGraphPathQuery, {
    variables: { id: workspaceId }
  });

  const workspace = data?.workspace;
  return workspace && getActiveGraphId(workspace);
}
