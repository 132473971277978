import {
  KN_FUNCTION,
  KN_FUNCTION_ARGUMENTS,
  KN_FUNCTION_OUTPUT,
  KN_KIND,
  KN_OPERATION
} from 'util/defines';
import {
  addKindsAndFunctionsToServices,
  removeKindsAndFunctionsFromServices
} from './service';
import {
  addToWorkspaceInventories,
  isBoilerplate,
  removeInstanceRefsFromWorkspaces,
  removeKindsAndFunctionsFromWorkspaceInventories
} from 'util/workspace';
import {
  cacheAddedPortalGraphs,
  removeNodesFromPortalGraphs
} from './graph/portalGraph';
import { groupBy, omit, uniq } from 'lodash';
import {
  removeArgumentValuesFromOperations,
  removeOperationsFromImplementations
} from './function';

const FunctionNodeKindNames = [
  KN_FUNCTION,
  KN_OPERATION,
  KN_FUNCTION_ARGUMENTS,
  KN_FUNCTION_OUTPUT
];

const FIELDS_TO_EXCLUDE = [
  'kind',
  '__typename',
  'displayType',
  'isRequired',
  'isList',
  'isEphemeral',
  'connectionIn',
  'connectionOut',
  'isDirty',
  'isNew',
  'isMarkedDeleted',
  'error',
  'isReservedField'
];

/**
 * Make sure nodes have a way to check if its entity is editable
 *
 * @param {string} modelServiceId The ID of the model service for the workspace
 * @param {string} logicServiceId The ID of the logic service for the workspace
 * @param {Object} node Data from a node model, not an actual NodeModel class
 * @param {Object} node.entityId ID of the entity
 * @param {Object} node.serviceId ID of the service the entity belongs to
 * @param {Object} node.nodeKindName Name of the Kind the entity is an instance of
 * @param {Object} node.client ApolloClient instance
 */
export function isEntityEditable({ modelServiceId, logicServiceId, node }) {
  const { entityId, serviceId, nodeKindName, client } = node;

  const isKind = nodeKindName === KN_KIND;
  const isFunction = FunctionNodeKindNames.includes(nodeKindName);

  if (isKind && serviceId !== modelServiceId) {
    return false;
  }

  if (isFunction && serviceId !== logicServiceId) {
    return false;
  }

  // Make sure you are editing a kind or a function node only
  if (
    (!isKind && !isFunction) ||
    isBoilerplate(entityId, nodeKindName, client)
  ) {
    return false;
  }

  return true;
}

/**
 * Nodes need to check if their entitiy's name is editable
 *
 * @param {string} modelServiceId The ID of the model service for the workspace
 * @param {string} logicServiceId The ID of the logic service for the workspace
 * @param {Object} node Data from a node model, not an actual NodeModel class
 * @param {Object} node.entityId ID of the entity
 * @param {Object} node.serviceId ID of the service the entity belongs to
 * @param {Object} node.nodeKindName Name of the Kind the entity is an instance of
 * @param {Object} node.client ApolloClient instance
 */
export function isEntityNameEditable({ modelServiceId, logicServiceId, node }) {
  const { nodeKindName } = node;
  const isFunctionArgs =
    nodeKindName === KN_FUNCTION_ARGUMENTS ||
    nodeKindName === KN_FUNCTION_OUTPUT;

  return isFunctionArgs
    ? false
    : isEntityEditable({
        modelServiceId,
        logicServiceId,
        node
      });
}

/**
 * Calculates the typeKindId for mutation input
 *
 * @param {Object} output Field or output
 */
export function getTypeKindId(field) {
  return (field.kind && field.kind.id) || null;
}

/**
 * Transforms output into the shape needed for update mutations.
 * Removes properties the backend doesn't understand.
 * Ensures the type and typeKindId are set appropriately.
 *
 * @param {Object} output Field representing function output
 */
export function prepareOutputForMutation(output) {
  return {
    outputType: output.type,
    outputKindId: getTypeKindId(output),
    outputModifiers: output.modifiers
  };
}

/**
 * Transforms schema into the shape needed for update mutations.
 * Removes properties the backend doesn't understand.
 * Ensures the type and typeKindId are set appropriately.
 *
 * @param {Array} schema List of fields to be updated
 */
export function prepareSchemaForMutation(schema) {
  return schema.reduce((acc, field) => {
    if (!field.isMarkedDeleted) {
      const typeKindId = getTypeKindId(field);
      acc.push(omit({ ...field, typeKindId }, FIELDS_TO_EXCLUDE));
    }
    return acc;
  }, []);
}

/**
 * Removes references to deleted entities from the cache.
 *
 * @param {Object} deleteEntitiesOutput The DeleteEntityOutput object returned from
 * the deletion.
 * @param {Array<Object>} deleteEntitiesOutput.workspaceServiceReferences  A list of objects
 * where the serviceId field has the ID of the Workspace service and the workspaceIds
 * field has an array of Workspace IDs that reference the Workspace service.
 * @param {Array<Object>} deleteEntitiesOutput.updatedWorkspaces The Array of Workspaces
 * that had instance refs deleted as a result of deleting the entities.
 * @param {Array<Object>} deleteEntitiesOutput.updatedPortalGraphs The Array of Portal Graphs
 * that had nodes deleted as a result of deleting the entities.
 * @param {Array<Object>} deleteEntitiesOutput.updatedImplementations The Array of Implementations
 * that had Operations deleted as a result of deleting the entities.
 * @param {Array<Object>} deleteEntitiesOutput.updatedOperations The Array of Operations
 * that had Argument Values deleted as a result of deleting the entities.
 * @param {Array<Object>} deleteEntitiesOutput.updatedServices The Array of services
 * that had Entities deleted from them.
 * @param {Array<Object>} deleteEntitiesOutput.workspaces The Array of Workspaces
 * the deleted entities belonged to.
 * @param {Array<string>} deleteEntitiesOutput.deletedBoilerplateKindIds The Array of IDs
 * of boilerplate Kinds that were deleted as a result of deleting the entities.
 * @param {Array<string>} deleteEntitiesOutput.deletedBoilerplateFunctionIds The Array of IDs
 * of boilerplate Functions that were deleted as a result of deleting the entities.
 * @param {Array<string>} deleteEntitiesOutput.deletedArgumentValueIds The Array of IDs
 * of Argument Values that were deleted as a result of deleting the entities.
 * @param {Array<string>} deleteEntitiesOutput.deletedOperationIds The Array of IDs
 * of Operations that were deleted as a result of deleting the entities.
 * @param {Array<string>} deleteEntitiesOutput.deletedPortalGraphNodeIds The Array of IDs
 * of Portal Graph Nodes that were deleted as a result of deleting the entities.
 * @param {Array<string>} deleteEntitiesOutput.deletedInstanceRefIds The Array of IDs
 * of Instance Refs that were deleted as a result of deleting the entities.
 * @param {Object} store The Apollo store
 * @param {Object} entities The lists of IDs of entities that were deleted.
 * @param {Array<string>} entities.kindIds The list of IDs of Kinds that were deleted.
 * @param {Array<string>} entities.functionIds The list of IDs of Functions that were deleted.
 */
export function removeReferencesToDeletedEntities(
  deleteEntitiesOutput,
  store,
  entities = {}
) {
  const { kindIds = [], functionIds = [] } = entities;
  const {
    workspaceServiceReferences = [],
    updatedWorkspaces = [],
    updatedPortalGraphs = [],
    updatedImplementations = [],
    updatedOperations = [],
    workspaces = [],
    deletedBoilerplateKindIds = [],
    deletedBoilerplateFunctionIds = [],
    deletedArgumentValueIds = [],
    deletedOperationIds = [],
    deletedPortalGraphNodeIds = [],
    deletedInstanceRefIds = [],
    updatedServices = []
  } = deleteEntitiesOutput;

  const updatedServiceIds = updatedServices.map(s => s.id);
  const serviceReferences = uniq(
    workspaceServiceReferences.flatMap(ref => ref.workspaceIds)
  );
  const allDeletedKinds = deletedBoilerplateKindIds.concat(kindIds);
  const allDeletedFunctions = deletedBoilerplateFunctionIds.concat(functionIds);

  // Remove deleted Kinds and Functions from Workspace inventories
  removeKindsAndFunctionsFromWorkspaceInventories(
    serviceReferences.concat(workspaces.map(w => w.id)),
    allDeletedKinds,
    allDeletedFunctions,
    store
  );

  // Remove deleted Kinds and Functions from the Workspace services
  removeKindsAndFunctionsFromServices(
    updatedServiceIds,
    allDeletedKinds,
    allDeletedFunctions,
    store
  );

  removeInstanceRefsFromWorkspaces(
    deletedInstanceRefIds,
    updatedWorkspaces.map(w => w.id),
    store
  );

  removeNodesFromPortalGraphs(
    deletedPortalGraphNodeIds,
    updatedPortalGraphs.map(pg => pg.id),
    store
  );

  removeOperationsFromImplementations(
    deletedOperationIds,
    updatedImplementations.map(impl => impl.id),
    store
  );

  removeArgumentValuesFromOperations(
    deletedArgumentValueIds,
    updatedOperations.map(op => op.id),
    store
  );
}

/**
 * Adds references to created entities into the cache.
 *
 * @param {Object} addEntitiesOutput The AddEntitiesOutput object returned from
 * the add.
 * @param {Array<Object>} addEntitiesOutput.workspaceServiceReferences A list of objects
 * where the serviceId field has the ID of the Workspace service and the workspaceIds
 * field has an array of Workspace IDs that reference the Workspace service.
 * @param {Array<Object>} addEntitiesOutput.newKinds The Array of Kinds
 * that were created as a result of the adding the entities.
 * @param {Array<Object>} addEntitiesOutput.newFunctions The Array of Functions
 * that were created as a result of the adding the entities.
 * @param {Array<Object>} addEntitiesOutput.newBoilerplateKinds The Array of boilerplate Kinds
 * that were created as a result of the adding the entities.
 * @param {Array<Object>} addEntitiesOutput.newBoilerplateFunctions The Array of boilerplate Functions
 * that were created as a result of the adding the entities.
 * @param {Array<Object>} addEntitiesOutput.updatedWorkspaces The Array of Workspaces
 * that had entities added to them as a result of adding the entities.
 * @param {Object} store The Apollo store
 */
export function addReferencesToCreatedEntities(addEntitiesOutput, store) {
  const {
    workspaceServiceReferences = [],
    newKinds = [],
    newFunctions = [],
    newBoilerplateKinds = [],
    newBoilerplateFunctions = [],
    updatedWorkspaces = []
  } = addEntitiesOutput;

  const newKindsByService = groupBy(
    newKinds.concat(newBoilerplateKinds),
    kind => kind.serviceId ?? kind.service.id
  );

  const newFunctionsByService = groupBy(newFunctions, func => func.service.id);

  const newBoilerplateFunctionsByService = groupBy(
    newBoilerplateFunctions,
    func => func.service.id
  );

  for (const updatedWorkspace of updatedWorkspaces) {
    const newKindIds =
      newKindsByService[updatedWorkspace.modelServiceId]?.map(k => k.id) ?? [];
    const newFunctionIds =
      newFunctionsByService[updatedWorkspace.logicServiceId]?.map(f => f.id) ??
      [];
    const newBoilerplateFunctionIds =
      newBoilerplateFunctionsByService[updatedWorkspace.modelServiceId]?.map(
        bf => bf.id
      ) ?? [];
    const newFunctionsAndBoilerplateIds = newFunctionIds.concat(
      newBoilerplateFunctionIds
    );
    const serviceReferences =
      workspaceServiceReferences.find(
        ref => ref.serviceId === updatedWorkspace.workspaceServiceId
      )?.workspaceIds ?? [];

    addToWorkspaceInventories(
      serviceReferences.concat([updatedWorkspace.id]),
      store,
      {
        serviceKindIds: newKindIds,
        serviceFunctionIds: newFunctionsAndBoilerplateIds
      }
    );

    addKindsAndFunctionsToServices(
      [updatedWorkspace.workspaceServiceId],
      newKindIds,
      newFunctionsAndBoilerplateIds,
      store
    );

    addKindsAndFunctionsToServices(
      [updatedWorkspace.modelServiceId],
      newKindIds,
      newBoilerplateFunctionIds,
      store
    );

    addKindsAndFunctionsToServices(
      [updatedWorkspace.logicServiceId],
      null,
      newFunctionIds,
      store
    );

    cacheAddedPortalGraphs(store, updatedWorkspace.id, newFunctionIds);
  }
}
