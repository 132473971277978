import { KN_WORKSPACE_LAYOUT } from './defines';
import { WorkspaceLayoutFragment } from 'graphql/Portal';
import { debounce } from 'lodash';
import gql from 'graphql-tag';
import { handleError } from './snackUtils';

export const WorkspaceLayoutQuery = gql`
  query WorkspaceLayout($id: ID!) {
    workspace(id: $id) {
      id
      layout {
        ...WorkspaceLayoutDetails
      }
    }
  }
  ${WorkspaceLayoutFragment}
`;

export const ResetWorkspaceLayoutMutation = gql`
  mutation ResetWorkspaceLayout($id: ID!) {
    resetWorkspaceLayout(id: $id)
  }
`;

export const UpdateWorkspaceLayoutMutation = gql`
  mutation UpdateWorkspaceLayout($input: UpdateWorkspaceLayoutInput!) {
    updateWorkspaceLayout(input: $input)
  }
`;

const WorkspaceLayoutExplorerOpenFragment = gql`
  fragment WorkspaceLayoutExplorerOpen on WorkspaceLayout {
    explorerOpen
  }
`;

const WorkspaceLayoutAssistantsOpenFragment = gql`
  fragment WorkspaceLayoutAssistantsOpen on WorkspaceLayout {
    dataVizOpen
  }
`;

const WorkspaceLayoutContextOpenFragment = gql`
  fragment WorkspaceLayoutContextOpen on WorkspaceLayout {
    contextOpen
  }
`;

/**
 * Handles updates to the workspace layout.
 */
class WorkspaceLayout {
  client = null;
  workspaceId = null;
  layoutId = null;
  canEdit = false;
  pendingWorkspaceLayoutUpdate = false;

  /**
   * Sets the apollo client, workspace ID, and workspace layout ID used when
   * updating the workspace layout.
   *
   * @param {ApolloClient} client The client to use to make mutations and check the cache
   * @param {string} workspaceId The ID of the workspace to work with
   * @param {string} layoutId The ID of the workspace layout to work with
   * @param {boolean} canEdit Signifies that the workspace can be edited when True.
   */
  setClientAndIds(client, workspaceId, layoutId, canEdit) {
    this.client = client;
    this.workspaceId = workspaceId;
    this.layoutId = layoutId;
    this.canEdit = canEdit;
  }

  /**
   * Handles the actual work of saving changes to the workspace layout
   *
   * @param {Object} layoutUpdate The updates to make to the layout
   */
  saveWorkspaceLayoutUpdate(layoutUpdate) {
    if (this.canEdit) {
      this.pendingWorkspaceLayoutUpdate = layoutUpdate;

      this.client
        .mutate({
          mutation: UpdateWorkspaceLayoutMutation,
          variables: {
            input: {
              id: this.layoutId,
              ...layoutUpdate
            }
          }
        })
        .catch(error =>
          handleError(this.client, 'Failed to save the Workspace layout', error)
        )
        .then(() => {
          if (
            this.pendingWorkspaceLayoutUpdate &&
            this.pendingWorkspaceLayoutUpdate !== layoutUpdate
          ) {
            this.saveWorkspaceLayoutUpdate(this.pendingWorkspaceLayoutUpdate);
          }

          this.pendingWorkspaceLayoutUpdate = false;
        });
    }

    const storeData = this.client.readQuery({
      query: WorkspaceLayoutQuery,
      variables: {
        id: this.workspaceId
      }
    });
    storeData.workspace.layout = Object.assign(
      storeData.workspace.layout,
      layoutUpdate
    );
    this.client.writeQuery({
      query: WorkspaceLayoutQuery,
      variables: { id: this.workspaceId },
      data: storeData
    });
  }

  /**
   * Updates the workspace layout
   *
   * @param {Object} updateObject The updates to make to the layout
   */
  handleUpdateLayout = updateObject => {
    if (this.pendingWorkspaceLayoutUpdate) {
      Object.assign(this.pendingWorkspaceLayoutUpdate, updateObject);
    } else {
      this.saveWorkspaceLayoutUpdate(updateObject);
    }
  };

  /**
   * Rate limited updating of the workspace layout, so that the server is not
   * spammed with a bunch of rapid fire changes.
   *
   * @param {Object} updateObject The updates to make to the layout
   */
  handleUpdateLayoutDebounced = debounce(this.handleUpdateLayout, 500);

  /**
   * Resets the panel size and visibility in the workspace to default settings.
   */
  handleResetWorkspaceLayout = async () => {
    try {
      await this.client.mutate({
        mutation: ResetWorkspaceLayoutMutation,
        variables: { id: this.layoutId },
        refetchQueries: [
          {
            query: WorkspaceLayoutQuery,
            variables: {
              id: this.workspaceId
            }
          }
        ]
      });
    } catch (error) {
      handleError(this.client, 'Failed to reset the workspace layout', error);
    }
  };

  /**
   * Saves a new width of the explorer panel
   *
   * @param {float} size Thew new width of the panel
   */
  handleResizeExplorer = size => {
    this.handleUpdateLayoutDebounced({ explorerSize: size });
  };

  /**
   * Saves a new width of the context panel
   *
   * @param {float} size Thew new width of the panel
   */
  handleResizeContext = size => {
    this.handleUpdateLayoutDebounced({ contextSize: size });
  };

  /**
   * Saves a new height of the assistants panel
   *
   * @param {float} size Thew new height of the panel
   */
  handleResizeAssistants = size => {
    this.handleUpdateLayoutDebounced({ dataVizSize: size });
  };

  /**
   * Toggles the visibility of the explorer panel.
   */
  handleToggleExplorer = () => {
    const layout = this.getFragmentData(WorkspaceLayoutExplorerOpenFragment);
    this.handleUpdateLayout({
      explorerOpen: !layout.explorerOpen
    });
  };

  /**
   * Toggles the visibility of the assistants panel.
   */
  handleToggleAssistants = () => {
    const layout = this.getFragmentData(WorkspaceLayoutAssistantsOpenFragment);
    this.handleUpdateLayout({
      dataVizOpen: !layout.dataVizOpen
    });
  };

  /**
   * Toggles the visibility of the context panel.
   */
  handleToggleContext = () => {
    const layout = this.getFragmentData(WorkspaceLayoutContextOpenFragment);
    this.handleUpdateLayout({
      contextOpen: !layout.contextOpen
    });
  };

  /**
   * Updates the current page displayed in the context panel.
   *
   * @param {string} mode The mode or page of the context panel
   */
  handleContextModeSelected = mode => {
    const layout = this.getFragmentData(WorkspaceLayoutContextOpenFragment);
    const layoutUpdate = { contextMode: mode };
    if (!layout.contextOpen) {
      layoutUpdate.contextOpen = true;
    }
    this.handleUpdateLayout(layoutUpdate);
  };

  /**
   * Pulls layout data from the cache based on the provided fragment.
   */
  getFragmentData(fragment) {
    return this.client.readFragment({
      id: `${KN_WORKSPACE_LAYOUT}:${this.layoutId}`,
      fragment
    });
  }
}

export default new WorkspaceLayout();
